import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { DispatchApiService } from '@cogent/client/shared/services/api/dispatch-api.service';
import { Note } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { MatButtonModule } from '@angular/material/button';
declare var Camera: any;

class WorkOrderAttachmentModel {
    constructor(public name: string = null, public noteId: string = null, public base64: string = null) { }

    get thumbnailUrl() {
        return `${ApiService.endPointDotNet}note/attachment/${this.noteId}/${this.name}/thumbnail`;
    }

    get downloadUrl() {
        return `${ApiService.endPointDotNet}note/attachment/${this.noteId}/${this.name}`;
    }
}

@Component({
    selector: 'app-upload-photos',
    templateUrl: './upload-photos.component.html',
    styleUrls: ['./upload-photos.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, MatProgressSpinnerModule, CheckCircleAnimatedComponent, MatButtonModule]
})
export class UploadPhotosComponent implements OnInit {

    workOrderId: string;
    attachments: WorkOrderAttachmentModel[] = [];
    saving = false;
    selectedIndex = 0;
    workOrderSummary: WorkOrderSummaryClient;
    hasNativeCamera = false;
    isLegacy = true;

    constructor(private activatedRoute: ActivatedRoute,
        private dispatchApi: DispatchApiService,
        private dialogService: DialogsService,
        private noteApi: NoteApiService,
        private serviceRepository: ServiceRepositoryService) { }

    ngOnInit(): void {

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.workOrderId = params.id;
                this.serviceRepository.getWorkOrderSummaryNoAuth(this.workOrderId).then(summary => this.workOrderSummary = summary);
            }
        });
    }

    showFromLibrary() {
        const nav: any = navigator;
        nav.camera.getPicture(
            imageData => {

                const fileName =
                    UtilitiesService.newid().replace('-', '').substring(0, 9) +
                    '.jpg';
                const attachment = new WorkOrderAttachmentModel(
                    fileName,
                    UtilitiesService.newid(),
                    'data:image/jpeg;base64,' + imageData);
                this.attachments.push(attachment);

            },
            error => {
                if (error !== 'No Image Selected') {
                    this.dialogService.alert('Can\'t Attach', 'Sorry, we could not attach that photo');
                }
            }, {
            quality: 30,
            destinationType: Camera.DestinationType.DATA_URL,
            correctOrientation: true,
            sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
            mediaType: Camera.MediaType.PICTURE,
        });
    }

    showFileUpload() {
        const nav: any = navigator;

        if (nav && nav.camera) {
            nav.camera.getPicture(
                imageData => {

                    const fileName =
                        UtilitiesService.newid().replace('-', '').substring(0, 9) +
                        '.jpg';
                    const attachment = new WorkOrderAttachmentModel(
                        fileName,
                        UtilitiesService.newid(),
                        'data:image/jpeg;base64,' + imageData);
                    this.attachments.push(attachment);

                },
                error => {
                    if (error !== 'No Image Selected') {
                        this.dialogService.alert('Can\'t Attach', 'Sorry, we could not attach that photo');
                    }
                }, {
                quality: 30,
                destinationType: Camera.DestinationType.DATA_URL,
                correctOrientation: true,
                mediaType: Camera.MediaType.CAMERA,
                encodingType: Camera.EncodingType.JPEG
            });
        } else {
            document.getElementById('fileToUpload1').click();
        }
    }

    handleFileUpload(evt) { this.handleFiles(evt); }

    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            const loadIt = (base64, fileName) => {
                const attachment = new WorkOrderAttachmentModel(fileName, null, base64);
                this.attachments.push(attachment);
            };

            reader.onload = (fileName => {
                return function (e) {
                    (this as any).dragTargetActive = false;
                    loadIt(e.target.result, fileName);

                };
            })(file.name);

            reader.readAsDataURL(file);
        }
    }

    get canSave() {
        return !this.saving && this.attachments && this.attachments.length;
    }


    removeAttachment(attachment) {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
    }

    async save() {
        this.saving = true;
        const note = new Note();
        note.policyId = this.workOrderSummary.policyId;
        note.workOrderId = this.workOrderId;
        note.noteText = 'Customer Pictures Attached';
        note.id = UtilitiesService.newid();

        await this.serviceRepository.saveNoteNoAuth(note);
        const promises = [];
        for (const attachment of this.attachments) {
            attachment.noteId = note.id;
            promises.push(this.noteApi.saveNoteAttachment(note.id, attachment as any));
        }

        await Promise.all(promises);


        const files = [];
        for (const attachment of this.attachments) {
            files.push({
                filename: attachment.name,
                base64: attachment.base64.replace('data:image/jpeg;base64,',''),
            });
        }

        //await this.serviceRepository.saveAuthorizationAttachments(note, this.attachments);
        this.saving = false;
        this.selectedIndex = 1;
    }

}
