

import { Component, Input, ElementRef, OnInit, AfterContentInit, ViewChild, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { SingleAddressEntryComponent } from "@cogent/client/shared/components/misc/single-address-entry/single-address-entry.component";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { CommonModule } from '@angular/common';
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip';
declare var google: any;

// interface ExtendedPolygon  {
//     area?: number;
// }

@Component({
    selector: 'app-map-measure',
    templateUrl: './map-measure.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    standalone: true,
    imports: [
        SingleAddressEntryComponent,
        MatButtonModule,
        CommonModule,
        MatTooltipModule,
        MatIconModule,
        DisplayTotalModule
    ],
    styleUrls: ['./map-measure.component.scss']
})
export class MapMeasure implements OnInit, AfterContentInit {

    totalArea = 0;
    @Output() totalAreaChange: EventEmitter<number> = new EventEmitter();
    propertyMeta: PropertyMetaModel
    address: { address1: string; city: any; state: any; country: any; postalCode: string; }

    editablePolygons: any[] = [];
    @ViewChild('mapTooltip') mapTooltip: MatTooltip;
    @ViewChild('drawToolTip') drawToolTip: MatTooltip;
    @ViewChild('saveToolTip') saveToolTip: MatTooltip;
    @ViewChild('panTooltip') panTooltip: MatTooltip;


    // @ViewChild('map') mapElement: any;
    @ViewChild('mapWrapper', { static: false }) mapElement: ElementRef;
    map: any;
    @Input() propertyAddress;
    drawingMode = false

    drawingManager: any;
    geocoder: any;
    customOverlay: any;
    selectedVertex: any | null = null;

    constructor(
        private customerRepository: CustomerRepositoryService,
        public noteApi: NoteApiService,
    ) { }

    ngOnInit(): void {
        this.initMap();

        setTimeout(()=> {
            this.drawingToolOn();
        }, 500);
    }

    ngAfterContentInit(): void {
        this.doIntro();
    }

    async doIntro() {
        // setTimeout(()=> {
        //     this.mapTooltip.show();
        //     setTimeout(()=> {
        //         this.mapTooltip.hide();
        //         this.drawToolTip.show();
        //         setTimeout(()=> {
        //             this.drawToolTip.hide();
        //             this.panTooltip.show();
        //             setTimeout(()=> {
        //                 this.panTooltip.hide();
        //                 this.saveToolTip.show();
        //                 setTimeout(()=> {
        //                     this.saveToolTip.hide();
        //                 }, 4000);
        //             }, 4000);
        //         }, 4000);
        //     }, 4000);
        // }, 1000);
    }

    calculatePolygonArea(polygon: any) {
        const area = google.maps.geometry.spherical.computeArea(polygon.getPath());
        polygon.area = Math.ceil(area * 10.764);
    }

    calculateTotalArea() {
        let totalArea = 0;
        for (const polygon of this.editablePolygons) {
            if (polygon.area) {
                totalArea += polygon.area;
            }
        }
        this.totalArea = Math.ceil(totalArea);
    }

    highlightedPolygonIndex: number

    highlightPolygon(index: number) {
        this.highlightedPolygonIndex = index

        for (const polygon of this.editablePolygons) {
            polygon.setOptions({ fillColor: 'rgba(159,121,121,0.19)', fillOpacity: 0.35 }); // Reset color
        }

        this.editablePolygons[index].setOptions({ fillColor: '#00FF00', fillOpacity: 0.5 });
    }

    deletePolygon(polygon: any) {
        this.editablePolygons.splice(this.editablePolygons.indexOf(polygon), 1);
        this.calculateTotalArea();
        polygon.setMap(null);
    }

    drawingToolOn() {
        this.drawingManager.setOptions({ drawingMode: google.maps.drawing.OverlayType.POLYGON })
        this.drawingMode = true
    }

    drawingToolOff() {
        this.drawingManager.setOptions({ drawingMode: null })
        this.drawingMode = false
    }

    initMap() {
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 19,
            mapTypeId: 'satellite',
            streetViewControl: false,
            tilt: 0,
            mapTypeControl: false,
            clickableIcons: false,
            disableDefaultUI: true,
            zoomControl: true,
        });
        this.geocoder = new google.maps.Geocoder();

        this.initializeDrawingManager()
        // this.toggleDrawingTool()

        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: any) => {
            if (event.type === 'polygon') {
                const newPolygon = event.overlay;

                newPolygon.setEditable(true);

                google.maps.event.addListener(newPolygon.getPath(), 'set_at', () => {
                    this.calculatePolygonArea(newPolygon);
                    this.calculateTotalArea();
                });

                google.maps.event.addListener(newPolygon.getPath(), 'insert_at', () => {
                    this.calculatePolygonArea(newPolygon);
                    this.calculateTotalArea();
                });

                google.maps.event.addListener(newPolygon.getPath(), 'remove_at', () => {
                    this.calculatePolygonArea(newPolygon);
                    this.calculateTotalArea();
                });

                this.calculatePolygonArea(newPolygon);

                this.editablePolygons.push(newPolygon);

                this.calculateTotalArea();
            }
        });

        this.displayAddressOnMap()

    }

    displayAddressOnMap() {
        this.geocoder.geocode({ 'address': `${this.propertyAddress.address1}, ${this.propertyAddress.city}, ${this.propertyAddress.state}, ${this.propertyAddress.country}, ${this.propertyAddress.postalCode}` }, (results, status) => {
            if (status === 'OK') {
                this.map.setCenter(results[0].geometry.location);
                new google.maps.Marker({
                    map: this.map,
                    position: results[0].geometry.location
                });
            } else {
                
            }
        });
    }



    initializeDrawingManager() {
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            }
        });

        this.drawingManager.setOptions({
            drawingControlOptions: {
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
            }
        })

        this.drawingManager.setMap(this.map);

        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: any) => {
            if (event.type === 'polygon') {
                let area = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
                this.totalArea += area
                this.totalArea = Math.ceil(this.totalArea)
            }
        });

    }

    updateSQFT() {
        this.totalAreaChange.emit(this.totalArea);
    }

    async addressCompleteChange(address) {
        this.address = address;
        this.propertyMeta = await this.customerRepository.getPropertyMeta(this.address.address1, this.address.postalCode);
    }
}
