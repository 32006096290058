import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@cogent/client/auth';

@Component({
    selector: 'app-request-service-auto-login',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './request-service-auto-login.component.html',
    styleUrls: ['./request-service-auto-login.component.css']
})
export class RequestServiceQutoLoginComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        private authService: AuthService) {

    }
    ngOnInit(): void {
        this.route.queryParams.subscribe(q => {
            if (q.candp) {
                const split = q.candp.split('_');
                window.localStorage.setItem("redirectTo", "/request-service/" + split[1] + '?itemId=' + split[2]);
                localStorage.removeItem('auth');
                this.authService.logIn(split[0]);
            }
        });
    }
}
