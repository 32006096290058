import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';



@NgModule({
  declarations: [DocumentUploadComponent],
  imports: [
    CommonModule,
    MaterialSharedModule,
  ],
  exports: [DocumentUploadComponent]
})
export class ClosingDocumentUploadModule { }
