
<div class="white-box">
    <h1 bottommargin30>Delete Account</h1>
    <mat-checkbox [(ngModel)]="doDelete">
        I confirm I want to delete my account and login
    </mat-checkbox>

    <div topmargin30 class="center">
        <button mat-button mat-raised-button [disabled]="!doDelete || deleting" color="primary" (click)="deleteAccount()">
            <mat-spinner *ngIf="deleting" class="thirty"></mat-spinner>
            Delete</button>
    </div>
</div>
