import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { AppointmentTimeSlot, GroupedAppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-create-follow-up',
    templateUrl: './create-follow-up.component.html',
    styleUrls: ['./create-follow-up.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        AppointmentSelectionComponent,
        CheckCircleAnimatedComponent,
        MatButtonModule,
        MatProgressSpinnerModule
    ]
})
export class CreateFollowUpComponent implements OnInit {

    workOrderId: string;
    workOrderSummary: WorkOrderSummaryClient;
    timeSlots: GroupedAppointmentTimeSlot[];
    selectedTimeSlots: AppointmentTimeSlot[] = [];
    saving = false;
    selectedIndex = 0;
    isLegacy = true;

    constructor(private activatedRoute: ActivatedRoute,
        private serviceRepository: ServiceRepositoryService,
        private dialogService: DialogsService,
        private serviceApi: ServiceApiService) { }

    async ngOnInit() {
        this.activatedRoute.params.subscribe(async params => {
            if (params.id) {
                this.workOrderId = params.id;

                this.workOrderSummary = await this.serviceApi.getWorkOrderSummaryById(this.workOrderId);
                this.serviceRepository.getAppointmentTimeSlots(this.workOrderSummary.lines[0].itemId).then(timeSlots => {
                    const groupedTimeSlots = GroupedAppointmentTimeSlot.fromAppointmentTimeSlots(timeSlots);
                    this.timeSlots = groupedTimeSlots;
                });
            }
        });
    }

    get firstItem() {
        if (this.workOrderSummary && this.workOrderSummary.lines.length) {
            return this.workOrderSummary.lines[0];
        }
    }

    get canSave() {
        if (this.saving) {
            return false;
        }

        return this.selectedTimeSlots
            && this.selectedTimeSlots.filter(i => i && i.startHour).length >= 3;
    }

    async save() {
        this.saving = true;
        const timeSlots = {
            timeSlots: this.selectedTimeSlots,
        };
        for (const ts of timeSlots.timeSlots) {
            ts.type = 'INSTALL';
        }

        await this.serviceRepository.requestFollowUpAppointment(this.workOrderSummary.id, timeSlots);
        this.saving = false;
        this.selectedIndex = 1;
    }

    appointmentAlreadyScheduled() {
        this.dialogService.confirm('Confirm', 'Just to confirm: <br>The service pro has already scheduled a follow-up appointment with you?').subscribe(async results=> {
            if(results) {
                await this.serviceRepository.stopAllJobPurchaseOrderWorflows(this.workOrderSummary.id);
                this.selectedIndex = 2;
            }
        });
    }
}
