import { Injectable } from '@angular/core';
import { Address, CashOut, CashOutAppliedToInvoice, CashOutSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { AddressApiService } from '@cogent/client/shared/services/api/address-api.service';
import { AcceptCashOutOfferArgs, CashOutOffer, CashOutOfferSummary, CashOutReason } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class CashOutApiService {


    constructor(private api: ApiService, private addressApi: AddressApiService) { }

    acceptCashOutOffer(id: string, selectedCashOutType: string) {
        const args = new AcceptCashOutOfferArgs();
        args.cashOutOfferId = id;
        args.sendMethod = selectedCashOutType;
        return this.api.patchNode(`cash-out/accept-offer`, args);
    }

    async saveCashOut(
        address: Address, cashOut: CashOut, appliedToInvoices: CashOutAppliedToInvoice[],
        itemsToDelete: CashOutAppliedToInvoice[]
    ) {
        await this.addressApi.saveAddress(address);
        await this.api.postIdDotNet(`CashOut`, cashOut);
        const promises = [];

        if (appliedToInvoices) {
            appliedToInvoices.forEach(inv => {
                promises.push(this.api.postIdDotNet('CashOutAppliedToInvoice', inv));
            });
        }
        if (itemsToDelete) {
            itemsToDelete.forEach(inv => {
                promises.push(this.api.deleteDotNet('CashOutAppliedToInvoice/' + inv.id));
            });
        }

        return Promise.all(promises);
    }

    updateCashOut(cashOut) {
        return this.api.postSingleDotNet(`CashOut`, cashOut);
    }

    saveCashOutOffer(cashOutOffer: CashOutOffer) {
        return this.api.postNode(`CashOutOffer`, cashOutOffer);
    }

    getReasons() {
        return this.api.getArrayNode(`CashOutReason`, { orderby: 'name' }, () => new CashOutReason());
    }

    approveCashOut(cashOutId: string) {
        return this.api.patchSingleDotNet(`CashOut/${cashOutId}`, { approvedDate: new Date() });
    }

    getCashOutById(cashOutId: string): Promise<CashOut> {
        return this.api.getSingleDotNet(`CashOut/${cashOutId}`, null, () => new CashOut());
    }

    getCashOutSummaryById(cashOutId: string): Promise<CashOutSummary> {
        return this.api.getSingleDotNet(`CashOutSummary/${cashOutId}`, null, () => new CashOutSummary());
    }

    getCashOutAppliedToInvoices(cashOutId: string): Promise<CashOutAppliedToInvoice[]> {
        return this.api.getArrayDotNet(`CashOutAppliedToInvoice`, { cashOutId_eq: cashOutId }, () => new CashOutAppliedToInvoice());
    }

    getCashOutsForWorkOrder(workOrderId: string): Promise<CashOut[]> {
        return this.api.getArrayDotNet('CashOut', {
            workOrderId_eq: workOrderId,
            orderby: 'CreatedDate'
        }, () => new CashOut());
    }

    getCashOutSummariesForWorkOrder(workOrderId: string): Promise<CashOutSummary[]> {
        return this.api.getArrayDotNet('CashOutSummary', {
            workOrderId_eq: workOrderId,
            orderby: 'CreatedDate'
        }, () => new CashOutSummary());
    }

    getCashOutOfferSummariesForWorkOrder(workOrderId: string) {
        return this.api.getArrayNode(`CashOutOfferSummary`, { workOrderId_eq: workOrderId, orderby: 'CreatedDate' }, () => new CashOutOfferSummary());
    }

    getCashOutOfferSummary(id: string) {
        return this.api.getSingleNode(`cash-out/get-cash-out-offer/${id}`, null, () => new CashOutOfferSummary());
    }

    getReplacementAndCashInLieuLanguage(workOrderId: string) {
        return this.api.getSingleNode(`cash-out/replacement-cash-in-lieu-language/${workOrderId}`);
    }

    deleteCashOutOffer(cashOutOfferId: string) {
        return this.api.deleteNode(`CashOutOffer/${cashOutOfferId}`);
    }

    unDeleteCashOutOffer(cashOutOfferId: string) {
        return this.api.unDeleteNode(`CashOutOffer/${cashOutOfferId}`);
    }

    getCashOutHtml(cashOutId: string): Promise<string> {
        return this.api.getTextDotNet('CashOut/' + cashOutId + '/offer/html');
    }

    deleteCashOut(cashOutId: string) {
        return this.api.deleteDotNet('CashOut/' + cashOutId);
    }

    unDeleteCashOut(cashOutId: string): Promise<any> {
        return this.api.unDeleteDotNet('CashOut/' + cashOutId);
    }
}