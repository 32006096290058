import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';


@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.css'],
    standalone: true,
    imports: [
        MatProgressSpinnerModule,
        MatProgressBarModule,
        CommonModule,
        MatSlideToggleModule,
        FormsModule,
        MatIconModule,
        MatButtonModule
    ]
})
export class OrderSummaryComponent implements OnInit {

    @Input() selectedPlan: PlanClient;
    @Input() basePrice: number;
    @Input() optionsPrice: number;
    @Input() discounts: number;
    @Input() isMonthly: boolean;
    @Output() isMonthlyChange: EventEmitter<boolean> = new EventEmitter();
    @Input() selectedIndex: number;
    @Input() totalSteps: number;
    @Input() showCompletion = false;
    @Input() hideMonthly: boolean;
    showIt = false;

    constructor(private sanitization: DomSanitizer) { }

    ngOnInit() {
        setTimeout(() => this.showIt = true, 100);
    }

    getBackground() {
        if (!this.selectedPlan) {
            return 'linear-gradient(135deg, #ed765e  0px, #fea858 100%)';
        }
        return this.sanitization.bypassSecurityTrustStyle(this.selectedPlan.planStyleObject ? this.selectedPlan.planStyleObject.cardBackBackgroundImage : null);
    }

    get total() {
        return this.basePrice + this.optionsPrice + this.discounts;
    }

    get pctComplete() {
        if (this.totalSteps === 0) {
            return 0;
        }
        return (this.selectedIndex / this.totalSteps) * 100;
    }

    updateIsMonthly(value: boolean) {
        this.isMonthly = value;
        this.isMonthlyChange.emit(value);
    }

    scrollDocumentDown() {
        window.scrollTo({ top: document.body.scrollHeight || document.documentElement.scrollHeight, left: 0, behavior: 'smooth' });
    }
}
