<div class="container" *ngIf="workOrderSummary">
    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs">
        <mat-tab label="Tab 1">

            <h1>How Did We Do?</h1>

            <!-- <img [src]="workOrderSummary.imageThumbnailUrl">
      <img [src]="workOrderSummary.itemUrl"> -->


            <div aflex>
                <div>
                    <p>How did we do servicing your
                        {{workOrderSummary.itemName}}
                        at {{workOrderSummary.propertyAddress}}?</p>

                    <div>

                    </div>
                </div>
                <div nogrow>
                    <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/survey.svg"
                        id="survey-image">
                </div>
            </div>
            <div>
                <h3>How Did {{workOrderSummary.contractorName}} Do?
                </h3><br />
                <div style="max-width: 500px;height: 50px;">
                    <ngx-star-rating id="rating" [(ngModel)]="workOrderSurvey.rating"></ngx-star-rating>
                </div>
            </div>
            <div topmargin40>
                <h3>And How Did We Do?
                </h3><br />
                <div style="max-width: 500px;height: 50px;">
                    <ngx-star-rating id="elevateRating" [(ngModel)]="workOrderSurvey.elevateRating"></ngx-star-rating>
                </div>
            </div>
            <div style="clear: both;"></div>

            <!-- <div aflex>
                <div style="text-align: center">

                </div>
                <div nogrow>


                </div>
            </div> -->
            <div topmargin40></div>
            <mat-form-field appearance="outline">
                <mat-label>Additional Comments</mat-label>
                <textarea matInput rows="5" [(ngModel)]="workOrderSurvey.comments"></textarea>
            </mat-form-field>
            <div style="text-align: right;">
                <button [disabled]="!canSubmit || saving" mat-button large mat-raised-button color="primary"
                    (click)="save()">
                    <mat-spinner twenty *ngIf="saving"></mat-spinner>
                    Submit
                </button>
            </div>
        </mat-tab>
        <mat-tab label="Tab 2">
            <div id="thankYouCard">
                <div style="text-align: center;">
                    <app-check-circle-animated height="130px"></app-check-circle-animated>
                </div>
                <div *ngIf="hideGoogleLink" class="center">
                    <h1>Thank You</h1>
                    <p class="info center">Your feedback is important to helping us elevate your experience.</p>
                </div>
                <div *ngIf="showGoogleLink" class="center fade-in-up">
                    <h1>Love Your Experience?</h1>
                    <p class="info">Tell the world. Post a review on Google and we'll send you a $25 Amazon gift card.
                        It's that simple!</p>
                    <a mat-button target="_blank" mat-raised-button
                        href="https://search.google.com/local/writereview?placeid=ChIJ-X62xBnzb0ARA05PW0-sm8o">
                        <mat-icon>reviews</mat-icon> Review Us
                    </a>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>