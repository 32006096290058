import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { FileModel } from '@cogent/shared/models/common/file.model';

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {
    @Input() policySummary: PolicySummary;
    @Input() hasSellersDisclosures: boolean;
    @Input() hasHomeInspection: boolean;
    @Output() hasSellersDisclosuresChange: EventEmitter<boolean> = new EventEmitter();
    @Output() hasHomeInspectionChange: EventEmitter<boolean> = new EventEmitter();
    @Output() inspectionUploaded: EventEmitter<boolean> = new EventEmitter();
    @Input() hideIcon = false;
    @Input() hideLanguage = false;
    @Input() showOnlyWhenNeeded = false;
    @Input() selectedIndex = 0;
    @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();
    @Input() checkExisting = true;
    @Input() hideLearnMore = false;

    inspectionUploadId = UtilitiesService.newid();
    sellersUploadId = UtilitiesService.newid();
    uploadingSellersDisclosures = false;
    uploadingInspection = false;
    isNeeded = false;
    checkmarkShown = false;

    constructor(
        private policyApi: PolicyApiService,
        private missionService: MissionService,
        private activatedRoute: ActivatedRoute) { }


    ngOnInit(): void {

        let homeInspectionChecked = false;
        let sellersDisclosuresChecked = false;

        setTimeout(() => this.checkmarkShown = true, 1000);

        if (this.policySummary) {
            const dt = new Date();
            dt.setDate(dt.getDate() - 60);

            //if (this.policySummary.effectiveDate > dt) {
            if (this.checkExisting) {
                this.policyApi.hasDocument(this.policySummary.id, 'Home_Inspection').then(results => {
                    homeInspectionChecked = true;
                    this.hasHomeInspection = results;
                    this.hasHomeInspectionChange.emit(results);
                    if (this.policySummary.coverageType === 'RealEstate' && !results && sellersDisclosuresChecked && !this.hasSellersDisclosures
                        && this.policySummary.effectiveDate > dt) {
                        this.isNeeded = true;
                    }
                });
            }
            // this.policyApi.hasDocument(this.policySummary.id, 'Sellers_Disclosures').then(results => {
            //     sellersDisclosuresChecked = true;
            //     this.hasSellersDisclosures = results;
            //     this.hasSellersDisclosuresChange.emit(results);
            //     if (this.policySummary.coverageType === 'RealEstate' && !results && homeInspectionChecked && !this.hasHomeInspection
            //         && this.policySummary.effectiveDate > dt) {
            //         this.isNeeded = true;
            //     }
            // });
        } else {
            this.activatedRoute.params.subscribe(params => {
                if (this.checkExisting) {
                    if (params.policyDocumentId) {
                        this.policyApi.hasDocument(params.policyDocumentId, 'Home_Inspection').then(results => {
                            this.hasHomeInspection = results;
                            this.hasHomeInspectionChange.emit(results);
                        });
                        this.policyApi.hasDocument(params.policyDocumentId, 'Sellers_Disclosures').then(results => {
                            this.hasSellersDisclosures = results;
                            this.hasSellersDisclosuresChange.emit(results);
                        });
                        this.policySummary = new PolicySummary();
                        this.policySummary.id = params.policyDocumentId;
                    }
                }
            });
        }
    }

    showSellersDisclosureUpload() {
        document.getElementById(this.sellersUploadId).click();
    }

    showHomeInspectionUpload() {
        document.getElementById(this.inspectionUploadId).click();
    }

    selectIndex(index) {
        this.selectedIndex = index;
        this.selectedIndexChange.emit(this.selectedIndex);
    }

    handleFiles(files, type: string) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const reader = new FileReader();

            const thisItem = this;
            const setUrl = (url) => {

                const fileModel = new FileModel();
                fileModel.fileUri = url;
                fileModel.name = file.name;

                if (type === 'Home_Inspection') {
                    thisItem.uploadingInspection = true;
                } else if (type === 'Sellers_Disclosures') {
                    thisItem.uploadingSellersDisclosures = true;
                }
                thisItem.policyApi.uploadDocument(this.policySummary.id, type, fileModel).then(() => {
                    thisItem.uploadingSellersDisclosures = false;
                    thisItem.uploadingInspection = false;

                    if (type === 'Home_Inspection') {
                        this.hasHomeInspection = true;
                        this.hasHomeInspectionChange.emit(true);
                        this.inspectionUploaded.emit(true);
                    } else if (type === 'Sellers_Disclosures') {
                        this.hasSellersDisclosures = true;
                        this.hasSellersDisclosuresChange.emit(true);
                    }
                    this.missionService.showSuccessToast('Document Uploaded');
                });
            };
            reader.onload = (function () {
                return function (e) {

                    (this as any).dragTargetActive = false;
                    setUrl(e.target.result);


                };
            })();

            reader.readAsDataURL(file);
        }
    }


}
