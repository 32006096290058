<h2>Why are you turning off auto renew?</h2>
<mat-dialog-content>
    <mat-radio-group class="auto-renew-cancel-radio-group" style="margin-bottom: .5rem" [(ngModel)]="selectedAutoRenewCancelReason">
        @for(reason of autoRenewCancelReasons(); track reason) {
            <mat-radio-button [value]="reason">{{reason.reason}}</mat-radio-button>
        }
    </mat-radio-group>
    <button mat-button mat-raised-button style="margin-right: .5em" (click)="cancelClicked()">Never Mind</button>
    <button mat-button mat-raised-button color="primary" [disabled]="!selectedAutoRenewCancelReason()" class="small-primary" [mat-dialog-close]="selectedAutoRenewCancelReason()">Save</button>
</mat-dialog-content>
