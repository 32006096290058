import { Component, OnInit } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ActivatedRoute } from '@angular/router';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { ApiService } from '@cogent/client/api';
import { InvoiceSummary, StripeCard, StripeTransactionCharge } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { LottiePlayerComponent } from '../../../visuals/lottie-player/lottie-player.component';

@Component({
    selector: 'app-make-payment',
    templateUrl: './make-payment.component.html',
    styleUrls: ['./make-payment.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        MatCheckboxModule,
        LottiePlayerComponent,
        FormsModule,
        MatIconModule,
    ]
})
export class MakePaymentComponent implements OnInit {

    unpaidInvoices: InvoiceSummary[];
    selectedCard: StripeCard;
    saving = false;
    creditCardValid = false;
    customerId: string = null;
    maintenanceAvailable = false;
    isLegacy = true;

    constructor(
        private customerServiceRepository: CustomerRepositoryService,
        private dialog: DialogsService,
        private missionService: MissionService,
        private entityApi: EntityApiService,
        private maintApi: MaintenanceServiceApiService,

        private activatedRoute: ActivatedRoute,
    ) { }

    async ngOnInit() {

        let wasSetup = false;
        this.activatedRoute.params.subscribe(params => {
            if (params.customerId) {
                this.customerId = params.customerId;

                const nextYear = new Date()
                nextYear.setFullYear(nextYear.getFullYear() + 1)
                this.customerServiceRepository.getUnpaidInvoices(null, params.customerId, nextYear).then(async invoices => {

                    this.unpaidInvoices = invoices;
                    this.unpaidInvoices.forEach(inv => {
                        if (inv.dueDate < new Date()) {
                            inv.selected = true;
                        }
                    });
                });

                wasSetup = true;
            }
        });
        if (wasSetup) {
            return;
        }

        this.refreshInvoices();
        const user = await this.entityApi.getLoggedInUser();
        this.customerId = user.id;
        //this.entityApi.getLoggedInUser().then(user => this.customerId = user.id);


    }

    getInvoiceUrl(invoice: InvoiceSummary) {
        if (invoice.type === 'maint') {
            return `${ApiService.endPointNode}scheduled-maintenance/invoice-html/${invoice.id}`;
        }
        return `${ApiService.endPointDotNet}invoice/${invoice.id}/html`;
    }

    private refreshInvoices() {
        this.entityApi.getLoggedInUser().then(async user => {

            console.log(user);
            const nextYear = new Date()
            nextYear.setFullYear(nextYear.getFullYear() + 1)
            this.customerServiceRepository.getUnpaidInvoices(user.loginId, user.id, nextYear).then(async invoices => {
                if (user.loginId) {
                    const pmInvoices = await this.customerServiceRepository.getUnpaidPropertyManagerInvoices(user.loginId);
                    invoices = invoices.concat(pmInvoices);
                }
                this.unpaidInvoices = invoices;

                this.maintenanceAvailable = await this.maintApi.maintServicesAvailable();

                if (this.maintenanceAvailable) {
                    const maintInvoices = await this.maintApi.getUnpaidInvoicesForLogin(user.loginId, user.id);
                    for (const maintInv of maintInvoices) {
                        const newInv = new InvoiceSummary();
                        newInv.id = maintInv.id;
                        newInv.type = 'maint';
                        newInv.amountDue = maintInv.due;
                        newInv.dueDate = maintInv.dueDate;
                        newInv.description = maintInv.orderedServices;

                        if (newInv.dueDate < new Date()) {
                            newInv.selected = true;
                        }
                        this.unpaidInvoices.push(newInv);
                    }
                }
                this.unpaidInvoices.sort((a, b) => a.dueDate < b.dueDate ? -1 : 1).forEach(inv => {
                    if (inv.dueDate < new Date()) {
                        inv.selected = true;
                    }
                });
            });
        });
    }

    get totalPayment(): number {
        if (!this.unpaidInvoices || this.unpaidInvoices.filter(i => i.selected).length === 0) {
            return 0;
        }

        return this.unpaidInvoices.filter(i => i.selected).map(i => i.amountDue).reduce((a, b) => a + b);
    }

    get canMakePayment() {
        return this.selectedCard && this.totalPayment > 0;
    }

    selectedCardChange(event) {

    }

    paymentValid(isValid) { this.creditCardValid = isValid; }

    makePayment() {
        this.saving = true;
        const charge = new StripeTransactionCharge();
        charge.amount = this.totalPayment;
        charge.invoicesToPay = this.unpaidInvoices.filter(i => i.selected).map(i => ({ amount: i.amountDue, invoiceId: i.id, type: i.type }));


        charge.stripeCardId = this.selectedCard.accountId;
        charge.stripeCustomerId = this.selectedCard.customer;

        this.entityApi.getLoggedInUser().then(user => {
            this.customerServiceRepository.createCharge(user.id, charge).then(results => {

                if (results) {
                    this.saving = false;
                    //this.missionService.showSuccessToast('Payment Successful');
                    this.missionService.showLottieInfoDone('Payment Successful');
                } else {

                }
                this.refreshInvoices();
            }).catch(() => {
                this.saving = false;
                this.dialog.alert('Payment Failed', 'Sorry, it appears as if that charge didn\'t go through.');
            });
        });
    }

}
