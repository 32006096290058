<div class="container">
    <div class="center" *ngIf="selectedIndex != 2" topmargin30 bottommargin40>
        <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/lock-orange-blob.svg" style="height: 100px;">
        <h1>Reset Password
        </h1>
    </div>


    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <form>
                <mat-form-field style="max-width: 450px;" appearance="outline" topmargin10>
                    <mat-label>Email Address</mat-label>
                    <input name="txtEmail" autocomplete="username" matInput type="email" [(ngModel)]="emailAddress">
                </mat-form-field>

                <button mat-button mat-raised-button color="primary" style="margin-left: 10px"
                    [disabled]="!canSend || sending" (click)="send()">
                    <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
                    Send
                </button>
            </form>
        </mat-tab>
        <mat-tab label="Second">
            <div topmargin30>
                <mat-form-field style="max-width: 450px;" appearance="outline">
                    <mat-label>Verification Code</mat-label>
                    <input matInput type="number" [(ngModel)]="code">
                </mat-form-field>

                <mat-form-field style="max-width: 450px" appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" [(ngModel)]="newPassword">
                </mat-form-field>

                <mat-form-field style="max-width: 450px" appearance="outline">
                    <mat-label>Confirm New Password</mat-label>
                    <input matInput type="password" [(ngModel)]="newPasswordConfirm">
                </mat-form-field>

                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>
                <button mat-button mat-raised-button color="primary" large [disabled]="!canReset || resetting"
                    (click)="reset()">
                    <mat-spinner *ngIf="resetting" class="thirty"></mat-spinner>
                    Reset
                </button>
            </div>
        </mat-tab>
        <mat-tab label="Third">


            <div style="text-align: center;">
                <app-check-circle-animated></app-check-circle-animated>
                <h1 topmargin40>Password Reset</h1>
                <p class="info">You should now be able to log in with your new password.</p>


                <a topmargin30 mat-button mat-raised-button color="primary" large routerLink="/">Log In</a>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>