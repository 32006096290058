import { Component, OnInit } from '@angular/core';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { PaymentItemSummary, PaymentSummary } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { CommonModule } from '@angular/common';
import { DateRangeDisplayAndSelectorComponent } from '@cogent/client/shared/components/misc/date-range-display-and-selector/date-range-display-and-selector.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-payment-report',
    templateUrl: './payment-report.component.html',
    styleUrls: ['./payment-report.component.css'],
    standalone: true,
    imports: [CommonModule, DateRangeDisplayAndSelectorComponent, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class PaymentReportComponent implements OnInit {

    startDate: Date;
    endDate: Date;
    payments: PaymentItemSummary[];
    loading = false;
    isLegacy = true;
    selectedPaymentItem: PaymentItemSummary;
    safeUrl: SafeUrl;

    constructor(private customerApi: CustomerRepositoryService,
        private sanitizer: DomSanitizer,
        private entityApi: EntityApiService) { }

    ngOnInit(): void {

        setTimeout(() => {
            if (!this.startDate && !this.endDate) {
                this.endDate = new Date();
                this.startDate = new Date();
                this.startDate = UtilitiesService.yearStart(this.startDate);
            }
        }, 1000);
    }

    get canSave() {
        return !this.loading && this.startDate && this.endDate;
    }

    async getData() {
        this.loading = true;
        const entity = await this.entityApi.getLoggedInUser();
        this.payments = await this.customerApi.getPaymentsByLogin(entity.loginId, this.startDate, this.endDate);
        const propertyManagerPayments = await this.customerApi.getPaymentsByPropertyManagerId(entity.id, this.startDate, this.endDate);
        const maintPayments = await this.customerApi.getMaintenanceServicePaymentsByLogin(entity.loginId, this.startDate, this.endDate);
    
        for (const pmPayment of propertyManagerPayments) {
            const foundPayment = this.payments.find(i => i.id === pmPayment.id);
            if (!foundPayment) {
                this.payments.push(pmPayment);
            }
        }

        this.payments = this.payments.concat(maintPayments as any);
        this.payments = this.payments.sort((a, b) => a.paymentCreatedDate > b.paymentCreatedDate ? 1 : -1);
        this.loading = false;
    }

    getPaymentRecieptUrl(item: PaymentItemSummary) {
        //https://local.upkeeplabs.com:5002/api/payment/d16edcc0-72ae-4d75-b944-f53fafa0836b/html
        if (item.policyNumber != -1) {
            return `${ApiService.endPointDotNet}payment/${item.paymentId}/html`
        } else {
            return `${ApiService.endPointNode}scheduled-maintenance/payment-pdf/${item.paymentId}`;
        }
    }

    export() {
        UtilitiesService.export('report', 'payments_report.xlsx');
    }

    showPaymentReceipt(paymentItem: PaymentItemSummary) {
        this.selectedPaymentItem = paymentItem;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getPaymentRecieptUrl(paymentItem));
    }
}
