<div class="container fancy-container" *ngIf="workOrderSummary"
    style="padding-top: 20px; padding-bottom: 100px;max-width: 900px;padding-right: 20px">
    <div id="canceledStamp" *ngIf="workOrderSummary.cancelledDate && selectedIndex == 0">
        <h1>Canceled</h1>
        {{workOrderSummary.cancelledDate | date: 'shortDate'}}
    </div>
    <div class="center" bottommargin30>
        <div bottommargin30>
            <img *ngFor="let line of workOrderSummary.lines" src="{{getThumbnailUrl(line)}}" class="item-thumbnail" />
            <h2>{{workOrderSummary.itemName}}</h2>
        </div>

        <button mat-button mat-raised-button color="primary" (click)="showContactUs()">
            <mat-icon>comment</mat-icon> Contact Us
        </button>
    </div>

    <div style="display: table;margin: 0 auto">
        <div flex bottommargin30>
            <div class="shadow-box">
                <div class="data-label">Job Number</div>
                <h1 class="big-data">{{workOrderSummary.number}}</h1>
            </div>
            <div class="shadow-box">
                <img style="height: 60px" [src]="getStreetViewUrl300x300(workOrderSummary.policyId)"
                    id="streetViewImage"><br>
                {{workOrderSummary.propertyAddress}}<br>
                {{workOrderSummary.propertyCity}}, {{workOrderSummary.propertyState}}
                {{workOrderSummary.propertyPostalCode}}
            </div>
            <div class="shadow-box">

                <div class="data-label">Service Fee Due</div>
                <h1 class="big-data" topmargin10>{{totalServiceFeesDue | currency}}</h1>

            </div>
        </div>
    </div>

    <div style="display: table;margin: 0 auto">

        <div class="progress-container">
            <div class="nub"></div>
            <div></div>
            <div class="icon complete">
                <mat-icon>send</mat-icon>
            </div>
            <div class="step-title complete">
                Sent
            </div>
            <div class="connector complete"></div>
            <div class="step-detail complete">
                <div *ngIf="!hideContractorInformation">

                    <div>The service request has been sent to:</div>
                    <div>

                        <img id="contractorLogo" style="height: 60px"
                            [src]="getEntityThumbnailUrl(workOrderSummary.contractorId)">
                        <br>
                        <h3 style="margin-top: 0">{{workOrderSummary.contractorName}}</h3>
                        {{workOrderSummary.contractorPhone | formatPhoneNumber}}
                        <br> {{workOrderSummary.contractorEmail}}
                    </div>
                </div>

                <div *ngIf="hideContractorInformation" bottommargin20>
                    <div class="center" bottommargin30>
                        <div class="throbber" style="transform: scale(1.5);"></div>

                        <div topmargin30> We are contacting our provider network to match you up with a service pro who
                            can work
                            with your scheduled.</div>
                        <div topmargin20>We'll send you confirmation when a service pro has been assigned.</div>
                    </div>
                </div>
            </div>


            <div class="icon " [class.complete]="isScheduled">
                <mat-icon>event</mat-icon>
            </div>
            <div class="step-title " [class.complete]="isScheduled">
                Scheduled
            </div>
            <div class="connector " [class.complete]="isScheduled"></div>
            <div class="step-detail " [class.complete]="isScheduled">
                <app-calendar-day-viewer *ngIf="workOrderSummary.scheduledDate"
                    [date]="workOrderSummary.scheduledDate"></app-calendar-day-viewer>

                <div *ngIf="workOrderSummary.scheduledDate">
                    <span class="data-label">Appointment Window: </span> {{workOrderSummary.scheduledStartWindow}} -
                    {{workOrderSummary.scheduledEndWindow}}
                </div>
            </div>


            <div class="icon" [class.complete]="isDiagnosed">
                <mat-icon>handyman</mat-icon>
            </div>
            <div class="step-title " [class.complete]="isDiagnosed">
                Diagnosed
            </div>
            <div class="connector " [class.complete]="isDiagnosed"></div>
            <div class="step-detail ">

            </div>

            <div class="icon complete" *ngIf="hasPurchaseOrder">
                <mat-icon>warehouse</mat-icon>
            </div>
            <div class="step-title complete" *ngIf="hasPurchaseOrder">
                Parts Ordered
            </div>
            <div class="connector complete" *ngIf="hasPurchaseOrder"></div>
            <div class="step-detail complete" *ngIf="hasPurchaseOrder">
                <div *ngIf="purchaseOrderItems.length > 1">
                    We need a few parts to complete your repair. Below are the parts we have ordered:
                </div>
                <div *ngIf="purchaseOrderItems.length === 1">
                    We need to order a part to complete your repair. Below is the part we have ordered:
                </div>
                <ul>
                    <li *ngFor="let part of purchaseOrderItems">{{part.partNumber}} - {{part.description}}</li>
                </ul>
            </div>


            <div class="icon " [class.complete]="partsShipped" *ngIf="hasPurchaseOrder">
                <mat-icon>local_shipping</mat-icon>
            </div>
            <div class="step-title " [class.complete]="partsShipped" *ngIf="hasPurchaseOrder">
                Parts Shipped
            </div>
            <div class="connector " [class.complete]="partsShipped" *ngIf="hasPurchaseOrder"></div>
            <div class="step-detail " [class.complete]="partsShipped" *ngIf="hasPurchaseOrder">
                <div *ngIf="partsShipped">
                    <div bottommargin30>
                        Your part(s) are coming in {{groupedParts.length}} shipment(s)</div>
                    <div class="shadow-box package-box" *ngFor="let group of groupedParts">

                        <img [src]="getShipperImage(group)" style="height: 60px;margin-top: -30px">

                        <div class="data-label">Tracking #</div>
                        {{group.trackingNumber}}

                        <div *ngIf="group.estimatedDeliveryDate && !group.deliveryDate" topmargin20>
                            <div class="data-label">Estimated Delivery Date</div>
                            {{group.estimatedDeliveryDate | date: 'shortDate'}}
                        </div>

                        <div *ngIf="group.deliveryDate" topmargin20>
                            <div class="data-label">Delivery Date</div>
                            {{group.deliveryDate | date: 'shortDate'}}
                        </div>

                        <div *ngIf="group.currentStatus" topmargin20>
                            <div class="data-label">Current Status</div>
                            {{group.currentStatus}}
                        </div>

                        <div aflex topmargin20>
                            <div></div>
                            <div nogrow>
                                <a mat-button mat-raised-button
                                    routerLink="/parts-tracking/{{group.purchaseOrderId}}">Details</a>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <div class="icon" [class.complete]="isComplete">
                <mat-icon>done</mat-icon>
            </div>
            <div class="step-title" [class.complete]="isComplete">
                Completed
            </div>
            <div class="connector" [class.complete]="isComplete"></div>
            <div class="step-detail" [class.complete]="isComplete">
                <div *ngIf="isComplete && workOrderSummary.surveyScore ===0 " class="center">
                    <div bottommargin30>
                        Your work is now complete. How did we do?
                    </div>

                    <a mat-button mat-raised-button routerLink="/survey/{{workOrderSummary.id}}">Take Survey</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="noWorkOrder" topmargin40 class="fade-in-up center">

    <img src="../../../assets/images/stop-watch.svg" style="height: 200px;">
    <h1>Sorry</h1>
    We weren't able to find that job yet. It may take just a moment before it becomes available.

    <div topmargin30>
        <button mat-button mat-raised-button color="primary" (click)="reloadPage()">Reload</button>
    </div>
</div>

<div class="bottom-tool-bar">
    <div class="container">
        <div class="center" *ngIf="workOrderSummary">

            <!-- <button mat-button class="option-button">
                <mat-icon>cloud_upload</mat-icon><br>
                Upload Inspection
            </button> -->

            <button *ngIf=" workOrderSummary.canCancel" class="option-button" mat-button (click)="startCancellation()">
                <mat-icon>block</mat-icon><br>
                Cancel Service Request
            </button>

            <button mat-button *ngIf="canReschedule" (click)="newTimeSlots()" class="option-button">
                <mat-icon>calendar_today</mat-icon><br> Reschedule
            </button>

            <button mat-button *ngFor="let command of extraCommands"
                [disabled]="showWorkingMessage && command === currentCommand" (click)="executeCommand(command)"
                class="option-button">
                <mat-icon *ngIf="!showWorkingMessage || command !== currentCommand">
                    {{command.iconClass}}</mat-icon>
                <mat-spinner *ngIf="showWorkingMessage && command === currentCommand" class="thirty">
                </mat-spinner><br>
                {{command.label}}
            </button>

            <button mat-button [disabled]="workingMessage" *ngFor="let func of customFunctions" class="option-button"
                (click)="runCustomFunction(func)">
                <mat-icon *ngIf="!workingMessage">{{func.iconClass}}</mat-icon>
                <mat-spinner *ngIf="workingMessage" class="thirty"></mat-spinner>
                <br>
                {{func.name}}
            </button>

        </div>
    </div>
</div>