import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ApiService } from '@cogent/client/api';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Entity } from '@upkeeplabs/models/cogent';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
declare var google: any;

@Component({
    selector: 'app-view-on-my-way',
    templateUrl: './view-on-my-way.component.html',
    styleUrls: ['./view-on-my-way.component.css'],
    standalone: true,
    imports: [
        CommonModule, MatIconModule, RouterModule, MatButtonModule
    ]
})
export class ViewOnMyWayComponent implements OnInit, OnDestroy {

    workOrderId: string;
    mapId = UtilitiesService.newid();
    directionsDisplay;
    directionsService = new google.maps.DirectionsService();
    map;
    coordinates: any[];
    eta: string;
    workOrderSummary: WorkOrderSummaryClient;
    tech: Entity;
    notTracking = false;
    interval: any;

    constructor(private activatedRoute: ActivatedRoute,
        private serviceRepository: ServiceRepositoryService,
        private entityApi: EntityApiService,
        private apiService: ApiService,
        private policyApi: PolicyApiService,
        private serviceApi: ServiceApiService) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.workOrderId = params.id;
            // this.serviceRepository.getWorkOrderSummary(this.workOrderId).then(workOrderSummary => {
            //     this.workOrderSummary = workOrderSummary;

            //     if (workOrderSummary.technicianId) {
            //         this.entityApi.getBasicEntity(workOrderSummary.technicianId).then(tech => {
            //             this.tech = tech;
            //         });
            //     }

            //     this.serviceRepository.getTechnicianCoordinates(this.workOrderId).then(coordinates => {
            //         this.coordinates = coordinates;
            //         this.setupMap();
            //     });
            // });
            this.updateLocation();
            this.interval = setInterval(() => this.updateLocation(), 60000);
        });
    }

    private updateLocation() {
        this.serviceRepository.getOnMyWayInfo(this.workOrderId).then(info => {
            this.workOrderSummary = UtilitiesService.copyObject(info.workOrderSummary, null, () => new WorkOrderSummaryClient());

            if (info.workOrderSummary && info.workOrderSummary.workOrderStatusId && info.workOrderSummary.workOrderStatusId.toUpperCase() !== '6D5AF43F-BC84-457A-A5AC-A02F7A7F8210'
                && info.workOrderSummary.workOrderStatusId.toUpperCase() !== 'B849F529-6C42-4BAB-9C6E-039BA5D123DC' && info.workOrderSummary.workOrderStatusId.toUpperCase() !== 'CD4BE0D3-777D-476A-930A-B32F2CCE50C1') {
                this.notTracking = true;
                clearInterval(this.interval);
            } else {
                this.tech = info.technician;
                this.coordinates = info.coordinates;
                this.setupMap();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    get techImageUrl() {
        if (!this.workOrderSummary) {
            return null;
        }

        return `${ApiService.endPointDotNet}entity/thumbnail/${this.workOrderSummary.technicianId}`;
    }

    getStreetViewUrl300x300(policyId: string) {
        return this.policyApi.getStreetViewUrl300x300(policyId);
    }

    getItemThumbnailUrl() {
        return this.serviceApi.getItemThumbnailUrl(this.workOrderSummary.lines[0].itemId);
    }

    getEntityThumbnailUrl(entityId: string) {
        return this.entityApi.getThumbnailUri(entityId);
    }

    private setupMap() {
        if (!document.getElementById(this.mapId)) {
            setTimeout(() => {
                this.setupMap();
            }, 50);

            return;
        }
        this.directionsDisplay = new google.maps.DirectionsRenderer();
        const chicago = new google.maps.LatLng(37.334818, -121.884886);
        const mapOptions = {
            zoom: 7,
            center: chicago,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
        };
        this.map = new google.maps.Map(document.getElementById(this.mapId), mapOptions);
        this.directionsDisplay.setMap(this.map);

        const start = new google.maps.LatLng(this.coordinates[0].latitude, this.coordinates[0].longitude);
        const end = new google.maps.LatLng(this.coordinates[1].latitude, this.coordinates[1].longitude);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(start);
        bounds.extend(end);
        this.map.fitBounds(bounds);
        const request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING,
        };
        const rendererOptions = {
            map: this.map,
            suppressMarkers: true
        };

        this.directionsService.route(request, (response, status) => {
            const time = response.routes[0].legs[0].duration.text;
            this.eta = time;
            if (status === google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setOptions(rendererOptions);
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
            } else {
                console.error("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }

            const techMarker = new google.maps.Marker({
                position: end,
                icon: `${ApiService.endPointDotNet}entity/${this.workOrderSummary.technicianId}/tiny-thumbnail`,
                map: this.map
            });

            const homeMarker = new google.maps.Marker({
                position: start,
                icon: `https://upkeeplabs.blob.core.windows.net/doc-public/cdn/images/home-30x30.png`,
                map: this.map
            });
        });
    }
}
