<div aflex bottommargin20>
    <h2 class="sub-heading">Cancel Service</h2>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="cancellationArgs">
    <div aflex>
        <div nogrow>
            <div class="data-label">Amount Paid</div>
            {{cancellationArgs.amountPaid | currency}}

        </div>
        <div nogrow>
            <div class="data-label">Amount Used</div>
            {{cancellationArgs.amountUsed | currency}}
        </div>
        <div nogrow *ngIf="cancellationArgs.cancellationFee">
            <div class="data-label">Cancellation Fee</div>
            {{cancellationArgs.cancellationFee | currency}}
        </div>
        <div></div>
        <div nogrow *ngIf="cancellationArgs.cancellationBalance >= 0">
            <div class="data-label">Refund Amount</div>
            <h1 class="big-data">{{cancellationArgs.cancellationBalance | currency}}</h1>
        </div>
        <div nogrow *ngIf="cancellationArgs.cancellationBalance < 0">
            <div class="data-label">Amount Due</div>
            <h1 class="big-data">{{cancellationArgs.amountToCharge | currency}}</h1>
        </div>
    </div>

    <p class="info" topmargin30>We're sorry to see you go, <br>can you tell us the reason?</p>
    <button mat-button style="text-transform: none;" class="toggle-button offset" (click)="cancellationArgs.selectedCancellationReason = reason"
        [class.selected]="reason === cancellationArgs.selectedCancellationReason"
        *ngFor="let reason of cancellationArgs.cancellationReasons">
        <mat-icon>{{reason.iconClass}}</mat-icon><br>
        {{reason.reason}}
    </button>
    <div *ngIf="cancellationArgs?.selectedCancellationReason?.reason=='Service Issues' || cancellationArgs?.selectedCancellationReason?.reason=='Other'" topmargin10>
        <mat-form-field appearance="outline">
            <mat-label>{{cancellationArgs?.selectedCancellationReason?.reason}}</mat-label>
            <textarea matInput rows="3" [(ngModel)]="cancellationArgs.cancellationNote"></textarea>
        </mat-form-field>
    </div>

    <div *ngIf="cancellationArgs.amountToCharge > 0">

        <app-payment-method-entry [(selectedCard)]="card" [hideManualCreditCardEntry]="true"
            [customerId]="service.customerId"></app-payment-method-entry>
    </div>


</div>

<div mat-dialog-actions>
    <div style="width: 100%" aflex *ngIf="cancellationArgs">
        <div></div>
        <div nogrow>

            <button [disabled]="!canSave" class="small-primary" (click)="save()" mat-button mat-raised-button
                color="primary">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Cancel Now
            </button>
        </div>
    </div>
</div>