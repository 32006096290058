<div class="container" [class.container-padding]="selectedIndex >= 0">
    <div class="mobile-margin">
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs" (selectedIndexChange)="setHash($event)">
            <mat-tab>
                <div class="container">
                    <div aflex>
                        <div>
                            <h1 class="page-title">
                                <span class="big">Property</span><br> <span class="little"> Selection</span>
                            </h1>
                        </div>
                        <div nogrow>
                            <button mat-button mat-raised-button class="small-primary" color="primary" (click)="validateAddress()"
                                [disabled]="!addressFilledOut || validatingAddress">Next
                                <i class="material-icons">keyboard_arrow_right</i>
                            </button>
                        </div>
                    </div>
                    <div flex class="padding mobile-margin" topmargin30>
                        <div>
                            <div topmargin20 bottommargin20>
                                <button mat-button mobile-block  mat-raised-button
                                    (click)="useMailingAddress()">Use My Mailing Address
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                            </div>
                            <app-address-editor [useOutlineFormField]="true" *ngIf="newPolicy && newPolicy.property"
                                (addressCompleteChange)="addressCompleteChange($event)" [address]="newPolicy.property"
                                (propertySelected)="propertySelected()"></app-address-editor>

                        </div>

                        <div id="about-property" nogrow>
                            <div *ngIf="propertyMeta">
                                <h2 class="sub-heading">About Your Property</h2>
                                <div class="data-label">Square Footage</div>
                                <div>{{propertyMeta.sqft}}</div>
                                <div class="data-label" topmargin20>Dwelling Type</div>
                                <div>{{propertyMeta.useCode}}</div>
                                <div class="data-label" topmargin20>Year Built</div>
                                <div>{{propertyMeta.yearBuilt}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- </mat-card> -->
                </div>
            </mat-tab>
            <mat-tab label="Plan Selection">
                <app-plan-offerings *ngIf="selectedIndex === 1" [(isMonthly)]="newPolicy.policy.isMonthly"
                    [plans]="plans" [(selectedPlan)]="selectedPlan" (selectedPlanChange)="planSelected($event)">
                </app-plan-offerings>
            </mat-tab>
            <mat-tab label="Options">
                <div aflex bottommargin20>
                    <div>
                        <h1 class="page-title">
                            <span class="big">Your </span><br>
                            <span class="little">
                                Options</span>
                        </h1>
                    </div>
                    <div nogrow>
                        <button mat-button mat-raised-button color="primary" *ngIf="selectedPlan" class="small-primary"
                            (click)="selectedIndex = 3">Next <i class="material-icons">keyboard_arrow_right</i></button>
                    </div>
                </div>

                <div *ngIf="selectedPlan">
                    <div id="mobile-options" class="soft-light mobile-margin">
                        <div class="mobile-option" aflex *ngFor="let option of selectedPlan.optionalItems">

                            <div nogrow>
                                <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                                </mat-checkbox>
                            </div>
                            <div class="option-item-name-container">
                                <h2 (click)="option.selected = !option.selected; optionSelected(option);">
                                    {{option.name}}
                                </h2>
                                <div>

                                </div>
                                <div>
                                    <div topmargin10 *ngIf="option.selected && !option.allowQuantity"><span
                                            class="data-label">Quantity:
                                        </span> 1</div>
                                    <div aflex *ngIf="option.selected && option.allowQuantity" topmargin10>
                                        <div nogrow class="data-label">Quantity: </div>
                                        <div>

                                            <mat-form-field appearance="outline" style="width: 100px !important" class="no-padding">
                                                <mat-select [(ngModel)]="option.quantity">
                                                    <mat-option *ngFor="let number of numbers" [value]="number">
                                                        {{ number }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div nogrow>
                                <span
                                    *ngIf="!newPolicy.policy.isMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                                <span
                                    *ngIf="newPolicy.policy.isMonthly">{{option.priceRecurring| currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="table" id="desktop-options">
                        <div class="table-header">
                            <div flex3>Item</div>
                            <div flex1>Price</div>
                            <div flex1>Selected</div>
                            <div flex1>Qty</div>
                        </div>
                        <div class="table-row" *ngFor="let option of selectedPlan.optionalItems">
                            <div flex3>{{option.name}}</div>
                            <div flex1>
                                <span
                                    *ngIf="!newPolicy.isMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                                <span
                                    *ngIf="newPolicy.isMonthly">{{option.priceRecurring| currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                            <div flex1>
                                <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                                </mat-checkbox>

                            </div>
                            <div flex1>
                                <span *ngIf="option.selected && !option.allowQuantity">
                                    1</span>
                                <div style="margin-top: -5px" *ngIf="option.selected && option.allowQuantity">
                                    <mat-form-field  appearance="outline" class="no-padding" style="width: 100px">
                                        <mat-select [(ngModel)]="option.quantity">
                                            <mat-option *ngFor="let number of numbers" [value]="number">
                                                {{ number }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <div aflex>
                    <div>
                        <h1 class="page-title">
                            <span class="big">Payment</span><br>
                            <span class="little">Information</span>
                        </h1>
                    </div>
                    <div nogrow>
                        <button mat-button mat-raised-button color="primary" class="small-primary" [disabled]="!creditCardValid"
                            (click)="selectedIndex  = 4">
                            Next <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                </div>

                <div style="margin-bottom: 20px">
                    <div>
                        <app-payment-method-entry [customerId]="customerId" paymentDescription="Premium Payment"
                            [paymentAmount]="totalPlanPrice" [(selectedCard)]="selectedCard"
                            (selectedCardChange)="paymentValid($event)">
                        </app-payment-method-entry>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Review & Submit">
                <div flex bottommargin20>
                    <div>
                        <h1 class="page-title">
                            <span class="big">Review </span><br>
                            <span class="little">
                                &amp; submit</span>
                        </h1>
                    </div>
                    <div nogrow>
                    </div>
                </div>
                <div flex>
                    <div>
                        <div *ngIf="selectedPlan" topmargin30>

                            <div class="review-grid">
                                <div class="gray-container-orange-border mobile-margin" padding flex1 topmargin20>


                                    <div class="center blob-icon-container">
                                        <img
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/location-on-orange-blob.svg">
                                    </div>
                                    <div aflex>
                                        <div>
                                            <div class="sub-heading" bottommargin30>Covered Property</div>
                                            <div *ngIf="newPolicy.property">
                                                <img *ngIf="newPolicy.property && newPolicy.property.address1 && selectedIndex >= 3"
                                                    id="street-view-image" [src]="propertyStreetView" />
                                                <address topmargin20>
                                                    {{newPolicy.property.address1}}<br>
                                                    {{newPolicy.property.city}}, {{newPolicy.property.state}}
                                                    {{newPolicy.property.postalCode}}
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="gray-container-orange-border mobile-margin" padding flex1 topmargin20>


                                    <div class="center blob-icon-container">
                                        <img
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/description-orange-blog.svg">
                                    </div>
                                    <div aflex>
                                        <div>
                                            <h2 class="sub-heading" bottommargin30>Selected Coverage</h2>

                                            <form topmargin30>
                                                <div aflex>
                                                    <div>
                                                        <mat-form-field style="margin-top: -12px"  appearance="outline">
                                                            <mat-label>Promotion Code</mat-label>
                                                            <input name="promotion-code-input" matInput
                                                                placeholder="Promotion Code"
                                                                [(ngModel)]="promotionCodeSearch">
                                                        </mat-form-field>
                                                    </div>
                                                    <div nogrow>
                                                        <button mat-button mat-raised-button
                                                            [disabled]="!promotionCodeSearch" class="small-primary"
                                                            (click)="findPromotionCode()" color="primary">Apply</button>
                                                    </div>
                                                </div>


                                            </form>
                                            <div aflex class="figure-row large">
                                                <div flex1>{{selectedPlan.name}} </div>
                                                <div nogrow class="dollar-figure">
                                                    {{planBasePrice | currency:'USD':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div *ngFor="let option of selectedOptions" aflex class="figure-row indent">
                                                <div flex1>
                                                    + {{option.name}}
                                                    <span *ngIf="option.quantity > 1"> (x{{option.quantity}})</span>
                                                </div>
                                                <div nogrow class="dollar-figure">
                                                    {{option.quantity * (newPolicy.policy.isMonthly ? option.priceRecurring : option.price) | currency:'USD':'symbol':'1.2-2' }}
                                                </div>
                                            </div>

                                            <div *ngIf="promotionCode" aflex class="figure-row indent">
                                                <div flex1>
                                                    <button style="margin-left: -59px; min-width: 56px;" mat-button
                                                        (click)="promotionCode = null">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    {{promotionCode.code}} <span *ngIf="promotionCode.description">-
                                                        {{promotionCode.description}}</span>
                                                </div>
                                                <div nogrow class="dollar-figure">
                                                    {{adjustmentAmount | currency}}
                                                </div>

                                            </div>
                                            <div aflex>
                                                <div></div>
                                                <div nogrow>
                                                    <app-display-total [total]="totalPlanPrice" label="Total Price:">
                                                    </app-display-total>
                                                </div>
                                            </div>
                                            <mat-slide-toggle [(ngModel)]="newPolicy.policy.isMonthly">Pay By Month
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                    <a mat-button block style="text-align: center" topmargin20 target="_blank"
                                        [href]="getSampleContractUrl(selectedPlan)" *ngIf="selectedPlan">
                                        <mat-icon>description</mat-icon>
                                        View Sample Contract
                                    </a>
                                </div>
                                <div class="gray-container-orange-border mobile-margin" padding flex1 topmargin20>
                                    <div class="center blob-icon-container">
                                        <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/credit-card-orange-blob.svg">
                                    </div>

                                    <div aflex>
                                        <div>
                                            <h2 class="sub-heading" bottommargin30>Payment Information</h2>
                                            <div class="center">
                                                <img *ngIf="selectedCardTypeUrl" class="review-image"
                                                    [src]="selectedCardTypeUrl">
                                                <div *ngIf="selectedCard">
                                                    {{selectedCard.brand}} {{selectedCard.last4}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="gray-container-orange-border mobile-margin" padding flex1 topmargin20>
                                    <div class="center blob-icon-container">
                                        <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/event-orange-blob.svg">
                                    </div>
                                    <div aflex>
                                        <div>
                                            <h2 class="sub-heading" bottommargin30>Payment Schedule</h2>

                                            <p>When would you like your coverage to start?</p>
                                            <mat-form-field style="max-width: 200px"  appearance="outline">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [min]="minStartDate" [matDatepicker]="picker"
                                                    [(ngModel)]="newPolicy.policy.effectiveDate">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div flex>

                            </div>

                            <div flex>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="right" topmargin30>
                    <button mat-button mobile-block mat-raised-button color="primary" large
                        [disabled]="submitting || !canSubmit" (click)="createOrder()">
                        <mat-spinner class="thirty" *ngIf="submitting"></mat-spinner> Submit
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

</div>
<app-order-summary [selectedIndex]="selectedIndex" [totalSteps]="4" [selectedPlan]="selectedPlanDelayed"
    [discounts]="adjustmentAmount" [(isMonthly)]="newPolicy.policy.isMonthly" [basePrice]="planBasePrice"
    [optionsPrice]="optionsPrice"></app-order-summary>