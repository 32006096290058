import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { DialogsService } from "@cogent/client/shared/services/dialog-service/dialog.service";
import { RecaptchaComponent } from 'ng-recaptcha-2';
import { AuthService } from '@cogent/client/auth';
import { ApiService } from '@cogent/client/api';
import { ActivatedRoute, RouterModule } from "@angular/router";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskDirective, provideEnvironmentNgxMask } from "ngx-mask";

@Component({
    templateUrl: './find-policy.html',
    styleUrls: ['./find-policy.css'],
    standalone: true,
    imports: [MatTabsModule, MatIconModule, MatFormFieldModule, MatProgressSpinnerModule, NgxMaskDirective, RouterModule,
        FormsModule, RecaptchaModule, CommonModule, MatInputModule, MatButtonModule]
})
export class FindPolicyComponent implements OnInit {
    @ViewChild(RecaptchaComponent) captcha: RecaptchaComponent;
    isCaptchaValid: boolean;
    captchaResponse: string;
    postalCode = '';
    streetAddress = '';
    name = '';
    policyNumber: number;
    findByPolicyNumber = false;
    findByStreetAddress = false;
    findByPhoneNumber = false;
    findByName = false;
    findByEmail = false;
    selectedIndex = 0;
    finding: boolean;
    searchResults: any;
    phoneNumber: string;
    smsCodeVisible = false;
    smsCode: string;
    email: string;
    recaptchaSiteKey: string;
    apelloMode = false;
    isLegacy = true;


    constructor(private authService: AuthService,
        private serviceRepository: ServiceRepositoryService,
        private activatedRoute: ActivatedRoute,
        private entityApi: EntityApiService,
        private dialogs: DialogsService) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['find-type'] === 'phone') {
                this.findByPhoneNumber = true;
                this.findByPolicyNumber = false;
            }
            if (params['phone-number']) {
                this.phoneNumber = params['phone-number'];
            }
            if (params['Source'] && params['Source'] === 'Apello') {
                this.apelloMode = true;
            }
        });


        this.entityApi.getRecaptchaSiteKey().then(r => {
            this.recaptchaSiteKey = r.key;
        });

    }

    login(property: any) {
        if (property.status !== 'Active') {
            this.dialogs.alert('Not Active', 'Sorry, your subscription is not currently active.<br>Please call 801-804-5132 for further assistance.');
            return;
        }
        window.localStorage.setItem("redirectTo", "/request-service/" + property.id + window.location.search);
        localStorage.removeItem('auth');
        this.authService.logIn(property.holderId, null, null, null, !ApiService.isLegacy);
    }

    resetType() {
        this.findByEmail = false;
        this.findByName = false;
        this.findByPhoneNumber = false;
        this.findByPolicyNumber = false;
        this.findByStreetAddress = false;
    }

    get noSelectionMade() {
        return !this.findByPolicyNumber
            && !this.findByStreetAddress
            && !this.findByPhoneNumber
            && !this.findByName
            && !this.findByEmail;
    }

    get canSendSMSCode() {
        if (!this.phoneNumber) {
            return false;
        }
        return UtilitiesService.validatePhoneNumber(this.phoneNumber);
    }

    get canSendEmailCode() {
        if (!this.email) {
            return false;
        }

        return UtilitiesService.validateEmail(this.email);
    }

    showFindByPolicyNumber() {
        this.findByPolicyNumber = true;
        this.findByStreetAddress = false;
        this.findByName = false;
        this.findByPhoneNumber = false;
        this.findByEmail = false;
    }

    showFindByStreetAddress() {
        this.findByPolicyNumber = false;
        this.findByStreetAddress = true;
        this.findByName = false;
        this.findByPhoneNumber = false;
        this.findByEmail = false;
    }

    showFindByName() {
        this.findByPolicyNumber = false;
        this.findByStreetAddress = false;
        this.findByName = true;
        this.findByPhoneNumber = false;
        this.findByEmail = false;
    }

    showFindByPhoneNumber() {
        this.findByPolicyNumber = false;
        this.findByStreetAddress = false;
        this.findByName = false;
        this.findByPhoneNumber = true;
        this.findByEmail = false;
        this.smsCodeVisible = false;
    }

    showFindByEmail() {
        this.findByPolicyNumber = false;
        this.findByStreetAddress = false;
        this.findByName = false;
        this.findByPhoneNumber = false;
        this.findByEmail = true;
        this.smsCodeVisible = false;
    }

    handleCorrectCaptcha(evt) {
        this.isCaptchaValid = true;
        this.captchaResponse = evt;
    }

    reset() {
        if (this.captcha) {
            this.captcha.reset();
            this.isCaptchaValid = false;
            this.captchaResponse = null;
        }
    }

    getPictureUrl(item) {
        return ApiService.endPointDotNet + 'address/' + item.addressId + '/StreetView';
    }

    captchaExpired() {
        this.isCaptchaValid = false;
        this.captchaResponse = null;
    }

    get formValid(): boolean {
        if ((this.captchaResponse || this.recaptchaSiteKey === '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' || this.apelloMode)
            && ((this.findByPolicyNumber && this.policyNumber)
                || (this.findByStreetAddress && this.streetAddress)
                || (this.findByName && this.name))
            && this.postalCode
            && this.postalCode.length === 5) {
            return true;
        }

        return false;
    }

    private setResponse(response) {
        this.finding = false;
        // this.captcha.reset();
        this.captchaResponse = null;
        if (response.success !== "true" && !this.findByPhoneNumber && !this.findByEmail) {
            // This should never happen
            this.dialogs.alert('Cannot confirm', 'Sorry, the captcha result does not verify');
            return;
        }

        if (response.success !== 'true' && (this.findByPhoneNumber || this.findByEmail)) {

            this.dialogs.alert('Cannot confirm', 'Sorry, we could not verifiy with that code.');
            return;
        }

        const filtered: any[] = [];

        response.results.forEach(policy => {
            if (policy.status === 'Active' || policy.status === 'Suspended') {
                filtered.push(policy);
            } else if (policy.canceledDate) {
                const dateToCheck = new Date(policy.canceledDate);
                dateToCheck.setMonth(dateToCheck.getDate() + 3);
                if (dateToCheck < new Date()) {
                    filtered.push(policy);
                }
            } else if (policy.status === 'Pending') {
                let policyToCheck = policy;
                let addPolicy = true;
                while (policyToCheck) {
                    policyToCheck = response.results.find(i => i.previousPolicyId === policyToCheck.id);
                    if (policyToCheck && (policyToCheck.status === 'Suspended' || policyToCheck.status === 'Active' || policyToCheck.status === 'Pending')) {
                        addPolicy = false;
                    }
                }
                if (addPolicy) {
                    filtered.push(policy);
                }
            } else if (policy.status === 'Expired') {
                const renewal = response.results.filter(i => i.previousPolicyId === policy.id)[0];

                if (!renewal) {
                    const dateToCheck = new Date(policy.canceledDate);
                    dateToCheck.setMonth(dateToCheck.getDate() + 3);
                    if (dateToCheck < new Date()) {
                        filtered.push(policy);
                    }

                }
            }
        });
        response.results = filtered;
        if (response.results.length === 0) {
            this.dialogs.alert('Nothing Found', 'Sorry, no properties were found matching the information you provided.');
            this.reset();
            return;
        }

        this.searchResults = response;
        this.selectedIndex = 1;
    }

    back() {
        this.selectedIndex = 0;
    }

    findByPhone() {
        this.finding = true;
        this.serviceRepository.getFindByPhoneNumberVerificationCode(this.phoneNumber).then(() => {
            this.finding = false;
            this.smsCodeVisible = true;
        });
    }

    findCodeByEmail() {
        this.finding = true;
        this.serviceRepository.getFindByEmailVerificationCode(this.email).then(() => {
            this.finding = false;
            this.smsCodeVisible = true;
        });
    }

    findPropertiesBySMSVerificationCode() {
        this.finding = true;
        this.serviceRepository.findPolicyByPhoneNumber(this.phoneNumber, this.smsCode).then(response => {
            this.setResponse(response);
            this.finding = false;
        });
    }

    findPropertiesByEmailVerificationCode() {
        this.finding = true;
        this.serviceRepository.findPolicyByEmail(this.email, this.smsCode).then(response => {
            this.setResponse(response);
            this.finding = false;
        });
    }

    find() {
        this.finding = true;
        if (this.findByPolicyNumber) {
            this.serviceRepository.findPolicyByNumberAndPostalCode(this.policyNumber, this.postalCode, this.captchaResponse, this.apelloMode).then(response => {
                this.setResponse(response);
            });
        } else if (this.findByStreetAddress) {
            this.serviceRepository.findPolicyByAddressAndPostalCode(this.streetAddress, this.postalCode, this.captchaResponse, this.apelloMode).then(response => {
                this.setResponse(response);
            });
        } else if (this.findByName) {
            this.serviceRepository.findPolicyByNameAndPostalCode(this.name, this.postalCode, this.captchaResponse, this.apelloMode).then(response => {
                this.setResponse(response);
            });
        }
    }
}
