import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CancelReason {
    @keyColumn() id: string;
    @column() type: string;
    @column() reason: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() lastModifiedDate?: Date;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;
    @column() availableOnHomeownerCoverage: boolean;
    @column() availableOnBuyerCoverage: boolean;
    @column() availableOnRenewalCoverage: boolean;
    @column() availableOnSellerCoverage: boolean;
    @column() sendEmail?: boolean;
    @column() blockRenewalNotices?: boolean;

    saving: boolean = false;
    deleting: boolean = false;
}