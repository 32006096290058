import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css'],
  standalone: true,
  imports: [ CommonModule ]
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  isLegacy = true;

  ngOnInit(): void {
  }

}
