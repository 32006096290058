import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { PolicyAdjustment } from '@upkeeplabs/models/cogent';

export class PolicyRenewalModel {
    renewalPlanId: string;
    optionalItems: SalesItemModel[];
    isMonthly: boolean = true;
    stripeCardId: string;
    createdSource: string;
    adjustments: PolicyAdjustment[];
    promotionCodeId: string;
}
