import { Component, OnInit } from '@angular/core';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarModule, CalendarView, DateAdapter } from 'angular-calendar';
import { Subject } from 'rxjs';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import {
    isSameDay,
    isSameMonth,
} from 'date-fns';
import { Router, RouterModule } from '@angular/router';
import { Entity, MaintenanceServiceCustomerPropertyServiceAppointmentSummary, WorkOrderSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatMenuModule,
        CalendarModule,
        RouterModule,
        // CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        MatIconModule]
})
export class CalendarComponent implements OnInit {

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    events: CalendarEvent[] = [];
    refresh: Subject<any> = new Subject();
    startHour = 6;
    endHour = 19;
    entity: Entity;
    activeDayIsOpen = false;
    maintenanceAvailable = false;
    isLegacy = true;

    viewDate: Date = new Date();

    propertyAppointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    workOrders: WorkOrderSummary[];
    constructor(
        private customerService: CustomerRepositoryService,
        private router: Router,
        private missionService: MissionService,
        private maintApi: MaintenanceServiceApiService,
        private entityApi: EntityApiService,) { }

    async ngOnInit() {
        this.entityApi.getLoggedInUser().then(user => {
            this.entity = user;
            this.customerService.getAllAppointmentsForLogin(user.loginId).then(async appointments => {
                this.workOrders = appointments;
                this.events = appointments.map(i => {
                    const actions: CalendarEventAction[] = i.technicianId ? [
                        {
                            label: ``,
                            onClick: ({ event }: { event: CalendarEvent }): void => { }
                        }
                    ] : [];
                    return {
                        start: this.getDateFromRecord(i, true),
                        end: this.getDateFromRecord(i, false),
                        meta: {
                            id: i.id,
                            subject: i.propertyAddress,
                            workOrderSummary: i,
                        },
                        title: `${i.itemName} - ${i.propertyAddress}`,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        },
                        actions,
                    };
                });


                this.propertyAppointments = await this.maintApi.getAppointmentsForLogin(user.loginId);
                this.loadNewCalendar();
                this.events = this.events.concat(this.propertyAppointments.map(i => {
                    const actions: CalendarEventAction[] = [];
                    return {
                        start: i.scheduledDate,
                        end: i.scheduledDate,
                        meta: {
                            id: i.id,
                            subject: i.maintenanceServiceName,
                            workOrderSummary: i,
                        },
                        title: `${i.maintenanceServiceName}`,
                        draggable: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true
                        },
                        actions,
                    };
                }));


            });
        });
    }

    handleEvent(action: string, event: CalendarEvent): void {

        if (action === 'Clicked') {

            this.selectItem({
                id: event.meta.id,
            });
        }
    }

    items: DayWorkOrders[] = [];

    backMonth() {
        const dt = new Date(this.viewDate);
        dt.setMonth(dt.getMonth() - 1);
        this.viewDate = dt;
        this.loadNewCalendar();
    }

    forwardMonth() {
        const dt = new Date(this.viewDate);
        dt.setMonth(dt.getMonth() + 1);
        this.viewDate = dt;
        this.loadNewCalendar();
    }

    loadNewCalendar() {

        this.items = [];
        const date = UtilitiesService.monthStart(this.viewDate);
        while (date.getDay() !== 0) {
            date.setDate(date.getDate() - 1);
        }
        const endDate = UtilitiesService.monthEnd(this.viewDate);
        while (date < endDate) {


            let fileDay = this.items.find(i => i.day.getDate() === date.getDate() && i.day.getMonth() === date.getMonth() && i.day.getFullYear() === date.getFullYear());
            if (!fileDay) {
                fileDay = new DayWorkOrders();
                fileDay.day = new Date(date);
                fileDay.workOrders = [];
                this.items.push(fileDay);
            }
            fileDay.previousMonth = date.getMonth() !== this.viewDate.getMonth();

            if (!fileDay.previousMonth) {
                fileDay.maintenanceServiceAppointments = this.propertyAppointments.filter(i => i.scheduledDate.getMonth() === fileDay.day.getMonth() && i.scheduledDate.getFullYear() === fileDay.day.getFullYear()
                    && i.scheduledDate.getDate() === fileDay.day.getDate());
                fileDay.workOrders = this.workOrders.filter(i => i.scheduledDate.getMonth() === fileDay.day.getMonth() && i.scheduledDate.getFullYear() === fileDay.day.getFullYear()
                    && i.scheduledDate.getDate() === fileDay.day.getDate());
            }

            date.setDate(date.getDate() + 1);
        }

    }

    get calendarUrl() {
        if (!this.entity) {
            return null;
        }
        return `${ApiService.endPointNode}scheduled-maintenance/customer-ical/${this.entity.loginId}`;
    }

    get appleWebCalCalendarUrl() {
        const calendarUrl = this.calendarUrl;
        if (calendarUrl) {
            return calendarUrl.replace('https://', 'webcal://');
        }
    }

    get googleWebCalCalendarUrl() {
        const calendarUrl = this.calendarUrl;
        if (calendarUrl) {
            return `https://calendar.google.com/calendar/render?cid=${this.calendarUrl}`;
        }
    }

    copyUrl() {
        navigator.clipboard.writeText(this.calendarUrl);
        this.missionService.showSuccessToast('URL Copied');
    }


    private setHourAndMinuteFromString(date: Date, hourString: string) {
        let hour = 0;
        let minute = 0;
        const dotIndex = hourString?.indexOf(':');

        if (dotIndex > -1) {
            hour = parseInt(hourString.substring(0, dotIndex), 10);
        } else {
            hour = parseInt(hourString, 10);
        }

        if (dotIndex > -1) {
            minute = parseInt(hourString.substring(dotIndex + 1, hourString.length), 10);

        }

        if (hourString?.toLowerCase().indexOf('pm') > -1 && hour !== 12) {
            hour += 12;
        }

        date.setHours(hour);
        date.setMinutes(minute);
    }

    private getDateFromRecord(record: any, start: boolean) {

        const baseDate = new Date(record.scheduledDate);

        this.setHourAndMinuteFromString(baseDate, start ? record.scheduledStartWindow : record.scheduledEndWindow);
        return baseDate;
    }

    selectItem(item) {
        const appt = this.propertyAppointments.find(i => i.id === item.id);
        if (appt) {
            this.router.navigateByUrl(`/maintenance-service-property/${appt.maintenanceServiceCustomerPropertyId}?appointmentId=${appt.id}`);
        } else {
            this.router.navigateByUrl(`work-order/${item.id}`);
        }
    }

    get excludeDays() {
        return [];
    }

    closeOpenMonthViewDay(data = null) {
        this.activeDayIsOpen = false;
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
        }
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    async eventTimesChanged({
        event,
        newStart,
        newEnd
    }: CalendarEventTimesChangedEvent, techAndWorkOrders?: any) {

        // const datePipe = new DatePipe('en-US');
        // if (event && event.meta && event.meta.type === 'unassigned') {
        //     if (!techAndWorkOrders) {
        //         return;
        //     }

        //     if (!UtilitiesService.datesEqual(newStart, event.start)) {
        //         this.dialogService.confirm('Confirm', `The original date selected was ${datePipe.transform(event.start, 'shortDate')}.  You selected an appointment date of ${datePipe.transform(newStart, 'shortDate')}.<br>Are you sure you want to continue?`).subscribe(async results => {
        //             if (results) {
        //                 // alert('make change');
        //                 newEnd = new Date(newStart);
        //                 const numberOfHours = event.end.getHours() - event.start.getHours();

        //                 newEnd.setHours(newStart.getHours() + numberOfHours);

        //                 this.updateAppointment(event, newStart, newEnd, true, techAndWorkOrders.technician.id);
        //             }
        //         });

        //         return;
        //     }

        //     return;
        // }
    }
}

export class DayWorkOrders {
    day: Date;
    workOrders: WorkOrderSummary[];
    maintenanceServiceAppointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    previousMonth = false;
    get dayNumber() {
        return this.day.getDate();
    }
}
