import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-send-text-message-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './send-text-message-icon.component.html',
  styleUrls: ['./send-text-message-icon.component.css']
})
export class SendTextMessageIconComponent {
    oneRunning = false;
    twoRunning = false;
    threeRunning = false;

    @Input() height = '60px';

    constructor() { }

    ngOnInit(): void {
        this.doAnimation();

    }

    private doAnimation() {
        setTimeout(() => {
            this.oneRunning = true;
        }, 300);

        setTimeout(() => {
            this.twoRunning = true
        }, 600);

        setTimeout(() => {
            this.threeRunning = true
        }, 900);

        setTimeout(() => {
            // this.oneRunning = false;
            // this.twoRunning = false;
            // this.threeRunning = false;

            // setTimeout(() => {
            //     this.doAnimation();
            // }, 1000);
        }, 5000);
    }
}
