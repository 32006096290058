<div aflex>
    <div>
        <h2 class="sub-heading">Change Frequency</h2>
    </div>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<!-- <pre>
    {{service | json}}
</pre> -->


<div *ngIf="selectedOffering">
    <div class="center">
        <button mat-button class="toggle-button frequency-button"
            [class.selected]="selectedOffering.selectedOffering === offering" mat-raised-button
            (click)="selectOffering(selectedOffering, offering)"
            *ngFor="let offering of selectedOffering.filteredOfferings">
            {{offering.frequencyType}}
            <h2 class="price">${{offering.caluclatedPrice}}</h2>
            <div>Per Visit</div>
        </button>
    </div>

    <div class="diff-totals" aflex *ngIf="currentDiff">

        <div>
            <h2 class="sub-heading">{{currentDiff.proratedAmount | currency}}</h2>
            <div class="data-label" *ngIf="currentDiff.proratedAmount > 0">Credit</div>
            <div class="data-label" *ngIf="currentDiff.proratedAmount < 0">Due Today</div>
        </div>
        <div>
            <h2 class="sub-heading">{{currentDiff.serviceMonthlyPrice | currency}}</h2>
            <div class="data-label">Service Monthly Price</div>
        </div>
        <div>
            <h2 class="sub-heading">{{currentDiff.newMonthlyPrice | currency}}</h2>
            <div class="data-label">New Monthly Payment</div>
        </div>
    </div>
</div>


<div aflex topmargin40>
    <div></div>
    <div nogrow>
        <button mat-button large mat-raised-button color="primary" (click)="save()" [disabled]="!canSave">
            <mat-spinner class="thirty" *ngIf="saving"></mat-spinner>
            Save
        </button>
    </div>
</div>