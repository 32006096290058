import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-add-coverage-menu',
  templateUrl: './add-coverage-menu.component.html',
  styleUrls: ['./add-coverage-menu.component.css'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterModule]
})
export class AddCoverageMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
