import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { GroupedProductOfferings, MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceOfferingSummaryWithPostalCode } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MaintenanceServiceLogic } from '@cogent/client/shared/logic/maintenance-service.logic';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-maint-services-change-frequency',
    templateUrl: './maint-services-change-frequency.component.html',
    styleUrls: ['./maint-services-change-frequency.component.css'],
     standalone: true,
     imports: [MatButtonModule, MatIconModule, MatProgressSpinnerModule, CommonModule]
})
export class MaintServicesChangeFrequencyComponent implements OnInit {

    offerings: GroupedProductOfferings[];
    selectedOffering: GroupedProductOfferings;
    saving = false;
    originalOffering: MaintenanceServiceOfferingSummaryWithPostalCode;
    currentDiff: any;

    constructor(public dialogRef: MatDialogRef<MaintServicesChangeFrequencyComponent>,
        @Inject(MAT_DIALOG_DATA) public service: MaintenanceServiceCustomerPropertyServiceSummary,
        private missionService: MissionService,
        private maintApi: MaintenanceServiceApiService) { }

    ngOnInit(): void {
        this.maintApi.getMaintenanceOfferingForAddressId(this.service.addressId).then(offerings => {
            this.maintApi.getAddress(this.service.addressId).then(address => {
                MaintenanceServiceLogic.applyFilters(offerings, address);
                this.selectedOffering = offerings.find(i => i.maintenanceServiceId === this.service.maintenanceServiceId);
                this.selectedOffering.filteredOfferings = UtilitiesService.copyArrayToTypedArray(this.selectedOffering.filteredOfferings, () => new MaintenanceServiceOfferingSummaryWithPostalCode());
                this.selectedOffering.selectedOffering = this.selectedOffering.filteredOfferings.find(i => i.maintenanceServicePricingId === this.service.maintenanceServicePricingId);
                this.originalOffering = this.selectedOffering.selectedOffering;
                if (this.originalOffering) {
                    this.selectOffering(this.selectedOffering, this.originalOffering);
                }
            });
        });
    }

    async selectOffering(grouping: GroupedProductOfferings, offering: MaintenanceServiceOfferingSummaryWithPostalCode) {
        grouping.selectedOffering = offering;

        const delta = await this.maintApi.getFrequencyChangeDifferential(this.service.id, offering);
        this.currentDiff = delta;
    }

    get canSave() {
        return !this.saving && this.selectedOffering && this.selectedOffering.selectedOffering && this.selectedOffering.selectedOffering !== this.originalOffering;
    }

    async save() {
        this.saving = true;
        await this.maintApi.changeServiceFrequency(this.service.id, this.selectedOffering.selectedOffering);
        this.saving = false;

        this.missionService.showSuccessToast('Frequency Updated');
        this.dialogRef.close(true);
    }
}
