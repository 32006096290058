import { Component } from '@angular/core';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    standalone: true,
    styleUrls: ['./contact.component.css']
})
export class ContactComponent {

}
