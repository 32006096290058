<div class="navigation-bar">
    <div class="container">
        <div aflex>
            <div>
                <h1>Register</h1>
            </div>
            <div nogrow>
                <button *ngIf="hasNextButton" mat-button mat-raised-button (click)="next()" [disabled]="!canGoNext"
                    class="small-primary" color="primary">
                    <mat-spinner *ngIf="goingNext" class="thirty"></mat-spinner>
                    Next</button>
            </div>
        </div>
    </div>
</div>
<div class="container top-margin" [class.legacy]="legacyPortal">
    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="0">
            <div class="center">
                <h1>Find Your Email</h1>
                <p class="info">Let's see if we can find your email address</p>
            </div>



            <div class="small-container" topmargin40>

                <img class="email-icon" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/email-orange-blob.svg">
                <br>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput [appEnterAction]="next" [componentRef]="this" type="email"
                        [(ngModel)]="args.emailAddress">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="1">
            <div class="center">
                <h1>You're Registered!</h1>
                <p class="info">Good news, it looks like you're already registered with that email address</p>

                <a mat-button mat-raised-button routerLink="/login">Log In</a>&nbsp;
                <a mat-button mat-raised-button routerLink="/security/reset-password">Reset Password</a>
            </div>
        </mat-tab>
        <mat-tab label="2">

            <div class="center">
                <h1>Check Your Inbox</h1>
                <p class="info">
                    Good news, we found a subscription that matches your email address.<br>
                    We've sent you a registration code
                </p>

                <div class="small-container" topmargin40>
                    <div bottommargin40>
                        <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/inbox-animated.svg?id={{newid}}" *ngIf="selectedIndex === 2"
                            class="email-icon">
                    </div>

                    <mat-form-field style="max-width: 250px;" appearance="outline">
                        <mat-label>Registration Code</mat-label>
                        <input matInput type="number" [appEnterAction]="next" [componentRef]="this"
                            [(ngModel)]="args.registrationCode">
                    </mat-form-field>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="3">
            <div class="center">
                <h1>Find Your Phone</h1>
                <p class="info">Let's see if we can find your phone number</p>
            </div>
            <div class="small-container" topmargin40>
                <mat-form-field appearance="outline">
                    <mat-label>Phone</mat-label>
                    <input matInput [appEnterAction]="next" mask="(000) 000-0000" [componentRef]="this" type="tel"
                        [(ngModel)]="args.phoneNumber">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="4">
            <div class="center">
                <h1>Check Your Messages</h1>
                <p class="info">
                    Good news, we found a subscription that matches your phone number.<br>
                    We've sent you a registration code via SMS text message.
                </p>

                <div class="small-container" topmargin40>
                    <div bottommargin40 style="margin-top: -80px;">
                        <app-send-text-message-icon *ngIf="selectedIndex === 4"></app-send-text-message-icon>
                    </div>

                    <mat-form-field style="max-width: 250px;" appearance="outline">
                        <mat-label>Registration Code</mat-label>
                        <input matInput type="number" [appEnterAction]="next" [componentRef]="this"
                            [(ngModel)]="args.registrationCode">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="5">
            <div class="center">
                <h1>Create Your Account</h1>
                <p class="info">
                    We just need a little contact information and then we can create your account
                </p>
                <div class="small-container" style="max-width: 800px;" topmargin40>
                    <app-basic-entity-entry [entity]="args.newCustomer" [(canSave)]="newCustomerValid"
                        [nameIsSearchBox]="false" [showRequiredName]="true" [showRequiredEmail]="true" [addressRequired]="true" 
                        [homePhoneRequired]="true" [showSinglePhoneNumberEntry]="true" [hideWebsiteEntry]="true" [hideInactiveBox]="true"
                        [hideContactMethodsEntry]="true" [hidePreferreds]="true" [hidePaperless]="true"
                        [hideTimezoneEntry]="true">

                    </app-basic-entity-entry>
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" [appEnterAction]="next" [componentRef]="this"
                            [(ngModel)]="args.password">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Password Cofirm</mat-label>
                        <input type="password" [appEnterAction]="next" [componentRef]="this" matInput
                            [(ngModel)]="args.passwordConfirm">
                        <mat-hint align="end" class="error-pwd" *ngIf="passwordsDontMatch">Passwords Don't
                            Match</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="6">
            <div class="center">

                <h1>Set your password</h1>
                <p class="info">
                    We'll have you set your password and then you'll be all set to log in
                </p>
            </div>

            <div class="small-container" topmargin40>
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/login-animated.svg?id={{newid}}" class="email-icon"
                    *ngIf="selectedIndex === 6">
                <mat-form-field appearance="outline" topmargin40>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" [appEnterAction]="next" [componentRef]="this"
                        [(ngModel)]="args.password">

                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password Cofirm</mat-label>
                    <input type="password" [appEnterAction]="next" [componentRef]="this" matInput
                        [(ngModel)]="args.passwordConfirm">

                    <mat-hint align="end" class="error-pwd" *ngIf="passwordsDontMatch">Passwords Don't Match</mat-hint>
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="7">

            <div class="center">

                <h1>User Name &amp; Password</h1>
                <p class="info">
                    We'll have you set your user name password and then you'll be all set to log in
                </p>
            </div>

            <div class="small-container" topmargin40>
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/login-animated.svg?id={{newid}}" class="email-icon"
                    *ngIf="selectedIndex === 7">
                <mat-form-field appearance="outline" topmargin40>
                    <mat-label>Email</mat-label>
                    <input matInput type="email" [appEnterAction]="next" [componentRef]="this"
                        [(ngModel)]="args.emailAddress">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" [appEnterAction]="next" [componentRef]="this"
                        [(ngModel)]="args.password">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password Cofirm</mat-label>
                    <input type="password" [appEnterAction]="next" [componentRef]="this" matInput
                        [(ngModel)]="args.passwordConfirm">
                    <mat-hint align="end" class="error-pwd" *ngIf="passwordsDontMatch">Passwords Don't Match</mat-hint>
                </mat-form-field>
            </div>
        </mat-tab>

        <mat-tab label="8">
            <div class="center" topmargin30 bottommargin20>
                <app-check-circle-animated></app-check-circle-animated>
            </div>
            <div class="center">
                <h1>Success</h1>
                <p class="info">Your login has been created. </p>
                <a mat-button mat-raised-button routerLink="/" color="primary">Login</a>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>