<div *ngIf="entity" topmargin20>
    <div>{{entity.name}}</div>
    <div flex>
        <div flex1 *ngIf="entity.address">

            <div>{{entity.address.address1}}</div>
            <div>{{entity.address.city}}, {{entity.address.state}} {{entity.address.postalCode}}</div>

        </div>
        <div flex1>
            <div *ngIf="entity.mobileNumber && entity.mobileNumber.number"><app-phone-display [phoneNumber]="entity.mobileNumber.number"></app-phone-display></div>
            <div *ngIf="entity.officeNumber && entity.officeNumber.number"><app-phone-display [phoneNumber]="entity.officeNumber.number"></app-phone-display></div>
            <div *ngIf="entity.homeNumber && entity.homeNumber.number"><app-phone-display [phoneNumber]="entity.homeNumber.number"></app-phone-display></div>

            <div *ngIf="entity.email">
                <app-email-compose-link [emailAddress]="entity.email"></app-email-compose-link></div>
        </div>
    </div>
</div>