import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
// import { formatDistance } from 'date-fns'
import { Entity } from '@upkeeplabs/models/cogent';


@Component({
    standalone: true,
    imports: [CommonModule, MatMenuModule, MatIconModule],
    selector: 'app-route-watcher',
    templateUrl: './route-watcher.component.html',
    styleUrls: ['./route-watcher.component.scss']
})

export class RouteWatcherComponent implements OnInit {
    url: string[]
    usersOnRoute: { id: string, lastModifiedDate: Date, name: string }[]
    currentUser: Entity;
    usersOnRouteWithTiming

    constructor(private entityApi: EntityApiService) {
        this.url = window.location.pathname.split('/')

        this.entityApi.getLoggedInUser().then(r => {
            this.currentUser = r
        })
    }

    interval: number;
    run = true;

    async update() {
        if (this.run) {
            this.currentUser && await this.getUsersWatcher();
            setTimeout(() => {
                this.update();
            }, 15000);
        }
    }
    async ngOnInit() {
        this.update();

    }

    async ngOnDestroy() {
        this.run = false;
    }


    async getUsersWatcher() {
        this.usersOnRoute = await this.entityApi.postUsersOnRoute(this.url.slice(-1).toString());
        this.usersOnRouteWithTiming = this.usersOnRoute.map(item => {
            return {
                ...item, timeDiff: ''
            }
        })
    }

}