<div class="container">

    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="Tab 1">
            <!-- <h1 class="page-title"><span class="big">Find</span><br><span class="small">Subscription</span></h1> -->
            <div style="padding-left: 15px;padding-right: 15px">
                <h2 class="sub-heading">Find Your Subscription</h2>

                <div *ngIf="noSelectionMade" class="info" bottommargin30>
                    Let's get started. How would you like to find your subscription?
                </div>
                <div *ngIf="!noSelectionMade" class="info" bottommargin30>
                    &nbsp;
                </div>

                <div style="display: inline-block;">
                    <div class="type-selection-buttons" [class.not-selected]="noSelectionMade">

                        <div *ngIf="findByPhoneNumber || noSelectionMade">
                            <button class="toggle-button" [class.selected]="findByPhoneNumber" mat-button
                                (click)="showFindByPhoneNumber()">
                                <h2>Text</h2>
                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/send-by-text.svg">
                                <p>Find by texting an access code</p>

                            </button>
                        </div>
                        <div *ngIf="findByEmail || noSelectionMade">
                            <button class="toggle-button" [class.selected]="findByEmail" mat-button
                                (click)="showFindByEmail()">
                                <h2>Email</h2>
                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/email-orange-blob.svg">
                                <p>Find by emailing an access code</p>
                            </button>
                        </div>
                        <div *ngIf="findByPolicyNumber || noSelectionMade">
                            <button class="toggle-button" [class.selected]="findByPolicyNumber" mat-button
                                (click)="showFindByPolicyNumber()">
                                <h2>Subscription</h2>
                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/description-orange-blog.svg">
                                <p>Lookup by using subscription # and postal code</p>
                            </button>
                        </div>
                        <div *ngIf="findByStreetAddress || noSelectionMade">
                            <button class="toggle-button" [class.selected]="findByStreetAddress" mat-button
                                (click)="showFindByStreetAddress()">
                                <h2>Street</h2>
                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/house-and-clouds-orange-blob.svg">
                                <p>Lookup by using street address and postal code</p>
                            </button>
                        </div>
                        <div *ngIf="findByName || noSelectionMade" style="border-bottom: none;">
                            <button class="toggle-button" [class.selected]="findByName" mat-button
                                (click)="showFindByName()" data-cy="findByName">
                                <h2>Name</h2>
                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/person-orange-blob.svg">
                                <p>Lookup by using name and postal code</p>
                            </button>
                        </div>

                        <div *ngIf="!noSelectionMade">
                            <button class="toggle-button" mat-button (click)="resetType()"
                                style="white-space: normal;text-transform: none;line-height: 14px;padding: 0">
                                <mat-icon style="font-size: 48px;width: 48px;height: 48px">clear</mat-icon><br>
                                Choose a different Method
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="noSelectionMade">
                    <div class="info" bottommargin20>Already have an account? <a style="text-decoration:underline"
                            routerLink="/homeowner-app">Login</a></div>

                </div>


                <div class="fade-in-up" *ngIf="!findByPhoneNumber && !findByEmail && !noSelectionMade">
                    <mat-form-field appearance="outline" *ngIf="findByPolicyNumber" class="slide-in-down"
                        style="max-width: 300px">
                        <mat-label>Subscription #</mat-label>
                        <input matInput type="number" [(ngModel)]="policyNumber">
                    </mat-form-field>
                    <mat-form-field style="max-width: 600px" *ngIf="findByStreetAddress" appearance="outline"
                        class="slide-in-down">
                        <mat-label>Street Address</mat-label>
                        <input matInput [(ngModel)]="streetAddress">
                        <mat-hint align="end">Enter exactly as it appears on your contract</mat-hint>
                    </mat-form-field>
                    <mat-form-field *ngIf="findByName" class="slide-in-down" style="max-width: 600px"
                        appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="name" data-cy="name-field">
                        <mat-hint align="end">Enter exactly as it appears on your contract</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="max-width: 300px">
                        <mat-label>Postal Code</mat-label>
                        <input matInput [(ngModel)]="postalCode" data-cy="postal-field">
                        <mat-hint align="end">90210</mat-hint>
                    </mat-form-field>

                    <re-captcha [siteKey]="recaptchaSiteKey" *ngIf="!apelloMode"
                        (resolved)="handleCorrectCaptcha($event)">
                    </re-captcha>

                    <button topmargin20 [disabled]="!formValid || finding" mat-button mat-raised-button large
                        mobile-block color="primary" (click)="find()" data-cy="submit-button"><i class="material-icons"
                            *ngIf="!finding">search</i>
                        <mat-spinner *ngIf="finding" class="thirty"></mat-spinner> Find
                    </button>
                </div>
                <div *ngIf="findByPhoneNumber" class="fade-in-up">


                    <ng-container *ngIf="!smsCodeVisible">
                        <form>
                            <p class="info" bottommargin30>
                                Enter your mobile number and we will text you a verification code to access your
                                account.
                                <br>It
                                must match the number we have on file.
                            </p>
                            <mat-form-field appearance="outline" style="max-width: 300px">
                                <mat-label>Phone Number</mat-label>
                                <input matInput name="find-by-phone" [(ngModel)]="phoneNumber" type="tel"
                                    mask="(000) 000-0000">
                            </mat-form-field>

                            <button mat-button mat-raised-button large [disabled]="finding || !canSendSMSCode"
                                mobile-block color="primary" (click)="findByPhone()">
                                <mat-spinner *ngIf="finding" class="thirty"></mat-spinner>
                                Send Code
                            </button>
                        </form>
                    </ng-container>

                    <div *ngIf="smsCodeVisible" class="slide-in-down">
                        <p class="info" bottommargin30>
                            Please enter the verification code you received.
                        </p>
                        <form>
                            <mat-form-field appearance="outline" style="max-width: 300px">
                                <mat-label>Verification Code</mat-label>
                                <input matInput name="one-time-code" [(ngModel)]="smsCode" inputmode="numeric"
                                    autocomplete="one-time-code">
                            </mat-form-field>

                            <button mat-button mat-raised-button color="primary" [disabled]="finding" large mobile-block
                                (click)="findPropertiesBySMSVerificationCode()">
                                <mat-spinner *ngIf="finding" class="thirty"></mat-spinner>
                                Verify
                            </button>
                        </form>
                        <button mat-button (click)="smsCodeVisible = false">
                            <mat-icon>redo</mat-icon>
                            Resend Code</button>
                    </div>
                </div>

                <div *ngIf="findByEmail" class="fade-in-up">


                    <ng-container *ngIf="!smsCodeVisible">
                        <form>
                            <p class="info" bottommargin30>
                                Enter your email address and we will email you a verification code. <br>It must match
                                the
                                address we have on file.
                            </p>
                            <mat-form-field appearance="outline" style="max-width: 300px">
                                <mat-label>Email</mat-label>
                                <input matInput name="find-by-email-box" [(ngModel)]="email" type="email">
                                <mat-hint align="end">johndoe123&#64;gmail.com</mat-hint>
                            </mat-form-field>

                            <button mat-button mat-raised-button large topmargin20
                                [disabled]="finding || !canSendEmailCode" mobile-block color="primary"
                                (click)="findCodeByEmail()">
                                <mat-spinner *ngIf="finding" class="thirty"></mat-spinner>
                                Send Code
                            </button>
                        </form>
                    </ng-container>

                    <div *ngIf="smsCodeVisible" class="slide-in-down">
                        <p class="info" bottommargin30>
                            Please enter the verification code you received.
                        </p>
                        <form>
                            <mat-form-field appearance="outline" style="max-width: 300px">
                                <mat-label>Verification Code</mat-label>
                                <input matInput [(ngModel)]="smsCode" name="email-one-time-code" inputmode="numeric" autocomplete="one-time-code">
                            </mat-form-field>

                            <button mat-button mat-raised-button color="primary" topmargin20 [disabled]="finding" large
                                mobile-block (click)="findPropertiesByEmailVerificationCode()">
                                <mat-spinner *ngIf="finding" class="thirty"></mat-spinner>
                                Verify
                            </button>
                        </form>
                        <button mat-button (click)="smsCodeVisible = false">
                            <mat-icon>redo</mat-icon>
                            Resend Code</button>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Tab 2">
            <h1 class="page-title"><span class="big">Matched</span><br><span class="small">Results</span></h1>

            <div *ngIf="searchResults" topmargin20>
                <!-- routerLink="/request-service/{{result.id}}" -->
                <a mat-button class="property-button" (click)="login(result)"
                    *ngFor="let result of searchResults.results; let i = index" data-cy="property-item">

                    <div aflex>
                        <div nogrow>
                            <img [src]="getPictureUrl(result)" class="big-icon">
                        </div>
                        <div>
                            <h4 matLine>{{result.address1}}</h4>
                            <p style="margin: 0">{{result.policyNumber}} - {{result.planName}} <span
                                    class="badge">{{result.status}}</span></p>
                        </div>
                    </div>

                </a>
            </div>
            <button topmargin20 mat-button (click)="back()"><i class="material-icons">keyboard_arrow_left</i>
                Back</button>
        </mat-tab>
    </mat-tab-group>


</div>
