<div id="padding-shifter">
    <mat-tab-group style="padding: 0" class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <div style="position: relative;max-width: 433px;padding: 19px;">
                <div id="sceen">
                    <svg [class.disappear]="animationComplete" id="elevate-a-outline" data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460.93 468.91">
                        <polygon points="106.33 468.91 0 468.91 177.67 104.92 223.78 203.3 106.33 468.91" />
                        <polygon points="276.59 96 230.47 193.75 184.36 96 230.47 0 276.59 96" />
                        <polygon points="354.61 467.91 460.93 467.91 283.26 103.92 237.15 202.3 354.61 467.91" />
                    </svg>
                    <svg id="elevate-a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 460.93 468.91">
                        <polygon class="gray-elevate"
                            points="106.33 468.91 0 468.91 177.67 104.92 223.78 203.3 106.33 468.91" />
                        <polygon class="gray-elevate" points="276.59 96 230.47 193.75 184.36 96 230.47 0 276.59 96" />
                        <polygon class="gray-elevate"
                            points="354.61 467.91 460.93 467.91 283.26 103.92 237.15 202.3 354.61 467.91" />
                    </svg>
                </div>
                <h1 id="heading" [class.appear]="animationComplete">Get Totally<br>Elevated</h1>
                <p topmargin40 bottommargin30>Get the most from your Elevate subscription by upgrading today and you'll
                    get a free service call, a ${{policySummary.serviceFeeAmount |  number : '1.0-2'}} value.</p>

                <ul class="upgrade-items">
                    <li *ngFor="let upgradeItem of upgradeItems">
                        <div aflex class="upgrade-item">
                            <div nogrow>
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460.93 468.91">
                                    <polygon class="gray-elevate"
                                        points="106.33 468.91 0 468.91 177.67 104.92 223.78 203.3 106.33 468.91" />
                                    <polygon class="gray-elevate"
                                        points="276.59 96 230.47 193.75 184.36 96 230.47 0 276.59 96" />
                                    <polygon class="gray-elevate"
                                        points="354.61 467.91 460.93 467.91 283.26 103.92 237.15 202.3 354.61 467.91" />
                                </svg>
                            </div>
                            <div style="margin-left: 5px;">
                                {{upgradeItem.name}}
                            </div>
                        </div>
                    </li>
                </ul>

                <div aflex id="figures-container">
                    <div aflex class="figures">
                        <div nogrow>
                            <h2 class="small-figure">
                                ${{upgradeAmount | number : '1.0-2'}} 
                            </h2>
                            <div class="data-label">Upgrade<br> Amount</div>
                        </div>
                        <div nogrow style="font-size: 27px;margin-top: 14px">
                            -
                        </div>
                        <div nogrow>
                            <h2 class="small-figure">
                                ${{policySummary.serviceFeeAmount |  number : '1.0-2'}}
                            </h2>
                            <div class="data-label">Prepaid Service <br> Call Fee</div>
                        </div>
                        <div nogrow style="font-size: 44px;">
                            =
                        </div>
                        <div>
                            <h1 class="large-figure">
                                ${{netAmount |  number : '1.0-2'}}
                            </h1>
                            <div class="data-label">Net Amount</div>
                        </div>
                    </div>
                    <div class="figures-end" nogrow style="margin-left: -1px;"></div>
                </div>


                <button mat-button mat-raised-button color="primary" (click)="selectedIndex = 1">Upgrade</button>
                <button mat-button [mat-dialog-close]>No Thanks</button>
            </div>
        </mat-tab>
        <mat-tab label="Second">
            <div class="upgrade-info">
                <div style="display: flex;height: 100%;flex-direction: column;min-height: 517px;">
                    <div style="flex: 1;">
                        <h1 class="sub-heading">Payment Entry</h1>
                        <div aflex>
                            <div nogrow>
                                <app-display-total [total]="upgradeAmount"></app-display-total>
                            </div>
                            <div>


                            </div>
                        </div>

                        <app-payment-method-entry *ngIf="policySummary" [customerId]="policySummary.holderId"
                            [(selectedCard)]="selectedCard"></app-payment-method-entry>

                        <div topmargin40></div>
                    </div>
                    <div style="flex: none">
                        <button mat-button mat-raised-button color="primary" [disabled]="!canUpgrade"
                            (click)="upgrade()" large block>
                            <mat-spinner *ngIf="upgrading" class="thirty"></mat-spinner>
                            Upgrade
                        </button>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <div class="upgrade-info">
                <div class="center" style="margin-top: 80px;margin-right: 20px;margin-left: 20px;">
                    <div></div>
                    <app-check-circle-animated></app-check-circle-animated>
                    <h1 topmargin40 class="sub-heading" style="font-size: 50px !important;text-transform: none;margin-left: 30px;margin-right: 30px;">Upgrade<br> Complete</h1>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    
<button mat-button id="close-button" *ngIf="selectedIndex !== 2" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
</button>
</div>
