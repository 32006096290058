import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Entity } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-paperless-prompt',
    templateUrl: './paperless-prompt.component.html',
    styleUrls: ['./paperless-prompt.component.css'],
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, MatButtonModule]
})
export class PaperlessPromptComponent implements OnInit {

    user: Entity;
    showPrompt = false;
    paperlessConfirmed = false;
    saving = false;

    constructor(private entityApi: EntityApiService) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.entityApi.getLoggedInUser(true).then(user => {
                this.user = user;
                if (user && !user.paperless && user.type !== 'Agent') {
                    this.showPrompt = true;
                }
            });
        }, 5000);
    }

    async goPaperless() {
        this.saving = true;
        await this.entityApi.goPaperless(this.user.id);
        this.saving = false;
        this.paperlessConfirmed = true;
        setTimeout(() => {
            this.showPrompt = false;
        }, 5000);
    }

    notNow() {
        this.showPrompt = false;
    }

}
