<div mat-dialog-content class="hide-tabs">
    <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab label="Tab 1">
            <div class="dialog-container">
                <div aflex>
                    <div>
                        <h1>{{title}}</h1>
                    </div>
                    <div nogrow>
                        <button mat-button matDialogClose>
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>
                <p class="info hide-mobile">Let's search for that individual by entering their name and pressing the
                    'Search' button</p>
                <form>
                    <div aflex topmargin20>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Search For</mat-label>
                                <input name="searchBox" matInput [(ngModel)]="searchFor">
                                <mat-hint align="end">search for names, emails, phone numbers etc.</mat-hint>
                            </mat-form-field>

                        </div>
                        <div nogrow>
                            <button mat-button mat-raised-button type="submit" class="search-button"
                                (click)="doSearch()" [disabled]="!searchFor || searching">
                                <mat-spinner *ngIf="searching" class="thirty"></mat-spinner>
                                <mat-icon *ngIf="!searching">search</mat-icon> <span class="hide-xs">Search</span>
                            </button>
                        </div>
                    </div>
                </form>



                <div *ngIf="searchResults && !searchResults.length" topmargin30 style="text-align: center;">
                    <h3>
                        <i class="material-icons">info_outline</i> There are no results found.
                    </h3>

                    <div topmargin40>
                        <button mat-button mat-raised-button large color="primary" (click)="createNewEntity()"><i
                                class="material-icons">add</i> Create new</button>
                    </div>
                </div>

                <div class="hide-mobile">
                    <table *ngIf="searchResults && searchResults.length" class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Company</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let result of searchResults" (click)="selectResult(result)">
                                <td>
                                    <mat-spinner class="twenty" *ngIf="result.selecting"></mat-spinner> {{result.title}}
                                </td>
                                <td>{{result.email}}</td>
                                <td>{{result.address1}}</td>
                                <td>{{result.additionalInfo}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="show-mobile" style="border-top: 1px solid rgb(200,200,200);">
                    <button mat-button class="mobile-block" block *ngFor="let result of searchResults"
                        (click)="selectResult(result)">
                        <h2>{{result.title}}</h2>
                        <div>{{result.email}}</div>
                        <div>{{result.address1}}</div>
                        <div>{{result.additionalInfo}}</div>
                    </button>
                </div>

                <div *ngIf="searchResults && searchResults.length" topmargin30>
                    <span>Don't see who you're looking for?</span>
                    <button mobile-block mat-button mat-raised-button topmargin20 (click)="createNewEntity()">
                        <i class="material-icons">add</i> Create new</button>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Parent Search Search">
            <div class="dialog-container">
                <div aflex>
                    <div>
                        <h1>{{parentTitle}}</h1>
                    </div>
                    <div nogrow>
                        <button mat-button matDialogClose>
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>
                <p class="info mobile-hide">Great! We can create a new record, we just need to find this individual's
                    parent company.<br> Enter their company name in the search box and press the 'Search' button.</p>
                <form>
                    <div aflex topmargin20>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Search For</mat-label>
                                <input name="searchBox" matInput [(ngModel)]="parentSearchFor">
                            </mat-form-field>
                        </div>
                        <div nogrow>
                            <button mat-button mat-raised-button type="submit" class="search-button"
                                (click)="doParentSearch()" [disabled]="!searchFor || searching">
                                <mat-icon *ngIf="!searching">search</mat-icon>
                                <mat-spinner *ngIf="searching" class="thirty"></mat-spinner> <span class="hide-xs">
                                    Search</span>
                            </button>
                        </div>
                    </div>
                </form>

                <div *ngIf="parentSearchResults && parentSearchResults.length">
                    <span>Don't see who you're looking for?</span>
                    <button mat-button (click)="createNewParent()" topmargin20>
                        <i class="material-icons">add</i> Create new</button>
                </div>
                <div *ngIf="parentSearchResults && !parentSearchResults.length" topmargin30 style="text-align: center;">
                    <h3>
                        <i class="material-icons">info_outline</i> Sorry, we couldn't find any companies matching that
                        search.
                    </h3>

                    <div topmargin40>
                        <button mat-button mat-raised-button large color="primary" (click)="createNewParent()"><i
                                class="material-icons">add</i> Create new</button>
                    </div>
                </div>

                <table *ngIf="parentSearchResults && parentSearchResults.length" class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let result of parentSearchResults" (click)="selectParentResult(result)">
                            <td>
                                <mat-spinner class="twenty" *ngIf="result.selecting"></mat-spinner> {{result.title}}
                            </td>
                            <td>{{result.email}}</td>
                            <td>{{result.address1}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>
        <mat-tab label="New Parent">

            <div class="dialog-container">

                <div aflex>
                    <div>
                        <h1>{{newParentEntryTitle}}</h1>
                    </div>
                    <div nogrow>
                        <button mat-button matDialogClose>
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>

                <app-basic-entity-entry [showPhoneNumberEntry]="true" [hideContactMethodsEntry]="true"
                    [hideTimezoneEntry]="true" [outline]="true" topmargin30 [nameIsSearchBox]="false"
                    [(canSave)]="canParentSave" [entity]="newParentEntity" style="display: block">
                </app-basic-entity-entry>

                <button mat-button color="primary" mat-raised-button [disabled]="!canParentSave || savingNew"
                    (click)="saveNewParentEntity()">
                    <mat-spinner *ngIf="savingNew" class="thirty"></mat-spinner> Save
                </button>
            </div>
        </mat-tab>
        <mat-tab label="Tab 2">
            <div class="dialog-container">
                <div aflex>
                    <div>
                        <h1>Create New {{newEntity.type}}</h1>
                    </div>
                    <div nogrow>
                        <button mat-button matDialogClose>
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>

                <app-basic-entity-entry [showPhoneNumberEntry]="true" [hideContactMethodsEntry]="true"
                    [hideTimezoneEntry]="true" [outline]="true" topmargin30 [nameIsSearchBox]="false"
                    [(canSave)]="canSave" [entity]="newEntity" style="display: block"></app-basic-entity-entry>

                <button mat-button color="primary" mat-raised-button [disabled]="!canSave || savingNew"
                    (click)="saveNewEntity()">
                    <mat-spinner *ngIf="savingNew" class="thirty"></mat-spinner> Save
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>