import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { ActivatedRoute } from '@angular/router';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { ApiService } from '@cogent/client/api';
import { PlanSelectionItemModel } from '@cogent/shared/models/sales/plan-selection-item.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { CommonModule, DatePipe } from '@angular/common';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { CoverageType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { CircleWidgetSmallModule } from '@cogent/client/shared/components/data-visualization/circle-widget-small/circle-widget-small.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
class Step {
    description: string;
    hideNextButton: boolean;
    showSkipButton: boolean;
}

class InspectionFile {
    fileName: string;
    fileBase64: string;
}

class OnBoardSaveArgs {
    //newPlanId: string;
    customerEmail: string;
    customerHomePhone: string;
    customerMobilePhone: string;
    preferredContactMethod: string;
    paperless: boolean;
    // newOptions: string[];
    // upgradeCard: StripeCard;
    // homeInspection: InspectionFile;
    // sellersDisclosures: InspectionFile;
    autoRenewalCard: StripeCard;
    loginId: string;
    password: string;
    key: string;
}

class ShoppingCartItem {
    description: string;
    price: number;
    quantity: number;
    deleting: boolean;
    iconClass: string;
    pageX: string;
    pageY: string;
    opacity: number;
    id: string;

    get extPrice() {
        return this.quantity * this.price;
    }
}

class GroupedCoverage {
    category: string;
    items: any[];
}

@Component({
    selector: 'app-existing-customer-onboarding',
    templateUrl: './existing-customer-onboarding.component.html',
    styleUrls: ['./existing-customer-onboarding.component.css'],
    standalone: true,
    imports: [
        CommonModule,
         CircleWidgetSmallModule,
         MatTabsModule,
         MatFormFieldModule,
         FormsModule,
         MatInputModule,
         MatIconModule,
         MatButtonModule,
         MatCheckboxModule,
         MatSelectModule,
         MatSlideToggleModule,
         PaymentMethodEntryModule,
         PaymentMethodEntryComponent,
         MatProgressSpinnerModule,
    ]
})
export class ExistingCustomerOnboardingComponent implements OnInit {

    selectedIndex = 0;
    showWelcomeScreen = true;
    starting = false;
    showBackground = true;
    steps: Step[] = [
        { description: 'Contact Information', hideNextButton: false, showSkipButton: false },
        { description: 'Renewals', hideNextButton: true, showSkipButton: true },
        { description: 'Portal Setup', hideNextButton: true, showSkipButton: true },
        { description: 'Review', hideNextButton: true, showSkipButton: false },
    ];
    currentStepDesc: string;
    policyId: string;
    setupAutoRenewalVisible = false;
    shoppingCartItems: ShoppingCartItem[] = [];
    currentItem: ShoppingCartItem;
    uploadId = UtilitiesService.newid();
    uploadType: string;
    customerId: string;
    plans: PlanClient[];
    planSelectionOpen = false;
    selectedPlan: PlanClient;
    selectedCoverageType: CoverageType;
    gettingPlans = false;
    tutorialOpen = false;
    tutorialIndex = 0;
    key: string;
    policySummary: PolicySummary;
    coverage: any[];
    groupedCoverage: GroupedCoverage[];
    endPoint: string;
    endPoint2: string;
    optionalItems: PlanSelectionItemModel[];
    promotedOptionalItems: PlanSelectionItemModel[];
    planName: string;
    showAllOptions = false;
    CREATE_LOGIN_INDEX = 5;
    passwordConfirm: string;
    doAutoRenewal = false;
    createLogin = false;
    selectedUpgradeCard: StripeCard;
    cardSelectionOpen;
    addingNewUpgradeCard = false;
    saving = false;
    showCompletion = false;
    showCompletionAnimation = false;
    fileUploadAvailable = false;
    tutorialGoingBack = false;
    lastIndex = 0;

    args: OnBoardSaveArgs = new OnBoardSaveArgs();

    constructor(private zone: NgZone,
        private customerRepository: CustomerRepositoryService,
        private activatedRoute: ActivatedRoute,
        private planApi: PlanApiService,
        private dialog: DialogsService,
        private api: ApiService) { }

    ngOnInit(): void {
        this.api.getApiEndpointDotNet().then(endPoint => this.endPoint = endPoint);
        this.api.getApiEndpointNode().then(endPoint2 => this.endPoint2 = endPoint2);
        this.currentStepDesc = this.steps[0].description;
        this.activatedRoute.params.subscribe(params => {
            if (params.key) {
                document.location.hash = '0';
                this.key = params.key;
                this.refreshScreen();
            }
        });
    }

    tutorialIndexChange(index) {
        this.tutorialGoingBack = index < this.lastIndex;
        this.lastIndex = index;
        this.showBackground = false;
        setTimeout(() => this.showBackground = true, 50);
    }

    private refreshScreen() {
        this.customerRepository.getPolicySummaryFromKey(this.key).then(ps => {
            if (ps) {
                this.policySummary = ps;
                this.args.customerEmail = ps.customerEmail;
                this.args.customerHomePhone = ps.customerHomeNumber;
                this.args.customerMobilePhone = ps.customerMobileNumber;
                this.customerId = ps.holder.id;
                this.args.preferredContactMethod = ps.holder.preferredContactMethod;
                this.args.paperless = ps.holder.paperless;
                this.planName = ps.planName;
                this.args.loginId = ps.customerEmail;


                // this.refreshOptionalItems();
            } else {
                this.dialog.alert('Sorry', 'It looks like we couldn\'t find you subscription.');
            }
        });
        this.customerRepository.getCoverageByKey(this.key).then(coverage => {
            this.coverage = coverage;
            this.groupedCoverage = [];
            for (const coverageItem of this.coverage) {
                let group = this.groupedCoverage.find(i => i.category === coverageItem.category);
                if (group == null) {
                    group = new GroupedCoverage();
                    group.category = coverageItem.category;
                    group.items = [];
                    this.groupedCoverage.push(group);
                }
                group.items.push(coverageItem);
            }
        });
    }

    async validateLogin() {
        if (!UtilitiesService.validateEmail(this.args.loginId)) {
            this.dialog.alert('Sorry', 'That doesn\'t seem to be a valid email.');
            return;
        }

        if (!this.args.password) {
            this.dialog.alert('Sorry', 'Please enter a password');
            return;
        }

        if (this.args.password !== this.passwordConfirm) {
            this.dialog.alert('Sorry', 'The password and the confirmation do not match.');
            return;
        }

        const userExists = await this.customerRepository.loginExists(this.args.loginId);
        if (userExists) {
            this.dialog.alert('In Use', 'Sorry, it looks like that email address is already registered to a different account.');
            return;
        }
        this.createLogin = true;
        this.selectedIndex++;
    }

    get cardGetUrl() {
        return `CustomerOnBoarding/${this.key}/stripe-cards`;
    }

    renewalCardChanged() {
        const datePipe = new DatePipe('en-US');
        this.steps[1].hideNextButton = false;
        this.steps[1].showSkipButton = false;
        this.doAutoRenewal = true;
        if (this.args.autoRenewalCard) {
            const expDate = new Date(this.args.autoRenewalCard.exp_year, this.args.autoRenewalCard.exp_month - 1, 1);
            if (expDate < this.policySummary.expirationDate) {
                this.dialog.alert('Warning', `It looks like your subscription expires in ${datePipe.transform(this.policySummary.expirationDate, 'MMMM yyyy')} and your credit card expires in ${datePipe.transform(expDate, 'MMMM yyyy')}<br>We may need to contact you for updated payment information before we will be able to process your auto-renewal request.`);
            }
        }
    }

    selectUpgradeCard() {
        this.cardSelectionOpen = false;
        this.selectedIndex++;
    }

    doNotAutoRenew() {
        this.setupAutoRenewalVisible = false;
        this.steps[4].hideNextButton = true;
        this.steps[4].showSkipButton = true;
        this.args.autoRenewalCard = null;
        this.doAutoRenewal = false;
    }

    goNext() {
        if (this.selectedIndex === 0) {
            if (!this.validateContactInfo()) {
                return false;
            }
        }

        if (this.selectedIndex === 2 && this.shoppingCartItems && this.shoppingCartItems.length > 0) {
            this.cardSelectionOpen = true;
            return;
        }
        this.selectedIndex = this.selectedIndex + 1;
    }

    private validateContactInfo(): boolean {
        if (!this.args.customerEmail || !UtilitiesService.validateEmail(this.args.customerEmail)) {
            this.dialog.alert('Sorry', 'Please enter a valid email address.').subscribe(() => {
                document.getElementById('customer-email-address').focus();
            });

            return false;
        }
        if (!UtilitiesService.validatePhoneNumber(this.args.customerHomePhone) && !UtilitiesService.validatePhoneNumber(this.args.customerMobilePhone)) {
            this.dialog.alert('Sorry', 'Please enter either a home number or a mobile number.').subscribe(() => {

                document.getElementById('customer-home-number').focus();
            });
            return false;
        }

        return true;
    }

    selectedIndexChange(index) {
        this.currentStepDesc = null;
        setTimeout(() => this.currentStepDesc = this.steps[this.selectedIndex].description);
        window.location.hash = index.toString();
        if (index === 2) {

            this.steps[2].hideNextButton = this.shoppingCartItems.length === 0;
            this.steps[2].showSkipButton = this.shoppingCartItems.length === 0;
        }
    }

    get pct() {
        return ((this.selectedIndex + 1) / this.totalSteps) * 100;
    }

    get contractUrl() {
        if (this.policySummary) {
            return `${this.endPoint2}policy/contract/pdf/${this.policySummary.id}`;
        }
    }

    closeTutorial() {
        this.tutorialOpen = false;
        this.start();
    }

    get totalSteps() {
        return this.steps.length;
    }

    get hideNextButton() {
        const current = this.steps[this.selectedIndex];
        if (current) {
            return current.hideNextButton;
        }
    }

    get showSkipButton() {
        const current = this.steps[this.selectedIndex];
        if (current) {
            return current.showSkipButton;
        }
    }

    get currentStep() {
        return this.steps[this.selectedIndex];
    }

    start() {
        this.starting = true;
        setTimeout(() => {
            this.showWelcomeScreen = false;
            if (!this.args.paperless) {
                setTimeout(() => {
                    this.args.paperless = true;
                }, 500);
            }
        }, 500);
    }

    get nextStep() {
        const next = this.steps[this.selectedIndex + 1];
        if (next) {
            return next.description;
        }
    }

    get progressLabel() {
        return `${this.selectedIndex + 1} of ${this.totalSteps}`;
    }

    skipStep() {
        if (this.selectedIndex === 5) {
            this.args.password = '';
            this.passwordConfirm = '';
            this.createLogin = false;
        }

        this.selectedIndex = this.selectedIndex + 1;
    }

    get additionalOptions() {
        if (!this.optionalItems) {
            return null;
        }

        return this.optionalItems.filter(i => this.promotedOptionalItems.indexOf(i) === -1 && (this.shoppingCartItems.map(i2 => i2.id).indexOf(i.planItemId) === -1 || i.allowQuantity));
    }

    get cartTotal() {
        if (!this.shoppingCartItems || this.shoppingCartItems.length === 0) {
            return 0;
        }

        return this.shoppingCartItems.map(i => i.price * i.quantity).reduce((a, b) => a + b);
    }

    uploadDocument(documentType: string) {
        // Force the file upload input element to clear out be removing and re-adding to DOM
        this.fileUploadAvailable = false;
        setTimeout(() => {
            this.fileUploadAvailable = true;
            setTimeout(() => {
                this.uploadType = documentType;
                document.getElementById(this.uploadId).click();
            });
        });
    }

    async getPlans() {
        this.gettingPlans = true;
        let age = 0;
        if (this.policySummary.propertyAddress.yearBuilt) {
            age = new Date().getFullYear() - this.policySummary.propertyAddress.yearBuilt;
        }
        if (isNaN(age) || age < 0) {
            age = 0;
        }
        this.plans = await this.planApi.getOfferedPlans(this.policySummary.coverageType as any,
            this.policySummary.propertyAddress.dwellingType as any,
            this.policySummary.propertyAddress.postalCode,
            this.policySummary.propertyAddress.squareFeet, age, null, null, null);
        this.plans = this.plans.filter(i => i.price > this.policySummary.basePrice && i.id !== this.policySummary.planId);
        for (const plan of this.plans) {
            plan.price = plan.price - this.policySummary.basePrice;
        }
        this.gettingPlans = false;
        this.planSelectionOpen = true;
    }

    get planUpgradeItem() {
        if (!this.shoppingCartItems) {
            return null;
        }

        return this.shoppingCartItems.find(i => i.id === 'UPGRADE');
    }


    @HostListener('window:hashchange')
    watchUrlHash() {
        this.zone.run(() => {
            const hash = window.location.hash;
            const hashIndex = parseInt(hash.replace('#', ''), 10);

            if (!isNaN(hashIndex)) {
                this.selectedIndex = hashIndex;
            }
        });
    }

    async save() {
        this.saving = true;
        this.args.key = this.key;
        const result = await this.customerRepository.completeExistingOnboarding(this.args);
        this.saving = false;

        if (result.success) {
            this.showCompletion = true;
            setTimeout(() => this.showCompletionAnimation = true, 500);
        } else {
            this.dialog.alert('Sorry', result.message);
        }
    }

}
