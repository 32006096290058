<div aflex>
    <div>
        <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="sub-heading">Reschedule
            Appointment</h1>
    </div>
    <div nogrow>
        <button mat-button [mat-dialog-close]>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <div *ngIf="!noTimeSlots">
        <p class="info" style="max-width: 600px">We'll send your new requested times to your service pro. You should get
            confirmation when they have accepted a new appointment time.</p>
        <app-appointment-selection [timeSlots]="timeSlots" [(selectedTimeSlots)]="selectedTimeSlots">
        </app-appointment-selection>
    </div>
    <div *ngIf="noTimeSlots">
        <p class="info">Please call your service pro to reschedule your appointment</p>
        <h2>{{data.workOrderSummary.contractorName}}</h2>
        <a href="tel:{{data.workOrderSummary.contractorPhone}}">
            {{data.workOrderSummary.contractorPhone | formatPhoneNumber}}
        </a>

    </div>    
</div>
<div mat-dialog-actions aflex>
    <div></div>
    <div nogrow><button mat-button mat-raised-button color="primary" class="small-primary" (click)="save()"
            [disabled]="!timeSlotsAreSelected || saving">
            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
            Send Request</button></div>

</div>