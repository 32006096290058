import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class LandingPageComponent implements OnInit {

    constructor(private customerApi: CustomerRepositoryService,
        private router: Router,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                const urlKey = params.id;

                this.customerApi.getLandingPageRedirect(urlKey).then(redirect => {
                    if (redirect) {
                        if (!redirect.redirectUrl.startsWith('/')) {
                            redirect.redirectUrl = '/' + redirect.redirectUrl;
                        }
                        this.router.navigateByUrl(redirect.redirectUrl);
                    } else {
                        this.router.navigateByUrl('/');
                    }
                });
            }
        });
    }

}
