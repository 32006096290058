import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { ClosingDocumentUploadModule } from '@cogent/client/shared/components/sales/closing-document-upload/closing-document-upload.module';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';

@Component({
    selector: 'app-document-upload-modal',
    templateUrl: './document-upload-modal.component.html',
    styleUrls: ['./document-upload-modal.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, ClosingDocumentUploadModule, CheckCircleAnimatedComponent]
})
export class DocumentUploadModalComponent implements OnInit {
    hasSellersDisclosures: boolean;
    hasHomeInspection: boolean;
    inspectionUploaded = false;
    pageIndex: number = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: PolicySummary,
        public dialogRef: MatDialogRef<DocumentUploadModalComponent>) { }

    ngOnInit(): void {
    }

    hasHomeInspectionChange(value) {
        this.inspectionUploaded = value;
    }

}
