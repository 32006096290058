import { Component, OnInit } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ApiService } from '@cogent/client/api';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-choose-property',
    templateUrl: './choose-property.component.html',
    styleUrls: ['./choose-property.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
    ]
})
export class ChoosePropertyComponent implements OnInit {
    myPolicies: any[];
    tabIndex = 0;
    noPolicies = false;
    customerPortalEnabled = false;
    slashBase = ApiService.isLegacy ? '/' : '/homeowner-app/';
    isLegacy = true;

    constructor(
        private customerService: CustomerRepositoryService,
        private entityApi: EntityApiService
    ) { }

    async ngOnInit() {
        this.customerService.getPoliciesForLoggedInUser().then(policies => {
            // policies = policies.concat(policies);
            // policies = policies.concat(policies);

            this.myPolicies = policies.filter(i => i.status === 'Active' || i.status === 'Pending');
            if (this.myPolicies.length === 0) {
                this.noPolicies = true;
            }
        });
        this.customerPortalEnabled = await this.customerService.getCustomerPortalEnabled();
    }

    get baseUrl() {
        return ApiService.endPointDotNet;
    }

}
