<div class="container">

    <div class="center" topmargin20 bottommargin20>
        <h1 class="month-and-year">{{viewDate | date: 'MMMM yyyy'}}</h1>
        <button mat-button [matMenuTriggerFor]="menu" *ngIf="calendarUrl">
            <mat-icon>notification_add</mat-icon>
            Subscribe</button>
        <mat-menu #menu="matMenu">

            <a mat-menu-item target="_blank" [href]="appleWebCalCalendarUrl">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/apple-calendar.svg" style="height: 25px;margin-right: 16px;">
                Import to Apple Calendar</a>
            <a target="_blank" mat-menu-item [href]="googleWebCalCalendarUrl">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/google-calendar.svg" style="height: 25px;margin-right: 16px">
                Import to Google Calendar</a>
            <button title="copy URL" mat-menu-item (click)="copyUrl()">
                <mat-icon>content_copy</mat-icon> Copy URL
            </button>
        </mat-menu>
    </div>
    <!-- <div flex>
        <div>
            <button mat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay($event)">
                <mat-icon>chevron_left</mat-icon>
                Previous
            </button>
            <button mat-button mwlCalendarToday (viewDateChange)="closeOpenMonthViewDay($event)"
                [(viewDate)]="viewDate">
                Today
            </button>
            <button mat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay($event)">
                Next
                <mat-icon>chevron_right</mat-icon>
            </button>

        </div>

    </div>
    <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)" [excludeDays]="excludeDays"
            (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [dayStartHour]="startHour"
            [dayEndHour]="endHour" [excludeDays]="excludeDays" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [dayStartHour]="startHour"
            [dayEndHour]="endHour" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-day-view>
    </div> -->
    <div aflex>
        <button mat-button nogrow (click)="backMonth()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <div class="center">
            {{viewDate | date: 'MMM/yyyy'}}
        </div>
        <button mat-button nogrow (click)="forwardMonth()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

    <div class="calendar">
        <div class="day-header">Sun</div>
        <div class="day-header">Mon</div>
        <div class="day-header">Tue</div>
        <div class="day-header">Wed</div>
        <div class="day-header">Thu</div>
        <div class="day-header">Fri</div>
        <div class="day-header">Sat</div>
        <div *ngFor="let item of items">
            
            <div class="day-number" *ngIf="!item.previousMonth">{{item.dayNumber}}</div>
            <div *ngFor="let workOrder of item.workOrders">
                <a class="appt" routerLink="/job-detail/{{workOrder.id}}">
                    {{workOrder.itemName}}
                </a>
            </div>
            <div *ngFor="let hms of item.maintenanceServiceAppointments" >
                <a class="appt" routerLink="/maintenance-service-property/{{hms.maintenanceServiceCustomerPropertyId}}">
                    {{hms.maintenanceServiceName}}
                </a>
            </div>
        </div>
    </div>

</div>