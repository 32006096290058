<svg style="height: 100px;" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <defs>
        <style>
            .cls-1 {
                fill: #5e5e5e;
            }

            .cls-2 {
                fill: none;
                stroke: #5e5e5e;
                stroke-linecap: round;
                stroke-miterlimit: 10;
                stroke-width: 22px;
            }

            @keyframes animate-mouth {
                from {
                    d: path('M119.31,238.13c23.22-1.06,51.9-1.68,82.93-1.68,27.36,0,52.88,.49,74.44,1.33');
                }

                to {
                    d: path('M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79')
                }
            }


            #mouth {
                animation: animate-mouth 5s ease;
            }
        </style>
    </defs>
    <!-- <path class="cls-1"
        d="M198,34c43.81,0,84.99,17.06,115.97,48.03,30.98,30.98,48.03,72.16,48.03,115.97s-17.06,84.99-48.03,115.97c-30.98,30.98-72.16,48.03-115.97,48.03s-84.99-17.06-115.97-48.03c-30.98-30.98-48.03-72.16-48.03-115.97s17.06-84.99,48.03-115.97c30.98-30.98,72.16-48.03,115.97-48.03m0-34C88.65,0,0,88.65,0,198s88.65,198,198,198,198-88.65,198-198S307.35,0,198,0h0Z" />
    <rect class="cls-1" x="340.96" y="324.25" width="26" height="66"
        transform="translate(-148.16 385.4) rotate(-48.47)" />
    <rect class="cls-1" x="438.1" y="336.61" width="69" height="250.05" rx="18.95" ry="18.95"
        transform="translate(-186.32 509.41) rotate(-48.47)" /> -->
    <g id="face">
        <g>
            <circle class="cls-1" cx="147.7" cy="167.79" r="23.79" />
            <circle class="cls-1" cx="248.3" cy="167.79" r="23.79" />
        </g>
        <path id="mouth" class="cls-2"
            d="M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79" />
    </g>
</svg>
