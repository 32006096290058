<div aflex>

    <div>
        <h3 matDialogTitle class="sub-heading">Payment Methods</h3>
    </div>
    <div nogrow>
        <button mat-button matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    <div class="hide-tabs">
        <!-- <div *ngIf="addingNewCard" class="save-as-default">
            <mat-checkbox [(ngModel)]="setAsDefault">Save as Default</mat-checkbox>
        </div> -->
        <div  [class.card-selector]="!addingNewCard">
            <app-payment-method-entry [defaultSelectedCardId]="policySummary.stripeCardId" [canDelete]="false"
                [(selectedCard)]="selectedCard" [(addingNewCard)]="addingNewCard" (newCardCreated)="newCardCreated()"
                [customerId]="policySummary.holder.id"></app-payment-method-entry>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!addingNewCard">
    <button [disabled]="!canSave" mat-button mat-raised-button color="primary" (click)="save()">

        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
        Save
    </button>
</mat-dialog-actions>