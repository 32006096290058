import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceOfferingAddOn } from '@upkeeplabs/models/cogent';
import { MissionService, ExecuteFunctionArgs } from '@cogent/client/shared/services/mission-service';
import { ApiService } from '@cogent/client/api';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-maint-services-request-add-on',
    templateUrl: './maint-services-request-add-on.component.html',
    styleUrls: ['./maint-services-request-add-on.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule]
})
export class MaintServicesRequestAddOnComponent implements OnInit {

    addOns: MaintenanceServiceOfferingAddOn[];
    summary: MaintenanceServiceCustomerPropertyServiceSummary;
    constructor(private api: ApiService,
        private maintApi: MaintenanceServiceApiService,
        private activatedRoute: ActivatedRoute,
        private serviceApi: ServiceApiService,
        private missionService: MissionService) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.maintApi.getCustomerPropertyServiceSummary(params.id).then(summary=> {
                    this.summary = summary;
                    this.maintApi.getAddOnsForOffering(summary.maintenanceServiceOfferingId).then(addOns => {
                        this.addOns = addOns;
                        for (const addOn of this.addOns) {
                            addOn.pictureUrl = this.serviceApi.getItemThumbnailUrl(addOn.id);
                        }
                    });
                });

            }
        });
    }

    startAddOn(addOn: MaintenanceServiceOfferingAddOn) {
        // this.api.getSingle2('MaintenanceServiceOfferingAddOn', { id_eq: addOn.id }).then(addOn => {

        // });
        const args = new ExecuteFunctionArgs();
        args.functionJson = addOn.processDefinition;
        args.name = addOn.name;
        args.objectInScope = this.summary;
        args.pictureUrl = addOn.pictureUrl;
        args.wizardStyle = true;
        this.missionService.raiseExecuteFunction(args);
    }

    test() {

    }

}
