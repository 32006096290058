

<mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
    <mat-tab label="First"> 
        <ng-container *ngIf="!showOnlyWhenNeeded || isNeeded">
    
            <div flex>
                <div nogrow class="mobile-center" *ngIf="!hideIcon">
                    <!-- <img src="../../../assets/images/fast-lane-circle.png" class="fast-lane"> -->
        
                    <svg id="lock-1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424 424">
                        <defs>
                          <style>
        
                          </style>
                        </defs>
                        <g>
                          <g>
                            <circle class="cls-1" cx="212" cy="212.00006" r="202.00006"/>
                            <path class="cls-1" d="M220.25,31.30426A192,192,0,0,1,356.01453,359.06879,192,192,0,0,1,84.48547,87.53976,190.74311,190.74311,0,0,1,220.25,31.30426m0-20c-117.08435,0-212,94.91559-212,212s94.91565,212,212,212,212-94.91559,212-212-94.91565-212-212-212Z" transform="translate(-8.25 -11.30426)"/>
                          </g>
                          <path class="cls-2" d="M247,107.59808V132.5h20.5v48H247V344H152.74011l99.1366,86.75446a212.1802,212.1802,0,0,0,176.291-164.61652Z" transform="translate(-8.25 -11.30426)"/>
                          <g>
                            <path class="cls-3" d="M219.90137,128.69568c13.26587,0,22.57934,8.98361,26.95019,14.3385,7.59644,9.30664,12.35559,21.99576,13.42066,35.47736H179.5304c1.06506-13.48163,5.82421-26.17072,13.42053-35.47736,4.371-5.35489,13.68457-14.3385,26.95044-14.3385m0-26c-36.7815,0-66.59876,36.53009-66.59876,81.59219a99.24134,99.24134,0,0,0,2.07825,20.29865c.51636-.04749,1.03845-.075,1.56714-.075H282.15723a17.0162,17.0162,0,0,1,2.24878.1535,99.22171,99.22171,0,0,0,2.094-20.37717c0-45.0621-29.81726-81.59219-66.59863-81.59219Z" transform="translate(-8.25 -11.30426)"/>
                            <g>
                              <path class="cls-3" d="M157.64551,333.9126a6.90346,6.90346,0,0,1-6.89551-6.895V201.80811a6.90357,6.90357,0,0,1,6.89551-6.89551h125.209a6.90357,6.90357,0,0,1,6.89551,6.89551V327.01758a6.90346,6.90346,0,0,1-6.89551,6.895Zm40.33789-31.605h44.38867l-2.959-32.11524a26.03287,26.03287,0,1,0-38.07813.2793Z" transform="translate(-8.25 -11.30426)"/>
                              <path class="cls-3" d="M279.75,204.91284v119h-119v-119h119m-88.79846,68.67993L189.202,290.21393l-2.32557,22.09363h66.45813l-2.011-21.83429-1.5824-17.181a36.02936,36.02936,0,1,0-58.78967.30047m91.90307-88.67993H157.64539A16.89536,16.89536,0,0,0,140.75,201.80814V327.01749a16.89537,16.89537,0,0,0,16.89539,16.89535H282.85461A16.89537,16.89537,0,0,0,299.75,327.01749V201.80814a16.89536,16.89536,0,0,0-16.89539-16.8953ZM209.09216,292.30756l2.74659-26.093a16.04167,16.04167,0,1,1,17.14672-.20786l2.42237,26.30085Z" transform="translate(-8.25 -11.30426)"/>
                            </g>
                          </g>
                        </g>
                        <path *ngIf="checkmarkShown" class="cls-4 checkmark" d="M409.26928,159.18612l-26.61656-20.77748a13.03517,13.03517,0,0,0-17.88163,1.90627L217.11253,316.53147l-66.79738-52.14339a10.279,10.279,0,0,0-14.10077,1.50321L111.087,295.87853a9.45429,9.45429,0,0,0,1.42561,13.62341l76.64161,59.828.0005.00042,24.008,18.741,4.69019,3.66126a10.27894,10.27894,0,0,0,14.10077-1.50321l1.69925-2.02789.00006-.00007L411.07719,176.46238A11.98931,11.98931,0,0,0,409.26928,159.18612Z" transform="translate(-8.25 -11.30426)"/>
                      </svg>
                      
                </div>
                <div class="mobile-center">
                    <p class="info-detail" *ngIf="!hideLanguage" [class.full-width]="hideIcon">
                        Get Inspection Protection by uploading your home inspection.<br><br>
        
                        <button mat-button *ngIf="!hideLearnMore" (click)="selectIndex(1)">Learn More
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </p>
                    <input type="file"
                        accept="application/pdf,application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        style="display: none;" id="{{inspectionUploadId}}" (change)="handleFiles($event, 'Home_Inspection')">
                    <input type="file"
                        accept="application/pdf,application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        style="display: none;" id="{{sellersUploadId}}" (change)="handleFiles($event, 'Sellers_Disclosures')">
        
        
                </div>
            </div>
            <div aflex>
                <button class="doc-button" mat-button (click)="showHomeInspectionUpload()">
                    <mat-icon style="color: green;" *ngIf="hasHomeInspection && !uploadingInspection">check_circle</mat-icon>
                    <mat-icon style="color: rgb(150,150,150);" *ngIf="!uploadingInspection && !hasHomeInspection">cloud_upload
                    </mat-icon>
                    <mat-spinner *ngIf="uploadingInspection" class="fifty"></mat-spinner>
                    <h3 class="sub-heading">Upload Inspection</h3>
                </button>
        
            </div>
        </ng-container>    
    </mat-tab>
    <mat-tab label="Second"> 
        <h3>What is it?</h3>
        <p>Elevate’s Inspection Protection covers breakdowns caused by unknown defects and/or conditions that were not detected by a home inspection and predated the effective date of the home warranty. Get an added layer of protection from undetected, unknown pre-existing conditions with Inspection protection!</p>    

        <h3 topmargin40>How do it get it?</h3>
        <p>
            Signing up is easy – simply Upload your Home Inspection Report or email it to <a href="mailto:">cs&#64;elevatehomescriptions.com</a>. If your home inspection did not identify defects or problems with your covered home systems and appliances, you’ll be covered for any mechanical breakdowns caused by unknown pre-existing conditions.
        </p>

        <button mat-button mat-raised-button mobile-block (click)="selectIndex(0)">
            <mat-icon>chevron_left</mat-icon> Back
        </button>
    </mat-tab>
    <mat-tab label="Third"> Content 3 </mat-tab>
  </mat-tab-group>