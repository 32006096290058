<div class="container fancy-container" style="max-width: 600px;">

    <div>
        <h1 class="sub-heading">Get Inspection Protection</h1>
    </div>
    <div  topmargin40>
        <app-document-upload *ngIf="policySummary" [policySummary]="policySummary"
            [(hasSellersDisclosures)]="hasSellersDisclosures" [(hasHomeInspection)]="hasHomeInspection">
        </app-document-upload>
    </div>
</div>