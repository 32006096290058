<div *ngIf="usersOnRoute?.length > 0" class="route-watcher-container">
    <button mat-button [matMenuTriggerFor]="routeWatcher" class="route-watcher-menu-btn">
        <i class="material-icons">remove_red_eye</i>
    </button>
    <mat-menu #routeWatcher="matMenu" yPosition="above" class="route-watcher-menu">
        <div *ngFor="let userOnRouteWithTiming of usersOnRouteWithTiming">
            <p><span>{{userOnRouteWithTiming.name}}</span> </p>
        </div>
    </mat-menu>
</div>

