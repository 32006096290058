


<div class="white-box" [class.shifted]="shifted">
    <div id="login-form" [class.show]="shifted">
        <div class="center" bottommargin40>
            <img style="height: 47px;" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/elevate-logo-homescriptions.svg">
        </div>
        <form id="submitForm">


            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput name="userName" autocomplete="username" [(ngModel)]="userName" type="email">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" name="password" autocomplete="current-password" [(ngModel)]="password">
            </mat-form-field>

            <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="rememberMe">Remember Me</mat-checkbox>

            <div class="center" topmargin30>
                <button [disabled]="loggingIn" mat-button mat-raised-button (click)="login()" color="primary">
                    <mat-spinner *ngIf="loggingIn" class="thirty"></mat-spinner>
                    Login
                </button>
            </div>



        </form>


    </div>
</div>

<p class="center info" topmargin30 style="margin-bottom: 0;padding-bottom: 0;">
    Additional Options

</p>
<div style="display: table;margin: 0 auto;">
    <p class="center additional-options">
        <a mat-button class="first" routerLink="/security/register">
            <mat-icon>account_circle</mat-icon><br>
            Register</a>
        <a mat-button class="second" routerLink="/security/reset-password">
            <mat-icon>lock_open</mat-icon><br>
            Forgot Password</a>
        <a mat-button class="third" routerLink="/find-property">
            <mat-icon>build</mat-icon><br>
            Request Service</a>
        <a mat-button class="fourth" routerLink="/quote">
            <img src="../../../assets/images/elevate-cart.svg" style="height: 50px;margin-top: -16px;margin-bottom: -2px;"><br> Order
        </a>
    </p>

    <div class="center apps" >
        <div style="margin-bottom: 10px;margin-top: 40px" class="info">Go mobile with our customer app</div>
        <a style="text-decoration: none;" href="https://apps.apple.com/app/elevate-customer/id1619781798"
            target="_blank">
            <img alt="Apple App Store Download" class="download-badge" src="/assets/images/app-store-badge.png">
        </a>
        <a style="text-decoration: none;"
            href="https://play.google.com/store/apps/details?id=com.elevatehw.customers.twa&amp;hl=en_US&amp;gl=US"
            target="_blank">
            <img class="download-badge" alt="Google Play Download" src="/assets/images/google-play.png">
        </a>
        <div style="margin-top: 10px; color: #666;" class="info">
            <a style="color: inherit; text-decoration: none;" href="tel:8018045132">801-804-5132</a> | <a style="color: inherit; text-decoration: none;" href="mailto:CS@ElevateHomescriptions.com">CS&#64;ElevateHomescriptions.com</a>
        </div>

    </div>
</div>
