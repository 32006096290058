<div id="welcome-screen" [class.fade-out]="starting" *ngIf="showWelcomeScreen && policySummary">
    <div class="narrow-container" style="max-width: 800px;">

        <div aflex>
            <div nogrow>
                <img src="../../../assets/images/elevate-a-icon-white.svg">
            </div>
            <div>
                <h1>Elevate<br> Your<br> Experience</h1>
            </div>
        </div>


        <p>Congratulations on the purchase of your new home! Give us a few minutes and we'll tailor your home
            subscription
            to perfectly fit your individual needs.
        </p>
        <button mat-button (click)="start()" large>Get Started</button><br>
        <p topmargin40 style="margin-bottom: 10px;">Want to know more about how your home subscription works?</p>
        <button mat-button (click)="tutorialOpen = true;tutorialIndex=0;">Show Me</button>
    </div>
</div>
<div class="parent" *ngIf="policySummary && !showCompletion" [class.fade-in]="starting">

    <div class="header">
        <div class="container">
            <div aflex>
                <div nogrow>
                    <div id="header-progress">
                        <app-circle-widget-small [height]="40" [percent]="pct"></app-circle-widget-small>
                    </div>
                    <div id="steps-label">
                        {{selectedIndex + 1}} of {{totalSteps}}
                    </div>
                </div>
                <div>

                    <div *ngIf="currentStepDesc" class="slide-in-up">
                        <h2>{{currentStepDesc}}</h2>
                        <div class="next" *ngIf="(selectedIndex + 1) < totalSteps">Next: {{nextStep}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container special-container">
        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex" *ngIf="policySummary"
            (selectedIndexChange)="selectedIndexChange($event)">
            <mat-tab label="First">

                <div class="padding">

                    <div class="header-container">
                        <div flex>

                            <div nogrow><img src="../../../assets/images/contact-circle.png" class="page-icon-image">
                            </div>
                            <div>
                                <p class="info-detail">Please take a moment to verify your contact information</p>
                            </div>
                        </div>
                    </div>


                    <mat-form-field appearance="outline" topmargin20>
                        <mat-label>
                            <mat-icon>email</mat-icon>
                            Email Address
                        </mat-label>
                        <input matInput id="customer-email-address" [(ngModel)]="args.customerEmail">
                    </mat-form-field>

                    <div flex>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>email</mat-icon>
                                Home Phone
                            </mat-label>
                            <input matInput type="tel" id="customer-home-number" mask="(000) 000-0000"
                                [(ngModel)]="args.customerHomePhone">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>phone_iphone</mat-icon>
                                Mobile
                            </mat-label>
                            <input matInput [(ngModel)]="args.customerMobilePhone" mask="(000) 000-0000">
                        </mat-form-field>
                    </div>


                    <mat-form-field appearance="outline" style="max-width: 300px;">
                        <mat-label> Preferred Contact Method</mat-label>
                        <mat-select [(ngModel)]="args.preferredContactMethod">
                            <mat-option value="Phone">Phone</mat-option>
                            <mat-option value="SMS">SMS Text Message</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-slide-toggle [(ngModel)]="args.paperless">Send Me Documents Paperless</mat-slide-toggle>

                    <div id="paperless-notification" class="slide-in-up arrow_box" *ngIf="args.paperless">
                        <div aflex>
                            <div nogrow>
                                <i class="fa fa-tree" style="font-size: 36px;color: green;"></i>
                            </div>
                            <div>Save time (and trees). Get all your important documents emailed to you quickly. </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="padding">
                    <ng-container *ngIf="!setupAutoRenewalVisible">
                        <ng-container *ngIf="policySummary.isMonthly">
                            <div class="header-container">
                                <div flex>

                                    <div nogrow><img src="../../../assets/images/credit-card-circle.png"
                                            class="page-icon-image">
                                    </div>
                                    <div>
                                        <p class="info-detail">Payment Information <span class="small-text"><br><br>
                                                We're working hard to provide you with tools to make your home warranty
                                                experience simple and easy. So
                                                we have change our payment processor.<br><br> To ensure uninterrupted
                                                coverage, please take a moment to provide
                                                us with updated payment information for your monthly subscription
                                                payment.
                                            </span></p>
                                    </div>
                                </div>
                            </div>

                            <div topmargin30 bottommargin20>
                                <!-- <button mat-button mat-raised-button mobile-block color="primary"
                                    (click)="setupAutoRenewalVisible = true">
                                    <mat-icon>restore_page</mat-icon>
                                    Enter Payment Details
                                </button> -->
                                <app-payment-method-entry buttonText="Add" [cardGetUrl]="cardGetUrl"
                                    paymentDescription="None" [paymentAmount]="0" [customerId]="customerId"
                                    [(selectedCard)]="args.autoRenewalCard" (selectedCardChange)="renewalCardChanged()">
                                </app-payment-method-entry>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!policySummary.isMonthly">
                            <div class="header-container">
                                <div flex>

                                    <div nogrow><img src="../../../assets/images/renew-circle.png"
                                            class="page-icon-image">
                                    </div>
                                    <div>
                                        <p class="info-detail">Auto-Renew <span class="small-text"><br><br> Your current
                                                coverage term lasts from
                                                {{policySummary.effectiveDate | date}} to
                                                {{policySummary.expirationDate | date}}.<br><br> You can ensure you
                                                don't miss a
                                                minute
                                                of coverage by setting
                                                your subscription to auto-renew</span></p>
                                    </div>
                                </div>
                            </div>

                            <div topmargin30 bottommargin20>
                                <button mat-button mat-raised-button mobile-block color="primary"
                                    (click)="setupAutoRenewalVisible = true">
                                    <mat-icon>restore_page</mat-icon>
                                    Auto-Renew Me
                                </button>
                            </div>

                        </ng-container>

                    </ng-container>

                    <div *ngIf="setupAutoRenewalVisible" class="slide-in-up">
                        <p class="info">
                            After your initial term ends on {{policySummary.expirationDate | date}}, you will then be
                            billed per
                            month. You can cancel at any time. We just need
                            a major credit card</p>
                        <app-payment-method-entry buttonText="Add" [cardGetUrl]="cardGetUrl" paymentDescription="None"
                            [paymentAmount]="0" [customerId]="customerId" [(selectedCard)]="args.autoRenewalCard"
                            (selectedCardChange)="renewalCardChanged()"></app-payment-method-entry>
                        <button mat-button mobile-block (click)="doNotAutoRenew()">Nevermind</button>
                    </div>
                </div>

            </mat-tab>
            <mat-tab>
                <div class="padding">
                    <div class="header-container">
                        <div flex>

                            <div nogrow><img src="../../../assets/images/account-circle.png" class="page-icon-image">
                            </div>
                            <div>
                                <p class="info-detail">Customer Portal<br><br><span class="small-text">With our new
                                        customer portal, you can request service, manage your
                                        coverage, pay bill and more.
                                        All
                                        from the convenience of your smartphone or computer.</span> </p>
                            </div>
                        </div>
                    </div>

                    <mat-form-field appearance="outline" topmargin20 style="max-width: 400px">
                        <mat-label>
                            <mat-icon>account_circle</mat-icon>
                            Email Login
                        </mat-label>
                        <input matInput type="email" [(ngModel)]="args.loginId">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 400px">
                        <mat-label>
                            <mat-icon>vpn_key</mat-icon>
                            Password
                        </mat-label>
                        <input matInput type="password" [(ngModel)]="args.password">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 400px">
                        <mat-label>
                            <mat-icon>vpn_key</mat-icon>
                            Confirm Password
                        </mat-label>
                        <input matInput type="password" [(ngModel)]="passwordConfirm">
                    </mat-form-field>

                    <button mat-button mat-raised-button bottommargin20 color="primary" mobile-block
                        (click)="validateLogin()">Create Login</button>
                </div>
            </mat-tab>

            <mat-tab>
                <div class="padding">

                    <div id="review-grid">
                        <div class="soft-light">
                            <h2>Contact</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div aflex>
                                    <div>
                                        <mat-icon>email</mat-icon>{{args.customerEmail}}
                                    </div>
                                </div>
                                <div aflex>
                                    <div flex1>
                                        <mat-icon>phone</mat-icon> {{args.customerHomePhone}}
                                    </div>

                                    <div flex1>
                                        <mat-icon>phone_iphone</mat-icon> {{args.customerMobilePhone}}
                                    </div>
                                </div>
                                <div aflex>
                                    <div flex1>
                                        <span class="data-label">
                                            Contact me via:</span>
                                        {{args.preferredContactMethod}}
                                    </div>
                                    <div flex1>
                                        <mat-checkbox [disabled]="true" [(ngModel)]="args.paperless">Paperless
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="soft-light">
                            <h2>Updated Payment Information</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div *ngIf="doAutoRenewal">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon style="font-size: 36px;color: green;">autorenew</mat-icon>
                                        </div>
                                        <div>
                                            Sit back and relax. We have your updated payment information.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!doAutoRenewal">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon style="font-size: 36px;color: orange;">warning</mat-icon>
                                        </div>
                                        <div>We may need to contact you to get updated payment information.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="soft-light">
                            <h2>Customer Portal</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div *ngIf="createLogin">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon style="font-size: 36px;color: green;">account_circle</mat-icon>

                                        </div>

                                        <div>
                                            A login will be created for our customer portal.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!createLogin">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon style="font-size: 36px;color: orange;">warning</mat-icon>

                                        </div>

                                        <div>
                                            No log in will be created. Don't worry, you can always create a login at a
                                            later time.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div id="toolbar">
        <div class="container">
            <div aflex>
                <div nogrow>
                    <button mat-button (click)="selectedIndex = selectedIndex - 1" [disabled]="selectedIndex === 0">
                        <mat-icon>chevron_left</mat-icon>
                        Back
                    </button>
                    <button mat-button mat-raised-button color="primary"
                        *ngIf="(selectedIndex + 1) < totalSteps && !hideNextButton" (click)="goNext()">Next

                        <mat-icon>chevron_right</mat-icon>
                    </button>

                    <button mat-button mat-raised-button *ngIf="showSkipButton" (click)="skipStep()">
                        <mat-icon>subdirectory_arrow_right</mat-icon>
                        Skip
                    </button>
                </div>
                <div>

                    <button *ngIf="(selectedIndex + 1) === totalSteps" [disabled]="saving" mat-button mat-raised-button
                        color="primary" mobile-block (click)="save()">
                        <mat-spinner class="thirty" *ngIf="saving"></mat-spinner>
                        Save
                    </button>
                </div>
                <div nogrow style="width: 60px">

                </div>
            </div>
        </div>

    </div>
</div>

<div id="current-item" [style.left]="currentItem.pageX" [style.opacity]="currentItem.opacity"
    [style.top]="currentItem.pageY" *ngIf="currentItem">

    <mat-icon>{{currentItem.iconClass}}</mat-icon><br>
    {{currentItem.description}}
</div>


<div id="tutorial" *ngIf="tutorialOpen">

    <button id="close-tutorial" mat-button (click)="tutorialOpen = false">
        <mat-icon>close</mat-icon>
    </button>
    <div style="position: relative;height: 100%;display: flex; height: 100%;flex-direction: column;overflow: hidden;">
        <div class="full-step" class="full-step" *ngIf="showBackground" [class.step1]="tutorialIndex === 0"
            [class.step2]="tutorialIndex === 1" [class.step3]="tutorialIndex === 2" [class.step4]="tutorialIndex === 3"
            [class.backward]="tutorialGoingBack" [class.step5]="tutorialIndex === 4">

        </div>
        <div style="flex: 1;">
            <mat-tab-group style="margin-top: 0;" class="hide-tabs" (selectedIndexChange)="tutorialIndexChange($event)"
                [(selectedIndex)]="tutorialIndex">
                <mat-tab>

                    <div>
                        <h1 style="padding-left: 30px;">Item Breakdown</h1>
                        <div class="center" style="padding: 30px;">
                            <div class="text-bg">
                                <p>Household items break. Usually at the least
                                    opportune time. With an elevate
                                    subscription,
                                    you
                                    get the assurance of coverage that is
                                    best in the business.
                                </p>
                            </div>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <h1 style="padding-left: 30px;">Contact Us First</h1>
                    <div class="center" style="padding: 30px;">
                        <div class="text-bg">
                            <p>
                                When breakdowns happen, contact us first. We will match you with a service pro with the
                                right skills to get the problem solved quickly and efficiently. Simply go online to
                                create a service request, and of course, we are always available by phone to answer your
                                questions.
                            </p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <h1 style="padding-left: 30px;">Repair</h1>
                    <div class="center" style="padding: 30px;">
                        <div class="text-bg">
                            <p>
                                The service pro will contact you in a timely manner to schedule a mutually convenient
                                time to visit your home, diagnose the problem, and perform the necessary repairs.
                            </p>

                            <p>In certain cases, parts may need to be ordered and the repairs may
                                need to be completed on a
                                follow-up appointment.</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>


                    <h1 style="padding-left: 30px;">Relax</h1>
                    <div class="center" style="padding: 30px;">
                        <div class="text-bg">
                            <p>
                                Once the covered repairs are complete, there's nothing left to do but relax. We cover
                                the
                                cost of the repairs, leaving you
                                with the peace-of-mind that comes from an Elevate subscription.
                            </p>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <h1 style="padding-left: 30px">
                        Get Started
                    </h1>

                    <div class="center" style="padding: 30px">
                        <div class="text-bg">
                            <p>We want to make sure you have the right coverage. Give
                                us a few minutes and we'll
                                tailor your home subscription
                                to perfectly fit your individual needs.
                            </p>
                        </div>

                        <button topmargin40 mat-button mat-raised-button large color="primary"
                            (click)="closeTutorial()">Get Started</button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="center" style="flex: none">

        </div>
        <div aflex style="flex:none">

            <div nogrow style="min-width: 96px;">
                <button mat-button *ngIf="tutorialIndex > 0" (click)="tutorialIndex = tutorialIndex - 1">
                    <mat-icon>chevron_left</mat-icon>
                    Back
                </button>
            </div>
            <div class="center" *ngIf="tutorialIndex < 4">
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 0"
                    (click)="tutorialIndex = 0"></button>
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 1"
                    (click)="tutorialIndex = 1"></button>
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 2"
                    (click)="tutorialIndex = 2"></button>
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 3"
                    (click)="tutorialIndex = 3"></button>
            </div>

            <div nogrow>
                <button *ngIf="tutorialIndex < 4" mat-button mat-raised-button color="primary"
                    (click)="tutorialIndex = tutorialIndex + 1">Next

                    <mat-icon>chevron_right</mat-icon>
                </button>

            </div>
        </div>
    </div>


</div>


<div id="completion" *ngIf="showCompletion">

    <div class="container center">
        <h1 topmargin40 bottommargin40>You're All Set</h1>
        <div style="min-height: 150px;">
            <img class="tutorial-image fade-in-delayed" *ngIf="showCompletionAnimation"
                src="../../../assets/images/relax_animated.png">
        </div>
        <p topmargin40>Sit back and relax.<br> You've got the peace of mind that comes from the best home subscription
            in the business.</p>


        <button mat-button mobile-block topmargin40 routerLink="/">Go To My Portal</button>
    </div>
</div>