import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { WorkOrderAttachment } from '@cogent/shared/models/service/work-order-attachment.model';
import { MaintenanceServiceCustomerPropertyServiceAppointmentSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { ApiService } from '@cogent/client/api';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CalendarDayViewerModule } from '@cogent/client/shared/components/data-visualization/calendar-day-view/calendar-day-viewer.module';

@Component({
    selector: 'app-maint-services-view-appointment',
    templateUrl: './maint-services-view-appointment.component.html',
    styleUrls: ['./maint-services-view-appointment.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, MatButtonModule, MatIconModule, MatCardModule, MatDialogModule, MatProgressSpinnerModule, CalendarDayViewerModule]
})
export class MaintServicesViewAppointmentComponent implements OnInit {

    selectedIndex = 0;
    saving = false;
    comments: string;
    completionDate: Date;
    cancelling = false;
    photos: WorkOrderAttachment[];
    editDateMode = false;

    constructor(
        public dialogRef: MatDialogRef<MaintServicesViewAppointmentComponent>,
        private maintApi: MaintenanceServiceApiService,
        private missionService: MissionService,
        private dialogService: DialogsService,
        @Inject(MAT_DIALOG_DATA) public appointment: MaintenanceServiceCustomerPropertyServiceAppointmentSummary,
    ) { }

    ngOnInit(): void {
        if (this.appointment.scheduledDate > new Date()) {
            this.completionDate = new Date();
        } else {
            this.completionDate = new Date(this.appointment.scheduledDate);
        }
    }

    cancelAppointment() {
        this.selectedIndex = 1;
    }

    get contractorUrl() {
        return `${ApiService.endPointNode}entity/photo/${this.appointment.contractorId}`;
    }

    get serviceUrl() {
        return `${ApiService.endPointNode}scheduled-maintenance/item-picture/${this.appointment.maintenanceServiceOfferingId}`;
    }

    async cancelNoCredit() {
        this.dialogService.confirm('Confirm', 'Are you sure you want to cancel this appointment?').subscribe(async results => {
            if (results) {
                this.cancelling = true;
                await this.maintApi.cancelAppointment(this.appointment.id, false);
                this.cancelling = false;
                this.appointment.cancellationDate = new Date();
                this.selectedIndex = 0;
                this.missionService.showSuccessToast('Appointment Canceled');
            }
        });
    }

    async cancelAndCredit() {
        this.dialogService.confirm('Confirm', 'Are you sure you want to cancel this appointment?').subscribe(async results => {
            if (results) {
                this.cancelling = true;
                await this.maintApi.cancelAppointment(this.appointment.id, true);
                this.cancelling = false;
                this.appointment.cancellationDate = new Date();
                this.selectedIndex = 0;
                this.missionService.showSuccessToast('Appointment Canceled');
            }
        });
    }

    uncancel() {
        this.dialogService.confirm('Confirm', 'Are you sure you want to uncancel this appointment?').subscribe(async results => {
            if (results) {
                this.cancelling = true;
                await this.maintApi.uncancelAppointment(this.appointment.id);
                this.cancelling = false;
                this.appointment.cancellationDate = null;
            }
        });
    }
}
