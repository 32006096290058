<div class="bg"></div>
<div class="bg-1" *ngIf="showBg">

</div>

<div class="container">
    <div class="apps-stuff mobile-padding">
        <h1 bottommargin40>Get the App</h1> 

        <div>
            <a title="Get on Apple App Store" class="store-link"
                href="https://apps.apple.com/app/elevate-customer/id1619781798">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/app-store-badge.png" alt="Apple App Store Icon">
            </a>

            <a class="store-link" title="Get On Google Play"
                href="https://play.google.com/store/apps/details?id=com.elevatehw.customers.twa&hl=en_US&gl=US">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/google-play.png" alt="Google Play Icon">
            </a>
        </div>

        <blockquote class="detail" *ngIf="detailVisible">
            Managing your Elevate HomeScription has never been easier. <br><br> You can request service, order coverage
            and pay bills with the ease of just a few taps.
        </blockquote>
    </div>

</div>