<div class="container hide-tabs">

    <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <app-payment-method-entry *ngIf="!saving" [customerId]="customerId" [dontAutoSelectCard]="true" [newCustomerMode]="true" (selectedCardChange)="cardEntered()" [(selectedCard)]="selectedCard">

            </app-payment-method-entry>

            <div class="center" *ngIf="saving">
                <mat-spinner  style="display: inline-block;"></mat-spinner><br>
                <span class="info">Saving ...</span>
            </div>
        </mat-tab>
        <mat-tab label="Second">

            <div style="display: table;margin: 0 auto;text-align: center;">
                <app-check-circle-animated></app-check-circle-animated>

                <h1>Success</h1>
                <p class="info">You can now close this window</p>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>