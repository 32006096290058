import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { StripeCard } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-enter-credit-card-from-workflow',
    templateUrl: './enter-credit-card-from-workflow.component.html',
    styleUrls: ['./enter-credit-card-from-workflow.component.css'],
    standalone: true,
    imports: [PaymentMethodEntryModule, PaymentMethodEntryComponent, CheckCircleAnimatedComponent, MatTabsModule, MatProgressSpinnerModule]
})
export class EnterCreditCardFromWorkflowComponent implements OnInit {

    customerId: string;
    workflowInstanceId: string;
    selectedCard: StripeCard;
    selectedIndex = 0;
    saving = false;
    constructor(private activatedRoute: ActivatedRoute,
        private functionApi: CustomFunctionApiService) { }

    ngOnInit(): void {
        //'new-card/:entityId/:workFlowInstanceId',


        this.activatedRoute.params.subscribe(params => {
            this.customerId = params.entityId;
            this.workflowInstanceId = params.workFlowInstanceId;
        });
    }

    async cardEntered() {
        this.saving = true;
        await this.functionApi.resumeFunction(this.workflowInstanceId);
        this.saving = false;
        this.selectedIndex = 1;
        //TODO: resume the workflow
    }

}
