import { Component, OnInit, NgZone, HostListener, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { ApiService } from '@cogent/client/api';
import { PlanSelectionItemModel } from '@cogent/shared/models/sales/plan-selection-item.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { CommonModule, DatePipe } from '@angular/common';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/plans-and-coverage/view-coverage/view-coverage.component';
import { MatDialog } from '@angular/material/dialog';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { CoverageType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { CircleWidgetSmallModule } from '@cogent/client/shared/components/data-visualization/circle-widget-small/circle-widget-small.module';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { ClosingDocumentUploadModule } from '@cogent/client/shared/components/sales/closing-document-upload/closing-document-upload.module';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { NgxMaskDirective } from 'ngx-mask';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';

class Step { 
    description: string;
    hideNextButton: boolean;
    showSkipButton: boolean;
}

class InspectionFile {
    fileName: string;
    fileBase64: string;
}

class OnBoardSaveArgs {
    newPlanId: string;
    customerEmail: string;
    customerHomePhone: string;
    customerMobilePhone: string;
    preferredContactMethod: string;
    paperless: boolean;
    newOptions: string[];
    upgradeCard: StripeCard;
    homeInspection: InspectionFile;
    homeInspectionUploaded = false;
    sellersDisclosures: InspectionFile;
    autoRenewalCard: StripeCard;
    loginId: string;
    password: string;
    key: string;
    payMonthly: boolean;
    customerCompleted?: boolean
    oneTimeUseCard?: boolean;
}

class ShoppingCartItem {
    description: string;
    price: number;
    priceRecurring: number;
    quantity: number;
    deleting: boolean;
    iconClass: string;
    pageX: string;
    pageY: string;
    opacity: number;
    id: string;
    url: string;
    allowMultiples: boolean;

    get extPrice() {
        return this.quantity * this.price;
    }
}

class GroupedCoverage {
    category: string;
    items: any[];
}

@Component({
    selector: 'app-customer-onboarding',
    templateUrl: './customer-onboarding.component.html',
    styleUrls: ['./customer-onboarding.component.css'],
    standalone: true,
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        CircleWidgetSmallModule,
        MatIconModule,
        MatSlideToggleModule,
        FormsModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        UpkeepPipesModule,
        ClosingDocumentUploadModule,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        MatCheckboxModule,
        DisplayTotalModule,
        PlanOfferingsModule,
        NgxMaskDirective,

    ]
})
export class CustomerOnboardingComponent implements OnInit {

    selectedIndex = 0;
    showWelcomeScreen = true;
    starting = false;
    cartOpen = false;
    showBackground = true;
    indexOffset = 0;
    optionSelectedIndex = 0;
    // showBrandForBrand = false;
    // showOrangeUpgrade = false;
    brandForBrandOption: PlanSelectionItemModel;
    orangeUpgradeOption: PlanSelectionItemModel;
    steps: Step[] = [
        { description: 'Contact Information', hideNextButton: false, showSkipButton: false },
        { description: 'Coverage', hideNextButton: true, showSkipButton: true, },
        { description: 'Options', hideNextButton: false, showSkipButton: false },
        { description: 'Documents', hideNextButton: true, showSkipButton: true },
        { description: 'Renewals', hideNextButton: true, showSkipButton: true },
        { description: 'Portal Setup', hideNextButton: true, showSkipButton: true },
        { description: 'Review', hideNextButton: true, showSkipButton: false },
    ];
    currentStepDesc: string;
    policyId: string;
    setupAutoRenewalVisible = false;
    shoppingCartItems: ShoppingCartItem[] = [];
    currentItem: ShoppingCartItem;
    uploadId = UtilitiesService.newid();
    uploadType: string;
    customerId: string;
    plans: PlanClient[];
    planSelectionOpen = false;
    selectedPlan: PlanClient;
    selectedCoverageType: CoverageType;
    gettingPlans = false;
    showPaymentSchedule = false;
    tutorialOpen = false;
    tutorialIndex = 0;
    key: string;
    policySummary: PolicySummary;
    coverage: any[];
    groupedCoverage: GroupedCoverage[];
    endPoint: string;
    endPoint2: string;
    optionalItems: PlanSelectionItemModel[];
    promotedOptionalItems: PlanSelectionItemModel[];
    planName: string;
    showAllOptions = false;
    CREATE_LOGIN_INDEX = 5;
    passwordConfirm: string;
    doAutoRenewal = false;
    createLogin = false;
    selectedUpgradeCard: StripeCard;
    cardSelectionOpen;
    addingNewUpgradeCard = false;
    saving = false;
    showCompletion = false;
    showCompletionAnimation = false;
    fileUploadAvailable = false;
    tutorialGoingBack = false;
    lastIndex = 0;
    isScrolled = false;
    creditBalance: number;
    monthlySchedule: any[];
    showMoreInspectionProtection = false;
    notFound = false;

    args: OnBoardSaveArgs = new OnBoardSaveArgs();

    constructor(private zone: NgZone,
        private customerRepository: CustomerRepositoryService,
        private activatedRoute: ActivatedRoute,
        private planApi: PlanApiService,
        private dialog: DialogsService,
        private policyApi: PolicyApiService,
        private serviceApi: ServiceApiService,
        private mdDialog: MatDialog,
        private api: ApiService) { }

    ngOnInit(): void {
        this.args.payMonthly = true;
        this.api.getApiEndpointDotNet().then(endPoint => this.endPoint = endPoint);
        this.api.getApiEndpointNode().then(endPoint2 => this.endPoint2 = endPoint2);
        this.currentStepDesc = this.steps[0].description;
        this.activatedRoute.params.subscribe(params => {
            if (params.key) {
                document.location.hash = '0';
                this.key = params.key;
                this.refreshScreen();
            }
        });
    }

    tutorialIndexChange(index) {
        this.tutorialGoingBack = index < this.lastIndex;
        this.lastIndex = index;
        this.showBackground = false;
        setTimeout(() => this.showBackground = true, 50);
    }

    get labelTotal() {
        return this.args.payMonthly ? 'Per Month' : 'Per Year';
    }

    private refreshScreen() {
        this.customerRepository.getPolicySummaryFromKey(this.key).then(async ps => {
            if (ps) {
                this.policySummary = ps;
                this.args.customerEmail = ps.customerEmail;
                this.args.customerHomePhone = ps.customerHomeNumber;
                this.args.customerMobilePhone = ps.customerMobileNumber;
                this.customerId = ps.holder.id;
                this.args.preferredContactMethod = ps.holder.preferredContactMethod;
                this.args.paperless = ps.holder.paperless;
                this.planName = ps.planName;
                this.args.loginId = ps.customerEmail;

                this.creditBalance = await this.policyApi.getPolicyCreditAmount(ps.id);

                if (this.creditBalance < 0) {
                    this.creditBalance = 0;
                }


                this.refreshOptionalItems();
            } else {
                //this.dialog.alert('Sorry', 'It looks like we couldn\'t find you subscription.');
                this.notFound = true;
            }
        });
        this.customerRepository.getCoverageByKey(this.key).then(coverage => {
            this.coverage = coverage;
            this.groupedCoverage = [];
            for (const coverageItem of this.coverage) {
                let group = this.groupedCoverage.find(i => i.category === coverageItem.category);
                if (group == null) {
                    group = new GroupedCoverage();
                    group.category = coverageItem.category;
                    group.items = [];
                    this.groupedCoverage.push(group);
                }
                group.items.push(coverageItem);
            }
        });
    }

    private _poolSpaOption: any;
    get poolSpaOption() {
        if (!this._poolSpaOption && this.promotedOptionalItems) {
            this._poolSpaOption = this.promotedOptionalItems.find(i => i.salesItemId === 'a211f2e3-4741-44f5-b11f-eb37e0649c7c');
        }

        return this._poolSpaOption;
    }

    private _waterSoftenerOption: any;
    get waterSoftenerOption() {
        if (!this._waterSoftenerOption && this.promotedOptionalItems) {
            this._waterSoftenerOption = this.promotedOptionalItems.find(i => i.salesItemId === '385f8005-9326-4bfe-a708-d9dd04afc626');
        }

        return this._waterSoftenerOption;
    }

    private _elevatedPipLeakOption: any;
    get elevatedPipeLeakOption() {
        if (!this._elevatedPipLeakOption && this.promotedOptionalItems) {
            this._elevatedPipLeakOption = this.promotedOptionalItems.find(i => i.salesItemId === '4848649d-29e5-4414-af07-48c3afc61818');
        }

        return this._elevatedPipLeakOption;
    }

    private _sprinklerSystemOption: any;
    get sprinklerSystemOption() {
        if (!this._sprinklerSystemOption && this.promotedOptionalItems) {
            this._sprinklerSystemOption = this.promotedOptionalItems.find(i => i.salesItemId === 'fa724d1d-6ac2-44db-898b-39807977f6e6');
        }

        return this._sprinklerSystemOption;
    }


    showCoverage(item: PlanSelectionItemModel) {
        this.mdDialog.open(ViewCoverageDialogComponent, { data: item.planItemId });
    }

    private async refreshOptionalItems() {
        const planId = this.args.newPlanId ? this.args.newPlanId : this.policySummary.planId;
        let optionalItems = await this.planApi.getPlanOptionalItems(planId);
        this.brandForBrandOption = optionalItems.find(i => i.salesItemId === '069d821e-ba24-4455-af60-5c32a3d8640c');
        this.orangeUpgradeOption = optionalItems.find(i => i.salesItemId === 'cc26b904-e2b1-4579-8b1b-983026b8484c');


        optionalItems = optionalItems.filter(i => i.showOnWeb && i.salesItemId !== '069d821e-ba24-4455-af60-5c32a3d8640c' && i.salesItemId !== 'cc26b904-e2b1-4579-8b1b-983026b8484c');
        for (const optionalItem of optionalItems) {
            // this.serviceApi.getItemThumbnailUrl(salesItem.id)
            optionalItem.url = this.serviceApi.getItemThumbnailUrl(optionalItem.salesItemId);
        }
        this.optionalItems = optionalItems;
        this.promotedOptionalItems = this.optionalItems.filter(i => i.isPromotedUpgrade && (this.shoppingCartItems.map(i2 => i2.id).indexOf(i.planItemId) === -1 || i.allowQuantity));

        const brandForBrandStep = this.steps.find(i => i.description === 'Brand For Brand');
        if (brandForBrandStep) {
            this.steps.splice(this.steps.indexOf(brandForBrandStep), 1);
        }
        const orangeUpgradeStep = this.steps.find(i => i.description === 'Orange Upgrade');
        if (orangeUpgradeStep) {
            this.steps.splice(this.steps.indexOf(orangeUpgradeStep), 1);
        }

        if (this.brandForBrandOption) {
            this.brandForBrandOption.url = this.serviceApi.getItemThumbnailUrl(this.brandForBrandOption.salesItemId);
            this.steps.splice(2, 0, { description: 'Brand For Brand', hideNextButton: true, showSkipButton: true, });
            this.indexOffset++;
        }
        if (this.orangeUpgradeOption) {
            this.orangeUpgradeOption.url = this.serviceApi.getItemThumbnailUrl(this.orangeUpgradeOption.salesItemId);
            this.steps.splice(3, 0, { description: 'Orange Upgrade', hideNextButton: true, showSkipButton: true, });
            this.indexOffset++;

        }

    }

    async validateLogin() {
        if (!UtilitiesService.validateEmail(this.args.loginId)) {
            this.dialog.alert('Sorry', 'That doesn\'t seem to be a valid email.');
            return;
        }

        if (!this.args.password) {
            this.dialog.alert('Sorry', 'Please enter a password');
            return;
        }

        if (this.args.password !== this.passwordConfirm) {
            this.dialog.alert('Sorry', 'The password and the confirmation do not match.');
            return;
        }

        const userExists = await this.customerRepository.loginExists(this.args.loginId);
        if (userExists) {
            this.dialog.alert('In Use', 'Sorry, it looks like that email address is already registered to a different account.');
            return;
        }
        this.createLogin = true;
        this.selectedIndex++;
    }

    get cardGetUrl() {
        return `CustomerOnBoarding/${this.key}/stripe-cards`;
    }

    renewalCardChanged() {
        const datePipe = new DatePipe('en-US');
        this.steps[4 + this.indexOffset].hideNextButton = false;
        this.steps[4 + this.indexOffset].showSkipButton = false;
        this.doAutoRenewal = true;
        if (this.args.autoRenewalCard) {
            const expDate = new Date(this.args.autoRenewalCard.exp_year, this.args.autoRenewalCard.exp_month - 1, 1);
            if (expDate < this.policySummary.expirationDate) {
                this.dialog.alert('Warning', `It looks like your subscription expires in ${datePipe.transform(this.policySummary.expirationDate, 'MMMM yyyy')} and your credit card expires in ${datePipe.transform(expDate, 'MMMM yyyy')}<br>We may need to contact you for updated payment information before we will be able to process your auto-renewal request.`);
            }
        }
    }

    addBrandForBrand(evt) {
        const existingItem = this.shoppingCartItems.find(i => i.id === this.brandForBrandOption.planItemId);
        if (existingItem) {
            this.shoppingCartItems.splice(this.shoppingCartItems.indexOf(existingItem), 1);
        }
        this.addCartItem(evt, this.brandForBrandOption);
        setTimeout(() => this.selectedIndex++, 500);
    }

    addOrangeUpgrade(evt) {
        // cartItem.id = option.planItemId;
        const existingItem = this.shoppingCartItems.find(i => i.id === this.orangeUpgradeOption.planItemId);
        if (existingItem) {
            this.shoppingCartItems.splice(this.shoppingCartItems.indexOf(existingItem), 1);
        }
        this.addCartItem(evt, this.orangeUpgradeOption);
        setTimeout(() => this.selectedIndex++, 500);
    }

    selectUpgradeCard() {
        this.cardSelectionOpen = false;
        this.doSave();
    }

    homeInspectionUploaded() {
        this.args.homeInspectionUploaded = true;
        this.selectedIndex++;
    }

    upgradeSelected(planId: string) {
        this.planApi.getPlanOfferingByPlanId(planId, this.policySummary.coverageType as any,
            this.policySummary.propertyAddress.dwellingType as any,
            this.policySummary.propertyAddress.postalCode,
            this.policySummary.propertyAddress.squareFeet,
            new Date().getFullYear() - this.policySummary.propertyAddress.yearBuilt,
            null,
            this.policySummary.propertyAddress.latitude,
            this.policySummary.propertyAddress.longitude).then(p => {
                p.price = p.price - this.policySummary.basePrice;
                this.planSelected(p);
            });
    }

    doNotAutoRenew() {
        this.setupAutoRenewalVisible = false;
        this.steps[4 + this.indexOffset].hideNextButton = true;
        this.steps[4 + this.indexOffset].showSkipButton = true;
        this.args.autoRenewalCard = null;
        this.doAutoRenewal = false;
    }

    goNext() {
        if (this.selectedIndex === 0) {
            if (!this.validateContactInfo()) {
                return false;
            }
        }

        // if (this.selectedIndex === (2 + this.indexOffset) && this.shoppingCartItems && this.shoppingCartItems.length > 0) {
        //     this.cardSelectionOpen = true;
        //     return;
        // }
        this.selectedIndex = this.selectedIndex + 1;
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.zone.run(() => {
            this.isScrolled = window.scrollY > 0;
        });
    }

    private validateContactInfo(): boolean {
        if (!this.args.customerEmail || !UtilitiesService.validateEmail(this.args.customerEmail)) {
            this.dialog.alert('Sorry', 'Please enter a valid email address.').subscribe(() => {
                document.getElementById('customer-email-address').focus();
            });

            return false;
        }
        if (!UtilitiesService.validatePhoneNumber(this.args.customerHomePhone) && !UtilitiesService.validatePhoneNumber(this.args.customerMobilePhone)) {
            this.dialog.alert('Sorry', 'Please enter either a home number or a mobile number.').subscribe(() => {

                document.getElementById('customer-home-number').focus();
            });
            return false;
        }

        return true;
    }

    optionsInterval;
    showCreditBalanceInfo = false;

    selectedIndexChange(index) {
        this.currentStepDesc = null;
        setTimeout(() => this.currentStepDesc = this.steps[this.selectedIndex].description);
        window.location.hash = index.toString();
        clearInterval(this.optionsInterval);
        if (index === 2 + this.indexOffset) {

            this.optionSelectedIndex = 0;
            this.optionsInterval = setInterval(() => {
                this.optionSelectedIndex++;
                if (this.optionSelectedIndex > 3) {
                    this.optionSelectedIndex = 0;
                }
            }, 10000);

            this.steps[2 + this.indexOffset].hideNextButton = this.shoppingCartItems.length === 0;
            this.steps[2 + this.indexOffset].showSkipButton = this.shoppingCartItems.length === 0;

        }

        if (index === 1 && this.creditBalance > 0) {
            setTimeout(() => this.showCreditBalanceInfo = true, 500);
        }
    }

    stopCarosel() {
        clearInterval(this.optionsInterval)
    }

    get pct() {
        return ((this.selectedIndex + 1) / this.totalSteps) * 100;
    }

    get contractUrl() {
        if (this.policySummary) {
            return `${this.endPoint2}policy/contract/pdf/${this.policySummary.id}`;
        }
    }

    closeTutorial() {
        this.tutorialOpen = false;
        this.start();
    }

    get totalSteps() {
        return this.steps.length;
    }

    get hideNextButton() {
        const current = this.steps[this.selectedIndex];
        if (current) {
            return current.hideNextButton;
        }
    }

    get showSkipButton() {
        const current = this.steps[this.selectedIndex];
        if (current) {
            return current.showSkipButton;
        }
    }

    get currentStep() {
        return this.steps[this.selectedIndex];
    }

    start() {
        this.starting = true;
        setTimeout(() => {
            this.showWelcomeScreen = false;
            if (!this.args.paperless) {
                setTimeout(() => {
                    this.args.paperless = true;
                }, 500);
            }
        }, 500);
    }

    get nextStep() {
        const next = this.steps[this.selectedIndex + 1];
        if (next) {
            return next.description;
        }
    }

    get progressLabel() {
        return `${this.selectedIndex + 1} of ${this.totalSteps}`;
    }

    skipStep() {
        if (this.selectedIndex === 5) {
            this.args.password = '';
            this.passwordConfirm = '';
            this.createLogin = false;
        }

        this.selectedIndex = this.selectedIndex + 1;
    }

    removeItem(item: ShoppingCartItem) {
        item.deleting = true;
        setTimeout(() => {
            this.shoppingCartItems.splice(this.shoppingCartItems.indexOf(item), 1);

            this.args.newOptions = this.shoppingCartItems.filter(i => i.id !== 'UPGRADE').map(i => i.id);
            if (item.id === 'UPGRADE') {
                this.planName = this.policySummary.planName;
                this.args.newPlanId = null;
                this.refreshOptionalItems();
                this.shoppingCartItems = [];
            }
            this.steps[2 + this.indexOffset].hideNextButton = this.shoppingCartItems.length === 0;
            this.steps[2 + this.indexOffset].showSkipButton = this.shoppingCartItems.length === 0;

            this.promotedOptionalItems = this.optionalItems.filter(i => i.isPromotedUpgrade && (this.shoppingCartItems.map(i2 => i2.id).indexOf(i.planItemId) === -1 || i.allowQuantity));
        }, 500);
    }

    get additionalOptions() {
        if (!this.optionalItems) {
            return null;
        }

        return this.optionalItems.filter(i => this.promotedOptionalItems.indexOf(i) === -1 && (this.shoppingCartItems.map(i2 => i2.id).indexOf(i.planItemId) === -1 || i.allowQuantity));
    }

    get cartTotal() {
        if (!this.shoppingCartItems || this.shoppingCartItems.length === 0) {
            return 0;
        }

        if (this.args.payMonthly) {
            return this.shoppingCartItems.map(i => i.priceRecurring * i.quantity).reduce((a, b) => a + b);
        } else {
            return this.shoppingCartItems.map(i => i.price * i.quantity).reduce((a, b) => a + b);
        }
    }

    get cartTotalMinusCredit() {
        if (this.cartTotal < this.creditBalance) {
            return 0;
        }

        return this.cartTotal - this.creditBalance;
    }

    uploadDocument(documentType: string) {
        // Force the file upload input element to clear out be removing and re-adding to DOM
        this.fileUploadAvailable = false;
        setTimeout(() => {
            this.fileUploadAvailable = true;
            setTimeout(() => {
                this.uploadType = documentType;
                document.getElementById(this.uploadId).click();
            });
        });
    }

    async getPlans() {
        this.gettingPlans = true;
        let age = 0;
        if (this.policySummary.propertyAddress.yearBuilt) {
            age = new Date().getFullYear() - this.policySummary.propertyAddress.yearBuilt;
        }
        if (isNaN(age) || age < 0) {
            age = 0;
        }
        this.plans = await this.planApi.getOfferedPlans(this.policySummary.coverageType as any,
            this.policySummary.propertyAddress.dwellingType as any,
            this.policySummary.propertyAddress.postalCode,
            this.policySummary.propertyAddress.squareFeet, age, null, null, null, null, false);

        this.plans = this.plans.filter(i => i.price > this.policySummary.basePrice && i.id !== this.policySummary.planId && i.showOnWeb);
        for (const plan of this.plans) {
            plan.price = plan.price - this.policySummary.basePrice;
            plan.priceRecurring = plan.priceRecurring - this.policySummary.basePriceRecurring;
        }

        this.gettingPlans = false;
        this.planSelectionOpen = true;
    }

    async planSelected(plan: PlanClient) {
        this.planSelectionOpen = false;
        this.args.newPlanId = plan.id;
        let planUpgradeItem = this.shoppingCartItems.find(i => i.id === 'UPGRADE');
        if (planUpgradeItem) {
            this.shoppingCartItems.splice(this.shoppingCartItems.indexOf(planUpgradeItem), 1);

        }
        this.planName = plan.name;
        planUpgradeItem = new ShoppingCartItem();
        planUpgradeItem.description = `${plan.name}`;
        planUpgradeItem.quantity = 1;
        planUpgradeItem.price = plan.price;
        planUpgradeItem.priceRecurring = plan.priceRecurring;
        planUpgradeItem.id = 'UPGRADE';

        this.shoppingCartItems = [];
        this.shoppingCartItems.push(planUpgradeItem);
        this.optionalItems = [];
        this.promotedOptionalItems = [];
        await this.refreshOptionalItems();
        this.selectedIndex++;
    }

    get planUpgradeItem() {
        if (!this.shoppingCartItems) {
            return null;
        }

        return this.shoppingCartItems.find(i => i.id === 'UPGRADE');
    }

    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (this.uploadType === 'inspection') {
                this.args.homeInspection = new InspectionFile();
                this.args.homeInspection.fileName = file.name;
            } else {
                this.args.sellersDisclosures = new InspectionFile();
                this.args.sellersDisclosures.fileName = file.name;
            }
            const reader = new FileReader();

            const setUrl = (url: string) => {
                const baseIndex = url.indexOf('base64,');
                url = url.substring(baseIndex + 7, url.length);
                if (this.uploadType === 'inspection') {
                    this.args.homeInspection.fileBase64 = url;
                } else {
                    this.args.sellersDisclosures.fileBase64 = url;
                }
                this.steps[3 + this.indexOffset].hideNextButton = !this.args.sellersDisclosures || !this.args.homeInspection;
                this.steps[3 + this.indexOffset].showSkipButton = this.steps[3 + this.indexOffset].hideNextButton;
            };
            reader.onload = (() => {
                return e => {
                    setUrl(e.target.result as string);
                };
            })();

            reader.readAsDataURL(file);
        }
    }

    @HostListener('window:hashchange')
    watchUrlHash() {
        this.zone.run(() => {
            const hash = window.location.hash;
            const hashIndex = parseInt(hash.replace('#', ''), 10);

            if (!isNaN(hashIndex)) {
                this.selectedIndex = hashIndex;
            }
        });
    }



    get scheduleFullAmount() {
        if (!this.monthlySchedule || this.monthlySchedule.length === 0) {
            return 0;
        }
        return this.monthlySchedule.map(i => i.fullAmount).reduce((a, b) => a + b);
    }


    get scheduleTotal() {
        if (!this.monthlySchedule || this.monthlySchedule.length === 0) {
            return 0;
        }
        return this.monthlySchedule.map(i => i.amount).reduce((a, b) => a + b);
    }

    get scheduleAppliedTotal() {
        if (!this.monthlySchedule || this.monthlySchedule.length === 0) {
            return 0;
        }

        return this.monthlySchedule.map(i => i.appliedCredit).reduce((a, b) => a + b);
    }

    get needsPaymentInformation() {
        if (this.shoppingCartItems.length === 0) {
            return false;
        }
        const totalToBeBilled = this.args.payMonthly ? this.cartTotal * this.policySummary.monthsOfCoverage : this.cartTotal;
        if (totalToBeBilled > this.creditBalance) {
            return true;
        }

        return false;
    }

    async save() {
        if (this.needsPaymentInformation) {
            this.monthlySchedule = [];
            if (this.args.payMonthly) {
                let creditBalance = this.creditBalance;
                for (let i = 0; i < this.policySummary.monthsOfCoverage; i++) {
                    const due = new Date().setMonth(new Date().getMonth() + i);
                    let amount = this.cartTotal;
                    let appliedCredit = 0;
                    if (creditBalance > 0) {
                        if (creditBalance > amount) {
                            appliedCredit = amount;
                            creditBalance -= amount;
                            amount = 0;
                        } else {
                            appliedCredit = creditBalance;
                            creditBalance = 0;
                            amount -= appliedCredit;
                        }
                    }
                    this.monthlySchedule.push({
                        due,
                        amount,
                        appliedCredit,
                        fullAmount: this.cartTotal,
                    });
                }
            }
            this.cardSelectionOpen = true;
        } else {
            this.dialog.confirm('Confirm', 'Complete the onboarding?').subscribe(results => {
                if (results) {
                    this.doSave();
                }
            });
        }
    }

    private async doSave() {
        this.saving = true;
        this.args.key = this.key;
        this.args.customerCompleted = true;
        if (!this.args.payMonthly) {
            this.args.oneTimeUseCard = !this.args.upgradeCard.saveCardForLater;
        } else {
            this.args.oneTimeUseCard = false;
        }
        const result = await this.customerRepository.completeOnboarding(this.args);
        this.saving = false;

        if (result.success) {
            this.doCompleteSequence();
        } else {
            this.dialog.alert('Sorry', result.message);
        }
    }

    teaseHMS = false;

    doCompleteSequence() {
        this.showCompletion = true;
        setTimeout(() => this.showCompletionAnimation = true, 500);

        setTimeout(() => this.teaseHMS = true, 5000);
    }

    private setOptionalItems() {
        this.args.newOptions = this.shoppingCartItems.filter(i => i.id !== 'UPGRADE').map(i => i.id);
    }

    get autoHref() {
        return `/authenticate/${this.policySummary?.holderId}/MAINT`;
    }

    addCartItem(evt, option: PlanSelectionItemModel) {
        this.currentItem = null;
        setTimeout(() => {
            let doAdd = true;
            if (!option.allowQuantity) {
                const foundItem = this.shoppingCartItems.find(i => i.id === option.planItemId);
                if (foundItem) {
                    doAdd = false;
                }
            }
            const cartItem = new ShoppingCartItem();
            cartItem.description = option.name;
            cartItem.price = option.price;
            cartItem.priceRecurring = option.priceRecurring;
            cartItem.url = option.url;
            cartItem.quantity = 1;
            cartItem.id = option.planItemId;
            cartItem.iconClass = option.iconClass;
            if (doAdd) {
                this.shoppingCartItems.push(cartItem);
            }
            this.currentItem = cartItem;
            this.currentItem.pageX = evt.pageX + 'px';
            this.currentItem.pageY = evt.pageY + 'px';
            this.currentItem = cartItem;
            this.currentItem.opacity = 1;
            this.steps[2 + this.indexOffset].hideNextButton = false;
            this.steps[2 + this.indexOffset].showSkipButton = false;
            this.setOptionalItems();

            setTimeout(() => {
                const cartButton = document.getElementById('cart-button');
                const boundingRect = cartButton.getBoundingClientRect();

                this.currentItem.pageX = boundingRect.x + 'px';
                this.currentItem.pageY = boundingRect.y + 'px';
                this.currentItem.opacity = 0;
            }, 1);

            setTimeout(() => {
                this.currentItem = null;

                this.promotedOptionalItems = this.optionalItems.filter(i => i.isPromotedUpgrade && (this.shoppingCartItems.map(i2 => i2.id).indexOf(i.planItemId) === -1 || i.allowQuantity));
            }, 501);
        });
    }
}
