import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-maint-services-contract-language',
    templateUrl: './maint-services-contract-language.component.html',
    styleUrls: ['./maint-services-contract-language.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule]
})
export class MaintServicesContractLanguageComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<MaintServicesContractLanguageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,) { }

    ngOnInit(): void {
    }

}
