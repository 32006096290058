import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { CustomFunction, MaintenanceServiceCustomerPropertyServiceAppointmentSummary, MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceCustomerPropertySummary, MaintenanceServiceInvoiceSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
// import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarModule, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { ApiService } from '@cogent/client/api';
import { Subject } from 'rxjs';
import { MaintServicesChangeFrequencyComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-change-frequency/maint-services-change-frequency.component';
import { MaintServicesViewAppointmentComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-view-appointment/maint-services-view-appointment.component';
import { MaintServicesCancelComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-cancel/maint-services-cancel.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { CalendarDayViewerModule } from '@cogent/client/shared/components/data-visualization/calendar-day-view/calendar-day-viewer.module';
import { MatMenuModule } from '@angular/material/menu';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-maintenance-service-property-view',
    templateUrl: './maintenance-service-property-view.component.html',
    styleUrls: ['./maintenance-service-property-view.component.css'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule, MatButtonModule, MatTabsModule, RouterModule, MatIconModule, CalendarDayViewerModule, MatMenuModule]
})
export class MaintenanceServicePropertyViewComponent implements OnInit {

    summary: MaintenanceServiceCustomerPropertySummary;
    coveredItems: MaintenanceServiceCustomerPropertyServiceSummary[];
    selectedService: MaintenanceServiceCustomerPropertyServiceSummary;
    id: string;
    propertyAppointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    serviceAppointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    invoices: MaintenanceServiceInvoiceSummary[];
    isLegacy = true;


    refresh: Subject<any> = new Subject();
    startHour = 6;
    endHour = 19;
    activeDayIsOpen = false;
    lottieReset = true;

    viewDate: Date = new Date();
    selectedAppointmentId: string;
    customFunctions: CustomFunction[];
    filteredFunctions: CustomFunction[];

    //
    constructor(private activatedRoute: ActivatedRoute,
        private serviceApi: ServiceApiService,
        private dialogService: DialogsService,
        private missionService: MissionService,
        private dialog: MatDialog,
        private functionApi: CustomFunctionApiService,
        private maintApi: MaintenanceServiceApiService,) { }

    async ngOnInit() {
        this.activatedRoute.params.subscribe(async params => {
            this.id = params.id;

            this.loadServices();
        });

        this.activatedRoute.queryParams.subscribe(async params => {
            if (params.appointmentId) {
                this.selectedAppointmentId = params.appointmentId;
            }
        })
    }

    get dueNow() {
        if (!this.invoices) {
            return 0;
        }
        const pastDue = this.invoices.filter(i => i.status === 'Past Due');
        if (pastDue.length === 0) {
            return 0;
        }

        return pastDue.map(i => i.due).reduce((a, b) => a + b);
    }


    getBackground(offering) {
        if (offering.lottieAnimationUrl) {
            return 'none';
        }
        return `url(${this.serviceApi.getItemThumbnailUrl(offering.maintenanceServiceOfferingId)})`;
    }

    getOfferingUrl(offering) {
        return this.serviceApi.getItemThumbnailUrl(offering.maintenanceServiceOfferingId);
    }

    get baseUrl() { 
        return ApiService.endPointDotNet;
    }

    get contractUrl() {
        return `${ApiService.endPointNode}scheduled-maintenance/contract/pdf/${this.id}`;
    }

    getServiceUrl(item) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${item.maintenanceServiceOfferingId}/Photo`;
    }

    showAppointment(appointment: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) {
        this.dialog.open(MaintServicesViewAppointmentComponent, { data: appointment, panelClass: 'no-overflow-dialog' });
    }

    get totalMonthlyPayment() {
        if (!this.coveredItems) {
            return 0;
        }

        return this.coveredItems.filter(i => !i.isOneTime).map(i => i.pricePerMonth).reduce((a, b) => a + b, 0);
    }

    backMonth() {
        const dt = new Date(this.viewDate);
        dt.setMonth(dt.getMonth() - 1);
        this.viewDate = dt;
        this.loadNewCalendar();
    }

    forwardMonth() {
        const dt = new Date(this.viewDate);
        dt.setMonth(dt.getMonth() + 1);
        this.viewDate = dt;
        this.loadNewCalendar();
    }

    items: DayAppointments[] = [];
    loadNewCalendar() {

        this.items = [];
        const date = UtilitiesService.monthStart(this.viewDate);
        while (date.getDay() !== 0) {
            date.setDate(date.getDate() - 1);
        }
        const endDate = UtilitiesService.monthEnd(this.viewDate);
        while (date < endDate) {


            let fileDay = this.items.find(i => i.day.getDate() === date.getDate() && i.day.getMonth() === date.getMonth() && i.day.getFullYear() === date.getFullYear());
            if (!fileDay) {
                fileDay = new DayAppointments();
                fileDay.day = new Date(date);
                this.items.push(fileDay);
            }
            fileDay.previousMonth = date.getMonth() !== this.viewDate.getMonth();

            if (!fileDay.previousMonth) {
                fileDay.maintenanceServiceAppointments = this.propertyAppointments.filter(i => i.scheduledDate.getMonth() === fileDay.day.getMonth() && i.scheduledDate.getFullYear() === fileDay.day.getFullYear()
                    && i.scheduledDate.getDate() === fileDay.day.getDate());

            }

            date.setDate(date.getDate() + 1);
        }
        

    }

    async select(service: MaintenanceServiceCustomerPropertyServiceSummary) {
        this.selectedService = service;
        this.serviceAppointments = [];

        this.serviceAppointments = await this.maintApi.getMaintenanceServiceCustomerPropertyServiceAppointmentSummaryForService(service.id);

        if(!this.customFunctions) {
            this.customFunctions = await this.functionApi.getFunctionsForSMSPropertyService();
        }

        const addOns = await this.maintApi.getAddOnsForOffering(service.maintenanceServiceOfferingId);

        const functions = [...this.customFunctions];

        for(const addOn of addOns) {
            const cs = new CustomFunction();
            cs.iconClass = addOn.iconClass;
            cs.functionJson = addOn.processDefinition;
            cs.name = addOn.name;
            cs.imageUrl = `${ApiService.endPointDotNet}WorkOrderItem/${addOn.id}/Photo`;
            functions.push(cs);
        }

        for (const func of functions) {
            if (func.screenAvailabilityFilter) {
                try {
                    const f = new Function('objectInScope', func.screenAvailabilityFilter);
                    const result = f(service);

                    if (!result) {
                        functions.splice(functions.indexOf(func), 1);
                    }
                } catch (e) {
                    console.error('error setting function filter: ')
                    console.error(func);
                    functions.splice(functions.indexOf(func), 1);
                }
            }
        }

        this.filteredFunctions = functions;
    }

    cancelService() {
        //cancelMaintenanceService
        // this.dialogService.confirm('Confirm', 'Are you sure you want to cancel this service?').subscribe(async results => {
        //     if (results) {
        //         await this.maintApi.cancelMaintenanceService(this.selectedService.id);
        //         this.loadServices();
        //         this.missionService.showSuccessToast('Service Removed');
        //         this.selectedService = null;
        //     }
        // });
        const ref = this.dialog.open(MaintServicesCancelComponent, { data: this.selectedService });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.loadServices();
            }
        });

    }

    async runCustomFunction(customFunction: CustomFunction) {

        this.missionService.raiseExecuteFunction({
            objectInScope: this.selectedService,
            functionJson: customFunction.functionJson,
            changeWorkingMessage: value => {

            },
            refreshKey: 'REFRESH-JOB-DETAIL'
        });
    }

    get calendarUrl() {
        if (!this.summary) {
            return null;
        }
        return `${ApiService.endPointNode}scheduled-maintenance/customer-ical/${this.summary.loginId}`;
    }

    get appleWebCalCalendarUrl() {
        const calendarUrl = this.calendarUrl;
        if (calendarUrl) {
            return calendarUrl.replace('https://', 'webcal://');
        }
    }

    get googleWebCalCalendarUrl() {
        const calendarUrl = this.calendarUrl;
        if (calendarUrl) {
            return `https://calendar.google.com/calendar/render?cid=${this.calendarUrl}`;
        }
    }

    copyUrl() {
        navigator.clipboard.writeText(this.calendarUrl);
        this.missionService.showSuccessToast('URL Copied');
    }

    changeFrequency() {
        const ref = this.dialog.open(MaintServicesChangeFrequencyComponent, { data: this.selectedService });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.loadServices(this.selectedService.id);
            }
        });
    }

    getInoviceUrl(invoice: MaintenanceServiceInvoiceSummary) {
        return `${ApiService.endPointNode}scheduled-maintenance/invoice-pdf/${invoice.id}`;
    }


    getContractorId(service: MaintenanceServiceCustomerPropertyServiceSummary) {
        return `${ApiService.endPointNode}entity/photo/${service.contractorId}`;
    }



    private async loadServices(selectedServiceId: string = null) {
        this.summary = await this.maintApi.getMaintServiceSummaryById(this.id);
        this.coveredItems = await this.maintApi.getMaintServiceCustomerPropertyServiceSummariesForProperty(this.id);
        this.coveredItems = this.coveredItems.filter(i => !i.cancellationDate);

        this.invoices = (await this.maintApi.getInvoicesForProperty(this.id)).filter(i => i.invoiceType === 'AR');

        if (selectedServiceId) {
            this.selectedService = this.coveredItems.find(i => i.id === selectedServiceId);
            if (this.selectedService) {
                this.select(this.selectedService);
            }
        }


        this.propertyAppointments = await this.maintApi.getAppointmentsForProperty(this.id);
        
        this.loadNewCalendar();

        if (this.selectedAppointmentId) {
            const found = this.propertyAppointments.find(i => i.id === this.selectedAppointmentId);
            if (found) {
                this.showAppointment(found);
            }
        }
        // this.events = this.propertyAppointments.map(i => {
        //     const actions: CalendarEventAction[] = [];
        //     return {
        //         start: this.getDateFromRecord(i, true),
        //         end: this.getDateFromRecord(i, false),
        //         meta: {
        //             id: i.id,
        //             subject: i.maintenanceServiceName,
        //             workOrderSummary: i,
        //         },
        //         title: `${i.maintenanceServiceName}`,
        //         draggable: true,
        //         resizable: {
        //             beforeStart: true,
        //             afterEnd: true
        //         },
        //         actions,
        //     };
        // });
    }

    // handleEvent(action: string, event: CalendarEvent): void {
    //     if (action === 'Clicked') {
    //         this.selectItem({
    //             id: event.meta.id,
    //         });
    //         if (event?.meta?.workOrderSummary) {
    //             this.showAppointment(event.meta.workOrderSummary);
    //         }
    //     }
    // }

    // private setHourAndMinuteFromString(date: Date, hourString: string) {
    //     let hour = 0;
    //     let minute = 0;
    //     const dotIndex = hourString.indexOf(':');

    //     if (dotIndex > -1) {
    //         hour = parseInt(hourString.substring(0, dotIndex), 10);
    //     } else {
    //         hour = parseInt(hourString, 10);
    //     }

    //     if (dotIndex > -1) {
    //         minute = parseInt(hourString.substring(dotIndex + 1, hourString.length), 10);

    //     }

    //     if (hourString.toLowerCase().indexOf('pm') > -1 && hour !== 12) {
    //         hour += 12;
    //     }

    //     date.setHours(hour);
    //     date.setMinutes(minute);
    // }

    private getDateFromRecord(record: any, start: boolean) {

        const baseDate = new Date(record.scheduledDate);

        return baseDate;
    }

    selectItem(item) {
        // this.router.navigateByUrl(`work-order/${item.id}`);
    }

    get excludeDays() {
        return [];
    }

    closeOpenMonthViewDay(data = null) {
        this.activeDayIsOpen = false;
    }

    // dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    //     if (isSameMonth(date, this.viewDate)) {
    //         this.viewDate = date;
    //         if (
    //             (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
    //             events.length === 0
    //         ) {
    //             this.activeDayIsOpen = false;
    //         } else {
    //             this.activeDayIsOpen = true;
    //         }
    //     }
    // }

    // setView(view: CalendarView) {
    //     this.view = view;
    // }

    // async eventTimesChanged({
    //     event,
    //     newStart,
    //     newEnd
    // }: CalendarEventTimesChangedEvent, techAndWorkOrders?: any) {

    //     // const datePipe = new DatePipe('en-US');
    //     // if (event && event.meta && event.meta.type === 'unassigned') {
    //     //     if (!techAndWorkOrders) {
    //     //         return;
    //     //     }

    //     //     if (!UtilitiesService.datesEqual(newStart, event.start)) {
    //     //         this.dialogService.confirm('Confirm', `The original date selected was ${datePipe.transform(event.start, 'shortDate')}.  You selected an appointment date of ${datePipe.transform(newStart, 'shortDate')}.<br>Are you sure you want to continue?`).subscribe(async results => {
    //     //             if (results) {
    //     //                 // alert('make change');
    //     //                 newEnd = new Date(newStart);
    //     //                 const numberOfHours = event.end.getHours() - event.start.getHours();

    //     //                 newEnd.setHours(newStart.getHours() + numberOfHours);

    //     //                 this.updateAppointment(event, newStart, newEnd, true, techAndWorkOrders.technician.id);
    //     //             }
    //     //         });

    //     //         return;
    //     //     }

    //     //     return;
    //     // }
    // }

}

export class DayAppointments {
    day: Date;
    maintenanceServiceAppointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    previousMonth = false;
    get dayNumber() {
        return this.day.getDate();
    }
}