import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Injectable({ providedIn: "root" })
export class StartupService {
    constructor(
        private http: HttpClient,
        private apiService: ApiService
    ) { }
    startupData: any;
    async load(): Promise<any> {
        this.startupData = null;
        const observable = this.http.get<any>(await this.apiService.getApiEndpointDotNet() + "entity/companyInfo");
        observable.subscribe(companies => {
            UtilitiesService.applicationName = companies.data[0].name;
            document.title = `${companies.data[0].name} Homeowner Portal`;
        });
        return observable.toPromise();
    }
} 