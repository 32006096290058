<div class="container fancy-container">
    <h1>Delete Account</h1>

    <p>User's can delete their account login and profile information through the app by going to Settings->Delete Account, or click here*:</p>
    <p class="center">
        <a mat-button mat-raised-button href="/delete-account" color="primary">Close Account</a>
    </p>

    <h2 topmargin30>What this does:</h2>
    <p>This will delete your user login and password disabling all access to your account through the application and web portal.  It will also remove you
        from any future marketing correspondence.
    </p>

    <h2 topmargin30>What it does NOT do:</h2>
    <p>This will NOT cancel any warranty contracts, payments due, service requests or any data associated with the warranty contract and associated home maintenance services.
        We will also continue to correspond with you with status updates on any on-going service requests or warranty issues.
    </p>
    <p>
        To cancel your warranty or associated home services, please refer to the cancellation section of your Home Services Contract or contact us at 801-804-5132.
    </p>

    <h2 topmargin30>Can't Log In?</h2>
    <p>You can also request account deletion by emailing cs&#64;elevatehomescriptions.com to request account deletion.  Please include your name, the email you use to log in and a phone number for verification. You can also call us at 801-804-5132 and we can assist with closing your account.</p>

    <p class="italic">*Requires you to log into your account</p>
</div>
