import { Directive, HostListener, Input, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appEnterAction]',
    standalone: true
})
export class EnterActionDirective {

    @Input() componentRef: any;
    @Input() appEnterAction: () => void;
    constructor(private _viewContainerRef: ViewContainerRef ) { }

    @HostListener('keyup', ['$event'])
    handleKeyUp(event: KeyboardEvent) {
        if(event.key === 'Enter' && this.appEnterAction) {
            const enter = this.appEnterAction.bind(this.componentRef);
            enter();
        }
    }
}
