import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AppointmentTimeSlot, GroupedAppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { WorkOrderPreferredTime } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';

export class RescheduleAppointmentDialogArgs {
    workOrderSummary: WorkOrderSummaryClient;
}

@Component({
    selector: 'app-reschedule-appointment',
    templateUrl: './reschedule-appointment.component.html',
    styleUrls: ['./reschedule-appointment.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, AppointmentSelectionComponent, UpkeepPipesModule, MatProgressSpinnerModule, DragDropModule]
})
export class RescheduleAppointmentComponent implements OnInit {
    selectedTimeSlots: AppointmentTimeSlot[];
    timeSlots: GroupedAppointmentTimeSlot[];
    saving = false;
    noTimeSlots = false;

    constructor(public dialogRef: MatDialogRef<RescheduleAppointmentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RescheduleAppointmentDialogArgs,
        private serviceApi: ServiceApiService,
        private missionService: MissionService) { }

    ngOnInit() {
        this.serviceApi.getAppointmentTimeSlots(this.data.workOrderSummary.lines[0].itemId).then(timeSlots => {
            const groupedTimeSlots = GroupedAppointmentTimeSlot.fromAppointmentTimeSlots(timeSlots);

            this.noTimeSlots = timeSlots.length === 0;
            this.timeSlots = groupedTimeSlots;
        });
    }

    get timeSlotsAreSelected() {
        return this.selectedTimeSlots && this.selectedTimeSlots.filter(i => i).length >= 2;
    }

    async save() {
        this.saving = true;
        const workOrderTimeSlots = this.selectedTimeSlots.map(ts => {
            const aptTimeSlot = new WorkOrderPreferredTime();
            aptTimeSlot.id = UtilitiesService.newid();
            aptTimeSlot.workOrderId = this.data.workOrderSummary.id;
            aptTimeSlot.date = ts.date;
            aptTimeSlot.startTime = ts.startTime;
            aptTimeSlot.endTime = ts.endTime;

            return aptTimeSlot;
        });

        await this.serviceApi.newPreferredTimeSlots(this.data.workOrderSummary.id, workOrderTimeSlots);
        this.saving = false;
        this.missionService.showSuccessToast('New time slots have been requested');
        this.dialogRef.close(true);
    }
}
