<div *ngIf="showPrompt" id="show-paperless-prompt-container">
    <div aflex id="paperless-question-container" [class.hide]="paperlessConfirmed">
        <div nogrow>
            <i class="fa fa-tree"></i>
        </div>
        <div style="line-height: 13px; font-size: 13px;">
            Save time (and maybe some trees). Paperless means you get documents faster.<br>
            <button style="margin-top: 10px;" mat-button [disabled]="saving"  mat-raised-button (click)="goPaperless()">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Go Paperless</button>
            <button mat-button style="color: #fff;" (click)="notNow()">Not Now</button>
        </div>
    </div>

    <div id="paperless-confirm" [class.show]="paperlessConfirmed">
        <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>

    </div>


</div>
