<div aflex>
    <div><h1 *ngIf="planDetail && planDetail.coverage" class="sub-heading">{{planDetail.coverage.title}}</h1></div>
    <div nogrow>
        <button mat-button matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content>
  <div *ngIf="planDetail" style="max-width: 600px;">
    <div *ngIf="planDetail.coverage">
      

      <div class="data-label" topmargin20>Covered</div>
      <div [innerHTML]="planDetail.coverage.coveredLanguage"></div>

      <div class="data-label" topmargin20>Not Covered</div>
      <div [innerHTML]="planDetail.coverage.notCoveredLanguage"></div>

      <div class="data-label" topmargin20>Limits</div>
      <div [innerHTML]="planDetail.coverage.limitsLanguage"></div>
    </div>
  </div>

  <div *ngIf="loading">
    <mat-spinner thirty class="thirty"></mat-spinner> Loading coverage language...
  </div>
</mat-dialog-content>