import {Component, inject, model, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatFormField} from "@angular/material/form-field";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatButton} from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {CancelReason} from "@upkeeplabs/models/cogent";

@Component({
  selector: 'app-auto-renew-modal',
  standalone: true,
  imports: [
    MatDialogContent,
    MatSelect,
    MatOption,
    MatFormField,
    MatRadioButton,
    MatRadioGroup,
    MatButton,
    MatDialogClose,
    FormsModule
  ],
  templateUrl: './auto-renew-modal.component.html',
  styleUrl: './auto-renew-modal.component.scss'
})
export class AutoRenewModalComponent implements OnInit{

  dialogRef = inject(MatDialogRef)
  data = inject(MAT_DIALOG_DATA)
  autoRenewCancelReasons = model<CancelReason[]>(this.data.autoRenewCancelReasons)
  selectedAutoRenewCancelReason = model<CancelReason>(this.data.selectedAutoRenewCancelReason)

  constructor() {
  }

  ngOnInit() {
  }

  cancelClicked() {
    this.dialogRef.close()
  }

}
