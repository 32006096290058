import { Component, OnInit } from '@angular/core';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MissionService, ExecuteFunctionArgs } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { Entity } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-customer-test',
    templateUrl: './customer-test.component.html',
    styleUrls: ['./customer-test.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class CustomerTestComponent implements OnInit {

    entity: Entity;

    constructor(private missionService: MissionService,
        private entityApi: EntityApiService,
        private dialogService: DialogsService,
        private functionApi: CustomFunctionApiService) { }

    ngOnInit(): void {
        this.entityApi.getLoggedInUser(false, true).then(e => this.entity = e);
    }

    async run() {

        const func = await this.functionApi.getCustomFunctionById('98247e6d-c033-4685-b435-38dd6e6c79dc');

        const args = new ExecuteFunctionArgs();
        args.functionJson = func.functionJson;
        args.name = '';// addOn.name;
        args.objectInScope = {};
        args.fixedSize = true;
        if (UtilitiesService.isIOS) {
            args.height = 'calc(100vh - 211px)';
        } else {
            args.height = 'calc(100vh - 140px)';
        }
        this.missionService.raiseExecuteFunction(args);
    }

    async testIt() {
        const result = await this.dialogService.confirm('Confirm', 'Does this work???').toPromise();
        if (result) {
            this.dialogService.alert('Alert', 'this works!!!');
        }
    }
}
