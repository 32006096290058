<!-- <div id="confirmation-background" *ngIf="selectedIndex === 4">
    <div class="container">
        <div style="display: inline-block; text-align: right;margin-top:60px">
            <h1 class="fancy-title">Relax</h1><span class="fancy-title-2">We <span
                    class="bold fancy-title-2">still</span> have you Covered</span>
        </div>

    </div>
</div> -->
<div class="container mobile-padding">
    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs" (selectedIndexChange)="setHash($event)">
        <mat-tab label="Plan Info">
            <div *ngIf="loading">
                <div class="center">
                    <mat-spinner style="display: inline-block"></mat-spinner><br>
                    Retrieving your renewal settings...
                </div>
            </div>
            <auto-renew-component
                [policySummary]="policySummary"
                [policyRenewalSummary]="otherPolicy"
                (showOptions)="showPlanOptions()"
                *ngIf="!policyWasRenewed && otherPolicy && !loading">
            </auto-renew-component>
        </mat-tab>
        <mat-tab label="Plan Selection">
            <div *ngIf="loading">
                <div class="center">
                    <mat-spinner style="display: inline-block"></mat-spinner><br>
                    Getting your plan selection...
                </div>
            </div>
    
            <div *ngIf="policyWasRenewed">
                <h1>Sorry,</h1>
                <p>It looks like your subscription has already been renewed.</p>
            </div>
            <div *ngIf="doNotRenew">
                <h1>
                Renewal portal</h1>
                <mat-icon>warning</mat-icon> We are sorry but the renewal portal is unavailable at this time.
            </div>
            <div style="padding-bottom: 50px" *ngIf="plans">
                <app-plan-offerings *ngIf="selectedIndex === 1" titleBig="Renewal" titleSmall="Plans"
                    [(isMonthly)]="newPolicy.isMonthly" [plans]="plans" [(selectedPlan)]="selectedPlan"
                    (selectedPlanChange)="planSelected($event)"></app-plan-offerings>
            </div>

            <!-- <button mat-button (click)="selectedIndex = 3">End</button> -->
        </mat-tab>
        <mat-tab label="Options">
            <div aflex bottommargin20>
                <div>
                    <h1 class="page-title">
                        <span class="big">Your </span><br>
                        <span class="little">
                            Options</span>
                    </h1>
                </div>
                <div nogrow>
                    <button mat-button mat-raised-button class="small-primary" color="primary" *ngIf="selectedPlan"
                        (click)="selectedIndex = 3">Next <i class="material-icons">keyboard_arrow_right</i></button>
                </div>
            </div>

            <div *ngIf="selectedPlan">
                <div id="mobile-options">
                    <div class="mobile-option" aflex *ngFor="let option of newPolicy.optionalItems">

                        <div nogrow>
                            <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                            </mat-checkbox>
                        </div>
                        <div>
                            <h2 (click)="option.selected = !option.selected; optionSelected(option);">{{option.name}}
                            </h2>
                            <div>

                            </div>
                            <div>
                                <div topmargin30 *ngIf="option.selected && !option.allowQuantity"><span
                                        class="data-label">Quantity:
                                    </span> 1</div>
                                <div aflex *ngIf="option.selected && option.allowQuantity" topmargin10>
                                    <div nogrow class="data-label">Quantity: </div>
                                    <div>

                                        <mat-form-field appearance="outline" class="no-padding" style="width: 100px">
                                            <mat-select [(ngModel)]="option.quantity">
                                                <mat-option *ngFor="let number of numbers" [value]="number">
                                                    {{ number }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div nogrow>
                            <span *ngIf="!newPolicy.isMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                            <span *ngIf="newPolicy.isMonthly">{{option.priceRecurring|
                                currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="table" id="desktop-options">
                    <div class="table-header">
                        <div flex3>Item</div>
                        <div flex1>Price</div>
                        <div flex1>Selected</div>
                        <div flex1>Qty</div>
                    </div>
                    <div class="table-row" *ngFor="let option of newPolicy.optionalItems">
                        <div flex3>{{option.name}}</div>
                        <div flex1>
                            <span *ngIf="!newPolicy.isMonthly">{{option.price| currency:'USD':'symbol':'1.2-2'}}</span>
                            <span *ngIf="newPolicy.isMonthly">{{option.priceRecurring|
                                currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <div flex1>
                            <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                            </mat-checkbox>

                        </div>
                        <div flex1>
                            <span *ngIf="option.selected && !option.allowQuantity">
                                1</span>
                            <div style="margin-top: -5px" *ngIf="option.selected && option.allowQuantity">
                                <mat-form-field appearance="outline" class="no-padding" style="width: 100px">
                                    <mat-select [(ngModel)]="option.quantity">
                                        <mat-option *ngFor="let number of numbers" [value]="number">
                                            {{ number }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Payment Information">
            <div aflex bottommargin20>
                <div>
                    <h1 class="page-title">
                        <span class="big">Payment </span><br>
                        <span class="little">
                            Information</span>
                    </h1>
                </div>
                <div nogrow>
                    <!-- <button mat-button (click)="selectedIndex = 3">Pay By Check</button> -->
                    <button mat-button mat-raised-button class="small-primary" color="primary" *ngIf="selectedPlan"
                        [disabled]="savingPaymentMethod" (click)="paymentNext()">Next <mat-icon
                            *ngIf="!savingPaymentMethod">keyboard_arrow_right</mat-icon>
                        <mat-spinner *ngIf="savingPaymentMethod" class="thirty"></mat-spinner>
                    </button>
                </div>
            </div>
            <app-payment-method-entry #paymentMethodEntry [hideSaveCardToWalletButton]="true"
                *ngIf="policySummary && policySummary.holder" [hideManualCreditCardEntry]="true"
                [(savingPaymentMethod)]="savingPaymentMethod" [canDelete]="false" [(selectedCard)]="selectedStripeCard"
                (selectedCardChange)="selectedCardChange($event)" [customerId]="policySummary.holder.id">
            </app-payment-method-entry>
        </mat-tab>

        <mat-tab label="Review & Submit">

            <div flex bottommargin20>
                <div>
                    <h1 class="page-title">
                        <span class="big">Review </span><br>
                        <span class="little">
                            &amp; submit</span>
                    </h1>
                </div>
            </div>
            <div aflex style="max-width:550px;">
                <div>
                    <mat-form-field appearance="outline" style="margin-top: -12px">
                        <mat-label>Promotion Code</mat-label>
                        <input name="promotion-code-input" matInput [(ngModel)]="promotionCodeSearch">
                    </mat-form-field>
                </div>
                <div nogrow style="margin-top: -3px; margin-left: -81px">
                    <button mat-button mat-raised-button class="small-primary" [disabled]="!promotionCodeSearch"
                        (click)="findPromotionCode()" color="primary">Apply</button>
                </div>
            </div>
            <div flex>
                <div>
                    <div class="figures" *ngIf="selectedPlan" topmargin30>
                        <div aflex class="figure-row large">
                            <div flex1>{{selectedPlan.name}} </div>
                            <div nogrow class="dollar-figure">
                                {{planBasePrice | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                        <!-- <div *ngIf="adjustmentAmount" aflex class="figure-row indent">
                            <div flex1>
                                + Discount
                            </div>
                            <div nogrow class="dollar-figure">
                                {{adjustmentAmount |
                                currency:'USD':'symbol':'1.2-2' }}
                            </div>
                        </div> -->
                        <div *ngIf="promotionCode" aflex class="figure-row indent">
                            <div flex1>
                                <button style="margin-left: -59px; min-width: 56px;" mat-button
                                    (click)="promotionCode = null">
                                    <mat-icon>close</mat-icon>
                                </button>
                                {{promotionCode.code}} <span *ngIf="promotionCode.description">-
                                    {{promotionCode.description}}</span>
                            </div>
                            <div nogrow class="dollar-figure">
                                {{adjustmentAmount | currency}}
                            </div>
                        </div>
                        <div *ngFor="let option of selectedOptions" aflex class="figure-row indent">
                            <div flex1>
                                + {{option.name}}
                                <span *ngIf="option.quantity > 1"> (x{{option.quantity}})</span>
                            </div>
                            <div nogrow class="dollar-figure">
                                {{option.quantity * (newPolicy.isMonthly ? option.priceRecurring : option.price) |
                                currency:'USD':'symbol':'1.2-2' }}
                            </div>
                        </div>


                        <div aflex>
                            <div nogrow>
                                <app-display-total [total]="totalPlanPrice" label="Total Price:"
                                    suffix="{{newPolicy.isMonthly ? ' /mo' : ' /yr'}}">
                                </app-display-total>
                            </div>
                            <div></div>
                        </div>


                    </div>
                </div>
                <div nogrow>

                    <div class="center hide-xs" *ngIf="policySummary" topmargin30>
                        <img [src]="addressStreetView">
                        <address> {{policySummary.propertyAddress.address1}}<br>
                            {{policySummary.propertyAddress.city}}, {{policySummary.propertyAddress.state}}
                            {{policySummary.propertyAddress.postalCode}}
                        </address>
                    </div>

                    <a mat-button target="_blank" [href]="getSampleContractUrl(selectedPlan)" *ngIf="selectedPlan">
                        <mat-icon>description</mat-icon><br>
                        View Sample Contract
                    </a>
                </div>
            </div>

            <div aflex>
                <div nogrow class="review-icon">
                    <mat-icon>credit_card</mat-icon>
                </div>
                <div>
                    <h2 class="sub-heading" bottommargin30>Payment Information</h2>
                    <div *ngIf="selectedStripeCard">
                        <img *ngIf="selectedCardTypeUrl" class="review-image" [src]="selectedCardTypeUrl">
                        <div *ngIf="selectedStripeCard">
                            {{selectedStripeCard.brand}} {{selectedStripeCard.last4}}
                        </div>
                    </div>
                    <div *ngIf="!selectedStripeCard">
                        No Credit Card Payment Method Entered.
                    </div>
                </div>
            </div>
            <div flex bottommargin20>
                <div>

                </div>
                <div nogrow>
                    <button mat-button mobile-block mat-raised-button color="primary" large
                        [disabled]="renewing || !canSubmit" (click)="renewPolicy()">
                        <mat-spinner class="thirty" *ngIf="renewing"></mat-spinner> Submit
                    </button>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <div class="center">

                <div style="margin-top: 60px;"></div>
                <app-check-circle-animated></app-check-circle-animated>
                <h1>Congratulations!</h1>
                <p>We have received your renewal information. You've got the peace-of-mind that can only come with an
                    Elevate Homescription warranty.</p>

                <div class="center">
                    <a mat-button mat-raised-button color="primary" [routerLink]="newPolicyUrl" large>View My
                        Coverage</a>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<app-order-summary [selectedIndex]="selectedIndex" *ngIf="selectedIndex !== 4" [totalSteps]="3"
    [selectedPlan]="selectedPlan" [discounts]="adjustmentAmount" [(isMonthly)]="newPolicy.isMonthly"
    [basePrice]="planBasePrice" [optionsPrice]="optionsPrice"></app-order-summary>