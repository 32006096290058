<div id="plan-summary-container" [class.collapsed]="!selectedPlan" *ngIf="showIt" [style.background]="getBackground()">
    <mat-progress-bar mode="determinate" [value]="pctComplete"></mat-progress-bar>
    <div class="container">
        <!-- <div *ngIf="!selectedPlan" class="blank-order-summary">

            <mat-icon>assignment</mat-icon>
            Order Summary
        </div> -->
        <div *ngIf="selectedPlan" id="slider-container" [class.slide]="showCompletion">
            <div aflex style="height: 66px;margin-bottom: 1px;">
                <div nogrow>
                    <div class="data-label">Base Plan</div>
                    <div>{{selectedPlan.name}}</div>
                </div>

                <div nogrow>
                    <div class="data-label">Price</div>
                    {{basePrice | currency}} + {{optionsPrice | currency}} <span *ngIf="discounts">
                        {{discounts | currency}}</span> = <br>
                    <mat-slide-toggle style="margin-top: 0" *ngIf="!hideMonthly" [(ngModel)]="isMonthly"
                        (ngModelChange)="updateIsMonthly($event)">Pay By Month</mat-slide-toggle>
                </div>
                <div style="padding-top: 16px">
                    <span class="total">{{total | currency}}</span>
                </div>
            </div>
            <div style="" id="affirmation-container" aflex>
                <div nogrow>
                    <mat-icon>thumb_up</mat-icon>
                </div>
                <div>

                    Just one more click away and you'll have the peace of mind that comes with our service
                </div>
                <div nogrow style="margin-left: 0;" class="hide-gt-sm">
                    <button style="color: #fff;" mat-button (click)="scrollDocumentDown()">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>