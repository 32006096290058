<div class="contact-main">
    <div><img src="https://elevatehomescriptions.com/wp-content/uploads/2022/11/woman-wearing-pajamas-at-home-in-kitchen-making-fr-2022-02-08-22-39-27-utc-1-scaled.jpg" alt=""></div>
    <div>
        <h1>Contact Us</h1>
        <h2>We'd love to hear from you! If you need more information give us a call and we would love to help.</h2>
        <p><a style="color: inherit; text-decoration: none;" href="elevatehomescriptions.com" target="_blank">elevatehomescriptions.com</a></p>
        <p><a style="color: inherit; text-decoration: none;" href="tel:8018045132">801-804-5132</a></p>
        <p><a style="color: inherit; text-decoration: none;" href="tel:8553498824">855-349-8824</a></p>
        <p><a style="color: inherit; text-decoration: none;" href="mailto:CS@ElevateHomescriptions.com">CS&#64;ElevateHomescriptions.com</a></p>
    </div>
</div>
