import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { LoginApiService } from '@cogent/client/shared/services/api/login-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Address, CustomerRegistrationArgs, Entity, Login, PhoneNumber } from '@upkeeplabs/models/cogent';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { RouterModule } from '@angular/router';
import { EnterActionDirective } from '@cogent/client/shared/directives/enter-action.directive';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { SendTextMessageIconComponent } from '@cogent/client/shared/components/visuals/send-text-message-icon/send-text-message-icon.component';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from '@cogent/client/shared/services/api/entity-api.service';

@Component({
    selector: 'app-register',
    standalone: true,
    imports: [CommonModule,
        MaterialSharedModule,
        CheckCircleAnimatedComponent,
        RouterModule,
        EnterActionDirective,
        NgxMaskDirective,
        BasicEntityEntryModule,
        SendTextMessageIconComponent],
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    providers: [
        provideNgxMask(),
    ]
})
export class CustomerRegisterComponent {

    selectedIndex = 0;
    args: CustomerRegistrationArgs = new CustomerRegistrationArgs();
    goingNext = false;
    newid = UtilitiesService.newid();
    newCustomerValid = false;
    isLegacy = true;

    constructor(private loginApi: LoginApiService,
        private entityApi: EntityApiService,
        private dialogService: DialogsService) {

        this.args.newCustomer = new Entity();
        this.args.newCustomer.id = UtilitiesService.newid();
        this.args.newCustomer.type = 'Customer';
        this.args.newCustomer.homeNumber = new PhoneNumber();
        this.args.newCustomer.homeNumber.type = 'Home';
        this.args.newCustomer.homeNumber.entityId = this.args.newCustomer.id;

        this.args.newCustomer.address = new Address();
        this.args.newCustomer.address.id = UtilitiesService.newid();
        this.args.newCustomer.homeNumber.id = UtilitiesService.newid();

        this.args.newCustomer.addressId = this.args.newCustomer.address.id;

    }

    get legacyPortal() {
        return ApiService.isLegacy;
    }

    async next() {
        if (!this.canGoNext && this.hasNextButton) {
            return;
        }
        switch (this.selectedIndex) {
            case 0:
                await this.getEmailInfo();
                break;
            case 2:
                await this.verifyVerificationCode();
                break;
            case 3:
                await this.findByPhoneNumber();
                break;
            case 4:
                await this.verifyVerificationCode(true);
                break;
            case 5:
                await this.createCustomerAndLogin();
                break;
            case 6:
                await this.createLogin();
                break;
            case 7:
                await this.createLogin();
                break;
        }
    }

    get hasNextButton() {
        switch (this.selectedIndex) {
            case 1:
                return false;
            case 8:
                return false;
        }

        return true;
    }


    get canGoNext() {
        if (this.goingNext) {
            return false;
        }

        switch (this.selectedIndex) {
            case 0:
                return this.args.emailAddress && UtilitiesService.validateEmail(this.args.emailAddress);
            case 2:
                return this.args.registrationCode;
            case 3:
                return this.args.phoneNumber && UtilitiesService.validatePhoneNumber(this.args.phoneNumber);
            case 4:
                return this.args.registrationCode;
            case 5:
                return this.newCustomerValid && this.args.password && this.args.password === this.args.passwordConfirm;
            case 6:
                return this.args.password && this.args.password === this.args.passwordConfirm;
            case 7:
                return this.args.emailAddress && UtilitiesService.validateEmail(this.args.emailAddress)
                    && this.args.password && this.args.password === this.args.passwordConfirm;
        }

        return true;
    }


    async verifyVerificationCode(sms: boolean = false) {
        this.goingNext = true;
        this.args = await this.loginApi.verifyVerificationCode(this.args);
        this.args.newCustomer = UtilitiesService.copyObject(this.args.newCustomer, null, () => new Entity());

        if (!this.args.verificationCode) {
            this.dialogService.alert('Not Found', 'Sorry, we couldn\'t find that registration code');
        } else {
            this.selectedIndex = sms ? 7 : 6;
        }
        this.goingNext = false;
    }

    get passwordsDontMatch() {
        return this.args.password && this.args.passwordConfirm && this.args.password !== this.args.passwordConfirm;
    }

    async createCustomerAndLogin() {
        this.goingNext = true;
        const login = new Login();
        login.id = UtilitiesService.newid();
        login.entityType = 'Customer';
        login.userName = this.args.emailAddress;
        login.passwordHash = this.args.password;

        await this.loginApi.saveLogin(login);

        this.args.newCustomer.loginId = login.id;

        this.args.login = login;
        await this.loginApi.completeCustomerRegistrationWithCreate(this.args);
        this.goingNext = false;
        this.selectedIndex = 8;
    }

    async createLogin() {
        this.goingNext = true;
        const login = new Login();
        login.id = UtilitiesService.newid();
        login.entityType = 'Customer';
        login.userName = this.args.emailAddress;
        login.passwordHash = this.args.password;


        await this.loginApi.saveLogin(login);

        this.args.login = login;
        await this.loginApi.completeCustomerRegistration(this.args);
        this.goingNext = false;
        this.selectedIndex = 8;
    }

    async getEmailInfo() {
        this.goingNext = true;
        const args = await this.loginApi.getCustomerRegistrationArgs(this.args);
        this.args = args;
        this.args.newCustomer = UtilitiesService.copyObject(this.args.newCustomer, null, () => new Entity());
        this.goingNext = false;

        if (args.loginExists) {
            // TODO: show login exists
            this.selectedIndex = 1;
        } else if (args.customerIds?.length) {
            // TODO: show validate email
            this.newid = UtilitiesService.newid();
            this.selectedIndex = 2;
        } else {
            // TODO: show phone number entry
            this.selectedIndex = 3;
        }

    }

    async findByPhoneNumber() {
        this.goingNext = true;
        const args = await this.loginApi.getCustomerRegistrationArgs(this.args);
        this.args = args;
        this.args.newCustomer = UtilitiesService.copyObject(this.args.newCustomer, null, () => new Entity());
        if (args.customerIds?.length) {
            this.selectedIndex = 4;
        } else {
            this.selectedIndex = 5;
            this.args.newCustomer.email = this.args.emailAddress;
            this.args.newCustomer.homeNumber.number = this.args.phoneNumber;

        }
        this.goingNext = false;

    }
}
