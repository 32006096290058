import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, RouterModule } from "@angular/router";
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { MatDialog } from '@angular/material/dialog';
import { CancelWorkOrderComponent } from '@cogent/client/apps/homeowner/service/cancel-work-order/cancel-work-order.component';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { RescheduleAppointmentDialogArgs, RescheduleAppointmentComponent } from '../reschedule-appointment/reschedule-appointment.component';
import { ObjectCommand } from '@cogent/client/shared/models/object-command.model';
import { QueueCommand, QueueQuery } from '@cogent/client/shared/models/object-queue.model';
import { QuestionWizardComponentV2 } from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';
import { CommandRunnerService } from '@cogent/client/shared/services/command-runner.service';
import { MissionService, ExecuteFunctionArgs } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';

import { ApiService } from '@cogent/client/api';
import { CustomFunction, InvoiceSummary, PurchaseOrderItemSummary, Tag, WorkOrderPreferredTime, WorkOrderStatusChange } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CalendarDayViewerModule } from '@cogent/client/shared/components/data-visualization/calendar-day-view/calendar-day-viewer.module';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';


export class StatusItem {
    constructor(public description: string, public iconClass: string, public complete: boolean = false, public subStatus: string = ' ') {

    }
}

class GroupedWorkOrderStatusChange {
    constructor(
        public date: Date,
        public items: WorkOrderStatusChange[]
    ) { }
}

class GroupedCommands {
    group: string;
    commands: QueueCommand[];
}

class GroupedPurchaseOrderItems {
    purchaseOrderId: string;
    trackingNumber: string;
    shipperName: string;
    partNumbers: string;
    estimatedDeliveryDate: Date;
    deliveryDate: Date;
    shipperId: string;
    currentStatus: string;
}

@Component({
    selector: 'app-homeowner-work-order-detail-two',
    templateUrl: './homeowner-work-order-detail-two.component.html',
    styleUrls: ['./homeowner-work-order-detail-two.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, CalendarDayViewerModule, RouterModule, UpkeepPipesModule]
})
export class HomeownerWorkOrderDetailTwoComponent implements OnInit {

    workOrderId: string;
    selectedIndex = 0;
    apptDate: Date = new Date();
    startWindow = '8 AM';
    endWindow = '1 PM';
    authoStarted = false;
    authoComplete = false;
    statusHistory: WorkOrderStatusChange[];
    @ViewChild('questionWizard') questionWizard: QuestionWizardComponentV2;
    helpMessage: string;
    serviceFeeInvoices: InvoiceSummary[];
    stepNumber: number;
    sending: boolean;
    preferredTimes: WorkOrderPreferredTime[];
    tags: Tag[];
    objectCommands: ObjectCommand[];
    extraCommands: QueueCommand[];
    showWorkingMessage = false;
    workingMessage: string;
    groupedPocketCommands: GroupedCommands[];
    invokeDefaultCommandId: string;
    // purchaseOrders: PurchaseOrderSummary[];
    purchaseOrderItems: PurchaseOrderItemSummary[];

    currentCommand: QueueCommand;
    noWorkOrder = false;
    isLegacy = true;


    workOrderSummary: WorkOrderSummaryClient;
    policySummary: PolicySummary;
    customFunctions: CustomFunction[];
    groupedParts: GroupedPurchaseOrderItems[] = [];


    steps: StatusItem[] = [
        new StatusItem('Transmitted', 'send'),
        new StatusItem('Scheduled', 'today'),
        new StatusItem('Dispatched', 'location_on'),
        new StatusItem('Authorized', 'gavel'),
        new StatusItem('Completed', 'check'),
    ];

    constructor(
        private serviceRepository: ServiceRepositoryService,
        private route: ActivatedRoute,
        private matDialog: MatDialog,
        private policyApi: PolicyApiService,
        private serviceApi: ServiceApiService,
        private entityApi: EntityApiService,
        private dialog: MatDialog,
        private api: ApiService,
        private settingsApi: SettingsApiService,
        private commandRunner: CommandRunnerService,
        private missionService: MissionService,
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.workOrderId = params.id;
            this.refreshScreen();
            this.setupCommands();
            this.refreshTags();

        });

        this.route.queryParams
            .subscribe(params => {
                if (params.commandId) {
                    this.invokeDefaultCommandId = params.commandId;
                    this.invokeDefaultCommand();
                }
            });
        this.settingsApi.getObjectCommandsForCustomerPortal('Service').then(commands => {
            this.objectCommands = commands;
            this.setupCommands();
        });

        this.settingsApi.getCustomFunctionsForCustomerPortalJob().then(functions => {
            this.customFunctions = functions;
        });
    }

    runCustomFunction(func: CustomFunction) {
        const args = new ExecuteFunctionArgs();
        args.functionJson = func.functionJson;
        args.iconClass = func.iconClass;
        args.objectInScope = this.workOrderSummary;
        args.wizardStyle = true;
        args.changeWorkingMessage = (workingMessage) => {
            this.workingMessage = workingMessage;
        }
        this.missionService.raiseExecuteFunction(args);

    }

    reloadPage() {
        window.location.reload();
    }

    async refreshTags() {
        this.tags = await this.serviceApi.getWorkOrderTags(this.workOrderId);
        this.setupCommands();
    }

    get canReschedule() {
        return this.workOrderSummary
            && this.workOrderSummary.scheduledDate
            && UtilitiesService.dayBegin(this.workOrderSummary.scheduledDate) > UtilitiesService.dayBegin(new Date());
    }

    getThumbnailMargin(line, workOrder: WorkOrderSummaryClient) {
        return (workOrder.lines.indexOf(line) * 25) + 'px';
    }
    getThumbnailUrl(workOrderLine: any) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${workOrderLine.itemId}/photo`;
    }

    private invokeDefaultCommand() {
        if (!this.invokeDefaultCommandId || !this.objectCommands) {
            return;
        }
        const command = this.extraCommands.find(i => i.id === this.invokeDefaultCommandId);
        if (command) {
            this.executeCommand(command);
            this.invokeDefaultCommandId = null;
        }

    }

    executeCommand(command: QueueCommand) {
        this.currentCommand = command;
        let queueQuery: QueueQuery;
        for (const objectCommand of this.objectCommands) {
            if (objectCommand.queueQuery.commands.indexOf(command) > -1) {
                queueQuery = objectCommand.queueQuery;
            }
        }
        this.commandRunner.startDoCommand(command.actions, null, this.workOrderSummary, this.workOrderSummary, queueQuery, () => this.refreshTagsAndScreen(), () => this.refreshTagsAndScreen(), (show, message) => {
            this.showWorkingMessage = show;
            this.workingMessage = message;
        }, null, null, null, null, () => { }, null, null);
    }

    private refreshTagsAndScreen() {
        this.refreshScreen();
        this.refreshTags();
    }

    get isScheduled() {
        return this.workOrderSummary.scheduledDate || this.workOrderSummary.dateCompleted || this.workOrderSummary.dateClosed;
    }

    get isComplete() {
        return this.workOrderSummary.dateCompleted || this.workOrderSummary.dateClosed;
    }

    get isDiagnosed() {
        if (!this.workOrderSummary || !this.workOrderSummary.scheduledDate) {
            return false;
        }
        return UtilitiesService.dayEnd(this.workOrderSummary.scheduledDate) < new Date() || this.workOrderSummary.dateCompleted || this.workOrderSummary.dateClosed || this.hasPurchaseOrder;
    }

    private _partsShipped?: boolean;

    get partsShipped() {
        if (!this.purchaseOrderItems) {
            return false;
        }
        if (!this._partsShipped) {
            this._partsShipped = this.purchaseOrderItems.filter(i => i.trackingNumber).length === this.purchaseOrderItems.length;
        }

        return this._partsShipped;
    }

    private async setupCommands() {
        if (!this.objectCommands || !this.workOrderSummary || !this.tags) {
            return;
        }


        this.extraCommands = [];

        outerLoop:
        for (const objectCommand of this.objectCommands) {
            const queueQuery = objectCommand.queueQuery;

            if (queueQuery.selectedCallTypes && queueQuery.selectedCallTypes.length > 0 && queueQuery.selectedCallTypes.indexOf(this.workOrderSummary.type) === -1) {
                continue;
            }
            if (queueQuery.selectedCallTypesExcluded && queueQuery.selectedCallTypesExcluded.length > 0 && queueQuery.selectedCallTypesExcluded.indexOf(this.workOrderSummary.type) > -1) {
                continue;
            }
            if (queueQuery.selectedContractors && queueQuery.selectedContractors.length > 0 && queueQuery.selectedContractors.filter(i => i.id === this.workOrderSummary.contractorId).length === 0) {
                continue;
            }
            if (queueQuery.selectedContractorsExcluded && queueQuery.selectedContractorsExcluded.length > 0 && queueQuery.selectedContractorsExcluded.filter(i => i.id === this.workOrderSummary.contractorId).length > 0) {
                continue;
            }
            if (queueQuery.selectedEmployees && queueQuery.selectedEmployees.length > 0 && queueQuery.selectedEmployees.filter(i => i.id === this.workOrderSummary.createdById).length === 0) {
                continue;
            }
            if (queueQuery.selectedEmployeesExcluded && queueQuery.selectedEmployeesExcluded.length > 0 && queueQuery.selectedEmployeesExcluded.filter(i => i.id === this.workOrderSummary.createdById).length > 0) {
                continue;
            }
            if (queueQuery.selectedItems && queueQuery.selectedItems.length > 0 && queueQuery.selectedItems.filter(i => i.id === this.workOrderSummary.itemId).length === 0) {
                continue;
            }
            if (queueQuery.selectedItemsExcluded && queueQuery.selectedItemsExcluded.length > 0 && queueQuery.selectedItemsExcluded.filter(i => i.id === this.workOrderSummary.itemId).length > 0) {
                continue;
            }
            if (queueQuery.selectedRegions && queueQuery.selectedRegions.length > 0 && queueQuery.selectedRegions.filter(i => i.id === this.workOrderSummary.regionId).length === 0) {
                continue;
            }
            if (queueQuery.selectedRegionsExcluded && queueQuery.selectedRegionsExcluded.length > 0 && queueQuery.selectedRegionsExcluded.filter(i => i.id === this.workOrderSummary.regionId).length > 0) {
                continue;
            }
            if (queueQuery.selectedSLAStatuses && queueQuery.selectedSLAStatuses.length > 0 && queueQuery.selectedSLAStatuses.indexOf(this.workOrderSummary.sLAStatus) === -1) {
                continue;
            }
            if (queueQuery.selectedSLAStatusesExcluded && queueQuery.selectedSLAStatusesExcluded.length > 0 && queueQuery.selectedSLAStatusesExcluded.indexOf(this.workOrderSummary.sLAStatus) > -1) {
                continue;
            }
            if (queueQuery.selectedTags && queueQuery.selectedTags.length > 0) {
                for (const tag of queueQuery.selectedTags) {
                    if (this.tags.filter(i => i.id === tag.id).length === 0) {
                        continue outerLoop;
                    }
                }
            }

            if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
                for (const tag of queueQuery.selectedTagsExcluded) {
                    if (this.tags.filter(i => i.id === tag.id).length > 0) {

                        continue outerLoop;
                    }
                }
            }
            if (queueQuery.selectedTrades && queueQuery.selectedTrades.length > 0 && queueQuery.selectedTrades.filter(i => i.id === this.workOrderSummary.tradeId).length === 0) {
                continue;
            }
            if (queueQuery.selectedTradesExcluded && queueQuery.selectedTradesExcluded.length > 0 && queueQuery.selectedTradesExcluded.filter(i => i.id === this.workOrderSummary.tradeId).length > 0) {
                continue;
            }

            if (queueQuery.selectedWorkOrderStatuses && queueQuery.selectedWorkOrderStatuses.length > 0 && queueQuery.selectedWorkOrderStatuses.filter(i => i.id === this.workOrderSummary.workOrderStatusId).length === 0) {
                continue;
            }
            if (queueQuery.selectedWorkOrderStatusesExcluded && queueQuery.selectedWorkOrderStatusesExcluded.length > 0 && queueQuery.selectedWorkOrderStatusesExcluded.filter(i => i.id === this.workOrderSummary.workOrderStatusId).length > 0) {
                continue;
            }
            this.extraCommands = this.extraCommands.concat(objectCommand.queueQuery.commands.filter(i => !i.hideFromUI));
        }
        this.invokeDefaultCommand();
    }

    startChat() {
        this.missionService.publish({ type: 'START-WEB-CHAT', messageBody: this.workOrderSummary });
    }


    getStreetViewUrl300x300(policyId: string) {
        return this.policyApi.getStreetViewUrl300x300(policyId);
    }

    getItemThumbnailUrl(itemId: string) {
        return this.serviceApi.getItemThumbnailUrl(itemId);
    }

    getEntityThumbnailUrl(entityId: string) {
        return this.entityApi.getThumbnailUri(entityId);
    }

    updateHashLocation(index) {
        window.location.hash = index;
    }

    newTimeSlots() {
        const args = new RescheduleAppointmentDialogArgs();
        args.workOrderSummary = this.workOrderSummary;
        const config: any = { data: args };

        if (window.innerWidth < 700) {
            config.maxWidth = '100vw';
            config.maxHeight = '100vh';
            config.height = '100%';
            config.width = '100%';
            // config.panelClass = ['full-screen-modal'];
            config.panelClass = 'full-screen-modal';
        }
        const dialogAction = this.dialog.open(RescheduleAppointmentComponent, config);

        dialogAction.afterClosed().subscribe(results => {
            if (results) {
                this.refreshScreen();
            }
        });
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash(event) {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }


        let hashNumber = parseInt(hash, 10);

        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }

        if (!isNaN(hashNumber)) {
            this.selectedIndex = hashNumber;
        }

    }


    showContactUs() {

        // this.missionService.subscription.subscribe(message => {
        //     if (message.type === 'START-WEB-CHAT') {
        //         this.startChat(message.messageBody.id);
        //     }
        // });
        this.missionService.publish({
            type: 'START-WEB-CHAT',
            messageBody: '',
        });
    }

    back() { this.selectedIndex = 0; }

    startCancellation() {
        const ref = this.matDialog.open(CancelWorkOrderComponent, { data: this.workOrderSummary });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.refreshScreen();
            }
        });
    }

    get hideContractorInformation() {
        if (!this.workOrderSummary) {
            return true;
        }

        return this.workOrderSummary.status === 'Awaiting 1st Service Pro Acceptance'
            || this.workOrderSummary.status === 'Awaiting Service Pro Acceptance'
            || this.workOrderSummary.status === 'Awaiting 2nd Service Pro Acceptance'
            || this.workOrderSummary.status === 'Awaiting Jump Ball Acceptance'
    }

    getShipperImage(group: GroupedPurchaseOrderItems) {
        return this.entityApi.getThumbnailUri(group.shipperId);
    }

    private trackPurchaseOrder(group: GroupedPurchaseOrderItems) {
        this.serviceApi.getPurchaseOrderTracking(group.purchaseOrderId).then(tracking => {
            if (tracking && tracking.currentStatus) {
                group.currentStatus = tracking.currentStatus;
                if (tracking.deliveryDate) {
                    group.deliveryDate = tracking.deliveryDate;
                }
                if (tracking.estimatedDeliveryDate) {
                    group.estimatedDeliveryDate = tracking.estimatedDeliveryDate;
                }
            }
        });
    }

    private async refreshScreen() {
        this.serviceRepository.getWorkOrderSummary(this.workOrderId)
            .then(workOrderSummary => {
                this.workOrderSummary = workOrderSummary;

                if (!workOrderSummary) {
                    this.noWorkOrder = true;
                    return;
                }
                workOrderSummary.lines = workOrderSummary.lines.filter(i => !i.cancelledDate);
                this.policyApi.getPolicySummary(this.workOrderSummary.policyId).then(ps => this.policySummary = ps);

                this.serviceApi.getPurchaseOrderItemsForWorkOrder(this.workOrderSummary.id).then(purchaseOrderItems => {
                    this.purchaseOrderItems = purchaseOrderItems;
                    for (const item of purchaseOrderItems) {
                        let groupedItem = this.groupedParts.find(i => i.purchaseOrderId === item.purchaseOrderId);
                        if (!groupedItem) {
                            groupedItem = new GroupedPurchaseOrderItems();
                            groupedItem.purchaseOrderId = item.purchaseOrderId;
                            groupedItem.shipperName = item.shipperName;
                            groupedItem.trackingNumber = item.trackingNumber;
                            groupedItem.deliveryDate = item.deliveryDate;
                            groupedItem.estimatedDeliveryDate = item.estimatedDeliveryDate;
                            groupedItem.partNumbers = '';
                            groupedItem.shipperId = item.shipperId;

                            this.groupedParts.push(groupedItem);
                        }
                        if (groupedItem.partNumbers) {
                            groupedItem.partNumbers += ', ';
                        }
                        groupedItem.partNumbers += item.partNumber;
                    }
                    for (const group of this.groupedParts) {
                        this.trackPurchaseOrder(group);
                    }
                });
                this.setupCommands();
                let stepNumber = -1;
                if (this.workOrderSummary.status === 'Sent') {
                    stepNumber = 0;
                } else if (this.workOrderSummary.status === 'Scheduled') {
                    stepNumber = 1;
                } else if (this.workOrderSummary.status === 'Dispatched') {
                    stepNumber = 2;
                } else if (this.workOrderSummary.status === 'Authorized' ||
                    this.workOrderSummary.status === 'Authorization Requested') {
                    stepNumber = 3;
                } else if (this.workOrderSummary.status === 'Complete') {
                    stepNumber = 4;
                } else if (this.workOrderSummary.status === 'Invoiced') {
                    stepNumber = 5;
                } else if (this.workOrderSummary.status === 'Parts Delay') {
                    this.steps.splice(4, 0, new StatusItem('Parts Delay', 'timelapse'));
                    stepNumber = 4;
                }

                this.serviceRepository.getServiceFeeInvoice(this.workOrderSummary.claimId).then(invoices => {
                    this.serviceFeeInvoices = invoices;
                });

                this.stepNumber = stepNumber;
                for (let i = 0; i <= stepNumber; i++) {
                    setTimeout((args) => { this.steps[args].complete = true; },
                        1000 + (500 * i), i);
                }

                if (this.workOrderSummary.sentDate) {
                    this.steps[0].subStatus = this.workOrderSummary.sentDescription;
                }
                if (this.workOrderSummary.scheduledDate) {
                    this.steps[1].subStatus =
                        this.workOrderSummary.appointmentDescription;
                }
                if (this.workOrderSummary.dateCompleted) {
                    this.steps[4].subStatus =
                        this.workOrderSummary.completedDescription;
                }
                if (this.workOrderSummary.dispatchedDate) {
                    this.steps[2].subStatus = this.workOrderSummary.dispatchDescription;
                }
            });
        this.serviceRepository.getWorkOrderHistory(this.workOrderId)
            .then(status => { this.statusHistory = status; });

        this.preferredTimes = await this.serviceRepository.getWorkOrderPreferredTime(this.workOrderId);
    }

    get totalServiceFeesCharged(): number {
        if (!this.serviceFeeInvoices || this.serviceFeeInvoices.length === 0) {
            return 0;
        }

        return this.serviceFeeInvoices.map(i => i.amount).reduce((a, b) => a + b);
    }

    get hasPurchaseOrder() {
        return this.purchaseOrderItems?.length;
    }

    get totalServiceFeesPaid(): number {
        if (!this.serviceFeeInvoices || this.serviceFeeInvoices.length === 0) {
            return 0;
        }

        return this.serviceFeeInvoices.map(i => i.amount - i.amountDue).reduce((a, b) => a + b);
    }

    get totalServiceFeesDue(): number {
        if (!this.serviceFeeInvoices || this.serviceFeeInvoices.length === 0) {
            return 0;
        }

        return this.serviceFeeInvoices.map(i => i.amountDue).reduce((a, b) => a + b);
    }

    get groupedActivity(): GroupedWorkOrderStatusChange[] {
        if (!this.statusHistory) { return null; }
        const result = [];

        this.statusHistory.forEach(item => {
            let group = result.filter(
                (groupedItem: GroupedWorkOrderStatusChange) =>
                    groupedItem.date.getFullYear() ===
                    item.createdDate.getFullYear() &&
                    groupedItem.date.getMonth() === item.createdDate.getMonth() &&
                    groupedItem.date.getDate() === item.createdDate.getDate())[0];

            if (group) {
                group.items.push(item);
            } else {
                group = new GroupedWorkOrderStatusChange(item.createdDate, [item]);
                result.push(group);
            }
        });

        return result;
    }

    get canSend() { return this.helpMessage; }

    sendRequest() {
        this.sending = true;

        this.serviceRepository.createWorkOrderHelpTask(
            this.workOrderSummary.id,
            this.workOrderSummary.policyId, this.helpMessage)
            .then(() => {
                this.sending = false;
                this.helpMessage = '';
                this.selectedIndex = 0;
            });
    }


}
