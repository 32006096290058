import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { CashOutOfferSummary, TermsAndLimitationItem } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { CashOutApiService } from '@cogent/client/shared/services/api/cash-out-api.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';

@Component({
    selector: 'app-approve-cash-out',
    templateUrl: './approve-cash-out.component.html',
    styleUrls: ['./approve-cash-out.component.css'],
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, MatTabsModule, MatButtonModule, MatIconModule, CheckCircleAnimatedComponent]
})
export class ApproveCashOutComponent implements OnInit {

    cashOutOfferId: string;
    //cashOut: CashOutSummary;

    cashOutOffer: CashOutOfferSummary;
    lines: WorkOrderLineSummaryClient[];
    selectedIndex = 0;
    selectedCashOutType: string;
    approving = false;
    termsAndLimitationItem: TermsAndLimitationItem;
    tAndCVisible = false;
    isLegacy = true;

    constructor(private serviceApi: ServiceApiService,
        private dialog: DialogsService,
        private cashOutApi: CashOutApiService,
        private missionService: MissionService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.cashOutOfferId = params.id;

            // this.serviceApi.getCashOutSummary(this.cashOutId).then(cashOut => {
            //     this.cashOut = cashOut;
            // });

            this.cashOutApi.getCashOutOfferSummary(this.cashOutOfferId).then(offer=> {
                this.cashOutOffer = offer;
                if(!offer) {
                    this.dialog.alert('Not Found', 'Sorry, we couldn\'t find that offer.');
                    return;
                }

                this.cashOutApi.getReplacementAndCashInLieuLanguage(this.cashOutOffer.workOrderId).then(language=> {
                    if(language) {
                        this.termsAndLimitationItem = language;
                    }
                });
            });



            // this.serviceApi.getCashOutAppliedLines(this.cashOutId).then(lines => {
            //     this.lines = lines;
            // });
        });
    }

    next() {
        this.selectedIndex++;
    }

    back() {
        this.selectedIndex--;
    }

    getThumbnailUrl() {
        if(!this.cashOutOffer) {
            return null;
        }
        return `${ApiService.endPointDotNet}WorkOrderItem/${this.cashOutOffer.itemId}/photo`;
    }

    get isCashInLieu() {
        return this.cashOutOffer?.type === 'CASH_IN_LIEU';
    }

    badAddress() {
        this.dialog.confirm('Contact Us', 'One of our agents can help you. <br><br>You can call us at 801-804-5132. Or you can chat online.<br> Would you like to chat now?').subscribe(async results => {
            if (results) {

                const workOrderSummary = await this.serviceApi.getWorkOrderSummaryNoAuth(this.cashOutOffer.workOrderId, this.cashOutOffer.contractorId);
                this.missionService.publish({ type: 'START-WEB-CHAT', messageBody: workOrderSummary });

            }
        });
    }

    get today() {
        return new Date();
    }

    get numberWords() {
        if (!this.cashOutOffer) {
            return '';
        }

        return UtilitiesService.numberToWords(this.cashOutOffer.amount);
    }

    async approve() {
        this.approving = true;

        // if (this.selectedCashOutType === 'Mail') {
        //     await this.serviceApi.approveCashOutMail(this.cashOutOffer.id);
        // } else if (this.selectedCashOutType === 'PayPal') {
        //     await this.serviceApi.approveCashOutPayPal(this.cashOutOffer.id);
        // }

        await this.cashOutApi.acceptCashOutOffer(this.cashOutOffer.id, this.selectedCashOutType);

        this.approving = false;
        this.selectedIndex++;
    }

}
