import { Component, Input, SimpleChanges, Output, EventEmitter, ElementRef, OnChanges } from '@angular/core';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntitySummary } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective } from 'ngx-mask';




@Component({
    templateUrl: './contact-selection.html',
    styleUrls: ['./contact-selection.css'],
    selector: 'my-contact-selection',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        NgxMaskDirective
    ]
})
export class ContactSelectionComponent implements OnChanges {
    @Input() customerId: string;
    @Input() contact: EntitySummary = new EntitySummary();
    @Output() contactChanged: EventEmitter<EntitySummary> = new EventEmitter<EntitySummary>();
    @Input() contactValid: boolean;
    @Output() contactValidChange: EventEmitter<boolean> = new EventEmitter();

    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    contactList: EntitySummary[] = [];

    selectedContact: EntitySummary;
    otherContactsVisible = false;

    constructor(private serviceRepository: ServiceRepositoryService) {

    }

    selectContact(contact: EntitySummary) {
        this.selectedContact = contact;
        this.contact.name = contact.name;
        this.contact.homeNumber = contact.homeNumber;
        this.contact.mobileNumber = contact.mobileNumber;
        if (this.contact.homeNumber && this.contact.homeNumber.indexOf(',') > -1) {
            this.contact.homeNumber = this.contact.homeNumber.split(',')[0];
        }
        this.contact.email = contact.email;
        this.setIsValid();
        setTimeout(() => this.setIsValid(), 500);
    }

    get isValid() {
        if (!this.contact) {
            return false;
        }

        return this.contact.name && this.contact.email && UtilitiesService.validateEmailList(this.contact.email)
            && ((this.contact.homeNumber && UtilitiesService.validatePhoneNumber(this.contact.homeNumber))
                || (this.contact.mobileNumber && UtilitiesService.validatePhoneNumber(this.contact.mobileNumber)));

    }

    setIsValid() {
        this.contactValidChange.emit(this.isValid);
    }



    isContactSelected(contact: EntitySummary) {
        return this.contact.name === contact.name
            && this.contact.homeNumber === contact.homeNumber
            && this.contact.mobileNumber === contact.mobileNumber
            && this.contact.email === contact.email;
    }

    ngOnChanges(changes: SimpleChanges) {
        const customerId = changes.customerId ? changes.customerId.currentValue : null;
        if (customerId) {

            this.serviceRepository.getCustomerSummary(customerId).then(customer => {
                this.contactList.push(customer);
                this.selectContact(customer);
                this.serviceRepository.getTenants(customerId).then(tenants => {
                    tenants.forEach(t => this.contactList.push(t));
                });
            });
        }
    }


    showOtherContacts(panel) {

        const rect = panel.getBoundingClientRect();
        panel.style.height = (rect.bottom - rect.top) + 'px';
        setTimeout(() => { this.otherContactsVisible = true; });

    }

    hideOtherContacts(panel) {

        panel.style.height = '';

        setTimeout(() => this.otherContactsVisible = false);
    }
}