<div style="min-height: 350px;">
    <div aflex>
        <div>
            <h2 class="sub-heading">Get Inspection Protection</h2>
        </div>
        <div nogrow>
            <button mat-button [mat-dialog-close]>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <app-document-upload *ngIf="!inspectionUploaded" [policySummary]="data" [(selectedIndex)]="pageIndex"
        [(hasSellersDisclosures)]="hasSellersDisclosures" (inspectionUploaded)="hasHomeInspectionChange($event)"
        [(hasHomeInspection)]="hasHomeInspection"></app-document-upload>

    <p *ngIf="!inspectionUploaded && pageIndex === 0">
        <span class="italic">Don't have your inspection handy?</span> No
        problem, you can provide it later.
    </p>
    <p *ngIf="inspectionUploaded" class="center" style="max-width: 490px;">
        <app-check-circle-animated height="150px"></app-check-circle-animated><br>
        Thanks for uploading. We'll work to get you the maximum possible protection.
    </p>

    <div aflex *ngIf="pageIndex === 0">
        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button [mat-dialog-close]
                *ngIf="!hasSellersDisclosures && !hasHomeInspection">I'll Do This Later</button>

            <button mat-button mat-raised-button color="primary" [mat-dialog-close]
                *ngIf="hasSellersDisclosures || hasHomeInspection">Continue</button>
        </div>

    </div>
    <div>
        <button mat-button block (click)="hasHomeInspection = false;inspectionUploaded=false" style="margin-top: 25px;"
        *ngIf="hasSellersDisclosures || hasHomeInspection">Upload Another Document</button>
    </div>
</div>