import {Component, EventEmitter, Input, OnInit, Output, signal} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import {CancelReason, PolicyRenewalSummary, StripeCard} from '@upkeeplabs/models/cogent';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import {MatDialog} from "@angular/material/dialog";
import {
  AutoRenewModalComponent
} from "@cogent/client/apps/homeowner/sales/auto-renew/auto-renew-modal/auto-renew-modal.component";

@Component({
    standalone: true,
    templateUrl: 'auto-renew.component.html',
    styleUrls: ['auto-renew.component.css'],
    selector: 'auto-renew-component',
    imports: [MatCardModule, MatSlideToggleModule, FormsModule, PaymentMethodEntryModule, PaymentMethodEntryComponent,CommonModule, MatButtonModule, MatProgressSpinnerModule, MatExpansionModule]
})
export class AutoRenewComponent implements OnInit {
    @Input() policySummary: PolicySummary
    @Input() policyRenewalSummary: PolicyRenewalSummary
    @Output() showOptions: EventEmitter<boolean> = new EventEmitter();

    selectedStripeCard: StripeCard
    savingPaymentMethod: boolean;
    saving = false
    panelOpenState = false;
    loadingAutoRenewCancelReasons = true

    autoRenewCancelReasons = signal([]);
    selectedAutoRenewCancellation = signal<CancelReason>(null)

    constructor(
      private policyApi: PolicyApiService,
      private dialog: MatDialog,
    ) { }

    async ngOnInit() {
      await this.initialize()
    }

    async showPlanOptions() {
        this.showOptions.emit(true);
    }

    async selectedCardChange(card: StripeCard) {
        this.selectedStripeCard = card;
        if (card.id != this.policyRenewalSummary.stripeCardId) {
            this.policyRenewalSummary.stripeCardId = card.id;
            this.save();
        }
    }

    async initialize() {
        this.policyRenewalSummary = await this.policyApi.getRenewalPolicySummary2(this.policySummary.id);
        this.autoRenewCancelReasons.set(await this.policyApi.getAutoRenewCancelReasons())
        this.loadingAutoRenewCancelReasons = false
    }

    changeAutoRenew() {
      if (!this.policySummary.autoRenew) {
        return this.save()
      }
      const dialogRef = this.dialog.open(AutoRenewModalComponent, {
        data: {
          autoRenewCancelReasons: this.autoRenewCancelReasons(),
          selectedAutoRenewCancelReason: this.selectedAutoRenewCancellation()
        }
      })

      dialogRef.afterClosed().subscribe(async r => {
        if (r.id) {
          this.selectedAutoRenewCancellation.set(r)
          this.policyRenewalSummary.autoRenew = false
          await this.save()
        }
      })
    }

    async save() {
        this.saving = true;

        if (this.policyRenewalSummary.autoRenew) {
          await this.policyApi.updateAutoRenewStatus(this.policySummary.id, true, this.selectedStripeCard);
        } else {
          await this.policyApi.updateAutoRenewStatus(this.policySummary.id, false, null, this.selectedAutoRenewCancellation()?.id)
        }

        this.saving = false;
    }

}
