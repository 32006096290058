<div id="cart-backdrop" *ngIf="cartOpen" (click)="cartOpen = false"></div>
<div class="center" topmargin40 *ngIf="!policySummary && !notFound">

    <mat-spinner style="display: inline-block;"></mat-spinner><br>
    We're looking up your information
</div>

<div class="center" *ngIf="notFound">
    <lottie-player style="height: 400px;margin-top: 40px"
    src="https://lottie.host/19adc2e6-0148-4ce3-84cf-afb96491e9e0/QkKceKMTFa.json" intermission="4000" background="transparent" speed="1" loop
    autoplay></lottie-player>
    <h1>Sorry</h1>
    <p class="info">It looks like we couldn't find your subscription</p>
</div>
<div id="welcome-screen" [class.fade-out]="starting" *ngIf="showWelcomeScreen && policySummary">
    <div class="narrow-container" style="max-width: 800px;">

        <div aflex>
            <div nogrow>
                <img src="../../../assets/images/elevate-a-icon-white.svg">
            </div>
            <div>
                <h1>Elevate<br> Your<br> Experience</h1>
            </div>
        </div>


        <p>Congratulations on the purchase of your new home! Give us a few minutes we'll tell you a little about
            how the home subscription works and then we'll tailor your home
            subscription
            to perfectly fit your home and needs.
        </p>
        <button mat-button (click)="tutorialOpen = true;tutorialIndex=0;" large>Activate</button><br>
    </div>
</div>
<div class="parent" *ngIf="policySummary && !showCompletion" [class.fade-in]="starting">
    <div class="accent fade-in-up" *ngIf="orangeUpgradeOption && selectedIndex === 3"></div>
    <div class="accent fade-in-up" *ngIf="brandForBrandOption && selectedIndex === 2"></div>
    <div class="header">
        <div class="container">

            <div aflex>
                <div nogrow>
                    <div id="header-progress">
                        <app-circle-widget-small [height]="40" [percent]="pct"></app-circle-widget-small>
                    </div>
                    <div id="steps-label">
                        {{selectedIndex + 1}} of {{totalSteps}}
                    </div>
                </div>
                <div>

                    <div *ngIf="currentStepDesc" class="slide-in-up">
                        <h2>{{currentStepDesc}}</h2>
                        <div class="next" *ngIf="(selectedIndex + 1) < totalSteps">Next: {{nextStep}}</div>
                    </div>
                </div>
                <div nogrow>
                    <button id="cart-button" class="toggle-button" [class.selected]="cartOpen" mat-button
                        (click)="cartOpen = !cartOpen" style="line-height: 14px;padding-top: 5px; padding-bottom: 5px;">
                        <mat-icon>shopping_cart</mat-icon><br>
                        {{cartTotal | currency}}
                        <span class="badge" style="position: absolute;top: 0;right: 8px;"
                            *ngIf="shoppingCartItems && shoppingCartItems.length > 0">
                            {{shoppingCartItems.length}}
                        </span>
                    </button>

                    <div id="cart" *ngIf="cartOpen">
                        <ul>
                            <li *ngFor="let item of shoppingCartItems" [class.deleting]="item.deleting">
                                <div aflex>
                                    <div nogrow>
                                        <img class="cart-image" [src]="item.url">
                                    </div>
                                    <div>
                                        {{item.description}}<br>
                                        <ng-container *ngIf="args.payMonthly">{{item.priceRecurring | currency}}
                                        </ng-container>
                                        <ng-container *ngIf="!args.payMonthly">{{item.price | currency}}</ng-container>

                                        <!-- x {{item.quantity}} -->
                                    </div>
                                    <div nogrow>
                                        <button mat-button (click)="removeItem(item)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>

                                </div>
                            </li>
                        </ul>

                        <div id="empty-cart" *ngIf="!shoppingCartItems || shoppingCartItems.length === 0">
                            <mat-icon>add_shopping_cart</mat-icon>
                            <br>
                            <h2>Your cart is empty</h2>
                        </div>
                        <div topmargin30 aflex>
                            <div class="padding">
                                <mat-slide-toggle [(ngModel)]="args.payMonthly">Pay Monthly</mat-slide-toggle>
                            </div>
                            <div nogrow class="padding">
                                <div class="data-label">
                                    <ng-container *ngIf="args.payMonthly">Monthly</ng-container>
                                    <ng-container *ngIf="!args.payMonthly">Annual</ng-container>

                                    Payment
                                </div>
                                <h2 class="price">{{cartTotal | currency}}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container special-container">
        <mat-tab-group class="hide-tabs no-padding" [(selectedIndex)]="selectedIndex" *ngIf="policySummary"
            (selectedIndexChange)="selectedIndexChange($event)">
            <mat-tab label="First">

                <div class="padding">

                    <div class="header-container">
                        <div flex>

                            <div nogrow><img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/contact-card.svg" style="height: 40px;"
                                    class="page-icon-image">
                            </div>
                            <div>
                                <p class="info-detail">Please take a moment to verify your contact information</p>
                            </div>
                        </div>
                    </div>


                    <div style="width: 100%;max-width: 620px;display:table; margin: 0 auto">
                        <mat-form-field appearance="outline" topmargin20>
                            <mat-label>
                                <mat-icon>email</mat-icon>
                                Email Address
                            </mat-label>
                            <input matInput id="customer-email-address" [(ngModel)]="args.customerEmail">
                        </mat-form-field>

                        <div flex>
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    <mat-icon>email</mat-icon>
                                    Home Phone
                                </mat-label>
                                <input matInput type="tel" id="customer-home-number" mask="(000) 000-0000"
                                    [(ngModel)]="args.customerHomePhone">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    <mat-icon>phone_iphone</mat-icon>
                                    Mobile
                                </mat-label>
                                <input matInput [(ngModel)]="args.customerMobilePhone" mask="(000) 000-0000">
                            </mat-form-field>
                        </div>


                        <!-- <mat-form-field appearance="outline" style="max-width: 300px;">
                        <mat-label> Preferred Contact Method</mat-label>
                        <mat-select [(ngModel)]="args.preferredContactMethod">
                            <mat-option value="Phone">Phone</mat-option>
                            <mat-option value="SMS">SMS Text Message</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                        <mat-slide-toggle [(ngModel)]="args.paperless">Send Me Documents Paperless</mat-slide-toggle>

                        <div id="paperless-notification" class="slide-in-up arrow_box" *ngIf="args.paperless">
                            <div aflex>
                                <div nogrow>
                                    <i class="fa fa-tree" style="font-size: 36px;color: green;"></i>
                                </div>
                                <div>Save time (and trees). Get all your important documents emailed to you quickly.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Second">
                <div class="padding">
                    <div *ngIf="showCreditBalanceInfo" class="fade-in-up credit-balance-info">
                        <img src="../../../assets/images/scale.svg" style="height: 50px;">
                        <!-- <h1>Good News</h1> -->
                        <h2 class="price" topmargin20>{{creditBalance | specialCurrency}}</h2>
                        <div class="data-label">Credit Balance</div>
                        <div topmargin30>It looks like you have a credit balance of {{creditBalance |
                            specialCurrency}}<br>
                            available to upgrade your coverage.</div>
                    </div>
                    <div class="header-container">
                        <div flex>

                            <div nogrow><img src="../../../assets/images/document-circle.png" class="page-icon-image">
                            </div>
                            <div>
                                <p class="info-detail">Pick the coverage that is right for you.</p>
                            </div>
                        </div>
                    </div>
                    <div aflex topmargin20>
                        <div flex1>
                            <div class="data-label">Your plan is:</div>
                            <h1>{{planName}}</h1>


                        </div>
                        <div flex1>
                            <div class="data-label">Subscription #</div>
                            <h1>{{policySummary.policyNumber}}</h1>
                        </div>
                        <!-- <div nogrow>

                        </div> -->
                    </div>
                    <button mat-button mobile-block mat-raised-button class="small-primary" bottommargin10
                        color="primary" [disabled]="gettingPlans" (click)="getPlans()">
                        <mat-spinner *ngIf="gettingPlans" class="thirty"></mat-spinner>
                        Change My Coverage
                    </button>
                    <div class="center" topmargin20>
                        <a style="line-height: 14px;" mat-button target="_blank" [href]="contractUrl">
                            <mat-icon>description</mat-icon><br>
                            View Contract
                        </a>
                    </div>
                    <div id="coverage-container" topmargin40>
                        <div *ngFor="let group of groupedCoverage" class="coverage-group shadow-box">
                            <div class="section-label">{{group.category}}</div>
                            <ul class="section-detail">
                                <li *ngFor="let item of group.items">
                                    <button mat-button (click)="showCoverage(item)">
                                        {{item.name}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>

            </mat-tab>
            <mat-tab *ngIf="brandForBrandOption">

                <div flex>
                    <div nogrow>
                        <img class="feature-graphic" src="../../../assets/images/high-end-appliance.png">
                    </div>
                    <div class="feature-text">
                        <h1>Brand For Brand Upgrade</h1>
                        <div topmargin40 class="data-label">Your Price</div>
                        <h2 class="price">
                            <ng-container *ngIf="args.payMonthly">{{brandForBrandOption.priceRecurring | currency}}
                            </ng-container>
                            <ng-container *ngIf="!args.payMonthly">{{brandForBrandOption.price | currency}}
                            </ng-container>

                        </h2>
                        <div class="italic" *ngIf="args.payMonthly">Per Month</div>
                        <div class="italic" *ngIf="!args.payMonthly">Per Year</div>


                        <div topmargin40 class="center">
                            <button mat-button mat-raised-button class="small-primary" color="primary"
                                (click)="addBrandForBrand($event)">Add
                                Brand For Brand</button>

                        </div>
                        <p topmargin30>Never have homeowners had so many different options when it comes to the kitchen
                            appliances. Kitchen appliances vary greatly in price, features, and aesthetics. When an
                            appliance needs to be replaced, most home warranties will not replace the appliance with the
                            exact same brand. What’s more, they often only cover up to $2,000 max on the replacement of
                            an appliance. That’s why Elevate has created the Brand-for-Brand upgrade. With the
                            Brand-for-Brand upgrade, we will guarantee to replace your covered appliances with the exact
                            same brand (when required) if it’s still available. What’s more, the Brand-for-Brand upgrade
                            will cover up to $10,000 in appliance replacement costs.
                        </p>


                        <div class="center" topmargin20>
                            <button mat-button class="outline-btn" (click)="showCoverage(brandForBrandOption)">Learn
                                More</button>

                        </div>
                    </div>
                </div>


            </mat-tab>
            <mat-tab *ngIf="orangeUpgradeOption">


                <div flex>
                    <div nogrow>
                        <img class="feature-graphic" src="../../../assets/images/elevate-orange.png">
                    </div>
                    <div class="feature-text">
                        <h1>Orange Upgrade</h1>
                        <div topmargin40 class="data-label">Your Price</div>
                        <h2 class="price">
                            <ng-container *ngIf="args.payMonthly">{{orangeUpgradeOption.priceRecurring | currency}}
                            </ng-container>
                            <ng-container *ngIf="!args.payMonthly">{{orangeUpgradeOption.price | currency}}
                            </ng-container>
                        </h2>
                        <div class="italic" *ngIf="args.payMonthly">Per Month</div>
                        <div class="italic" *ngIf="!args.payMonthly">Per Year</div>
                        <div topmargin40 class="center">
                            <button mat-button mat-raised-button class="small-primary" color="primary"
                                (click)="addOrangeUpgrade($event)">Add
                                Orange Upgrade</button>

                        </div>
                        <p topmargin30>Home Service Plans are intended to cover the costs to repair or replace covered
                            systems and appliances when they fail due to normal wear. However, there may be situations
                            in which additional fees may be required that are typically covered by home warranties such
                            as upgrades required due to local code requirements, the cost of permits, disposal fees,
                            crane costs, and costs to correct previously mismatched systems or improper installation.
                            That’s why Elevate created the Orange Upgrade. The Orange Upgrade provides coverage for the
                            following:</p>
                        <ul>
                            <li>Code upgrades</li>
                            <li>Permits</li>
                            <li>Disposal Fees</li>
                            <li>Crane Costs</li>
                            <li>Improper installation or mismatched systems</li>
                        </ul>
                        <div class="center" topmargin20>
                            <button mat-button class="outline-btn" (click)="showCoverage(orangeUpgradeOption)">Learn
                                More</button>
                        </div>


                    </div>


                </div>
            </mat-tab>
            <mat-tab label="Third">
                <mat-tab-group style="margin-top: -25px;margin-bottom: -35px;" class="hide-tabs no-padding"
                    [(selectedIndex)]="optionSelectedIndex">
                    <mat-tab>
                        <div class="slider-container"
                            style="background-image: url(../../../assets/images/house-slider-bg.jpg);">
                            <div class="mask" topmargin40>
                                <div flex>

                                    <div nogrow><img src="../../../assets/images/check-list.svg"
                                            style="filter: brightness(0) invert(1);" class="page-icon-image">
                                    </div>
                                    <div>
                                        <p class="info-detail">
                                            Get the right options<br><br>
                                            <span class="small-text">
                                                Adding
                                                the right coverage options can save you thousands in unexpected
                                                repair
                                                bills.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="slider-container" style="background-image: url(../../../assets/images/pool.jpg);">
                            <div class="mask">
                                <h1>Pool/Spa</h1>
                                <p topmargin20>Cannon Ball! Your pool and spa should be fun, relaxing, and stress free!
                                    Having the right coverage will ensure that you can keep the pool parties going all
                                    summer long!
                                </p>
                                <ng-container *ngIf="poolSpaOption">
                                    <h2 class="price">{{poolSpaOption.priceRecurring | currency}}</h2>
                                    Per Month
                                </ng-container>
                            </div>
                            <div class="center" style="display: table;margin: 0 auto" topmargin40>
                                <div flex>
                                    <div><button mat-button mat-raised-button color="primary" class="small-primary"
                                            (click)="addCartItem($event, poolSpaOption)">Add Pool/Spa Equipment
                                            Coverage</button></div>
                                    <div><button class="white-button" mat-button
                                            (click)="showCoverage(poolSpaOption)">Learn
                                            More</button></div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="slider-container"
                            style="background-image: url(../../../assets/images/water.jpg);background-position: center;">
                            <div class="mask">
                                <h1>Water Softner</h1>
                                <p topmargin20>Eliminate the Headaches of Hard Water with our Water Softener option.
                                    Hard Water buildup can quickly wear down your appliances and plumbing systems which
                                    can become a costly problem for homeowners.</p>
                                <ng-container *ngIf="waterSoftenerOption">
                                    <h2 class="price">{{waterSoftenerOption.priceRecurring | currency}}</h2>
                                    Per Month
                                </ng-container>
                            </div>
                            <div class="center" style="display: table;margin: 0 auto" topmargin40>
                                <div flex>
                                    <div><button mat-button mat-raised-button color="primary" class="small-primary"
                                            (click)="addCartItem($event, waterSoftenerOption)">Add Water Softener
                                            Coverage</button></div>
                                    <div><button class="white-button" mat-button
                                            (click)="showCoverage(waterSoftenerOption)">Learn More</button></div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>
                    <mat-tab>
                        <div class="slider-container"
                            style="background-image: url(../../../assets/images/grass.jpg);background-position: bottom;">
                            <div class="mask">
                                <h1>Sprinkler System Coverage</h1>
                                <p topmargin20>The grass will always be greener on your side of the fence with our
                                    Elevate Exclusive Sprinkler Coverage. </p>
                                <ng-container *ngIf="sprinklerSystemOption">
                                    <h2 class="price">{{sprinklerSystemOption.priceRecurring | currency}}</h2>
                                    Per Month
                                </ng-container>
                            </div>
                            <div class="center" style="display: table;margin: 0 auto" topmargin40>
                                <div flex>
                                    <div><button mat-button mat-raised-button color="primary" class="small-primary"
                                            (click)="addCartItem($event, sprinklerSystemOption)">Add Sprinkler
                                            Coverage</button></div>
                                    <div><button class="white-button" mat-button
                                            (click)="showCoverage(sprinklerSystemOption)">Learn More</button></div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="carosel-dot-container">
                    <div (click)="optionSelectedIndex = 0;stopCarosel();" [class.selected]="optionSelectedIndex === 0">
                    </div>
                    <div (click)="optionSelectedIndex = 1;stopCarosel();" [class.selected]="optionSelectedIndex === 1">
                    </div>
                    <div (click)="optionSelectedIndex = 2;stopCarosel();" [class.selected]="optionSelectedIndex === 2">
                    </div>
                    <div (click)="optionSelectedIndex = 3;stopCarosel();" [class.selected]="optionSelectedIndex === 3">
                    </div>
                </div>
                <div class="padding">
                    <div class="center" topmargin30 bottommargin30>
                        <img src="../../../assets/images/popular.svg" style="height: 50px;">
                        <h2>Popular Items</h2>
                    </div>

                    <div class="option-button-container">
                        <div *ngFor="let option of promotedOptionalItems" mat-button class="option-button shadow-box">
                            <div style="flex: 1">
                                {{option.name}}<br>
                                <h2>
                                    <ng-container *ngIf="args.payMonthly">{{option.priceRecurring | currency}}
                                    </ng-container>
                                    <ng-container *ngIf="!args.payMonthly">{{option.price | currency}}</ng-container>

                                </h2>
                                <span class="time-label" *ngIf="args.payMonthly">per month</span>
                                <span class="time-label" *ngIf="!args.payMonthly">per year</span>
                                <img [src]="option.url">
                            </div>
                            <div style="flex: none;text-align: center;">
                                <button mat-button mat-raised-button
                                    (click)="addCartItem($event, option)">Add</button><br>
                                <button mat-button (click)="showCoverage(option)">Learn More</button>
                            </div>




                        </div>
                    </div>

                    <div topmargin40 class="center">
                        <button mat-button mobile-block (click)="showAllOptions = !showAllOptions">
                            <!-- <mat-icon class="rotate" [class.rotated]="showAllOptions">chevron_right</mat-icon> -->
                            <img style="height: 40px;" class="rotate" [class.rotated]="showAllOptions"
                                src="../../../assets/images/show-more.png"><br>
                            <span *ngIf="showAllOptions">Hide</span>
                            <span *ngIf="!showAllOptions">Show Me More</span>
                        </button>
                    </div>

                    <div *ngIf="showAllOptions" class="slide-in-up option-button-container">
                        <div *ngFor="let option of additionalOptions" mat-button class="option-button shadow-box">
                            <div style="flex: 1">
                                {{option.name}}<br>
                                <h2>
                                    <ng-container *ngIf="args.payMonthly">{{option.priceRecurring | currency}}
                                    </ng-container>
                                    <ng-container *ngIf="!args.payMonthly">{{option.price | currency}}</ng-container>

                                </h2>
                                <span class="time-label" *ngIf="args.payMonthly">per month</span>
                                <span class="time-label" *ngIf="!args.payMonthly">per year</span>
                                <img [src]="option.url">
                            </div>
                            <div style="flex: none;text-align: center;">
                                <button mat-button mat-raised-button
                                    (click)="addCartItem($event, option)">Add</button><br>
                                <button mat-button (click)="showCoverage(option)">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="padding inspection-protection-container">

                    <app-document-upload [hideLearnMore]="true" [checkExisting]="false"
                        (inspectionUploaded)="homeInspectionUploaded()" [policySummary]="policySummary">
                    </app-document-upload>
                    <div class="info-container">
                        <div>
                            <h2 bottommargin20 topmargin20>What is it?</h2>
                            <p>Upgrade your warranty to protect yourself from unknown pre-existing conditions! Elevate’s
                                Inspection Protection covers breakdowns caused by unknown pre-existing conditions that
                                were not detected by a home inspection. Get an added layer of protection from
                                undetected, unknown pre-existing conditions with Inspection Protection! </p>

                        </div>
                        <div>

                        </div>
                        <div>
                            <h2 bottommargin20 topmargin20>How do it get it?</h2>
                            <p>All you need to do to qualify for Inspection Protection and enjoy coverage on unknown
                                pre-existing conditions is to upload your home inspection below or email it to <a
                                    href="mailto:inspectionprotection@elevatehomescriptions.com">inspectionprotection&#64;elevatehomescriptions.com</a>
                                .</p>


                        </div>

                    </div>

                    <div class="center">
                        <button mat-button mat-raised-button
                            (click)="showMoreInspectionProtection = !showMoreInspectionProtection">
                            Learn More
                        </button>
                    </div>

                    <div class="fade-in-up" topmargin10 *ngIf="showMoreInspectionProtection">
                        <p>
                            No one wants to be caught off guard, especially when it comes from a breakdown due to a
                            pre-existing condition on a home you just purchased. Nearly 20% of new homeowners encounter
                            a mechanical failure with one of their home systems or appliances within the first 30 days
                            after they move into their new home. Unfortunately, most home warranties are known to deny
                            service requests in the beginning of a home warranty because many of these breakdowns are
                            caused by a pre-existing condition. That’s why Elevate launched Inspection Protection – to
                            protect new subscribers from denied service requests caused by unknown pre-existing
                            conditions!
                        </p>
                        <p>
                            With Inspection Protection, Elevate will cover mechanical failures caused by unknown
                            pre-existing conditions so long as it was not detected on your home inspection report.
                            Signing up is easy – simply Upload your Home Inspection Report or email it to <a
                                href="mailto:inspectionprotection@elevatehomescriptions.com">inspectionprotection&#64;elevatehomescriptions.com</a>.
                            If your home inspection did not identify defects or problems with your covered home systems
                            and appliances, you’ll be covered for any mechanical breakdowns caused by unknown
                            pre-existing conditions.
                            Upgrade your warranty now with Inspection Protection!

                        </p>
                    </div>

                </div>
            </mat-tab>
            <mat-tab>
                <div class="padding">
                    <ng-container *ngIf="!setupAutoRenewalVisible">
                        <div class="header-container">
                            <div flex>

                                <div nogrow><img src="../../../assets/images/renew.svg" class="page-icon-image">
                                </div>
                                <div>
                                    <p class="info-detail">Auto-Renew <span class="small-text"><br><br> Your current
                                            coverage term lasts from
                                            {{policySummary.effectiveDate | date}} to
                                            {{policySummary.expirationDate | date}}.<br><br> You can ensure you
                                            don't miss a
                                            minute
                                            of coverage by setting
                                            your subscription to auto-renew</span></p>
                                </div>
                            </div>
                        </div>

                        <div topmargin30 bottommargin20 class="center">
                            <button mat-button mat-raised-button mobile-block color="primary" class="small-primary"
                                (click)="setupAutoRenewalVisible = true">
                                <mat-icon>restore_page</mat-icon>
                                Auto-Renew Me
                            </button>
                        </div>

                    </ng-container>

                    <div *ngIf="setupAutoRenewalVisible" class="slide-in-up">
                        <p class="info">
                            After your initial term ends on {{policySummary.expirationDate | date}}, you will then be
                            billed per
                            month. You can cancel at any time. We just need
                            a major credit card</p>
                        <app-payment-method-entry buttonText="Add" [cardGetUrl]="cardGetUrl" paymentDescription="None"
                            [paymentAmount]="0" [customerId]="customerId" [(selectedCard)]="args.autoRenewalCard"
                            (selectedCardChange)="renewalCardChanged()"></app-payment-method-entry>
                        <button mat-button mobile-block (click)="doNotAutoRenew()">Nevermind</button>
                    </div>
                </div>

            </mat-tab>
            <mat-tab>
                <div class="padding">
                    <div class="header-container">
                        <div flex>

                            <div nogrow> <img src="../../../assets/images/web-portal.svg" style="height: 60px;">
                            </div>
                            <div>
                                <p class="info-detail">Customer Portal &amp; Apps<br><br><span class="small-text">With
                                        our
                                        customer portal, you can request service, manage your
                                        coverage, pay bill and more.
                                        All
                                        from the convenience of computer or smartphone app.</span> </p>
                            </div>

                        </div>
                    </div>

                    <mat-form-field appearance="outline" topmargin20 style="max-width: 400px">
                        <mat-label>
                            <mat-icon>account_circle</mat-icon>
                            Email Login
                        </mat-label>
                        <input matInput type="email" [(ngModel)]="args.loginId">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 400px">
                        <mat-label>
                            <mat-icon>vpn_key</mat-icon>
                            Password
                        </mat-label>
                        <input matInput type="password" [(ngModel)]="args.password">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 400px">
                        <mat-label>
                            <mat-icon>vpn_key</mat-icon>
                            Confirm Password
                        </mat-label>
                        <input matInput type="password" [(ngModel)]="passwordConfirm">
                    </mat-form-field>

                    <button mat-button mat-raised-button bottommargin20 class="small-primary" color="primary"
                        mobile-block (click)="validateLogin()">Create Login</button>


                    <div topmargin30>

                        <div>
                            <a target="_blank" href="https://apps.apple.com/app/elevate-customer/id1619781798">
                                <img src="../../../assets/images/apple-app-store.png" style="height: 40px;">
                            </a>
                            <a target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.elevatehw.customers.twa&hl=en_US&gl=US">
                                <img src="../../../assets/images/google-play.png" style="height: 40px;">
                            </a>

                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <div class="padding">

                    <div id="review-grid">
                        <div class="shadow-box">
                            <h2>Contact</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div aflex>
                                    <div>
                                        <mat-icon>email</mat-icon>{{args.customerEmail}}
                                    </div>
                                </div>
                                <div aflex>
                                    <div flex1 *ngIf="args.customerHomePhone">
                                        <mat-icon>phone</mat-icon> {{args.customerHomePhone | formatPhoneNumber}}
                                    </div>

                                    <div flex1 *ngIf="args.customerMobilePhone">
                                        <mat-icon>phone_iphone</mat-icon> {{args.customerMobilePhone |
                                        formatPhoneNumber}}
                                    </div>
                                </div>
                                <div aflex>
                                    <!-- <div flex1>
                                        <span class="data-label">
                                            Contact me via:</span>
                                        {{args.preferredContactMethod}}
                                    </div> -->
                                    <div flex1>
                                        <mat-checkbox [disabled]="true" [(ngModel)]="args.paperless">Paperless
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="shadow-box">
                            <h2>Additional Coverage</h2>
                            <div topmargin30>
                                <div class="summary-cart-container">
                                    <div class="summary-cart-item" *ngFor="let cartItem of shoppingCartItems">
                                        <div class="center">
                                            <img [src]="cartItem.url">
                                        </div>
                                        <div aflex>
                                            <div>
                                                {{cartItem.description}}
                                            </div>
                                            <div nogrow>
                                                <ng-container *ngIf="args.payMonthly">{{cartItem.priceRecurring |
                                                    currency}}</ng-container>
                                                <ng-container *ngIf="!args.payMonthly">{{cartItem.price | currency}}
                                                </ng-container>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div aflex *ngIf="shoppingCartItems && shoppingCartItems.length">
                                    <div>
                                    </div>
                                    <div nogrow>

                                        <app-display-total [label]="labelTotal" [total]="cartTotal"></app-display-total>
                                        <mat-slide-toggle [(ngModel)]="args.payMonthly">Pay Monthly</mat-slide-toggle>
                                    </div>
                                </div>

                                <div *ngIf="!shoppingCartItems || shoppingCartItems.length === 0">
                                    <h1>No additional coverage</h1>
                                </div>

                            </div>
                        </div>
                        <div class="shadow-box">
                            <h2>Documents</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div>
                                    <mat-checkbox [disabled]="true" [(ngModel)]="args.homeInspectionUploaded">Home
                                        Inspection
                                    </mat-checkbox>
                                </div>
                                <!-- <mat-checkbox [disabled]="true" [(ngModel)]="args.sellersDisclosures">Seller's
                                    Disclosures
                                </mat-checkbox> -->
                            </div>
                        </div>
                        <div class="shadow-box">
                            <h2>Auto-Renew</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div *ngIf="doAutoRenewal">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon style="font-size: 36px;color: green;">autorenew</mat-icon>
                                        </div>
                                        <div>
                                            Sit back and relax. Your subscription is set to auto-renew.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!doAutoRenewal">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon class="warning">warning</mat-icon>
                                        </div>
                                        <div>Your subscription will NOT auto-renew and will expire <span
                                                *ngIf="policySummary.expirationDate"> on </span>
                                            <span *ngIf="!policySummary.expirationDate"> at the end of the term</span>
                                            {{policySummary.expirationDate | date: 'shortDate'}}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="shadow-box">
                            <h2>Customer Portal</h2>
                            <div style="padding-left: 30px;" topmargin30>
                                <div *ngIf="createLogin">
                                    <div aflex>
                                        <div nogrow>
                                            <img src="../../../assets/images/web-portal.svg" style="height: 60px;">

                                        </div>

                                        <div>
                                            A login will be created for our customer portal.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!createLogin">
                                    <div aflex>
                                        <div nogrow>
                                            <mat-icon class="warning">warning</mat-icon>

                                        </div>

                                        <div>
                                            No log in will be created. Don't worry, you can always create a login at a
                                            later time.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div flex>
                        <div></div>
                        <div nogrow>
                            <button *ngIf="(selectedIndex + 1) === totalSteps" [disabled]="saving" mat-button
                                mat-raised-button mobile-block color="primary" large (click)="save()">
                                <mat-spinner class="thirty" *ngIf="saving"></mat-spinner>
                                Finish
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div id="toolbar" [class.scrolled]="isScrolled">
        <div class="container">
            <div aflex style="white-space: nowrap;">
                <div>

                </div>
                <div nogrow>
                    <button mat-button (click)="selectedIndex = selectedIndex - 1" [disabled]="selectedIndex === 0">
                        <mat-icon>chevron_left</mat-icon>
                        Back
                    </button>
                    <button mat-button mat-raised-button color="primary" class="small-primary"
                        *ngIf="(selectedIndex + 1) < totalSteps && !hideNextButton" (click)="goNext()">
                        <div>
                            Next

                            <mat-icon>chevron_right</mat-icon>
                        </div>
                    </button>

                    <button mat-button mat-raised-button *ngIf="showSkipButton" (click)="skipStep()">
                        <mat-icon>subdirectory_arrow_right</mat-icon>
                        Skip
                    </button>

                </div>
            </div>
        </div>

    </div>
</div>

<div id="current-item" [style.left]="currentItem.pageX" [style.opacity]="currentItem.opacity"
    [style.top]="currentItem.pageY" *ngIf="currentItem">

    <img style="height: 100px;" [src]="currentItem.url">
    <br>
    {{currentItem.description}}
</div>

<div id="plan-selection" *ngIf="planSelectionOpen">
    <button id="close-plan-selection" mat-button (click)="planSelectionOpen = false">
        <mat-icon>close</mat-icon>
    </button>
    <app-plan-offerings [isMonthly]="true" [hideMonthlySelection]="true" [plans]="plans" [(selectedPlan)]="selectedPlan"
        (selectedPlanChange)="planSelected($event)"></app-plan-offerings>

</div>

<div id="tutorial" *ngIf="tutorialOpen">

    <button id="close-tutorial" mat-button (click)="closeTutorial()">
        <mat-icon>close</mat-icon>
    </button>
    <div style="position: relative;height: 100%;display: flex; height: 100%;flex-direction: column;overflow: hidden;">
        <div class="full-step" class="full-step" *ngIf="showBackground" [class.step1]="tutorialIndex === 0"
            [class.step2]="tutorialIndex === 1" [class.step3]="tutorialIndex === 2" [class.step4]="tutorialIndex === 3"
            [class.backward]="tutorialGoingBack" [class.step5]="tutorialIndex === 4">

        </div>
        <div style="flex: 1;">
            <mat-tab-group style="margin-top: 0;" class="hide-tabs" (selectedIndexChange)="tutorialIndexChange($event)"
                [(selectedIndex)]="tutorialIndex">
                <mat-tab>

                    <div>
                        <h1 style="padding-left: 30px;">Item Breakdown</h1>
                        <div class="center" style="padding: 30px;">
                            <!-- <img topmargin40 class="tutorial-image"
                            src="../../../assets/images/AdobeStock_317013238_Preview.jpg"> -->
                            <div class="text-bg">
                                <p>Household items break. Usually at the least
                                    opportune time. With an elevate
                                    subscription,
                                    you
                                    get the assurance of coverage that is
                                    best in the business.
                                </p>
                            </div>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <h1 style="padding-left: 30px;">Contact Us First</h1>
                    <div class="center" style="padding: 30px;">
                        <!-- <img topmargin40 class="tutorial-image" src="../../../assets/images/app_animated.png"> -->
                        <div class="text-bg">
                            <p>
                                When breakdowns happen, contact us first. We will match you with a service pro with the
                                right skills to get the problem solved quickly and efficiently. Simply go online to
                                create a service request, and of course, we are always available by phone to answer your
                                questions.
                            </p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <h1 style="padding-left: 30px;">Repair</h1>
                    <div class="center" style="padding: 30px;">
                        <!-- <img class="tutorial-image" src="../../../assets/images/washer_repair_animated.png"> -->
                        <div class="text-bg">
                            <p>
                                The service pro will contact you in a timely manner to schedule a mutually convenient
                                time to visit your home, diagnose the problem, and perform the necessary repairs.
                            </p>

                            <p>In certain cases, parts may need to be ordered and the repairs may
                                need to be completed on a
                                follow-up appointment.</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>


                    <h1 style="padding-left: 30px;">Relax</h1>
                    <div class="center" style="padding: 30px;">
                        <!-- <img class="tutorial-image" src="../../../assets/images/relax_animated.png"> -->
                        <div class="text-bg">
                            <p>
                                Once the covered repairs are complete, there's nothing left to do but relax. We cover
                                the
                                cost of the repairs, leaving you
                                with the peace-of-mind that comes from an Elevate subscription.
                            </p>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <h1 style="padding-left: 30px">
                        Get Started
                    </h1>

                    <div class="center" style="padding: 30px">

                        <!-- <img class="tutorial-image" src="../../../assets/images/checklist_animated.png"> -->
                        <div class="text-bg">
                            <p>We want to make sure you have the right coverage. Give
                                us a few minutes and we'll
                                tailor your home subscription
                                to perfectly fit your individual needs.
                            </p>
                        </div>

                        <button topmargin40 mat-button mat-raised-button large color="primary"
                            (click)="closeTutorial()">Get Started</button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="center" style="flex: none">

        </div>
        <div aflex style="flex:none">

            <div nogrow style="min-width: 96px;">
                <button *ngIf="tutorialIndex === 0" mat-button style="color: #fff;background-color: rgba(0,0,0,.3)"
                    (click)="closeTutorial()">Skip Tutorial</button>
                <button mat-button *ngIf="tutorialIndex > 0" (click)="tutorialIndex = tutorialIndex - 1">
                    <mat-icon>chevron_left</mat-icon>
                    Back
                </button>
            </div>
            <div class="center" style="white-space: nowrap;" *ngIf="tutorialIndex < 4">
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 0"
                    (click)="tutorialIndex = 0"></button>
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 1"
                    (click)="tutorialIndex = 1"></button>
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 2"
                    (click)="tutorialIndex = 2"></button>
                <button mat-button class="progress-dot" [class.selected]="tutorialIndex === 3"
                    (click)="tutorialIndex = 3"></button>
            </div>

            <div nogrow>

                <button *ngIf="tutorialIndex < 4" class="small-primary" mat-button mat-raised-button color="primary"
                    (click)="tutorialIndex = tutorialIndex + 1">
                    <div>
                        Next

                        <mat-icon>chevron_right</mat-icon>
                    </div>
                </button>

            </div>
        </div>
    </div>


</div>

<div class="backdrop" *ngIf="cardSelectionOpen" (click)="cardSelectionOpen = false"></div>

<div id="card-selection" *ngIf="cardSelectionOpen">
    <button id="close-card-selection" mat-button (click)="cardSelectionOpen = false">
        <mat-icon>close</mat-icon>
    </button>
    <h1>Payment Information</h1>
    <p class="info" topmargin20 *ngIf="args.payMonthly">
        You will be billed in 12 installments of {{cartTotal |currency}} each.

        The first payment
        is due today. <ng-container *ngIf="creditBalance > 0">
            Your credit balance of {{creditBalance | currency}} will be used first. Once the balance has been exhausted,
            we will then use your credit card.
        </ng-container>

    </p>
    <p class="info" topmargin20 *ngIf="!args.payMonthly">
        You will be billed one time a total of {{cartTotalMinusCredit | currency}} for
        {{policySummary.monthsOfCoverage}} months of coverage.
    </p>
    <app-payment-method-entry buttonText="Add" [allowOneTimeUse]="!args.payMonthly" [cardGetUrl]="cardGetUrl" paymentDescription="None" [paymentAmount]="0"
        (newCardCreated)="selectUpgradeCard()" [(addingNewCard)]="addingNewUpgradeCard" [customerId]="customerId"
        [(selectedCard)]="args.upgradeCard">
    </app-payment-method-entry>

    <div class="pay-btn-container">
        <div flex>
            <div> <button mat-button topmargin20 mat-raised-button *ngIf="!addingNewUpgradeCard"
                    [disabled]="!args.upgradeCard" color="primary" class="small-primary" mobile-block
                    (click)="selectUpgradeCard()">
                    Save Coverage
                </button></div>
            <div> <button mobile-block topmargin20 *ngIf="args.payMonthly" mat-button mat-raised-button
                    (click)="showPaymentSchedule  = !showPaymentSchedule">
                    <mat-icon class="rotate" [class.rotated]="showPaymentSchedule">chevron_right</mat-icon>
                    <span *ngIf="showPaymentSchedule">Hide Payment Schedule</span>
                    <span *ngIf="!showPaymentSchedule">Show Payment Schedule</span>
                </button></div>
        </div>
    </div>




    <div *ngIf="args.payMonthly && showPaymentSchedule">

        <table class="table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th class="right">Amount</th>
                    <th class="right">Charge</th>
                    <th class="right" *ngIf="creditBalance > 0">Applied Credit</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let schedule of monthlySchedule">
                    <td>
                        {{schedule.due |date: 'shortDate'}}
                    </td>
                    <td class="right">
                        {{schedule.fullAmount | currency}}
                    </td>
                    <td class="right">
                        {{schedule.amount | currency}}
                    </td>
                    <td *ngIf="creditBalance > 0" class="right">
                        {{schedule.appliedCredit | currency}}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>Total</th>
                    <th class="right">{{scheduleFullAmount | currency}}</th>
                    <th class="right">{{scheduleTotal | currency}}</th>
                    <th class="right" *ngIf="creditBalance > 0">{{scheduleAppliedTotal | currency}}</th>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<div id="completion" *ngIf="showCompletion">

    <div class="container center">
        <h1 topmargin40 bottommargin40>You're All Set</h1>
        <div class="complete-container" [class.complete-container-complete]="teaseHMS">
            <lottie-player style="height: 200px" src="../../../assets/lottie/check-circle-complete.json"
                background="transparent" speed="1" autoplay>
            </lottie-player>
            <p topmargin40 *ngIf="!teaseHMS">Sit back and relax.<br> You've got the peace of mind that comes from the
                best home subscription
                in the business.</p>
        </div>



        <a mat-button mobile-block mat-raised-button topmargin40 routerLink="/" style="padding-top: 15px">
            <img src="../../../assets/images/web-portal.svg" style="height: 36px;"><br>
            Go To My Portal</a>

        <lottie-player [routerLink]="autoHref" class="teaser" style="height: 400px;margin-top: 40px"
            src="../../../assets/lottie/hms-teaser-complete.json" *ngIf="teaseHMS" background="transparent" speed="1"
            autoplay></lottie-player>
    </div>
</div>