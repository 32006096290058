import { Component, Input, OnInit } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-phone-display',
    templateUrl: './phone-display.component.html',
    styleUrls: ['./phone-display.component.css']
})
export class PhoneDisplayComponent implements OnInit {

    @Input() phoneNumber: string;
    constructor() { }

    ngOnInit(): void {
    }

    get display() {
        return UtilitiesService.formatPhoneNumber(this.phoneNumber);
    }

}
