import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
@Component({
    selector: 'app-update-warranty-payment-method',
    templateUrl: './update-warranty-payment-method.component.html',
    styleUrls: ['./update-warranty-payment-method.component.css'],
    standalone: true,
    imports: [CommonModule, PaymentMethodEntryModule, MatButtonModule, MatDialogModule, MatIconModule, MatProgressSpinnerModule,PaymentMethodEntryComponent]
})
export class UpdateWarrantyPaymentMethodComponent implements OnInit {

    policySummary: PolicySummary;
    selectedCard: StripeCard;
    addingNewCard: boolean;
    setAsDefault: boolean;
    saving = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private policyApi: PolicyApiService,
        public dialogRef: MatDialogRef<UpdateWarrantyPaymentMethodComponent>,
        private missionService: MissionService) {
        this.policySummary = data;
    }

    ngOnInit() {

    }

    // async setSelectedCard(card: StripeCard) {
    //     if (!card) {
    //         return;
    //     }

    //     await this.policyApi.updateDefaultPolicyCard(this.policySummary.id, card.id, card.exp_month, card.exp_year);

    //     this.policySummary.stripeCardId = card.id;
    //     this.missionService.showSuccessToast('Payment Method Updated');
    // }

    // async clearPaymentMethod() {
    //     await this.policyApi.clearDefaultPolicyCard(this.policySummary.id);
    //     this.policySummary.stripeCardId = null;
    //     this.missionService.showSuccessToast('Payment Method Cleard');
    // }

    get canSave() {
        return this.selectedCard && !this.saving;
    }

    async newCardCreated() {
        if(this.selectedCard) {
            await this.save();
        }
    }

    async save() {
        const card   = this.selectedCard;
        this.saving = true;
        await this.policyApi.updateCustomerPortalPolicyCreditCard(this.policySummary.id, this.selectedCard.id, this.selectedCard.exp_month, this.selectedCard.exp_year);

        this.saving = false;
        this.dialogRef.close(card);
    }

}
