import { CUSTOM_ELEMENTS_SCHEMA, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { Address, WebQuote } from '@upkeeplabs/models/cogent';
import { SingleAddressEntryComponent } from '@cogent/client/shared/components/misc/single-address-entry/single-address-entry.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { StopWatchComponent } from '@cogent/client/shared/components/visuals/stop-watch/stop-watch.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { OrderMaintenanceServiceComponent } from '@cogent/client/shared/components/maintenance-service/order-maintenance-service/order-maintenance-service.component';
import { NgxMaskDirective } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-maint-services-landing',
    templateUrl: './maint-services-landing.component.html',
    styleUrls: ['./maint-services-landing.component.css'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    standalone: true,
    imports: [CommonModule, MatTabsModule, NgxMaskDirective, SingleAddressEntryComponent, OrderMaintenanceServiceComponent, MatExpansionModule, RouterModule, MatFormFieldModule, MatInputModule, FormsModule, StopWatchComponent, MatProgressSpinnerModule, MatButtonModule, MatIconModule]
})
export class MaintServicesLandingComponent implements OnInit {


    loading = false;
    entityId: string;
    redirectUrl: string;

    quote: WebQuote = new WebQuote();
    addressFixed = false;
    selectedIndex = 0;
    warrantyExpanded = false;
    servicesExpanded = false;
    editingAddress = true;
    showQuoteDetails = false;

    @ViewChild('addressEntry') addressEntry: SingleAddressEntryComponent;
    showMore = true;
    isLegacy = true;

    constructor(private activatedRoute: ActivatedRoute,
        private dialogService: DialogsService,
        private customerService: CustomerRepositoryService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.quote.id = UtilitiesService.newid();
        this.quote.source = 'maintenance-service';
        this.quote.address = new Address();
        this.quote.address.id = UtilitiesService.newid();
        this.quote.queryParameters = JSON.stringify(UtilitiesService.getQueryParams());

        setTimeout(() => this.setAddressFixed(), 100);
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.target) {
                const split = params.target.split('_');
                this.entityId = split[0];
                this.redirectUrl = split[1];
                this.router.navigateByUrl(this.autoHref)
            }
            if (params.quoteId) {
                this.customerService.getWebQuote(params.quoteId).then(q => {
                    this.quote = q;
                    this.selectedIndex = 1;
                });
            }
        });
    }

    async addressCompleteChange(evt) {

    }

    get autoHref() {
        return `/authenticate/${this.entityId}/${this.redirectUrl}`;
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        this.setAddressFixed();
    }

    getFirstAddress() {
        this.addressEntry.getFirstOption();
    }

    // @HostListener('window:hashchange', ['$event'])
    // watchUrlHash() {
    //     let hash = window.location.hash;
    //     if (hash) {
    //         hash = hash.replace('#', '');
    //     }
    //     let hashNumber = parseInt(hash, 10);
    //     if(hashNumber === 0) {
    //         this.selectedIndex = 0;
    //     }
    // }

    selectVisible: boolean;
    setSelect(value: boolean) {
        this.selectVisible = value;
    }

    async next() {
        // this.selectedIndex = 1;
        if (!this.quote.address.id) {
            this.quote.address.id = UtilitiesService.newid();
        }

        if (!this.quote.address?.address1) {
            await this.dialogService.alert('Entry Required', 'Please select your address').toPromise();
            return;
        }

        if (!this.quote.name) {
            await this.dialogService.alert('Entry Required', 'Please enter your name').toPromise();
            document.getElementById('q-name').focus();
            return;
        }
        if (!this.quote.phone || !UtilitiesService.validatePhoneNumber(this.quote.phone)) {
            await this.dialogService.alert('Entry Required', 'Please enter your phone').toPromise();
            document.getElementById('q-phone').focus();
            return;
        }
        if (!this.quote.email || !UtilitiesService.validateEmail(this.quote.email)) {
            await this.dialogService.alert('Entry Required', 'Please enter your email').toPromise();
            document.getElementById('q-email').focus();
            return;
        }



        this.loading = true;
        await this.customerService.saveWebQuote(this.quote);
        this.selectedIndex = 1;
        this.loading = false;
    }

    setAddressFixed() {
        if (!document.getElementById('measure-element')) {
            return;
        }
        this.addressFixed = document.getElementById('measure-element').getBoundingClientRect().top < 0;
        this.showMore = document.getElementById('measure-element-1').getBoundingClientRect().top >= 0;
    }

    doScroll() {

        window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' });
    }

    get y() {
        return window.screenY;
    }

    reset() {
        this.selectedIndex = 0;
        this.addressEntry.reset();
        document.location.hash = '';
    }
}
