<div id="chat-container" [class.shifted]="adjustLocation" *ngIf="chatSupported">

    <div style="position: relative;">
        <button mat-button [class.top]="collapsed" *ngIf="showChat" id="expand-collapse"
            (click)="collapsed = !collapsed">
            <mat-icon  [class.rotate]="collapsed">keyboard_arrow_down</mat-icon>
        </button>
        <div id="chat-client" [class.collapsed]="collapsed" [class.show]="showChat"></div>
        <div style="text-align: right;" *ngIf="!hideChatButton">
            <button mat-button id="chat-button" [disabled]="connecting" (click)="startChat()">
                <!-- <mat-icon *ngIf="!connecting">chat</mat-icon> -->
                <img *ngIf="!connecting" style="width: 23px;vertical-align: middle;" src="../../../assets/images/contact-us-white.svg">
                <mat-spinner *ngIf="connecting" class="thirty"></mat-spinner>

                <span class="add-text">Chat With Us</span>
            </button>
        </div>
    </div>
</div>