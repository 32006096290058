<div class="container">

    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First"> 
            <div class="search-header" [class.has-results]="searchResults && searchResults.length">

                <div style="text-align: center;">
                    <img _ngcontent-xbw-c12="" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/attach-orange-blob.svg" style="height: 120px">
                    <h2 class="sub-heading" topmargin10>Attach Existing Coverage</h2>
                </div>
                <div aflex style="max-width: 500px;" topmargin30>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Address</mat-label>
                            <input matInput (keyup)="handleKey($event)" [(ngModel)]="searchFor">
                            <div matSuffix>
                                <button id="search-button" (click)="search()" [disabled]="searching" mat-button
                                    mat-raised-button>
                                    <mat-icon style="display: inline;" *ngIf="!searching">search</mat-icon>
                                    <mat-spinner *ngIf="searching" class="thirty"></mat-spinner>
                                </button>
                            </div>
                        </mat-form-field>
                    </div>
        
                </div>
            </div>
        
            <div class="fade-in-up center" *ngIf="searchResults && !searchResults.length">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/find-csv-circle.png">
                <br>
                <h1>Sorry, we couldn't find anything that matched</h1>
            </div>
        
            <div class="search-results-container" [class.has-results]="searchResults && searchResults.length">
                <div *ngFor="let policy of searchResults" class="home-list-item">
                    <img [src]="getUrl(policy)">
                    <div aflex style="position: relative;">
                        <div nogrow style="position: absolute; left: -26px; top: calc(50% - 28px);">
                            <mat-icon>home</mat-icon>
                        </div>
                        <div style="padding-top: 8px;"> <span class="property-address"> {{policy.propertyAddress}}
                                {{policy.propertyCity}}</span>
        
                            <div *ngIf="!policy.showCodeEntry" topmargin10>
                                <mat-radio-group class="no-bold wrap-radio-content" aria-label="Select an option"
                                    [(ngModel)]="policy.sendOption">
                                    <mat-radio-button *ngIf="policy.homeNumberId" value="Home">Send SMS Message to
                                        {{policy.homeNumber}}</mat-radio-button>
                                    <mat-radio-button *ngIf="policy.mobileNumberId" value="Mobile">
                                        Send SMS Message to {{policy.mobileNumber}}
                                    </mat-radio-button>
                                    <mat-radio-button *ngIf="policy.email" value="Email">
                                        Send Email Message to {{policy.email}}
                                    </mat-radio-button>
                                </mat-radio-group>
        
                                <div topmargin20></div>
                                <button mat-button mat-raised-button color="primary" (click)="showVerifyDialog(policy)"
                                    [disabled]="!policy.sendOption || policy.sendingCode">
                                    <mat-spinner *ngIf="policy.sendingCode" class="thirty"></mat-spinner>
                                    Send Verification Code
                                </button>
        
                            </div>
        
                        </div>
        
        
                    </div>
        
                    <div *ngIf="policy.showCodeEntry" topmargin20>
                        <mat-form-field appearance="outline" style="max-width: 300px;">
                            <mat-label>Code</mat-label>
                            <input matInput type="number" [(ngModel)]="registrationCode">
                        </mat-form-field>
        
                        <button mat-button mat-raised-button color="primary" (click)="registerWithCode(policy)"
                            [disabled]="!registrationCode || saving">
                            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                            Register
                        </button>
                        <button mat-button (click)="policy.showCodeEntry = false">Send Another Code</button>
                    </div>
        
                </div>
        
                <button mat-button mat-raised-button *ngIf="searchResults && searchResults.length" (click)="searchAgain()" mobile-block>Search Again</button>
            </div>
            <!-- <button (click)="selectedIndex = 1"></button> -->
        </mat-tab>
        <mat-tab label="Second"> 
            <div class="center">
                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin40 bottommargin40>Success</h1>

                <a mat-button mat-raised-button color="primary" routerLink="/">Home</a>
                
                <div class="or" topmargin40 bottommargin40>OR</div>

                <button mat-button mat-raised-button  (click)="searchAgain()">Attach Another</button>
            </div>    
        </mat-tab>
        <mat-tab label="Third"> Content 3 </mat-tab>
      </mat-tab-group>



</div>