import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@cogent/client/auth';

@Component({
    selector: 'app-auto-authenticate',
    templateUrl: './auto-authenticate.component.html',
    styleUrls: ['./auto-authenticate.component.css'],
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule ]
})
export class AutoAuthenticateComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService) { }
    ngOnInit(): void {
        this.activatedRoute.params.subscribe(async params => {
            const entityId = params.entityId;
            const target: string = params.redirectTarget;


            localStorage.removeItem('auth');
            switch (target?.toUpperCase()) {
                case 'MAINT':
                    window.localStorage.setItem("redirectTo", '/maintenance-service/portal-order');
                    break;
            }
            this.authService.logIn(entityId, null, null, 'full');

        });

    }
}
