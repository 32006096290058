import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
    standalone: true,
    imports: [ CommonModule, MatButtonModule ]
})
export class ErrorComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
