import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@cogent/client/auth';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { sha256 } from 'js-sha256';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: true,
    imports: [CommonModule, MatInputModule, MatFormFieldModule, FormsModule, MatCheckboxModule, MatButtonModule, MatProgressSpinnerModule, RouterModule, MatIconModule]
})
export class LoginComponent implements OnInit {

    userName: string;
    password: string;
    loggingIn = false;
    rememberMe: boolean;

    shifted = true;

    constructor(private authService: AuthService,
        private router: Router,
        private dialogService: DialogsService) { }

    ngOnInit(): void {
        if(localStorage.getItem('rememberme') === 'true') {
            this.rememberMe = true;
            this.userName = localStorage.getItem('username');
            this.password = localStorage.getItem('password');
        }  
    }

    async login() {
        if(!this.userName || !this.password) {
            this.dialogService.alert('Required', 'Please enter your user name and password.');
            return;
        }
        this.loggingIn = true;
        const codeVerifier = this.authService.generateRandom(40);
        const args = {
            authorizeParameters: {
                client_id: 'customers',
                response_type: 'code',
                scope: this.authService.authConfig.scope,
                state: this.authService.generateRandom(40),
                code_challenge: this.authService.base64urlEncode(this.authService.hexToByteString(sha256(codeVerifier))),
                redirect_uri: 'none',
                code_challenge_method: 'S256'
            },
            login: {
                userName: this.userName,
                passwordHash: this.password,
            }
        }

        const response: any = await this.authService.localAuthenticate(args);
        this.loggingIn = false;
        if (!response.success) {
            this.dialogService.alert('No Match', 'Sorry, we could not find that user name and password combination.');
        } else {
            localStorage.setItem('auth', JSON.stringify(response));
            if(this.rememberMe) {
                localStorage.setItem('rememberme','true');
                localStorage.setItem('username',this.userName);
                localStorage.setItem('password',this.password);
            } else {
                localStorage.removeItem('rememberme');
                localStorage.removeItem('username');
                localStorage.removeItem('password');

            }
            let navUrl = localStorage.getItem('redirectTo');
            if(!navUrl) {
                navUrl = '/';
            }
            this.router.navigateByUrl(navUrl);
        }
    }
}
