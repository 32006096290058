import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UserNotificationApiService } from '@cogent/client/shared/services/api/user-notification-api.service';
import { UserNotification } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    standalone: true,
    imports: [ CommonModule, MatButtonModule, MatIconModule, RouterModule]
})
export class NotificationsComponent implements OnInit {

    userNotifications: UserNotification[];
    selectedNotification: UserNotification;
    isLegacy = true;


    constructor(private userNotificationApi: UserNotificationApiService) { }

    ngOnInit(): void {
        this.userNotificationApi.getAllUserNotifications().then(userNotifications => {
            this.userNotifications = userNotifications;
        });
    }

    selectNotification(notification: UserNotification) {
        const selectedOnes = this.userNotifications.filter(i => i.selected);
        for (const selectedNotification of selectedOnes) {
            selectedNotification.selected = false;
        }

        this.selectedNotification = null;
        setTimeout(()=> {
            this.selectedNotification = notification;
        });
        notification.selected = true;
        if (!notification.wasRead) {
            this.userNotificationApi.markUserNotificationRead(notification.id).then(() => {
                notification.wasRead = true;
            });
        }

    }

}
