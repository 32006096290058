<div class="container fancy-container bottom-right-rounded">
    <div flex>
        <div>
            <h1 class="page-title">
                <span class="big">Add-On</span><br><span class="little">Request</span>
            </h1>
        </div>
    </div>

    <ng-container *ngIf="addOn">

        <div flex>
            <div>

                <h2 class="sub-heading" bottommargin20>Property</h2>

                <h3 style="margin-bottom: 0;">{{addOn.customerName}}</h3>
                <div>{{addOn.propertyAddress1}}</div>
                <div *ngIf="addOn.propertyAddress2">{{addOn.propertyAddress2}}</div>
                <div>
                    {{addOn.propertyCity}}, {{addOn.propertyState}} {{addOn.propertyPostalCode}}
                </div>

                <div topmargin40 *ngIf="addOn.quoteSubmittedDate">
                    <div class="data-label" *ngIf="addOn.contractorComments">Comments from {{addOn.contractorName}}:
                    </div>
                    <div>{{addOn.contractorComments}}</div>

                    <div class="data-label" topmargin40>Quote Amount</div>
                    <h1 class="big-data">
                        {{addOn.price | currency}}
                    </h1>

                    <div class="button-block" *ngIf="canApproveOrReject">
                        <button mat-button mat-raised-button (click)="approveBid()" class="small-primary"
                            color="primary">Approve</button>&nbsp;
                        <button mat-button mat-raised-button (click)="reject()">Reject Bid</button>&nbsp;
                    </div>
                </div>

                <div topmargin40 *ngIf="addOn.approvedDate" class="center">
                    <app-check-circle-animated height="125px"></app-check-circle-animated>
                    <p class="info">You have accepted the bid</p>
                </div>

                <div topmargin40 *ngIf="!addOn.quoteSubmittedDate && !wasContractorRejected">
                    <app-typing-notification></app-typing-notification>
                    <p class="info" style="max-width: 300px">
                        {{addOn.contractorName}} is reviewing your request and we'll share their response when we get a
                        price.
                    </p>
                </div>
                <div topmargin40 *ngIf="wasContractorRejected">
                    <p  class="info center" style="max-width: 300px">
                        <app-frowny-face></app-frowny-face><br>
                        Unfortunately {{addOn.contractorName}} has told us they cannot fulfill this request.
                    </p>
                </div>
            </div>
            <div>

                <h2 class="sub-heading" bottommargin20>Request</h2>

                <app-add-on-request-args-viewer [addOn]="addOn"></app-add-on-request-args-viewer>
            </div>
        </div>


    </ng-container>
</div>