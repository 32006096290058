import { Component, HostListener, AfterViewInit, NgZone, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { ScrollAnimationService } from '@cogent/client/shared/services/common/scroll-animation.service';
import { Observable, merge, fromEvent, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthService } from '@cogent/client/auth';
import { ToastSuccessComponent } from '@cogent/client/shared/components/toast-success/toast-success.component';
import { ExecuteFunctionArgs, MissionService, ShellMenuItemModel } from '@cogent/client/shared/services/mission-service';
import { UserNotificationApiService } from '@cogent/client/shared/services/api/user-notification-api.service';
import { FunctionQuestionRendererComponent } from '@cogent/client/shared/components/functions/function-question-renderer/function-question-renderer/function-question-renderer.component';
import { FunctionRunner } from '../../../../../node/logic/functions/function-runner.logic';
import { FunctionCustomFormModalComponent } from '@cogent/client/shared/components/functions/function-custom-form/function-custom-form-modal/function-custom-form-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getAngularExecutors, getSingletonFunction } from '@cogent/client/shared/logic/client-functions.angular.logic';
import { getSharedExecutors } from '../../../../../node/logic/functions/shared/control-flow-functions.logic';

import { ApiFailureComponent } from '@cogent/client/shared/components/api-failure/api-failure.component';
import { LazyComponentProviderService } from '@cogent/client/shared/services/lazy-component-provider.service';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    // standalone: true,
    // imports: [CommonModule, MatButtonModule, RouterModule, MatIconModule, MatProgressSpinnerModule, FunctionQuestionRendererModule, WebSocketHandlerModule, ConnectChatComponent]
})
export class AppComponent implements AfterViewInit, OnInit {
    isScrolled = false;
    isHome = false;
    isLogin = false;
    isPolicyView = false;
    sideNavOpen = false;
    isBilling = false;
    breadcrumbs: any[];
    isWhite = false;
    hideHeader = false;
    unreadNotificationCount = 0;
    useFunctionBackdrop = false;
    showSalesTelephone = false;

    customerPortalMenuItems: ShellMenuItemModel[] = [
        new ShellMenuItemModel('Home', null, null, '/'),
        new ShellMenuItemModel('Request Service', null, null, '/select-property'),
        new ShellMenuItemModel('Make Payment', null, null, '/make-payment'),
        new ShellMenuItemModel('Contact', null, null, '/contact'),
        new ShellMenuItemModel('Settings', null, null, '/settings'),
        new ShellMenuItemModel('Log Out', null, null, '/auth/log-out'),
    ];

    registerMenuItems: ShellMenuItemModel[] = [

        new ShellMenuItemModel('Log In', null, null, '/auth/log-out'),
    ];
    overrideMenuItems: ShellMenuItemModel[];
    enteringAnimation = false;
    online: Observable<boolean>;
    isOnline = true;
    currentRoute: string;
    isRegister = false;
    embedded = false;
    ringBell = false;
    @ViewChild('functionQuestionRendererComponent') functionQuestionRendererComponent: FunctionQuestionRendererComponent;
    showFunctionQuestions = false;
    currentRunner: FunctionRunner;
    currentArgs: ExecuteFunctionArgs;


    constructor(
        private router: Router,
        private scrollAnimation: ScrollAnimationService,
        private zone: NgZone,
        private activatedRoute: ActivatedRoute,
        private missionService: MissionService,
        private dialog: DialogsService,
        private authService: AuthService,
        private matDialog: MatDialog,
        private lazy: LazyComponentProviderService,
        private gtmService: GoogleTagManagerService,
        private api: ApiService,
        private userNotificationApi: UserNotificationApiService,
        private snackBar: MatSnackBar, @Inject(DOCUMENT) private htmlDocument: HTMLDocument
    ) {

        AuthService.localLoginUrl = '/login';
        const urlParams = new URLSearchParams(window.location.search);

        const embeddedParam = urlParams.get('embedded');
        if (embeddedParam === 'true') {
            this.embedded = true;
        }
        this.isLogin = document.location.pathname === '/security/login';
        if (!this.isLogin) {

            this.isLogin = document.location.pathname === '/login';
        }

        missionService.overrideMenuItems.subscribe(
            () => {
                // this.overrideMenuItems = menuItems;
            });

        missionService.toast.subscribe(message => {
            this.snackBar.open(message, null, { duration: 10000, });
        });

        this.missionService.subscription.subscribe(message => {
            if (message.type === 'user-changed') {
                if (this.authService.isLoggedIn) {
                    this.overrideMenuItems = [];
                }
            }
            
            if (message.type === 'show-header') {
                this.hideHeader = false;
                const header = document.getElementById('appHeader');
                if(header) {
                    header.style.display = '' ;
                    header.style.height = '';
                }
                const appContent = document.getElementById('app-content');
                if(appContent) {
                    appContent.style.paddingTop = '';
                    appContent.style.paddingBottom = '';
                }

            }
        });

        // missionService.toastSuccess.subscribe(message => {
        //     this.snackBar.openFromComponent(
        //         ToastSuccessComponent,
        //         { data: message, duration: 4000, panelClass: ['success-toast'] });
        // });

        missionService.toastSuccess.subscribe(message => {
            this.snackBar.openFromComponent(
                ToastSuccessComponent,
                { data: message, duration: 4000, panelClass: ['success-toast'] });
        });

        missionService.toastWarning.subscribe(message => {
            this.snackBar.open(message, null, { duration: 7000, panelClass: ['warning-toast'] })
        });

        missionService.apiError.subscribe(() => {
            if (this.isOnline) {
                this.snackBar.openFromComponent(
                    ApiFailureComponent,
                    { duration: 10000, data: null, panelClass: ['failed-toast'], });
            }
        });

        this.online =
            merge(of(navigator.onLine),
                fromEvent(window, 'online').pipe(map(() => true)),
                fromEvent(window, 'offline').pipe(map(() => false)));

        this.online.subscribe(value => { this.isOnline = value; });
        // this.userNotificationApi.getUnreadNotificationCount().then(count => {
        //     this.unreadNotificationCount = count;
        //     if (count > 0) {
        //         setTimeout(() => {
        //             this.ringBell = true;
        //             setTimeout(() => this.ringBell = false, 5000);
        //         }, 3000);
        //     }
        // });

        router.events.subscribe(val => {
            if (val instanceof NavigationStart) {
                const navEnd: NavigationStart = val;

                if (navEnd.navigationTrigger === 'imperative' && this.authService.isLoggedIn) {
                    this.overrideMenuItems = [];
                }
            }
            if (val instanceof NavigationEnd) {

                window.scrollTo(0, 0);

                const nav = val as NavigationEnd;

                const gtmTag = {
                    event: 'page',
                    pageName: nav.url,
                };
                this.gtmService.pushTag(gtmTag);


                this.isHome = nav.url === '/';
                this.isLogin = nav.url === '/security/login' || nav.urlAfterRedirects === '/security/login' || nav.url === '/login';
                this.isRegister = nav.url === '/security/register';
                this.hideHeader = nav.url && (nav.url.toLowerCase().indexOf('/onboarding') > -1
                    || nav.url.startsWith('/existing-onboarding')
                    || nav.url.startsWith('/maintenance-service?')
                    || nav.url.endsWith('/maintenance-service')
                    || nav.url.endsWith('/maintenance-service/')
                    || nav.url.endsWith('/new-quote')
                    || nav.url.indexOf('/test') > -1
                    || nav.url.startsWith('/quote')
                    || nav.url.startsWith('/maintenance-service#')
                    || nav.url.startsWith('/apps'));
                this.currentRoute = nav.url;

                this.showSalesTelephone = nav.url.indexOf('/quote') > -1;
                // this.isWhite = nav.url === '/select-property' || nav.url.startsWith('/request-service/');

                this.isPolicyView = nav.url.indexOf('/view-property/') > -1;

                this.breadcrumbs = [];
                let currentRoute = this.activatedRoute.root;
                let url = '';

                do {
                    const childrenRoutes = currentRoute.children;
                    currentRoute = null;
                    childrenRoutes.forEach(r => {
                        if (r.outlet === 'primary') {
                            const routeSnapshot = r.snapshot;
                            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
                            this.breadcrumbs.push({ label: r.snapshot.data, url });
                            currentRoute = r;
                        }
                    });
                } while (currentRoute);

                if (this.breadcrumbs && this.breadcrumbs.length > 0) {
                    const breadCrumb = this.breadcrumbs[this.breadcrumbs.length - 1];

                    if (breadCrumb && breadCrumb.label && breadCrumb.label.title) {
                        document.title = `${UtilitiesService.applicationName} - ${breadCrumb.label.title}`;
                    } else {
                        document.title = UtilitiesService.applicationName;
                    }
                }

                if (this.isHome) {
                    document.body.className = 'bg-image';
                } else {
                    document.body.className = 'white-background';
                }

                if (nav && nav.url && nav.url.indexOf('#') === -1) {
                    this.enteringAnimation = true;
                }

                setTimeout(() => this.enteringAnimation = false, 750);
            }
        });

        const favIconUrl = `${ApiService.endPointDotNet}Entity/Company/fav-icon`;
        this.htmlDocument.getElementById('fav-icon-link').setAttribute('href', favIconUrl);
        missionService.undo.subscribe(undoArgs => {
            this.snackBar.open(undoArgs.message, 'UNDO', { duration: undoArgs.timeout, panelClass: 'undo-bg' })
                .onAction()
                .subscribe(() => { undoArgs.callback(); });
        });
    }

    ngOnInit() {

        if (!this.authService.isLoggedIn) {
            this.overrideMenuItems = [
                new ShellMenuItemModel('About', null, null, 'https://www.elevatehomescriptions.com/about'),
                new ShellMenuItemModel('Request Service', null, null, '/find-property'),
                new ShellMenuItemModel('Partnerships', null, null, '#', null, 'partnerships'),
                new ShellMenuItemModel('Login', null, null, '#', null, 'login'),
            ];
        }

        this.missionService.functionToExecute.subscribe(args => {

            this.useFunctionBackdrop = args.useFunctionBackdrop;
            this.runCustomFunction(args);
        });
    }

    ngAfterViewInit() {

        setTimeout(() => {

            this.setActiveMenuItem();
        }, 500);

        const splash = document.getElementById('splashScreen');
        splash.classList.add('remove');
        setTimeout(() => {
            splash.parentElement.removeChild(splash);
        }, 1000);
    }

    get isIOS() {
        return UtilitiesService.isIOSAndSafari();
    }



    get menuItems(): ShellMenuItemModel[] {
        if (!this.overrideMenuItems || this.overrideMenuItems.length === 0) {
            return this.defaultMenuItems;
        }

        return this.overrideMenuItems;
    }

    get defaultMenuItems(): ShellMenuItemModel[] {

        if (this.isRegister) {
            return this.registerMenuItems;
        }

        return this.customerPortalMenuItems;
    }

    get isLoggedIn() {
        return this.authService.isLoggedIn;
    }

    get homeUrl() {
        if (!this.authService.isLoggedIn) {
            return 'https://www.elevatehomescriptions.com/';
        }
        return '/';
    }

    scrollToFragment(fragment: string, $event) {
        $event.cancelBubble = true;
        this.scrollAnimation.scrollToElement(fragment);
    }

    toggleSideNav() {
        this.sideNavOpen = !this.sideNavOpen;
    }

    private async runCustomFunction(args: ExecuteFunctionArgs) {
        this.currentArgs = args;
        const angularFunctions = getAngularExecutors();
        const sharedFunctions = getSharedExecutors();
        const allFunctions = sharedFunctions.concat(angularFunctions);
        const functionRunner = new FunctionRunner(allFunctions, {
            api: this.api,
            missionService: this.missionService,
            dialogService: this.dialog,
            matDialog: this.matDialog,
            lazy: this.lazy,
            router: this.router,
            functionRetriever: (id) => {
                return this.api.getSingleNode(`function/function-definition/${id}`);
            }
        }, args.objectInScope, (result, final) => {
            if (final) {
                this.showFunctionQuestions = false;
                this.setDocumentOverflow()
            }
        }, "angular");
        this.currentRunner = functionRunner;
        functionRunner.getSingleton = getSingletonFunction();

        functionRunner.showCustomForm = questions => {
            FunctionCustomFormModalComponent.ShowModal(this.matDialog, questions, functionRunner);
        };

        functionRunner.onTransfer = runner => {
            this.currentRunner = runner;
        };



        functionRunner.setupFunctionQuestionRender = async runner => {
            return new Promise((resolve, reject) => {

                this.showFunctionQuestions = true;
                this.setDocumentOverflow();

                setTimeout(() => {
                    this.functionQuestionRendererComponent.reset();
                    runner.questionRenderer = this.functionQuestionRendererComponent.renderer;
                    resolve();
                });
            });
        }

        functionRunner.changeWorkingMessage = args.changeWorkingMessage;


        functionRunner.runProcess(args.functionJson);

    }

    setDocumentOverflow() {
        document.querySelector('html').style.overflow = this.showFunctionQuestions ? 'hidden' : 'unset';
    }

    isInViewport(elem) {
        const bounding = elem.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    isScrolledIntoView(elementId) {

    }

    setActiveMenuItem() {
        const fragmentMenuItems = this.menuItems.filter(i => i.documentFragment);

        fragmentMenuItems.forEach(i => {
            const element = document.getElementById(i.documentFragment.replace('#', ''));
            if (element && this.isInViewport(element)) {
                i.active = true;
                fragmentMenuItems.filter(ni => ni !== i).forEach(ni => ni.active = false);
                return;
            }
        });
    }


    doMenuItem(menuItem: ShellMenuItemModel, event) {
        this.sideNavOpen = false;
        if (menuItem.url) {
            if (menuItem.url.toLocaleLowerCase().indexOf('https://') === -1) {
                this.router.navigateByUrl(menuItem.url);
            } else {
                document.location.href = menuItem.url;
            }
        } else if (menuItem.documentFragment) {
            this.scrollToFragment(menuItem.documentFragment, event);
        } else {
            menuItem.action();
        }
    }

    get companyUrl() {
        return ApiService.endPointDotNet + 'entity/company/logo';
    }

    get companyWhiteUrl() {
        return ApiService.endPointDotNet + 'entity/company/logo/white';
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.zone.run(() => {
            this.isScrolled = window.scrollY > 0;
            this.setActiveMenuItem();
        });
    }
}


