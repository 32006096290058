import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Address } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-verify-home-sqft',
    standalone: true,
    imports: [MatButtonModule, CommonModule, TextFieldModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatDialogModule, FormsModule],
    templateUrl: './verify-home-sqft.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    styleUrl: './verify-home-sqft.component.scss'
})
export class VerifyHomeSqftComponent {

    constructor(public dialogRef: MatDialogRef<VerifyHomeSqftComponent>,
        @Inject(MAT_DIALOG_DATA) public address: any,) {

    }

    get canSave() {
        return this.address.dwellingType && this.address.squareFeet && this.address.yearBuilt;
    }

    save() {
        this.dialogRef.close(true);
    }
}
