<svg id="Layer_1" [style.height]="height" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 696 747">
    <defs>
      <style>
        .cls-1, .cls-2 {
          stroke-width: 0px;
        }
  
        .cls-2 {
          fill: none;
        }
  
        .cls-3 {
          fill: none;
          stroke: #000;
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 40px;
        }
        @keyframes rotate {
          from {
              transform: rotate(0deg);
          }
          to {
              
              transform: rotate(360deg);
          }
        }
        .second-hand {
          animation: rotate 2s ease;
          transform-origin: 50% 100%;
          transform-box: fill-box;
          animation-delay: 1s;
        }
      </style>
    </defs>
    <line class="cls-3" x1="262.5" y1="20" x2="433.5" y2="20"/>
    <g>
      <g>
        <path class="cls-2" d="m348,726.5c-87.48,0-169.72-34.07-231.58-95.92-61.86-61.86-95.92-144.1-95.92-231.58s34.07-169.72,95.92-231.58,144.1-95.92,231.58-95.92,169.72,34.07,231.58,95.92c61.86,61.86,95.92,144.1,95.92,231.58s-34.07,169.72-95.92,231.58c-61.86,61.86-144.1,95.92-231.58,95.92Z"/>
        <path class="cls-1" d="m348,92c41.46,0,81.66,8.11,119.49,24.11,36.55,15.46,69.39,37.6,97.6,65.81,28.21,28.21,50.35,61.04,65.81,97.6,16,37.82,24.11,78.02,24.11,119.49s-8.11,81.66-24.11,119.49c-15.46,36.55-37.6,69.39-65.81,97.6-28.21,28.21-61.04,50.35-97.6,65.81-37.82,16-78.02,24.11-119.49,24.11s-81.66-8.11-119.49-24.11c-36.55-15.46-69.39-37.6-97.6-65.81-28.21-28.21-50.35-61.04-65.81-97.6-16-37.82-24.11-78.02-24.11-119.49s8.11-81.66,24.11-119.49c15.46-36.55,37.6-69.39,65.81-97.6,28.21-28.21,61.04-50.35,97.6-65.81,37.82-16,78.02-24.11,119.49-24.11m0-41C155.8,51,0,206.8,0,399s155.8,348,348,348,348-155.8,348-348S540.19,51,348,51h0Z"/>
      </g>
      <line class="cls-3" x1="574" y1="134" x2="608" y2="100"/>
    </g>
    <line class="cls-3 second-hand" x1="348" y1="150" x2="348" y2="399"/>
  </svg>
