<div class="container fancy-container">

    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs">

        <mat-tab label="First">
            <div class="center">
                <h1>How'd we do?</h1>
                <p class="info" *ngIf="summary" topmargin40
                    style="display: table;margin: 0 auto;max-width: 600px; width: 100%;">
                    {{summary.contractorName}} completed {{summary.maintenanceServiceName}} on {{summary.completedDate |
                    date: 'shortDate'}}. Rate your experience.
                </p>
                <div style="display: table;margin: 0 auto;">
                    <ngx-star-rating [(ngModel)]="args.rating"></ngx-star-rating>
                </div>

                <div class="padding">
                    <div style="display: table;margin: 0 auto;max-width: 600px; width: 100%;">
                        <mat-form-field appearance="outline">
                            <mat-label>Comments</mat-label>
                            <textarea rows="6" [(ngModel)]="args.comments" matInput></textarea>
                        </mat-form-field>
                    </div>
                    <button mat-button [disabled]="!canSave" large (click)="save()" mat-raised-button color="primary"
                        mobile-block>
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="summary && summary.contractorComments">
                <div class="comment" topmargin30>

                    <pre>{{summary.contractorComments}}</pre>
                </div>
                <div class="comment-name">
                    {{summary.contractorName}}</div>
            </div>
            <div topmargin40 *ngIf="images && images.length > 0">

                <h2 class="sub-heading">Photos</h2>

                <div>
                    <a *ngFor="let img of images" class="thumb" [href]="getFullUrl(img)" target="_blank">
                        <img [src]="getPictureUrl(img)">
                    </a>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Second">

            <div class="center">
                <app-check-circle-animated></app-check-circle-animated>
                <h1>Thank you</h1>
            </div>
        </mat-tab>
    </mat-tab-group>


</div>