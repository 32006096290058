<div class="container fancy-container">
    <h2 class="sub-heading" bottommargin20>My Property</h2>
    <div *ngIf="summary">
        <div flex>
            <div aflex>
                <div nogrow>
                    <img style="height: 75px;"
                        src="{{baseUrl}}address/{{summary.propertyId}}/StreetView?width=150&height=150" />
                </div>
                <div class="address">
                    <div>{{summary.propertyAddress1}}</div>
                    <div>{{summary.propertyAddress2}}</div>
                    <div>{{summary.propertyCity}}, {{summary.propertyState}} {{summary.propertyPostalCode}}</div>
                </div>
            </div>
            <div nogrow class="center" style="padding-top: 33px">

                <h2 class="sub-heading">{{summary.nextPaymentDate | date: 'shortDate'}}</h2>
                <div class="data-label">Next Payment</div>
            </div>
            <div nogrow class="center">
                <h1 class="big-data">{{totalMonthlyPayment | currency}}</h1>
                <div class="data-label">Monthly Payment</div>

            </div>
        </div>

        <a mat-button target="_blank" [href]="contractUrl"> 
            <span class="material-symbols-outlined">
                contract
                </span>
            Download Contract</a>

    </div>

    <mat-tab-group>
        <mat-tab label="My Services">
            <div class="plan-selection-outer-container">
                <div class="plan-selection-container">
                    <div *ngFor="let service of coveredItems" class="service-item plan">
                        <div class="flip-container" [class.flip-it]="service.flipped">
                            <div class="flipper">

                                <div class="front">
                                    <!-- <mat-icon class="checked" *ngIf="service.selected">check</mat-icon> -->
                                    <div class="lottie-container">
                                        <!-- <lottie-player style="height: 100px"  *ngIf="lottieReset && service.lottieAnimationUrl"
                                        [src]="service.lottieAnimationUrl"
                                        background="transparent" speed="1" intermission="5000" loop autoplay></lottie-player> -->
                                    </div>
                                    <div class="service-container">
                                        <div>

                                            <h2 class="category-name-h2" [style.color]="service.color">
                                                {{service.name}}</h2>
                                            <div class="data-label">
                                                Price</div>
                                            <ng-container *ngIf="service.isOneTime">
                                                <h2 class="price">${{service.price}}</h2>
                                                <div>One Time</div>
                                            </ng-container>
                                            <ng-container *ngIf="!service.isOneTime">
                                                <h2 class="price">${{service.pricePerMonth}}</h2>
                                                <div>Per Month</div>
                                            </ng-container>



                                            <br>
                                            <button mat-button mat-raised-button color="primary"
                                                [style.background-color]="service.color"
                                                class="plan-detail-btn small-primary"
                                                (click)="select(service)">Details</button>
                                            <!-- <br>
                                            {{service.frequencyName}} -->
                                        </div>

                                        <div>
                                            <div class="summary">
                                                <div *ngIf="!service.contractorName">
                                                    <div class="throbber"></div>
                                                    <div
                                                        style="display: inline-block;vertical-align: top; margin-left: 11px;">
                                                        Locating a service provider</div>

                                                </div>
                                                <div *ngIf="service.contractorName">
                                                    {{service.contractorName}}<br>

                                                    <img style="height: 30px" [src]="getContractorId(service)">
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="back">

                                    <button mat-button class="close-detail-button" (click)="service.flipped = false">
                                        <mat-icon>chevron_left</mat-icon><br> Back
                                    </button>

                                    <h2 [style.color]="service.color" [style.border-bottom-color]="service.color"
                                        style="border-bottom-style: solid;border-bottom-width: 1px;padding-bottom: 5px;margin-bottom: 15px">
                                        {{service.name}}</h2>
                                    <div style="text-align: left;" [innerHTML]="service.contractLanguage">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <a routerLink="/maintenance-service-add-service/{{id}}" class="add-btn" mat-button>
                        <mat-icon>add</mat-icon><br>
                        Add Service
                    </a>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Invoices">
            <div bottommargin30>
                <div class="data-label">Due Now</div>
                <h1 class="big-data">{{dueNow | currency}}</h1>

                <a mat-button mat-raised-button routerLink="/make-payment">Make Payment</a>
            </div>

            <a mat-button class="invoice" [href]="getInoviceUrl(invoice)" target="_blank"
                *ngFor="let invoice of invoices">

                <div aflex>
                    <div nogrow>

                        <mat-icon *ngIf="invoice.status === 'Past Due'">alarm</mat-icon>
                        <mat-icon *ngIf="invoice.status === 'Paid'">check_circle</mat-icon>
                        <mat-icon *ngIf="invoice.status === 'Pending'">hourglass_top</mat-icon>
                    </div>
                    <div>
                        <div><span class="data-label">Invoice #</span> {{invoice.invoiceNumber}}
                        </div>
                        <div><span class="data-label">Due Date</span> {{invoice.dueDate | date: 'shortDate'}}</div>
                        <div>
                            <span class="data-label">Amount</span> {{invoice.amount | currency}}
                        </div>
                        <div>
                            <span class="data-label">Amount Due</span> {{invoice.due | currency}}
                        </div>

                    </div>
                    <div nogrow class="center" style="max-width: 200px;white-space: normal;">
                        <div>
                            <div *ngFor="let item of invoice.items" class="service-item-img-container">
                                <img [src]="getServiceUrl(item)">
                            </div>
                        </div>
                        {{invoice.orderedServices}}
                    </div>

                </div>

            </a>
        </mat-tab>
        <mat-tab label="Calendar">


            <div class="center" topmargin20 bottommargin20>
                <h1 class="month-and-year">{{viewDate | date: 'MMMM yyyy'}}</h1>
                <button mat-button [matMenuTriggerFor]="menu" *ngIf="calendarUrl">
                    <mat-icon>notification_add</mat-icon>
                    Subscribe</button>
                <mat-menu #menu="matMenu">

                    <a mat-menu-item target="_blank" [href]="appleWebCalCalendarUrl">
                        <img src="../../../assets/images/apple-calendar.svg" style="height: 25px;margin-right: 16px;">
                        Import to Apple Calendar</a>
                    <a target="_blank" mat-menu-item [href]="googleWebCalCalendarUrl">
                        <img src="../../../assets/images/google-calendar.svg" style="height: 25px;margin-right: 16px">
                        Import to Google Calendar</a>
                    <button title="copy URL" mat-menu-item (click)="copyUrl()">
                        <mat-icon>content_copy</mat-icon> Copy URL
                    </button>
                </mat-menu>
            </div>
            <div aflex>
                <button mat-button nogrow (click)="backMonth()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <div class="center">
                    {{viewDate | date: 'MMM/yyyy'}}
                </div>
                <button mat-button nogrow (click)="forwardMonth()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
        
            <div class="calendar">
                <div class="day-header">Sun</div>
                <div class="day-header">Mon</div>
                <div class="day-header">Tue</div>
                <div class="day-header">Wed</div>
                <div class="day-header">Thu</div>
                <div class="day-header">Fri</div>
                <div class="day-header">Sat</div>
                <div *ngFor="let item of items">
                    
                    <div class="day-number" *ngIf="!item.previousMonth">{{item.dayNumber}}</div>
                    <div *ngFor="let hms of item.maintenanceServiceAppointments" >
                        <a class="appt" (click)="showAppointment(hms)" style="cursor: pointer;" >
                            {{hms.maintenanceServiceName}}
                        </a>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>


</div>

<div id="service-config-panel" *ngIf="selectedService">
    <button mat-button class="close-detail-button-2" (click)="selectedService = null">
        <mat-icon>close</mat-icon>
    </button>

    <div
        style="overflow-x: hidden;overflow-y: auto;height: calc(100vh - 79px);margin-top: -59px; padding-top: 20px;padding-right: 10px; padding-left: 5px">
        <div class="center">
            <div style="border-radius: 50%; height: 150px; width: 150px;overflow:hidden;display: inline-block;">
                <img *ngIf="!selectedService.lottieAnimationUrl" [src]="getOfferingUrl(selectedService)">
                <lottie-player style="height: 100px"  *ngIf="lottieReset && selectedService.lottieAnimationUrl"
                [src]="selectedService.lottieAnimationUrl"
                background="transparent" speed="1" intermission="5000" loop autoplay></lottie-player>
            </div>
            <h2 class="category-name-h2" [style.color]="selectedService.color" style="text-align: center;">
                {{selectedService.name}}</h2>
            <div style="margin-top: -20px; margin-bottom: 40px;" *ngIf="selectedService.contractorName">
                {{selectedService.contractorName}}<br>
                <img [src]="getContractorId(selectedService)" style="height: 50px">
            </div>
        </div>

        <button mat-button mat-raised-button (click)="cancelService()">
            <mat-icon>highlight_off</mat-icon>
            Cancel Service
        </button>

        <button *ngIf="selectedService.allowChangeFrequency" mat-button mat-raised-button (click)="changeFrequency()"
            style="margin-left: 20px">
            <mat-icon>edit</mat-icon> Change Frequency
        </button>

        <button *ngFor="let func of filteredFunctions" style="margin-left: 20px;margin-bottom: 20px; vertical-align: top;" mat-button mat-raised-button (click)="runCustomFunction(func)">
            <img *ngIf="func.imageUrl" style="height: 24px;vertical-align: middle;" [src]="func.imageUrl">
            <mat-icon *ngIf="func.iconClass">{{func.iconClass}}</mat-icon> {{func.name}}
        </button>

        <mat-tab-group>
            <mat-tab label="Appointments">
                <div nogrow class="appt-list">
                    <button mat-button *ngFor="let appointment of serviceAppointments"
                        (click)="showAppointment(appointment)">
                        <div aflex class="upcomming-job">
                            <div nogrow>

                                <!-- <h2>{{appointment.scheduledDate | date: 'd'}}</h2>
                                <div>{{appointment.scheduledDate | date: 'MMM'}}</div>
                                <div>{{appointment.scheduledDate | date: 'yyyy'}}</div> -->
                                <app-calendar-day-viewer [date]="appointment.scheduledDate">
                                </app-calendar-day-viewer>
                            </div>
                            <div>
                                <div>{{appointment.maintenanceServiceName}}</div>
                                <div>{{appointment.contractorName}}</div>
                                <div>{{appointment.scheduledDate | date: 'shortDate'}}</div>

                            </div>
                            <div nogrow *ngIf="appointment.completedDate">
                                <mat-icon>done</mat-icon>
                            </div>
                            <div nogrow *ngIf="appointment.cancellationDate">
                                <mat-icon>cancel</mat-icon>
                            </div>
                        </div>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Terms">
                <div aflex>
                    <div flex1>
                        <div class="data-label">Frequency</div>
                        {{selectedService.frequencyName}}
                    </div>
                    <div flex1>
                        <div class="data-label">Vists/Year</div>
                        {{selectedService.visitsPerYear}}
                    </div>
                </div>
                <div aflex topmargin20>
                    <div flex1>
                        <div class="data-label">Price/Month</div>
                        {{selectedService.pricePerMonth | currency}}
                    </div>
                    <div flex1>
                        <div class="data-label">Service Pro</div>
                        {{selectedService.contractorName}}
                    </div>
                </div>

                <h2 class="sub-heading" topmargin30>Contract Terms</h2>
                <div class="contract-t-c" [innerHTML]="selectedService.contractLanguage"></div>
            </mat-tab>
        </mat-tab-group>
        <!-- <pre>
            {{selectedService | json}}
        </pre> -->
    </div>
</div>