import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@cogent/client/api';
import { AppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { Router } from '@angular/router';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { PlanStyle, StripeCard } from '@upkeeplabs/models/cogent';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    selector: 'app-service-request-summary',
    templateUrl: './service-request-summary.component.html',
    styleUrls: ['./service-request-summary.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        
    ]
})
export class ServiceRequestSummaryComponent implements OnInit, OnChanges {


    @Input() policySummary: PolicySummary;
    @Input() serviceFeeOverride: number;
    // @Input() selectedItem: WorkOrderItem;
    @Input() selectedIndex: number;
    @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();
    @Input() totalSteps: number;
    @Input() selectedCard: StripeCard;
    @Input() selectedTimeSlots: AppointmentTimeSlot[];
    @Input() lineItems: WorkOrderLineSummaryClient[];

    planStyle: PlanStyle;

    constructor(private settingsApi: SettingsApiService,
        private sanitization: DomSanitizer,
        private api: ApiService,
        private router: Router,
        private dialogService: DialogsService) { }

    ngOnInit() {

    }

    getBackground() {
        if (!this.planStyle) {
            return null;
        }

        const planStyleObj = JSON.parse(this.planStyle.style);
        if (planStyleObj) {
            return this.sanitization.bypassSecurityTrustStyle(planStyleObj.cardBackBackgroundImage);
        }
        return null;
    }

    get pctComplete() {
        if (this.totalSteps === 0) {
            return 0;
        }
        return (this.selectedIndex / this.totalSteps) * 100;
    }

    get serviceFee() {
        if (this.serviceFeeOverride != null && this.serviceFeeOverride !== undefined) {
            return this.serviceFeeOverride;
        }
        if (!this.policySummary) {
            return null;
        }

        return this.policySummary.serviceFeeAmount;
    }

    get itemNames() {
        if(!this.lineItems) {
            return null;
        }

        return this.lineItems.map(i=>i.itemName).join(', ');
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.policySummary && changes.policySummary.currentValue) {
            this.planStyle = await this.settingsApi.getPlanStyle(changes.policySummary.currentValue.planId);
        }
        if (changes.selectedTimeSlots) {

        }
    }

    getItemUrl(lineItem: WorkOrderLineSummaryClient) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${lineItem.itemId}/photo`;
    }

    getLeftMargin(lineItem: WorkOrderLineSummaryClient) {
        return (this.lineItems.indexOf(lineItem) * 10) + 'px';
    }

    get propertyUrl() {
        if (!this.policySummary) {
            return null;
        }

        return `${ApiService.endPointDotNet}policy/${this.policySummary.id}/StreetView`;
    }

    goToPropertySelection() {
        this.dialogService.confirm('Confirm', 'Are you sure you want to select a different property?').subscribe(results => {
            if (results) {
                this.router.navigateByUrl('/select-property');
            }
        });
    }

    goToStep(index) {
        this.selectedIndex = index;
        this.selectedIndexChange.emit(index);
    }

    get cardImage() {
        if (!this.selectedCard) {
            return null;
        }
        if (this.selectedCard.brand === 'Visa') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png';
        }
        if (this.selectedCard.brand === 'MasterCard') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png';

        }
        if (this.selectedCard.brand === 'Discover') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png';

        }
        if (this.selectedCard.brand === 'American Express') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png';

        }
    }
}
