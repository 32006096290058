import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-renew-now-dialog',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule],
    templateUrl: './renew-now-dialog.component.html',
    styleUrls: ['./renew-now-dialog.component.css'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RenewNowDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<RenewNowDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

     }

    renew() {
        this.dialogRef.close(this.data);
    }

    dismiss() {
        this.dialogRef.close('dimiss');
    }
}
