<div class="container fancy-container">

    <!-- <h2 class="sub-heading">User Notifications</h2> -->

    <h1 class="page-title">
        <span class="big">Notification</span><br>
        <span class="little">Center</span>
    </h1>

    <div class="hide-mobile">

        <div aflex>
            <div nogrow class="notification-select-column">

                <button [class.was-read]="notification.wasRead" [class.selected]="notification === selectedNotification"
                    (click)="selectNotification(notification)" mat-button class="notification-select-btn"
                    *ngFor="let notification of userNotifications">

                    <div aflex class="notification-container">
                        <div nogrow>
                            <mat-icon *ngIf="!notification.wasRead">markunread</mat-icon>
                            <mat-icon *ngIf="notification.wasRead">mark_email_read</mat-icon>
                        </div>
                        <div>
                            <h3>{{notification.description}}</h3>
                            <p>{{notification.createdDate | date: 'short'}}</p>
                        </div>
                    </div>


                </button>
            </div>
            <div>
                <div *ngIf="selectedNotification" class="fade-in-left">
                    <p>
                        {{selectedNotification.description}}
                    </p>
                    <div class="center" topmargin40 *ngIf="selectedNotification.url">
                        <button mat-button mat-raised-button color="primary" large
                            [routerLink]="selectedNotification.url">Visit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="show-mobile">
        <div *ngFor="let notification of userNotifications">
            <button [class.was-read]="notification.wasRead" [class.selected]="notification.selected"
                (click)="selectNotification(notification)" mat-button class="notification-select-btn">

                <div aflex class="notification-container">
                    <div nogrow>
                        <mat-icon *ngIf="!notification.wasRead">markunread</mat-icon>
                        <mat-icon *ngIf="notification.wasRead">mark_email_read</mat-icon>
                    </div>
                    <div>
                        <h3>{{notification.description}}</h3>
                        <p>{{notification.createdDate | date: 'short'}}</p>
                    </div>
                </div>

            </button>
            <div class="notification-detail-panel" [class.expanded-detail]="notification.selected">
                <div style="padding: 20px;">
                    <p>{{notification.description}}</p>
                    <div class="center" topmargin40 *ngIf="notification.url">
                        <button mat-button mat-raised-button color="primary" large
                            [routerLink]="notification.url">Visit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>