import { Component, ViewChild, HostListener, ElementRef, AfterViewInit, OnInit, NgZone, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { AuthService } from '@cogent/client/auth';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AppointmentTimeSlot, GroupedAppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { QuestionWizardComponentV2 } from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { MatDialog } from '@angular/material/dialog';
import { UpsellCoverageComponent } from '@cogent/client/shared/components/sales/upsell-coverage/upsell-coverage/upsell-coverage.component';
import { DocumentUploadModalComponent } from '@cogent/client/apps/homeowner/general/document-upload-modal/document-upload-modal.component';
import { Claim, EntitySummary, GroupedProductOfferings, MaintenanceServiceCustomerPropertyServiceSummary, Note, PolicyServiceOffering, PrepaidServiceFee, StripeCard, Tag, WhiteGloveJobItem, WorkOrderPreferredTime } from '@upkeeplabs/models/cogent';
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { WorkOrderClient } from '@cogent/client/shared/models/service/work-order-client.model';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { SettingsComponent } from '@cogent/client/apps/homeowner/general/settings/settings.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { ContactSelectionComponent } from '@cogent/client/shared/components/service/contact-selection/contact-selection';
import { ServiceRequestSummaryComponent } from '@cogent/client/shared/components/service/service-request-summary/service-request-summary.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { JobItemSelectionComponent } from '@cogent/client/shared/components/service/job-item-selection/job-item-selection.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaymentMethodEntryComponent } from '../../accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { LottiePlayerComponent } from '../../visuals/lottie-player/lottie-player.component';
declare var Camera: any;


export class WorkOrderAttachmentModel {
    constructor(public name: string = null, public noteId: string = null, public base64: string = null) { }

    get thumbnailUrl() {
        return `${ApiService.endPointDotNet}note/attachment/${this.noteId}/${this.name}/thumbnail`;
    }

    get downloadUrl() {
        return `${ApiService.endPointDotNet}note/attachment/${this.noteId}/${this.name}`;
    }
}


@Component({
    templateUrl: './request-service.html',
    styleUrls: ['./request-service.css'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatInputModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        QuestionWizardComponentV2,
        PaymentMethodEntryModule,
        ContactSelectionComponent,
        ServiceRequestSummaryComponent,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        RouterModule,
        UpkeepPipesModule,
        AppointmentSelectionComponent,
        JobItemSelectionComponent,
        PaymentMethodEntryComponent,
        LottiePlayerComponent
    ]
})
export class RequestServiceComponent implements AfterViewInit, OnInit {
    workOrderItems: PolicyServiceOffering[];
    allWorkOrderItems: PolicyServiceOffering[];
    filteredItems: WorkOrderItemClient[];
    filter = '';
    selectedItem: PolicyServiceOffering;
    policySummary: PolicySummary;
    contractors: any;
    isReadonly = true;
    contact: EntitySummary = new EntitySummary();
    policyId: string;
    questionWizardComplete = false;
    workOrder: WorkOrderClient = new WorkOrderClient();
    selectedCard: StripeCard;
    downButtonVisible = false;
    coverage: any;
    tabIndex = 0;
    creditCardValid: boolean;
    submittingWorkOrder = false;
    acceptTermsAndConditions: boolean;
    attachments: WorkOrderAttachmentModel[] = [];
    timeSlots: GroupedAppointmentTimeSlot[];
    selectedTimeSlots: AppointmentTimeSlot[];
    planDetails: any;
    creditBalance: number;
    notActiveVisible = false;
    fromAddNewItem = false;
    newWorkOrderId: string;


    previousPolicyId: string = null;
    previousItemId: string = null;
    previousBrand: string = null;
    contactValid = false;
    selectedPrepaidServiceFee: PrepaidServiceFee;
    allPrepaidServiceFees: PrepaidServiceFee[];
    loading = false;
    customerPortalEnabled: boolean;
    serviceFeePaymentItemId: string;
    lineItems: WorkOrderLineSummaryClient[] = [];
    sendServiceFeeInvoice = false;

    steps = ['Item Selection', 'Questions', 'Photos', 'Service Fee', 'Contact Information', 'Appointment Selection', 'Review & Submit'];

    @ViewChild('questionWizard') questionWizard: QuestionWizardComponentV2;
    @ViewChild('wizardContainer') wizardContainer: ElementRef;
    @ViewChild('jobItemSelection') jobItemSelection: JobItemSelectionComponent;
    tags: Tag[];
    showFinishAnimation = false;

    existingWorkOrders: WorkOrderSummaryClient[];
    embedded = false;
    loggedInAgentId: string;
    serviceFeeMessages: any[];
    isLoggedIn = false;
    isAgent = false;
    paramSource: string;
    maintenanceServiceCustomerPropertyServiceSummaries: MaintenanceServiceCustomerPropertyServiceSummary[];
    maintOfferings: GroupedProductOfferings[];
    selectedOffering: GroupedProductOfferings
    defaultItemId: string;
    idempotencyKey: string = UtilitiesService.newid();
    isLegacy = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private serviceRepository: ServiceRepositoryService,
        private zone: NgZone,
        private missionService: MissionService,
        private dialogService: DialogsService,
        private customerRepository: CustomerRepositoryService,
        private maintApi: MaintenanceServiceApiService,
        private policyApi: PolicyApiService,
        private dialog: MatDialog,
        private functionApi: CustomFunctionApiService,
        private serviceApi: ServiceApiService,
        private authService: AuthService,
        private entityApi: EntityApiService,
    ) {
        const urlParams = new URLSearchParams(window.location.search);

        const embeddedParam = urlParams.get('embedded');


        this.loggedInAgentId = urlParams.get('entityId');
        if (embeddedParam === 'true') {
            try {
                document.getElementById('appHeader').style.display = 'none';
                document.getElementById('appHeader').style.height = '0px';

                document.getElementById('app-content').style.paddingTop = '3px';
            } catch { }
            this.embedded = true;
        }
    }


    async ngOnInit() {
        this.route.params.subscribe(params => {
            const id = params.id;
            this.policyId = id;
            this.loadPolicyInformation();
            this.loadCredits();
            this.loadUnusedPrepaidServiceFeesForPolicy();
        });
        this.missionService.publish({
            messageBody: '',
            type: 'chat-shift-up'
        });

        this.route.queryParams.subscribe(q => {
            if (q.itemId) {
                this.defaultItemId = q.itemId;
            }
        })


        const entity = await this.entityApi.getLoggedInUser();
        if (entity?.type === 'Agent') {
            this.loggedInAgentId = entity.id;
            this.isAgent = true;
        }

        this.customerPortalEnabled = await this.customerRepository.getCustomerPortalEnabled();
        const urlParams = new URLSearchParams(window.location.search);
        this.paramSource = urlParams.get('Source');


        this.workOrder.preferredContactMethod = 'Phone';
    }

    private async loadMaintServiceOfferings() {
        this.maintOfferings = await this.maintApi.getMaintenanceOfferingForAddressId(this.policySummary.propertyAddress.id);
    }

    get isAnonymous() {
        return this.authService.parsedJwt && this.authService.parsedJwt.roles === 'Anonymous';
    }

    addAdditionalItem(doAdd = true) {
        this.workOrderItems = null;
        if (!this.selectedItem) {
            this.selectedItem = this.allWorkOrderItems.find(i => i.id === this.lineItems[0].itemId);
        }
        let item = this.selectedItem;

        setTimeout(() => {
            this.workOrderItems = this.allWorkOrderItems.filter(i => i.tradeId === item.tradeId && !i.singleItemJobItem);
        });
        if (doAdd) {
            this.addWorkOrderLine();
        }
        this.tabIndex = 0;
    }

    ngAfterViewInit() { document.body.classList.add('haze'); }

    get baseUrl() {
        return ApiService.endPointDotNet;
    }

    clearSelectedItem() {
        this.tabIndex = 0;
        if (this.lineItems.length > 0) {
            this.lineItems.splice(this.lineItems.length - 1, 1);
        }

        if (this.lineItems.length === 0) {
            this.workOrderItems = this.allWorkOrderItems;
        }

        this.selectedItem = null;
    }

    backToQuestions() {
        this.tabIndex = 2;

        if (!this.fromAddNewItem) {
            const lastLineItem = this.lineItems[this.lineItems.length - 1];
            this.selectedItem = this.workOrderItems.find(i => i.id == lastLineItem.itemId);
            this.planDetails = this.selectedItem;
            this.lineItems.splice(this.lineItems.length - 1, 1);
        }
        this.fromAddNewItem = false;
    }

    get customerId() {
        if (!this.policySummary) {
            return null;
        }
        if (this.embedded || this.isAgent) {
            return this.loggedInAgentId;
        }
        // if (this.authService.parsedJwt && this.authService.parsedJwt.roles === 'Anonymous') {
        //     return null;
        // }

        return this.policySummary.holder.id;
    }

    get homeownerContactOk(): boolean { return true; }

    get canSubmitClaim(): boolean {
        if (!this.homeownerContactOk) {
            return false;
        }

        if (!this.acceptTermsAndConditions) {
            return false;
        }

        if (!this.lineItems || this.lineItems.length === 0) {
            return false;
        }


        return true;
    }

    get timeSlotsAreSelected() {
        return this.selectedTimeSlots && this.selectedTimeSlots.filter(i => i).length >= 3;
    }

    get appliedCreditAmount() {
        if (!this.policySummary) {
            return 0;
        }

        if (this.policySummary.serviceFeeAmount > this.creditBalance) {
            return this.creditBalance;
        }

        return this.policySummary.serviceFeeAmount;
    }

    get serviceFeeBalance() {
        if (this.isPotentialRecall) {
            return 0;
        }
        if (this.policySummary) {
            return this.policySummary.serviceFeeAmount - this.appliedCreditAmount;
        }
        return null;
    }

    get streetView() {
        if (!this.policySummary) {
            return null;
        }

        return `${ApiService.endPointDotNet}policy/${this.policySummary.id}/StreetView`;
    }

    get hasNativeCamera() {
        const nav: any = navigator;
        return nav && nav.camera;
    }

    @HostListener('window:hashchange')
    watchUrlHash() {

        this.zone.run(() => {

            if (this.tabIndex === 8) {
                return;
            }

            const hash = window.location.hash;

            if (hash === '#SelectItem') {
                if (!this.fromAddNewItem) {
                    this.clearSelectedItem();
                }
                this.fromAddNewItem = false;
                this.tabIndex = 0;
            } else if (hash === '#ExistingRequests') {
                this.tabIndex = 1;
            } else if (hash === '#Questions') {

                this.backToQuestions();
                this.tabIndex = 2;
            } else if (hash === '#Photos') {
                if (this.lineItems.length > 0) {
                    this.tabIndex = 3;
                }
            } else if (hash === '#ServiceFees') {
                if (this.lineItems.length > 0) {
                    this.tabIndex = 4;
                }
            } else if (hash === '#Contact') {
                if (this.lineItems.length > 0) {
                    this.tabIndex = 5;
                }
            } else if (hash === '#Appointment') {
                if (this.lineItems.length > 0) {
                    this.tabIndex = 6;
                }
            } else if (hash === '#Review') {
                if (this.lineItems.length > 0) {
                    this.tabIndex = 7;
                }
            } else {
                this.tabIndex = 0;
            }
        });
    }



    checkScroll() { this.setDownButtonVisibility(); }

    get lastStepIndex() {
        if ((!this.selectedItem || this.selectedItem.allowTimeSlotSelection) && !this.isWhiteGloveJob) {
            return 7;
        }

        return 6;
    }

    get totalSteps() {
        return this.lastStepIndex;
    }

    goToContactInformation() {
        if (this.selectedCard) {
            const expirationDate = UtilitiesService.monthEnd(new Date(this.selectedCard.exp_year, this.selectedCard.exp_month - 1, 1));
            if (expirationDate < new Date()) {
                this.dialogService.alert('Card Expired', 'Sorry, it appears as if your card has expired.');
                return;
            }
            this.tabIndex = 5;
        } else {
            this.tabIndex = 5;
        }
    }


    removePrepaidServiceFee() {
        this.selectedPrepaidServiceFee = null;
        this.loadCredits();
    }

    quesionWizardCompleteChange(isComplete: boolean) {
        if (isComplete) {

        }
    }

    private loadUnusedPrepaidServiceFeesForPolicy() {
        this.serviceApi.getUnusedPrepaidServiceFeesForPolicy(this.policyId).then(prePaidServiceFees => {

            if (prePaidServiceFees && prePaidServiceFees.length > 0) {
                this.selectedPrepaidServiceFee = prePaidServiceFees[0];
                this.allPrepaidServiceFees = prePaidServiceFees;
                this.creditBalance = 0;
            }
        });
    }

    setDownButtonVisibility() {
        if (this.tabIndex !== 7) {
            this.downButtonVisible = false;

            return;
        }

        if ((document.body.offsetHeight + window.scrollY) <
            (document.body.scrollHeight - 80)) {
            // you're at the bottom of the page
            this.downButtonVisible = true;
        } else {
            this.downButtonVisible = false;
        }
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.setDownButtonVisibility();
    }

    goToBottom() {
        window.scrollTo({
            top: document.body.scrollHeight - document.body.offsetHeight,
            behavior: 'smooth',
        });
        this.downButtonVisible = false;
    }

    callMe() {
        this.selectedTimeSlots = [];
        this.tabIndex = 7;
    }

    onStepChange() {
        setTimeout(() => this.setDownButtonVisibility(), 500);
        if (this.tabIndex === 0) {
            window.location.hash = 'SelectItem';
        } else if (this.tabIndex === 1) {
            window.location.hash = 'ExistingRequests';
        } else if (this.tabIndex === 2) {
            window.location.hash = 'Questions';
        } else if (this.tabIndex === 3) {
            window.location.hash = 'Photos';
        } else if (this.tabIndex === 4) {
            window.location.hash = 'ServiceFees';
        } else if (this.tabIndex === 5) {
            window.location.hash = 'Contact';
        } else if (this.tabIndex === 6) {
            window.location.hash = 'Appointment';
        } else if (this.tabIndex === 7) {
            window.location.hash = 'Review';
        } else if (this.tabIndex === 8) {
            this.showFinishAnimation = false;
            setTimeout(() => this.showFinishAnimation = true, 1000);
        }
        window.scrollTo(0, 0);
    }

    goForward() {

    }

    goBack() {
        if (this.tabIndex > 0) {
            this.tabIndex--;
            if (this.tabIndex === 1 && this.existingWorkOrders.length === 0) {
                this.tabIndex--;
            }
        }
    }

    async checkContactInfo() {
        const data = await this.entityApi.getFullEntity(this.policySummary.holderId)
        if (data.homeNumber.number !== this.contact.homeNumber || data.email !== this.contact.email) {
            this.dialog.open(SettingsComponent, {
                data: {
                    ...data,
                    homeNumber: {
                        number: this.contact.homeNumber
                    },
                    email: this.contact.email
                }
            }).afterClosed().subscribe(r => {
                this.goToTimeslotStep()
            })
        } else this.goToTimeslotStep()
    }

    goToTimeslotStep() {
        if (this.lineItems[0].item.allowTimeSlotSelection && !this.isWhiteGloveJob) {
            this.tabIndex = 6;
        } else {
            this.tabIndex = 7;
        }
    }

    replaceAll(target, search, replacement) {
        if (!target) {
            return;
        }
        return target.split(search).join(replacement);
    }

    getThumbnailUrl(workOrder: WorkOrderSummaryClient) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${workOrder.itemId}/photo`;
    }

    get firstItem() {
        if (this.lineItems && this.lineItems.length) {
            return this.lineItems[0];
        }
    }

    previousJobs: WhiteGloveJobItem[];
    showPreviousJobs = false;
    showWhiteGloveConfirmation = false;

    doWhiteGlove() {
        this.isWhiteGloveJob = true;
        this.tabIndex++;
    }

    get firstPreviousDate() {
        if (!this.previousJobs) {
            return null;
        }
        return this.previousJobs[0]?.createdDate;
    }

    get secondPreviousDate() {
        if (!this.previousJobs) {
            return null;
        }
        return this.previousJobs[1]?.createdDate;
    }

    async itemSelected(item: PolicyServiceOffering) {
        const inExcludedArea = await this.customerRepository.getJobItemInExcludedArea(item.id, this.policySummary.id);
        if (inExcludedArea) {
            this.dialogService.alert('Not Available', 'We\'re so sorry, this item isn\'t currently available in your area');
            return;
        }

        this.previousJobs = await this.serviceApi.getPreviousJobsByItem(this.policySummary.id, item.id);

        if (this.previousJobs.length > 1) {
            this.showPreviousJobs = true;
        }

        this.selectedItem = item;
        this.fromAddNewItem = true;
        if (item) {

            // const recentWorkOrders = await this.customerRepository.getRecentWorkOrdersForLoggedInUser();
            // const cutOffDate = new Date();
            // cutOffDate.setDate(cutOffDate.getDate() - 45);

            // this.existingWorkOrders = recentWorkOrders.filter(wo => wo.itemId === item.id
            //     && wo.propertyAddress === this.policySummary.propertyAddress.address1
            //     && wo.createdDate > cutOffDate);

            if (this.previousJobs.length > 1) {
                this.tabIndex = 1;
            }
            else {
                this.tabIndex = 2;
            }


            //this.workOrder.itemId = item.id;
            this.coverage = item;

            this.serviceRepository.getAppointmentTimeSlots(this.selectedItem.id).then(timeSlots => {
                const today = new Date();
                const timeToday = timeSlots.find(i => i.dt.getMonth() == today.getMonth() && i.dt.getDate() === today.getDate() && i.dt.getFullYear() === today.getFullYear());
                if (timeToday) {
                    const offset = UtilitiesService.getUTCOffsetMinutes(this.policySummary.propertyState);
                    const difference = new Date().getTimezoneOffset() + offset;
                    const localTime = new Date();
                    localTime.setMinutes(localTime.getMinutes() + difference);
                    if (localTime.getHours() >= 12) {
                        const todaysToRemove = timeSlots.filter(i => i.dt.getMonth() == today.getMonth() && i.dt.getDate() === today.getDate() && i.dt.getFullYear() === today.getFullYear());
                        for (const item of todaysToRemove) {
                            timeSlots.splice(timeSlots.indexOf(item), 1);
                        }
                    }
                }
                const groupedTimeSlots = GroupedAppointmentTimeSlot.fromAppointmentTimeSlots(timeSlots);
                this.timeSlots = groupedTimeSlots;
            });



            this.planDetails = item;
        }
    }

    questionsCompleted() {
        const item = this.selectedItem;
        setTimeout(() => this.workOrderItems = this.allWorkOrderItems.filter(i => i.tradeId === item.tradeId && !i.singleItemJobItem));

        this.addWorkOrderLine();
        this.continueAfterItemSelection();

    }

    get categoryName() {
        if (!this.lineItems || this.lineItems.length === 0) {
            return null;
        }

        return this.lineItems[0].item.category;
    }

    isPotentialRecall = false;

    goToServiceFees() {
        if (this.isWhiteGloveJob) {
            const sixtyDaysAgo = new Date();
            sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
            if (this.previousJobs[0]?.createdDate > sixtyDaysAgo && this.lineItems?.length === 1) {
                this.isPotentialRecall = true;
                this.tabIndex = 5;
                return;
            } else {
                this.isPotentialRecall = false;
            }
        }
        this.tabIndex++;
    }

    photosBack() {
        if (this.isWhiteGloveJob && this.isPotentialRecall) {
            this.tabIndex = 2;
        } else {
            this.tabIndex--;
        }
    }

    continueAfterItemSelection() {

        this.tabIndex = 3;
        const policyId = this.policySummary.id;

        this.serviceFeeMessages = [];
        const urlParams = new URLSearchParams(window.location.search);
        let source = urlParams.get('Source');
        if (!source) { source = "Portal" };
        this.serviceApi.getDynamicServiceFeeV2(policyId, this.lineItems, source).then(serviceFeePackage => {
            this.serviceFeeMessages = [];
            const serviceFee = serviceFeePackage.serviceFee;
            for (const item of serviceFeePackage.messages) {
                this.serviceFeeMessages.push(item);
            }
            // this.serviceFeeMessages = serviceFeePackage.messages[0];
            if (!isNaN(serviceFee)) {
                this.policySummary.serviceFeeAmount = serviceFee;
            }
        });


        this.updateContractorSelection();
    }


    addWorkOrderLine() {
        this.fromAddNewItem = true;
        const line = new WorkOrderLineSummaryClient();
        line.id = UtilitiesService.newid();
        line.itemId = this.selectedItem.id;
        line.item = this.selectedItem;
        let number = 64;
        for (const line of this.lineItems) {
            if (line.line && line.line.length > 0) {
                number = line.line.charCodeAt(0);
            }
        }
        line.line = String.fromCharCode(number + 1);
        line.qandA = this.questionWizard.questionsJson;
        line.planDetails = this.planDetails;
        line.itemName = this.selectedItem.name;
        line.brandId = this.questionWizard.getBrandId();

        this.lineItems.push(line);
        this.selectedItem = null;
        this.planDetails = null;
    }

    removeWorkOrderLine(line: WorkOrderLineSummaryClient, event) {
        line.deleting = true;
        event.stopPropagation();
        setTimeout(() => {
            line.deleting = false;
            const index = this.lineItems.indexOf(line);
            this.lineItems.splice(index, 1);
            if (this.lineItems.length === 0) {
                this.workOrderItems = this.allWorkOrderItems;
                this.tabIndex = 0;


            }
            this.missionService.showUndo(new UndoArguments(() => {
                this.lineItems.splice(index, 0, line);
                const foundItem = this.workOrderItems.find(i => i.id === line.itemId);
                this.workOrderItems = this.allWorkOrderItems.filter(i => i.tradeId === foundItem.tradeId && !i.singleItemJobItem);
            }, 'Item removed'));
        }, 400);

    }

    updateContractorSelection() {
        if (this.isWhiteGloveJob) {
            delete this.workOrder.contractorId;
            delete this.workOrder.sendViaEmail;
            delete this.workOrder.sendViaText;
            delete this.workOrder.sendEmail;
            delete this.workOrder.sendText;

            return;
        }
        let brand = null;
        if (this.questionWizard) {
            brand = this.questionWizard.getBrand();
        }


        this.previousBrand = brand;
        this.previousPolicyId = this.policyId;

        this.serviceApi.getContractorSelectionV2(this.policyId, this.lineItems)
            .then(contractorSelection => {
                contractorSelection = contractorSelection.filter(i => i.onHoldSort !== 1);
                if (contractorSelection.length > 0) {
                    this.workOrder.contractorId = contractorSelection[0].id;
                    this.workOrder.sendViaEmail =
                        contractorSelection[0].contactViaEmail;
                    this.workOrder.sendViaText =
                        contractorSelection[0].contactViaSMSText;
                    this.workOrder.sendEmail = contractorSelection[0].email;
                    this.workOrder.sendText = contractorSelection[0].mobileNumber ? contractorSelection[0].mobileNumber : contractorSelection[0].phoneNumber;
                } else {
                    this.workOrder.contractorId = null;
                }
            });
    }

    paymentValid(isValid) {
        this.creditCardValid = isValid;
        if (isValid) {
            this.sendServiceFeeInvoice = false;
        }
    }

    private async loadCredits() {
        this.creditBalance = await this.policyApi.getPolicyCreditAmount(this.policyId);
        if (this.creditBalance < 0) {
            this.creditBalance = 0;
        }
    }

    private loadPolicyInformation() {
        this.loading = true;
        this.serviceRepository.getPolicySummary(this.policyId)
            .then(async pc => {
                this.policySummary = pc;


                const effectiveDateCheck = new Date(this.policySummary.effectiveDate);
                if (effectiveDateCheck) {
                    effectiveDateCheck.setDate(effectiveDateCheck.getDate() + 30);
                }
                if (this.policySummary.upgradeSuggestionPlanId && this.policySummary.effectiveDate &&
                    effectiveDateCheck > new Date()
                    && this.policySummary.coverageType === 'RealEstate' && !this.policySummary.isMonthly) {
                    this.showUpgrade();
                }

                if (this.policySummary.holder && this.contact) {
                    this.contact.name = this.policySummary.holder.name;
                    this.contact.email = this.policySummary.holder.email;
                }
                if (pc.status === 'Active' || pc.status === 'Pending') {
                    this.serviceRepository.getPolicyWorkOrderItems(this.policyId)
                        .then(workOrderItems => {
                            this.workOrderItems = workOrderItems.filter(i => !i.notCovered);
                            this.allWorkOrderItems = workOrderItems.filter(i => !i.notCovered);
                            this.loading = false;
                            if (pc.status === 'Pending') {
                                this.workOrderItems = this.workOrderItems.filter(i => i.allowServiceBeforeActive);
                                this.allWorkOrderItems = this.workOrderItems.filter(i => i.allowServiceBeforeActive);
                            }
                            if (this.defaultItemId) {
                                const item = this.workOrderItems.find(i => i.id === this.defaultItemId);
                                if (item) {
                                    this.itemSelected(item);
                                }
                            }
                        });
                    this.loadDocumentsDialog();
                } else {
                    this.loading = false;
                    this.notActiveVisible = true;
                }



                await this.loadMaintServiceOfferings();
                this.entityApi.getLoggedInUser().then(user => {

                    if (user?.loginId) {
                        this.maintApi.getMaintServiceSummaries(user.loginId).then(properties => {
                            const prop = properties.find(i => i.propertyId === this.policySummary.propertyAddress.id);
                            if (prop) {
                                this.maintApi.getMaintServiceCustomerPropertyServiceSummariesForProperty(prop.id).then(async summaries => {
                                    this.maintenanceServiceCustomerPropertyServiceSummaries = summaries.filter(i => !i.cancellationDate);
                                    for (const prop of this.maintenanceServiceCustomerPropertyServiceSummaries) {
                                        prop.addOns = await this.maintApi.getAddOnsForOffering(prop.maintenanceServiceOfferingId);
                                        const service = this.maintOfferings?.find(i => i.maintenanceServiceId === prop.maintenanceServiceId);
                                        if (service) {

                                            this.maintOfferings.splice(this.maintOfferings.indexOf(service), 1);
                                        }
                                    }
                                });
                            } else {
                                this.maintenanceServiceCustomerPropertyServiceSummaries = [];
                            }
                        });
                    }
                    else {
                        this.maintApi.getMaintServiceSummariesByCustomerId(this.policySummary.holderId).then(properties => {
                            const prop = properties.find(i => i.propertyId === this.policySummary.propertyAddress.id);
                            if (prop) {
                                this.maintApi.getMaintServiceCustomerPropertyServiceSummariesForProperty(prop.id).then(async summaries => {
                                    this.maintenanceServiceCustomerPropertyServiceSummaries = summaries.filter(i => !i.cancellationDate);
                                    for (const prop of this.maintenanceServiceCustomerPropertyServiceSummaries) {
                                        prop.addOns = await this.maintApi.getAddOnsForOffering(prop.maintenanceServiceOfferingId);

                                        const service = this.maintOfferings?.find(i => i.maintenanceServiceId === prop.maintenanceServiceId);
                                        if (service) {

                                            this.maintOfferings.splice(this.maintOfferings.indexOf(service), 1);
                                        }
                                    }
                                });
                            } else {
                                this.maintenanceServiceCustomerPropertyServiceSummaries = [];
                            }
                        });
                    }
                });



            });

        this.policyApi.getPolicyTags(this.policyId).then(tags => {
            this.tags = tags;
        });


    }

    async customMaintenanceServiceRequest(maintService: MaintenanceServiceCustomerPropertyServiceSummary) {
        const func = await this.functionApi.getCustomFunctionById(maintService.addOnFunctionId);

        this.missionService.raiseExecuteFunction({
            functionJson: func.functionJson,
            objectInScope: maintService,
            description: func.description,
            iconClass: func.iconClass,
        });
    }

    addOnInvoked(value: any) {
        this.missionService.raiseExecuteFunction({
            functionJson: value.addOn.processDefinition,
            objectInScope: value,
            useFunctionBackdrop: true,
        });
    }

    addMaintServiceClicked() {
        this.entityApi.getLoggedInUser().then(user => {
            if (user?.loginId) {
                this.maintApi.getMaintServiceSummaries(user.loginId).then(properties => {
                    const prop = properties.find(i => i.propertyId === this.policySummary.propertyAddress.id);
                    if (prop) {
                        this.router.navigateByUrl(`/maintenance-service-add-service/${prop.id}/${this.jobItemSelection.selectedMaintOffering?.maintenanceServiceId}`);
                    }
                    else {
                        this.router.navigateByUrl(`/maintenance-service-new-order/${this.policySummary.id}/${this.jobItemSelection.selectedMaintOffering?.maintenanceServiceId}`);
                    }
                });
            } else {
                this.maintApi.getMaintServiceSummariesByCustomerId(this.policySummary.holderId).then(properties => {
                    const prop = properties.find(i => i.propertyId === this.policySummary.propertyAddress.id);
                    if (prop) {
                        this.router.navigateByUrl(`/maintenance-service-add-service/${prop.id}`);
                    }
                    else {
                        this.router.navigateByUrl(`/maintenance-service-new-order/${this.policySummary.id}`);
                    }
                });
            }
        });

    }

    async loadDocumentsDialog() {
        if (this.policySummary.coverageType === 'RealEstate') {
            const dt = new Date();
            dt.setDate(dt.getDate() - 60);

            if (this.policySummary.effectiveDate > dt) {
                const hasHomeInspection = await this.policyApi.hasDocument(this.policyId, 'Home_Inspection');
                const hasSellersDisclosures = await this.policyApi.hasDocument(this.policyId, 'Sellers_Disclosures');
                if (!hasHomeInspection && !hasSellersDisclosures) {
                    this.dialog.open(DocumentUploadModalComponent, { data: this.policySummary });
                }
            }
        }
    }

    showFromLibrary() {
        const nav: any = navigator;
        nav.camera.getPicture(
            imageData => {

                const fileName =
                    UtilitiesService.newid().replace('-', '').substring(0, 9) +
                    '.jpg';
                const attachment = new WorkOrderAttachmentModel(
                    fileName,
                    UtilitiesService.newid(),
                    'data:image/jpeg;base64,' + imageData);
                this.attachments.push(attachment);

            },
            error => {
                if (error !== 'No Image Selected') {
                    this.dialogService.alert('Can\'t Attach', 'Sorry, we could not attach that photo');
                }
            }, {
            quality: 30,
            destinationType: Camera.DestinationType.DATA_URL,
            correctOrientation: true,
            sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
            mediaType: Camera.MediaType.PICTURE,
        });
    }

    showFileUpload() {
        const nav: any = navigator;

        if (nav && nav.camera) {
            nav.camera.getPicture(
                imageData => {

                    const fileName =
                        UtilitiesService.newid().replace('-', '').substring(0, 9) +
                        '.jpg';
                    const attachment = new WorkOrderAttachmentModel(
                        fileName,
                        UtilitiesService.newid(),
                        'data:image/jpeg;base64,' + imageData);
                    this.attachments.push(attachment);

                },
                error => {
                    if (error !== 'No Image Selected') {
                        this.dialogService.alert('Can\'t Attach', 'Sorry, we could not attach that photo');
                    }
                }, {
                quality: 30,
                destinationType: Camera.DestinationType.DATA_URL,
                correctOrientation: true,
                mediaType: Camera.MediaType.CAMERA,
                encodingType: Camera.EncodingType.JPEG
            });
        } else {
            document.getElementById('fileToUpload1').click();
        }
    }

    showUpgrade() {
        const ref = this.dialog.open(UpsellCoverageComponent, { data: this.policySummary });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.loadPolicyInformation();
                this.loadCredits();
                this.loadUnusedPrepaidServiceFeesForPolicy();
            }
        });
    }

    handleFileUpload(evt) { this.handleFiles(evt); }

    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            const loadIt = async (base64, fileName) => {
                base64 = await UtilitiesService.resizeImage(base64, 800, 800);

                const attachment = new WorkOrderAttachmentModel(fileName, null, base64);
                this.attachments.push(attachment);
            };

            reader.onload = (fileName => {
                return function (e) {
                    // this.dragTargetActive = false;
                    loadIt(e.target.result, fileName);

                };
            })(file.name);

            reader.readAsDataURL(file);
        }
    }

    doPayAfterService() {
        this.tabIndex++;
        this.sendServiceFeeInvoice = true;
    }

    get isSellersCoverage() {
        return this.policySummary?.coverageType === 'Sellers';
    }


    removeAttachment(attachment) {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
    }


    async submitWorkOrder() {
        this.submittingWorkOrder = true;

        const claim = new Claim();

        claim.id = UtilitiesService.newid();
        claim.policyId = this.policyId;
        claim.status = 'Active';
        claim.serviceFee = this.policySummary.serviceFeeAmount;
        claim.invoiceForServiceFee = this.sendServiceFeeInvoice;
        if (this.isPotentialRecall) {
            claim.serviceFeeWaiveReason = 'POSSIBLE_RECALL';
        }

        const urlParams = new URLSearchParams(window.location.search);
        let source = urlParams.get('Source');
        if (!source) { source = "Portal" };

        claim.source = source;
        claim.lines = this.lineItems

        if (this.selectedPrepaidServiceFee) {
            claim.prepaidServiceFeeId = this.selectedPrepaidServiceFee.id;
        }
        if (this.selectedCard && !this.selectedPrepaidServiceFee) {
            claim.stripeCardId = this.selectedCard.id;
            if (this.embedded) {
                claim.stripeCustomerId = this.customerId;
            }
        }

        if (!this.selectedPrepaidServiceFee) {
            if (this.creditBalance > 0) {
                if (this.creditBalance > claim.serviceFee) {
                    claim.serviceFee = 0;
                } else {
                    claim.serviceFee = claim.serviceFee - this.creditBalance;
                }
            }

            if (claim.serviceFee > 0 && !this.serviceFeePaymentItemId && !this.isPotentialRecall) {
                if (!this.sendServiceFeeInvoice) {
                    const billingCustomerId = this.embedded ? this.customerId : null;
                    const result = await this.serviceApi.takeServiceFeePayment(this.policySummary.id,
                        claim.serviceFee,
                        claim.stripeCardId,
                        this.policySummary.holderId,
                        billingCustomerId,
                        this.selectedCard.saveCardForLater,
                        this.idempotencyKey
                    );

                    claim.stripeCardId = null;
                    if (!result.success) {
                        this.dialogService.alert('Payment Declined', `Sorry, the payment did not go through<br>Message: ${result.message}`);
                        this.submittingWorkOrder = false;
                        return;
                    } else {
                        if (result.paymentItems && result.paymentItems.length && result.paymentItems.length > 0) {
                            claim.serviceFeePaymentItemId = result.paymentItems[0].id;
                        }
                    }
                }
            } else if (claim.serviceFee > 0 && this.serviceFeePaymentItemId) {
                claim.serviceFeePaymentItemId = this.serviceFeePaymentItemId;
            }
        }




        try {
            const lineItems = JSON.parse(JSON.stringify(this.lineItems));
            const copy: any = {};
            copy.lineItems = lineItems;
            for (const item of lineItems) {
                delete item.item;
                delete item.planDetails;
            }
            copy.contact = this.contact;
            copy.preferredTimes = this.selectedTimeSlots;
            copy.source = source;
            claim.workorderJSON = JSON.stringify(copy);
        } catch {
            console.error('error logging');
        }

        this.workOrder.claimId = claim.id;
        // this.workOrder.status = 'Active';
        this.workOrder.type = '1st Call';
        this.workOrder.contactEmail = this.contact.email;
        this.workOrder.contactName = this.contact.name;
        this.workOrder.contactPhone = this.contact.homeNumber;
        this.workOrder.contactMobile = this.contact.mobileNumber;
        this.workOrder.sendAsync = true;

        try {
            const urlParams = new URLSearchParams(window.location.search);
            let source = urlParams.get('Source');
            if (!source) { source = "Portal" };
        } catch {
            source = 'Portal';
        }

        this.workOrder.applicationSource = source;
        this.workOrder.whiteGlove = this.isWhiteGloveJob;

        this.workOrder.workOrderLines = this.lineItems;
        delete this.workOrder.itemId;

        if (!this.workOrder.id) {
            this.workOrder.id = UtilitiesService.newid();
            this.newWorkOrderId = this.workOrder.id;
        }
        try {
            if (this.selectedTimeSlots) {
                this.workOrder.preferredTimes = [];
                this.selectedTimeSlots.forEach(i => {
                    if (i) {
                        const preferredTime = new WorkOrderPreferredTime();
                        preferredTime.workOrderId = this.workOrder.id;
                        preferredTime.date = i.date;
                        preferredTime.startTime = i.startTime;
                        preferredTime.endTime = i.endTime;

                        // fix for timezone issue
                        preferredTime.date.setHours(preferredTime.date.getHours() + 3);
                        this.workOrder.preferredTimes.push(preferredTime);
                    }
                });
            }
        } catch { }

        claim.workOrder = this.workOrder as any;
        claim.itemName = this.lineItems[0].itemName;
        this.serviceRepository.saveClaim(claim, (errors => {
            this.dialogService.alert('Not Saved', `Sorry, we were not able to complete the request:<br> Message: ${errors[0].message} `);
        }) as any).then(dbClaimId => {

            if (!dbClaimId) {
                this.submittingWorkOrder = false;
                return;
            }

            // if (this.isWhiteGloveJob) {
            //     this.addWhiteGloveTag();
            // }

            if (this.attachments && this.attachments.length > 0) {
                const note = new Note();
                note.policyId = this.policyId;
                note.workOrderId = this.workOrder.id;
                note.noteText = 'Customer Pictures Attached';

                this.serviceRepository.saveAuthorizationAttachments(note, this.attachments).then(() => { });
            }

            // this.serviceRepository.saveWorkOrder(this.workOrder)
            //     .then(async woId => {

            //         this.newWorkOrderId = woId;
            //     });
            this.entityApi.getLoggedInUser().then(user => {
                if (user) {
                    this.isLoggedIn = true;
                }
                this.tabIndex = 8;
            });


        });
    }

    isWhiteGloveJob = false;

    // addWhiteGloveTag() {
    //     //ADD WHITE_GLOVE_JOB TAG
    //     this.serviceApi.getWorkOrderTag('f803bcbf-43fb-4e3f-b85c-2d1ae579e020').then((tag) => {
    //         this.serviceApi.addWorkOrderTags(this.workOrder.id, [tag]).then(() => { });
    //     });
    // }

    // async whiteGloveCheck() {
    //     this.isWhiteGloveJob = false;
    //     for (const line of this.lineItems) {
    //         const jobs: WhiteGloveJobItem[] = await this.serviceApi.getPreviousJobsByItem(this.policySummary.id, line.itemId);

    //         if (jobs && jobs.length >= 2) {
    //             this.isWhiteGloveJob = true;
    //         }
    //     }
    // }

}
