import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '@cogent/client/apps/homeowner/security/change-password/change-password.component';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Entity } from '@upkeeplabs/models/cogent';
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { BasicEntityEntryModule } from "@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        BasicEntityEntryModule,
        MatButtonModule,
    ]
})
export class SettingsComponent implements OnInit {


    currentEntity: Entity;
    saving = false;
    isLegacy = true;

    constructor(private entityApi: EntityApiService,
        private missionService: MissionService,
        private dialog: MatDialog,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: Entity) { }

    ngOnInit() {
        if (!this.data) {
            this.entityApi.getLoggedInUser(true).then(user => {
                this.currentEntity = user;
            });
        } else {
            this.currentEntity = this.data
        }
    }

    async save() {
        if (this.data) {
            const homeNumber = this.currentEntity.phoneNumbers.find(phoneNumber => phoneNumber.type == 'Home')
            homeNumber.number = this.data.homeNumber.number
        }
        this.saving = true;
        await this.entityApi.updateEntity(this.currentEntity, false);
        this.saving = false;
        this.missionService.showSuccessToast('Your savings have been saved');
        if (this.data) this.dialog.closeAll()
    }




    showChangePassword() {
        const ref = this.dialog.open(ChangePasswordComponent);
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.missionService.showSuccessToast('Your password has been changed');
            }
        });
    }

}
