import { PolicyAdjustment, PolicyOption } from "@upkeeplabs/models/cogent";


export class UpdatePolicyCoverageArgs {
    policyId: string;
    planId: string;
    isMonthly: boolean;
    coverageType: string;
    options: PolicyOption[];
    adjustments: PolicyAdjustment[];
    planBasePrice: number;
    planBasePriceRecurring: number;
    totalPrice: number;
    totalPriceRecurring: number;
    monthlyProcessingFee: number;
    upgradePaymentAmount: number;
    stripeCardId: string;
    stripeCustomerId: string;
}