import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { PolicyServiceOffering } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-view-coverage',
    templateUrl: './view-coverage.component.html',
    styleUrls: ['./view-coverage.component.css'],
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule]
})
export class ViewCoverageComponent implements OnInit {

    contractLanguage: PolicyServiceOffering;
    planDetail: any;
    loading = false;
    isLegacy = true;

    constructor(
        private dataAccess: DataApiService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.loading = true;
            this.dataAccess.getPlanItemDetail(params.id).then(detail => {
                this.planDetail = detail;
                this.loading = false;
            });
        });
    }

}
