import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-delete-account',
  standalone: true,
  imports: [CommonModule, MaterialSharedModule],
  templateUrl: './request-delete-account.component.html',
  styleUrls: ['./request-delete-account.component.css']
})
export class RequestDeleteAccountComponent implements OnInit {

    userName: string;
    doDelete = false;
    deleting = false

    constructor(private api: ApiService,
        private missionService: MissionService,
        private router: Router,
        private entityApi: EntityApiService) {
    
    }

    ngOnInit(): void {
        
        
    }

    async deleteAccount() {
        this.deleting = true;
        await this.entityApi.deleteLogin();
        this.router.navigateByUrl(`/auth/log-out`);
        this.missionService.showSuccessToast('Account Removed');
        this.deleting = false;
    }
}
