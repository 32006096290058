
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { AddressApiService } from '@cogent/client/shared/services/api/address-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import {
    Address,
    Entity,
    GroupedProductOfferings,
    MaintenanceServiceAddServiceArgs,
    MaintenanceServiceFilter,
    MaintenanceServiceFilterOption,
    MaintenanceServiceOffering,
    MaintenanceServiceOfferingSummaryWithPostalCode,
    MaintenanceServiceOrderArgs,
    MarketingSource,
    PlanPricingAdjustment,
    PlanPricingAdjustmentItem,
    PromotionCode,
    StripeCard, WebQuote
} from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { CommonModule } from '@angular/common';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { CircleWidgetSmallModule } from '@cogent/client/shared/components/data-visualization/circle-widget-small/circle-widget-small.module';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { AddressStreetViewComponent } from '@cogent/client/shared/components/misc/address-street-view/address-street-view.component';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { SingleAddressEntryComponent } from '@cogent/client/shared/components/misc/single-address-entry/single-address-entry.component';
import { AuthService } from '@cogent/client/auth';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MaintServicesContractLanguageComponent } from '@cogent/client/shared/components/maintenance-service/maint-services-contract-language/maint-services-contract-language.component';
import { ShortNumberPipe } from '@cogent/client/shared/pipes/short-number.pipe';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MapMeasure } from "@cogent/client/shared/components/misc/map-measure/map-measure.component";
import { PaymentMethodEntryComponent } from '../../accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { MatRadioModule } from "@angular/material/radio";
import { MarketingSourceApi } from "@cogent/client/shared/services/api/marketing-source-api.service";
import { NgxMaskDirective } from "ngx-mask";

class OfferingSchedule {
    offering: MaintenanceServiceOfferingSummaryWithPostalCode;
    group: GroupedProductOfferings;
    editMode = false;
    adjustment: PlanPricingAdjustment;
    adjustmentItems: PlanPricingAdjustmentItem[];

    get pricePerVisit() {
        return this.offering.price;
    }
    get frequencyType() {
        return this.offering.frequencyType;
    }

    get maintenanceServiceName() {
        return this.offering.maintenanceServiceName;
    }

    get visitsPerYear() {
        return this.offering.visitsPerYear;
    }

    get calculatedPrice() {
        return this.offering.caluclatedPrice;
    }

    get monthlyPrice() {
        if (this.offering.isOneTime) {
            return this.offering.caluclatedPrice;
        }

        return (this.visitsPerYear * this.offering.caluclatedPrice) / 12;
    }

    get firstMonthPrice() {
        if (this.offering.isOneTime) {
            return this.offering.caluclatedPrice;
        }

        return this.offering.initialVisitPrice ? this.offering.initialVisitPrice : this.monthlyPrice;

    }
}

@Component({
    selector: 'app-order-maintenance-service',
    standalone: true,
    imports: [CommonModule,
        MatSliderModule,
        MatInputModule,
        MatFormFieldModule,
        MatTabsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        CircleWidgetSmallModule,
        AddressEditorComponent,
        DatePickerWrapperComponent,
        BasicEntityEntryModule,
        AddressStreetViewComponent,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        ShortNumberPipe,
        RouterModule,
        MaintServicesContractLanguageComponent,
        SingleAddressEntryComponent,
        MatDialogModule,
        UpkeepPipesModule,
        DisplayTotalModule, MapMeasure, MatRadioModule, NgxMaskDirective],
    templateUrl: './order-maintenance-service.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    styleUrls: ['./order-maintenance-service.component.css']
})
export class OrderMaintenanceServiceComponent implements OnInit, OnChanges {

    selectedMaintenanceServiceId = "";
    offeringSchedule: OfferingSchedule[];
    selectedIndex = 0;
    addressComplete = false;
    @Input() address: Address = new Address();
    @Input() source: string = 'Portal';
    @Input() quoteId: string;
    @Input() quoteQueryParams: string;
    quoteEmail: string;
    quotePhone: string;
    quoteName: string;
    quoteSent = false;
    sendingQuote = false;
    offerings: GroupedProductOfferings[];
    selectedOfferings: GroupedProductOfferings[];
    onePanelCount = 0;
    twoPanelCount = 0;
    fourPanelCount = 0;
    sixPanelCount = 0;
    editSquareFootage = false;
    entityCanSave = false;
    selectedCard: StripeCard;
    creditCardValid = false;
    savingCustomer = false;
    showDetails = false;
    loadingServices = false;
    editSecondaryUnit = false;
    lottieReset = true;
    doWindowCleaning = false;
    doLawnMower = false;
    doPool = false;
    doVacuum = false;
    showBackdrop = false;
    mailingSameAsProperty = true;
    password: string;
    confirmPassword: string;
    passwordsMatch = true;
    showLastScene = false;
    showLastSceneLastText = false;
    addMode = false;
    customer: Entity = new Entity();
    maintenanceServiceCustomerPropertyId: string;
    orderFromPortal = false;
    myPolicies: PolicySummary[];
    existingProperites: Address[];
    showNewPropertyEntry = false;
    selectedProperty: Address;
    cardSelected = false;
    addressUpdated = false;
    creditBalance: number = 0;
    propertyId: string;
    skipCustomer = false;
    @Input() isSubComponent: boolean;
    @Output() goBackParent: EventEmitter<boolean> = new EventEmitter();
    adjustment: PlanPricingAdjustment;
    adjustmentItems: PlanPricingAdjustmentItem[];
    showOtherBathroom = false;
    showOtherBedroom = false;
    lawnEdited = false;
    bathroomCounts = [
        1,
        2,
        2.5,
        3,
        3.5,
        4,
        4.5
    ];
    bedroomCounts = [
        1, 2, 3, 4, 5, 6, 7
    ];
    startDate = new Date();


    selectedMarketingSource: MarketingSource;
    marketingSources: MarketingSource[];
    utmSource: string;


    lotSQFTRanges = [
        { start: 0, end: 7999, name: 'Small', height: '32px' },
        { start: 8000, end: 11999, name: 'Medium', height: '42px' },
        { start: 12000, end: 15999, name: 'Large', height: '52px' },
        { start: 16000, end: 25000, name: 'X-Large', height: '62px' }
    ];
    isLegacy = true;

    updateSQFT(value: number) {
        if (value) {
            this.address.lawnSquareFootage = value;
            this.editSquareFootage = false;
            this.lawnEdited = true;
            for (const offering of this.selectedOfferings) {
                if (offering.maintenanceServiceId === 'f401dcec-0547-45c4-af6e-8bc792bf1e64') {
                    offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= this.address.lawnSquareFootage && i.rangeEnd >= this.address.lawnSquareFootage);
                    if (offering.filteredOfferings.length === 1) {
                        this.selectOffering(offering, offering.filteredOfferings[0]);
                    }
                }
            }
        }
    }


    constructor(private maintApi: MaintenanceServiceApiService,
                private activatedRoute: ActivatedRoute,
                protected router: Router,
                private entityApi: EntityApiService,
                protected authService: AuthService,
                private dialogService: DialogsService,
                private missionService: MissionService,
                private policyApi: PolicyApiService,
                private dialog: MatDialog,
                private addressApi: AddressApiService,
                private customerService: CustomerRepositoryService,
                private serviceApi: ServiceApiService,
                private marketingSourceApi: MarketingSourceApi) { }



    async ngOnInit() {
        const addr: any = new Address();
        addr.id = UtilitiesService.newid();
        this.customer.address = addr;
        this.customer.type = 'Customer';
        this.customer.id = UtilitiesService.newid();
        this.customer.contactViaEmail = false;
        this.customer.contactViaSMSText = false;
        this.customer.paperless = true;
        this.customer.inactive = false;
        document.location.hash = '0';

        if (this.router.url.includes('sales')) {
            this.marketingSources = await this.marketingSourceApi.getMarketingSources('internalHMS')
        } else {
            this.marketingSources = await this.marketingSourceApi.getMarketingSources('customerPortalHMS')
        }

        this.utmSource = this.quoteQueryParams ? JSON.parse(this.quoteQueryParams)?.utm_source : null;
        if (this.utmSource) {
            this.selectedMarketingSource = this.marketingSources?.find(source => source.name.toLowerCase() == this.utmSource.toLowerCase())
        }

        this.entityApi.getLoggedInUser(true).then(user => {
            if (user && user.type !== "Employee") {

                this.customer = user;
                this.orderFromPortal = true;
                this.loadProperties();
                this.loadCreditBalance(this.customer.id);
                this.source = 'Cogent';
            }
            if (!user && this.authService.parsedJwt?.sub) {
                this.customer.id = this.authService.parsedJwt.sub;
            }
        });

        this.activatedRoute.url.subscribe(url => {
            const seg = url.find(i => i.path === 'portal-order');
            if (seg && !this.existingProperites) {
              this.loadProperties();
            }
        });
        this.activatedRoute.queryParams.subscribe(async params=> {
            if(params.quoteId) {
                this.quoteId = params.quoteId;
                await this.loadQuote();
            }
        });

        this.activatedRoute.params.subscribe(async params => {
            this.selectedMaintenanceServiceId = params.maintenanceServiceId;
            if (params.id) {
                this.addMode = true;
                this.orderFromPortal = false;
                const summary = await this.maintApi.getMaintServiceSummaryById(params.id);
                this.address = await this.maintApi.getAddress(summary.propertyId);
                const meta = await this.maintApi.getPropertyMeta(this.address.address1, this.address.city, this.address.state, this.address.postalCode);
                if (meta) {
                    if (meta.bathroomCount) {
                        this.address.bathroomCount = meta.bathroomCount;
                    }
                    if (meta.bedroomCount) {
                        this.address.bedroomCount = meta.bedroomCount;
                    }
                }
                await this.loadProductOfferings(false);
                const currentServices = await this.maintApi.getMaintServiceCustomerPropertyServiceSummariesForProperty(params.id);
                const ids = currentServices.filter(i => !i.cancellationDate && !i.isOneTime).map(i => i.maintenanceServiceId);
                this.offerings = this.offerings.filter(i => ids.indexOf(i.maintenanceServiceId) === -1);
                this.selectedIndex = 0;
                this.maintenanceServiceCustomerPropertyId = params.id;
            }
            if (params.propId) {
                this.propertyId = params.propId;
                this.address = await this.maintApi.getAddress(this.propertyId);
                this.selectedIndex = 1;
                await this.loadProductOfferings(false);
            }
            if (params.policyId) {
                const ps = await this.policyApi.getPolicySummary(params.policyId);
                const maintenanceServiceProperties = await this.maintApi.getMaintServiceCustomerPropertyByAddressId(ps.propertyAddress.id);
                if (maintenanceServiceProperties?.length > 0) {
                    this.maintenanceServiceCustomerPropertyId = maintenanceServiceProperties[0].id;
                }
                this.address = ps.propertyAddress;
                this.customer = ps.holder;
                this.selectedProperty = ps.propertyAddress;
                this.selectedIndex = 1;
                await this.loadProductOfferings(false);
                if (this.maintenanceServiceCustomerPropertyId) {
                    const currentServices = await this.maintApi.getMaintServiceCustomerPropertyServiceSummariesForProperty(this.maintenanceServiceCustomerPropertyId);
                    const ids = currentServices.filter(i => !i.cancellationDate && !i.isOneTime).map(i => i.maintenanceServiceId);
                    this.offerings = this.offerings.filter(i => ids.indexOf(i.maintenanceServiceId) === -1);
                }
                this.skipCustomer = true;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSubComponent && changes.isSubComponent.currentValue) {
            setTimeout(() => {
                this.selectedIndex = 1;
                this.loadProductOfferings(false);
            }, 200);
        }
        if (changes.quoteId && changes.quoteId.currentValue) {
            this.loadQuote();
        }
    }

    private loadCreditBalance(customerId: string) {
        this.customerService.getCustomerCreditBalance(customerId).then(balance => {
            this.creditBalance = balance;
        });
    }

    async loadQuote() {
        const quote = await this.customerService.getWebQuote(this.quoteId);
        this.customer.name = quote.name;
        this.customer.email = quote.email;
        this.address = quote.address;
        if (this.address) {
          await this.loadProductOfferings()
        }
        this.customer.homeNumber.number = quote.phone;
        if (this.customer.name && this.customer.email && this.customer.homeNumber.number) {
            this.entityCanSave = true;
        }
    }

    get minStartDate() {
        if (!this.selectedOfferings) {
            return null;
        }
        let minDate = new Date();
        for (const offering of this.selectedOfferings) {
            if (offering.minStartDate > minDate) {
                minDate = new Date(offering.minStartDate);
            }
        }

        return minDate;
    }

    loadingPromotionCode = false;
    promotionCodeCode: string;
    promotionCode: PromotionCode;

    get canLoadPromo() {
        return this.promotionCodeCode && !this.loadingPromotionCode;
    }

    async loadPromo() {
        delete this.promotionCode;
        this.loadingPromotionCode = true;

        let foundOffering = false;
        const promotionCode = await this.maintApi.getPromotion(this.promotionCodeCode);

        if (promotionCode && promotionCode.maintenanceSerivceOfferings
            && (!promotionCode.expirationDate || promotionCode.expirationDate > new Date())
            && (!promotionCode.effectiveDate || promotionCode.effectiveDate < new Date())) {

            const offerings: MaintenanceServiceOffering[] = JSON.parse(promotionCode.maintenanceSerivceOfferings);


            for (const group of this.selectedOfferings) {
                if (group.selectedOffering) {
                    const off = offerings.find(i => i.id === group.selectedOffering.maintenanceServiceOfferingId);
                    if (off) {
                        foundOffering = true;
                        this.promotionCode = promotionCode;
                    }
                }
            }
        }

        if (!this.promotionCode) {
            this.dialogService.alert('Not Found', 'Sorry, we couldn\'t find that promotion code.');
            for (const item of this.offeringSchedule) {
                delete item.adjustment;
                delete item.adjustmentItems;
            }
        } else {
            this.adjustment = await this.maintApi.getAdjustment(this.promotionCode.adjustmentId);
            this.adjustmentItems = await this.maintApi.getAdjustmentItems(this.promotionCode.adjustmentId);

            for (const item of this.offeringSchedule) {
                item.adjustment = this.adjustment;
                item.adjustmentItems = this.adjustmentItems;
            }
        }
        this.loadingPromotionCode = false;
    }

    get startDateDelayed() {
        const dt = new Date();
        dt.setDate(dt.getDate() + 7);
        return this.startDate > dt;
    }

    get firstPayment() {
        if (!this.startDateDelayed) {
            return new Date();
        }
        const dt = new Date(this.startDate);
        dt.setDate(dt.getDate() - 2);

        return dt;
    }

    get isCogent() {
        return document.location.toString().indexOf(':4200') > 0 || document.location.toString().indexOf('cogent.') > -1;
    }

    maintenanceProductsGoBack() {
        if (!this.isSubComponent) {
            this.selectedIndex = this.selectedIndex - 1;
        } else {
            this.goBackParent.emit(true);
        }
    }

    private loadProperties() {
        this.orderFromPortal = true;
        if (this.existingProperites) {
            return;
        }

        this.entityApi.getLoggedInUser(false, true).then(async user => {
            this.customerService.getPoliciesForLoggedInUser()
                .then(async policies => {
                    this.myPolicies = policies;
                    const properties = this.myPolicies.map(i => i.propertyAddress);
                    const props = [];
                    for (const prop of properties) {
                        const foundProp = props.find(i => i.id === prop.id);
                        if (!foundProp) {
                            props.push(prop);
                        }
                    }
                    const summaries = await this.maintApi.getMaintServiceSummariesByCustomerId(user.id);

                    for (const summary of summaries) {
                        const foundProp = props.find(i => i.id === summary.propertyId);
                        if (!foundProp) {
                            const address = await this.addressApi.getAddress2(summary.propertyId);
                            if (address) {
                                props.push(address);
                            }
                        }
                    }
                    this.existingProperites = props;
                    if (this.propertyId) {
                        const foundProperty = this.existingProperites.find(i => i.id === this.propertyId);
                        if (foundProperty) {
                            this.selectedProperty = foundProperty;
                            await this.loadProductOfferings(false);
                            this.selectedIndex = 1;
                        }
                    }
                });
        });

    }

    editSchedule(schedule: OfferingSchedule) {
        const group = this.selectedOfferings.find(i => i.offerings.indexOf(schedule.offering) > -1);
        schedule.editMode = !schedule.editMode;
        schedule.group = group;
    }

    updateOffering(schedule: OfferingSchedule, value: MaintenanceServiceOfferingSummaryWithPostalCode) {
        schedule.group.selectedOffering = value;
    }

    deleteItem(schedule: OfferingSchedule) {
        if (!schedule.group) {
            schedule.group = this.selectedOfferings.find(i => i.offerings.indexOf(schedule.offering) > -1);
        }
        const scheduleIndex = this.offeringSchedule.indexOf(schedule);
        const selectedItem = schedule.group.selectedOffering;

        schedule.group.selectedOffering = null;
        this.offeringSchedule.splice(scheduleIndex, 1);
        this.missionService.showUndo(new UndoArguments(() => {
            schedule.group.selectedOffering = selectedItem;
            this.offeringSchedule.splice(scheduleIndex, 0, schedule);
        }, 'Undo Remove'));
    }

    get baseUrl() {
        return ApiService.endPointDotNet;
    }

    get isAddressStepComplete() {
        if (!this.orderFromPortal || this.showNewPropertyEntry) {
            return this.addressComplete;
        }

        if (this.orderFromPortal && !this.showNewPropertyEntry) {
            return this.selectedProperty;
        }
    }

    formatLabel(value: number) {

        return value;
    }

    showFrenchPanes() {

    }

    addressCompleteChange(complete: any) {
        this.addressComplete = complete;
    }

    get lotAcres() {
        if (!this.address) {
            return 0;
        }

        return this.address.lawnSquareFootage / 43560;
    }

    async loadProductOfferings(doAdvance = true) {


        if (this.orderFromPortal && !this.showNewPropertyEntry && this.selectedProperty) {
            this.address = this.selectedProperty;
            const user = await this.entityApi.getLoggedInUser();
            const summaries = await this.maintApi.getMaintServiceSummaries(user?.loginId);


            const foundSummary = summaries.find(i => i.propertyId === this.selectedProperty.id);
            if (foundSummary) {
                this.router.navigateByUrl(`/maintenance-service-add-service/${foundSummary.id}`);
                return;
            }
        }

        this.loadingServices = true;
        if (doAdvance) {
            this.selectedIndex++;
        }

        if (!this.address.squareFeet || !this.address.lotSquareFootage || !this.address.bathroomCount) {

            try {
                const meta = await this.maintApi.getPropertyMeta(this.address.address1, this.address.city, this.address.state, this.address.postalCode);

                if (meta) {

                    if (meta.bathroomCount) {
                        this.address.bathroomCount = meta.bathroomCount;
                    } else if (!this.address.bathroomCount) {
                        this.address.bathroomCount = 2;
                    }
                    if (meta.bedroomCount) {
                        this.address.bedroomCount = meta.bedroomCount;
                    } else if (!this.address.bedroomCount) {
                        this.address.bedroomCount = 3;
                    }

                    if (meta.latitude) {
                        this.address.latitude = meta.latitude;
                    }
                    if (meta.longitude) {
                        this.address.longitude = meta.longitude;
                    }
                    if (meta.propertySqft) {
                        this.address.lotSquareFootage = meta.propertySqft;
                        this.address.lawnSquareFootage = UtilitiesService.round(this.address.lotSquareFootage * .4, 0);
                    }

                    if (meta.sqft && meta.squareFeet > this.address.squareFeet) {
                        this.address.squareFeet = meta.sqft;
                    } else {
                        if (this.address.squareFeet < 2000) {
                            this.address.squareFeet = 2000;
                        }
                    }
                    this.addressUpdated = true;
                }
            } catch { }
        }
        if (!this.address.lawnSquareFootage) {
            this.address.lawnSquareFootage = UtilitiesService.round(this.address.lotSquareFootage * .4, 0);

        }

        const offering = await this.maintApi.getMaintenanceOfferingsForAddress(this.address);
        if (this.quoteId) {
            await this.customerService.updateWebQuoteDetails(this.quoteId, JSON.stringify(offering));
        }

        this.offerings = offering;

        this.loadingServices = false;
        for (const offer of offering) {
            if (!offer.filtersToShow || offer.filtersToShow.length === 0) {
                offer.readyToShowOfferings = true;
                //
                if (offer.filteredOfferings.length === 1) {
                    this.selectOffering(offer, offer.filteredOfferings[0]);
                }
            } else {
                for (const filter of offer.filtersToShow) {
                    if (filter.filterOptions) {
                        for (const option of filter.filterOptions) {
                            option.pictureUrl = this.serviceApi.getItemThumbnailUrl(option.id);
                        }
                    }
                }
            }
            offer.offerings = UtilitiesService.copyArrayToTypedArray(offer.offerings, () => new MaintenanceServiceOfferingSummaryWithPostalCode());

            if (offer.maintenanceServiceId === 'b688383e-0dcf-4943-bbd1-7c53477345b9') {
                for (const price of offer.offerings) {
                    price.secondaryUnitCount = this.address.bathroomCount;
                }
            }

            if (offer.maintenanceServiceId === this.selectedMaintenanceServiceId) {
                offer.selected = true;
                this.selectOffering(offer, offer.filteredOfferings[0]);
            }
        }
    }

    showOfferingContractDetails(item) {
        this.dialog.open(MaintServicesContractLanguageComponent, { data: item });
    }

    get cardBrandUrl() {
        if (!this.selectedCard) {
            return null;
        }
        const prefix = 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/';
        if (this.selectedCard.brand?.toLowerCase() === 'visa') {
            return prefix + 'visa.png';
        }
        if (this.selectedCard.brand?.toLowerCase() === 'american express') {
            return prefix + 'americanexpress.png';
        }
        if (this.selectedCard.brand?.toLowerCase() === 'discover') {
            return prefix + 'discover.png';
        }
        if (this.selectedCard.brand?.toLowerCase() === 'mastercard') {
            return prefix + 'mastercard.png';
        }
    }

    paymentGoBack() {
        this.selectedIndex = this.selectedIndex - (this.orderFromPortal ? 2 : 1);
    }

    get isConfirmationStep() {
        return this.selectedIndex === this.totalSteps + 1;
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }
        let hashNumber = parseInt(hash, 10);

        if (this.selectedIndex > 0 && hashNumber === 0 && this.isSubComponent) {
            this.goBackParent.emit(true);
            return;
        }
        if (!isNaN(hashNumber) && !this.isConfirmationStep) {
            this.selectedIndex = hashNumber;
        }
    }

    get contactFilledOut() {
        return this.entityCanSave
            && this.password
            && this.password === this.confirmPassword;
    }

    getBackground(offering: GroupedProductOfferings) {
        if (offering.lottieAnimationUrl) {
            return 'none';
        }
        return `url(${this.serviceApi.getItemThumbnailUrl(offering.maintenanceServiceOfferingId)})`;
    }

    get canConfigServices() {
        return this.offerings?.filter(i => i.selected).length > 0;
    }

    get servicesStartIndex() {
        return this.addMode ? 1 : 2;
    }

    editPayment() {
        this.selectedIndex = this.servicesEndIndex + (this.skipCustomer ? 0 : 1);
    }

    setFilterOption(selectedOffering: GroupedProductOfferings, filter: MaintenanceServiceFilter, option: MaintenanceServiceFilterOption) {
        filter.selectedOption = option;

        if (selectedOffering.filtersToShow.filter(i => !i.selectedOption).length === 0) {
            selectedOffering.readyToShowOfferings = true;
            // pricingFilterOptions
        }
        this.setFilters();
        if (selectedOffering.filteredOfferings.length === 1) {
            this.selectOffering(selectedOffering, selectedOffering.filteredOfferings[0]);
        }
    }

    timeout: any;
    validatePassword() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.passwordsMatch = this.password === this.confirmPassword;
        }, 1000);
    }

    // get totalPlanPrice() {
    //     return 0;
    // }

    get servicesEndIndex() {
        return this.servicesStartIndex + (this.selectedOfferings?.length ?? 0);
    }

    get totalSteps() {
        if (this.skipCustomer) {
            return this.servicesEndIndex + 1;
        }
        return this.servicesEndIndex + 2;
    }

    get pctComplete() {
        return (this.selectedIndex / this.totalSteps) * 100;
    }

    get isServiceSelected() {
        const index = this.selectedIndex - this.servicesStartIndex;
        const group = this.selectedOfferings[index];

        return group!.selectedOffering && group.filteredOfferings.indexOf(group.selectedOffering) > -1; // && group.selectedOffering.preferredDay;
    }

    async saveCustomerAndAdvance() {
        this.savingCustomer = true;
        await this.customerService.saveCustomerAndPhone(this.customer as any);
        this.savingCustomer = false;
        this.selectedIndex++;
    }

    paymentValid(isValid) {
        this.creditCardValid = isValid;
        if (isValid) {
            this.cardSelected = true;
        }
    }

    incrementOneUp(group: GroupedProductOfferings) {
        this.onePanelCount++;
        this.doWindowPanelFilter(group);
    }

    incrementTwoUp(group: GroupedProductOfferings) {
        this.twoPanelCount++;
        this.doWindowPanelFilter(group);
    }

    incrementFourUp(group: GroupedProductOfferings) {
        this.fourPanelCount++;
        this.doWindowPanelFilter(group);
    }

    incrementSixUp(group: GroupedProductOfferings) {
        this.sixPanelCount++;
        this.doWindowPanelFilter(group);
    }

    interval;
    lotChange(newSqft: number) {
        // clearInterval(this.interval);
        // this.interval = setTimeout(() => this.setFilters(true), 1000);
        this.setFilters();
    }

    secondaryUnitChange(selectedOffering: GroupedProductOfferings, count: number) {
        for (const price of selectedOffering.offerings) {
            price.secondaryUnitCount = count;
        }
    }

    incrementOneDown(group: GroupedProductOfferings) {
        if (this.onePanelCount > 0) {
            this.onePanelCount--;
            this.doWindowPanelFilter(group);
        }
    }

    formatSliderLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k SQFT';
        }

        return `${value} SQFT`;
    }

    setOfferingSchedule() {
        this.offeringSchedule = [];
        for (const group of this.selectedOfferings) {
            if (group.selectedOffering) {
                const scheduleItem = new OfferingSchedule();
                scheduleItem.offering = group.selectedOffering;

                this.offeringSchedule.push(scheduleItem);
            }
        }
    }

    get monthlyTotal() {
        if (!this.offeringSchedule || this.offeringSchedule.length === 0) {
            return 0;
        }

        return UtilitiesService.round(this.offeringSchedule.map(i => i.monthlyPrice).reduce((a, b) => a + b), 2);
    }

    get firstMonthTotal() {
        if (!this.offeringSchedule || this.offeringSchedule.length === 0) {
            return 0;
        }
        let appliedOfferings: MaintenanceServiceOffering[] = [];

        if (this.promotionCode) {
            appliedOfferings = JSON.parse(this.promotionCode.maintenanceSerivceOfferings);
        }
        return UtilitiesService.round(this.offeringSchedule.map(i => {
            let price = i.firstMonthPrice;
            const foundOffering = appliedOfferings.find(j => j.id === i.offering.maintenanceServiceOfferingId);
            if (foundOffering && this.adjustmentItems && this.adjustmentItems.length > 0) {
                const ai = this.adjustmentItems[0];
                if (ai.priceAdjustmentType === 'Percent') {
                    if (ai.priceAdjustment > 1 || ai.priceAdjustment < -1) {
                        ai.priceAdjustment /= 100;
                    }
                    price = price + (price * ai.priceAdjustment);
                }
                if (ai.priceAdjustmentType === 'Flat') {
                    price += ai.priceAdjustment;
                }
            }
            return price;
        }).reduce((a, b) => a + b), 2);
    }

    configuredServiceNext() {
        this.selectedIndex = this.selectedIndex + 1;
        this.setOfferingSchedule();
        if (this.orderFromPortal && this.selectedIndex === this.servicesEndIndex && !this.addMode && !this.skipCustomer) {
            this.selectedIndex++;
        }
    }

    incrementSixDown(group: GroupedProductOfferings) {
        if (this.sixPanelCount > 0) {
            this.sixPanelCount--;
            this.doWindowPanelFilter(group);
        }
    }

    incrementTwoDown(group: GroupedProductOfferings) {
        if (this.twoPanelCount > 0) {
            this.twoPanelCount--;
            this.doWindowPanelFilter(group);
        }
    }

    incrementFourDown(group: GroupedProductOfferings) {
        if (this.fourPanelCount > 0) {
            this.fourPanelCount--;
            this.doWindowPanelFilter(group);
        }
    }

    selectedIndexChange(index: number) {
        document.location.hash = index as any;
        if (index === this.totalSteps + 1) {

            setTimeout(() => {
                this.showLastScene = true;

                setTimeout(() => {
                    this.showLastSceneLastText = true;

                }, 400);
            }, 1500);
        }
        this.lottieReset = false;
        setTimeout(() => this.lottieReset = true);
    }

    doWindowPanelFilter(group: GroupedProductOfferings) {
        group.filteredOfferings = group.offerings.filter(i => i.rangeStart <= this.windowPaneCount && i.rangeEnd >= this.windowPaneCount);
        for (const offering of group.filteredOfferings) {
            offering.secondaryUnitCount = this.sixPanelCount;
        }
    }

    get windowPaneCount() {
        // return this.onePanelCount + (this.twoPanelCount * 2) + (this.fourPanelCount * 4) + (this.sixPanelCount * 6);
        return this.onePanelCount;
    }

    configServices() {
        this.selectedIndex = this.selectedIndex + 1;
        this.selectedOfferings = this.offerings.filter(i => i.selected);

        this.setFilters();
        for (const offering of this.selectedOfferings) {
            const buffer = offering.offerings[0].schedulingBufferDays;
            const day = new Date();
            day.setDate(day.getDate() + buffer);
            offering.startDate = day;
            offering.minStartDate = new Date(day);
            if (offering.readyToShowOfferings && offering.filteredOfferings.length === 1) {
                this.selectOffering(offering, offering.filteredOfferings[0]);
            }
        }
        this.startDate = new Date(this.minStartDate);
    }



    updateBathroomCount() {
        // const count = this.address.bathroomCount;
        // this.address.bathroomCount = Math.round(count);
        // if(this.address.bathroomCount !== count) {
        //     this.setFilters();
        // }
    }

    get canSendQuote() {
        if (this.quoteName && this.quoteEmail && this.quotePhone) {
            return false
        }
        return true
    }

    async sendWebQuoteFromCogent() {
        const quote = new WebQuote();
        quote.id = this.quoteId || UtilitiesService.newid();
        quote.address = this.address;
        quote.address.id = this.address.id || UtilitiesService.newid()
        quote.email = this.quoteEmail;
        quote.phone = this.quotePhone;
        quote.name = this.quoteName;
        quote.source = 'maintenance-service'
        quote.queryParameters = JSON.stringify({ utm_source: 'internal' })

        if (!quote.name) {
            await this.dialogService.alert('Entry Required', 'Please enter a name').toPromise();
            document.getElementById('q-name').focus();
            return;
        }
        if (!quote.phone || !UtilitiesService.validatePhoneNumber(quote.phone)) {
            await this.dialogService.alert('Entry Required', 'Please enter a phone number').toPromise();
            document.getElementById('q-phone').focus();
            return;
        }
        if (!quote.email || !UtilitiesService.validateEmail(quote.email)) {
            await this.dialogService.alert('Entry Required', 'Please enter email').toPromise();
            document.getElementById('q-email').focus();
            return;
        }

        if (this.quoteSent) {
            await this.dialogService.alert('Already Sent', 'You have already sent out a quote with this information').toPromise();
            return;
        }



        if (!this.quoteSent) {
            this.sendingQuote = true;
        }

        const offering = await this.maintApi.getMaintenanceOfferingsForAddress(quote.address);
        quote.quoteDetails = JSON.stringify(offering)

        await this.customerService.saveWebQuote(quote);
        this.missionService.showSuccessToast('Quote Sent');

        this.sendingQuote = false
        this.quoteSent = true;

    }

    async setFilters(updateDynamic = false) {
        for (const offering of this.selectedOfferings) {
            offering.filteredOfferings = offering.offerings;
            //Window cleaning

            if (offering.maintenanceServiceId === 'aa37213c-b515-4255-a8e6-1679eaa1a4fe') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart === 0);
            }

            //House cleaning
            if (offering.maintenanceServiceId === 'b688383e-0dcf-4943-bbd1-7c53477345b9') {

                if (!this.address.bedroomCount) {
                    this.address.bedroomCount = 3;
                }
                if (!this.address.bathroomCount) {
                    this.address.bathroomCount = 2;
                }

                const bedCount = Math.round(this.address.bedroomCount);
                const bathCount = Math.round(this.address.bathroomCount);


                //offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart === bedCount && i.rangeEnd === bathCount);

                for (const item of offering.offerings) {
                    item.secondaryUnitCount = bedCount;
                    item.tertiaryUnitCount = bathCount;
                }

                if (offering.filteredOfferings.length === 1) {
                    offering.selectedOffering = offering.filteredOfferings[0];
                }
                // if (this.address.bathroomCount == undefined || this.address.bedroomCount == undefined) {
                //     offering.filteredOfferings = [];
                // } else {
                //     const unitCount = (this.address.bathroomCount ?? 0) + (this.address.bedroomCount ?? 0);
                //     offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= unitCount && i.rangeEnd >= unitCount);
                //     if (offering.filteredOfferings.length === 1) {
                //         this.selectOffering(offering, offering.filteredOfferings[0]);
                //     }
                // }
            }

            // Lawn Care UT
            if (offering.maintenanceServiceId === 'f401dcec-0547-45c4-af6e-8bc792bf1e64') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= this.address.lawnSquareFootage && i.rangeEnd >= this.address.lawnSquareFootage);
            }

            // Landscape Maintenance AZ
            if (offering.maintenanceServiceId === 'fcc8cc9f-c991-4bb0-9356-8564312b9ce4') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= this.address.lotSquareFootage && i.rangeEnd >= this.address.lotSquareFootage);
            }

            // Pest Control
            if (offering.maintenanceServiceId === '5b617cdf-a788-4bb7-b76b-da0460dfd9c3') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= this.address.squareFeet && i.rangeEnd >= this.address.squareFeet);
            }

            if (offering.filtersToShow && offering.filtersToShow.length > 0) {
                for (const filter of offering.filtersToShow) {
                    if (filter.selectedOption) {

                        const filterOptionId = filter.selectedOption.id;

                        offering.filteredOfferings = offering.filteredOfferings.filter(i => {


                            if (!i.pricingFilterOptions) {
                                return true;
                            }
                            try {

                                // Array of filter Ids that can be applied (setup on the maintenance service)
                                const filterOptionIdsToApply = JSON.parse(i.pricingFilterOptions);

                                const filterOptionIds = filter.filterOptions.map(i => i.id);
                                let applyFilter = false;
                                for (const idToApply of filterOptionIdsToApply) {

                                    if (filterOptionIds.indexOf(idToApply) > -1) {
                                        applyFilter = true;
                                    }
                                }

                                if (applyFilter) {
                                    if (filterOptionIdsToApply.indexOf(filterOptionId) === -1) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }

                            } catch {
                                return true;
                            }
                        });
                    }
                }
            }

            offering.filteredOfferings = offering.filteredOfferings.sort((a, b) => a.price > b.price ? 1 : -1);

            for (const off of offering.filteredOfferings) {
                if (off.dynamicPricingType && updateDynamic) {
                    off.loading = true;
                    const updated = await this.maintApi.updateMaintenanceServiceDynamicPrice(this.address, off);
                    off.loading = false;

                    off.price = updated.price;
                    off.costPerMonth = updated.costPerMonth;
                    off.initialVisitPrice = updated.initialVisitPrice;
                }
            }
        }
    }

    selectOffering(grouping: GroupedProductOfferings, offering: MaintenanceServiceOfferingSummaryWithPostalCode) {
        grouping.selectedOffering = offering;
        console.log(offering);
    }

    saving = false;

    get canSave() {
        if (this.saving) {
            return false;
        }
        if (!this.selectedOfferings) {
            return false;
        }
        const count = this.selectedOfferings.filter(i => i.selectedOffering && !i.selectedOffering.approved).length;
        if (count > 0) {
            return false;
        }

        return true;
    }

    async save() {
        this.saving = true;
        if (!this.addMode) {
            const args = new MaintenanceServiceOrderArgs();
            const services = [];

            for (const group of this.selectedOfferings) {
                if (group.selectedOffering) {
                    group.selectedOffering.startDate = group.startDate;
                    services.push(group.selectedOffering);
                }
            }

            args.card = this.selectedCard;
            args.customer = this.customer as any;
            args.password = this.password;
            args.property = this.address;
            args.orderedServices = services;
            args.fromPortal = this.orderFromPortal;
            args.promotionId = this.promotionCode?.id;
            args.source = this.source;
            args.marketingSourceId = this.selectedMarketingSource?.id;

            if (this.skipCustomer) {
                args.fromPortal = true;
            }
            for (const service of args.orderedServices) {

                if (service.maintenanceServiceId === 'b688383e-0dcf-4943-bbd1-7c53477345b9') {
                    service.price = service.caluclatedPrice;
                    const secondaryCost = (service.secondaryUnitCost ?? 0) * ((service.secondaryUnitCount ?? 0) - (service.includedSecondaryUnits ?? 0));
                    const tertiaryCost = (service.tertiaryUnitsCost ?? 0) * ((service.tertiaryUnitCount ?? 0) - (service.includedTertiaryUnits ?? 0));

                    service.cost = service.cost + secondaryCost + tertiaryCost;
                }
                service.startDate = this.startDate;
            }

            const result = await this.maintApi.order(args);
            if (result) {
                this.saving = false;
                this.selectedIndex++;

            } else {
                this.dialogService.alert('Payment Denied', 'Sorry, it appears as if the payment did not go through.<br>Please check your card and try again.').subscribe(() => {
                    this.selectedIndex = 4;
                });
                this.saving = false;
            }
        } else {
            const args = new MaintenanceServiceAddServiceArgs();
            args.servicesToAdd = [];
            args.source = this.source;
            args.maintenanceServicePropertyId = this.maintenanceServiceCustomerPropertyId;
            args.address = this.address;

            for (const group of this.selectedOfferings) {
                if (group.selectedOffering) {
                    args.servicesToAdd.push(group.selectedOffering);
                }
            }
            if (this.addressUpdated) {
                args.address = this.address;
            }

            const result = await this.maintApi.addServices(args);

            this.saving = false;
            this.selectedIndex = 4;

        }
    }
}



