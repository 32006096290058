import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { ConvertProposedWorkOrderToWorkOrderArgs } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';

@Component({
    selector: 'app-approve-contactor-proposed-work-order',
    templateUrl: './approve-contactor-proposed-work-order.component.html',
    styleUrls: ['./approve-contactor-proposed-work-order.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, MatButtonModule, MatProgressSpinnerModule, CheckCircleAnimatedComponent, PaymentMethodEntryModule, PaymentMethodEntryComponent, RouterModule]
})
export class ApproveContactorProposedWorkOrderComponent implements OnInit {

    args = new ConvertProposedWorkOrderToWorkOrderArgs();
    saving = false;
    selectedIndex = 0;
    declining = false;
    isLegacy = true;

    constructor(private activatedRoute: ActivatedRoute,
        private dialogService: DialogsService,
        private serviceApi: ServiceApiService) { }

    ngOnInit(): void {

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.serviceApi.getContractorProposedWorkOrderSummary(params.id).then(summary => {
                    this.args.contactEmail = summary.customerEmail;
                    this.args.contactPhone = summary.customerHomeNumber;
                    this.args.contactMobile = summary.customerMobileNumber;
                    this.args.contactName = summary.customerName;
                    this.args.contractorId = summary.contractorId;

                    this.args.summary = summary;

                    const line = new WorkOrderLineSummaryClient();
                    line.itemId = summary.workOrderItemId;
                    line.policyId = summary.policyId;
                    line.qandA = summary.qandA;
                    if (summary.rejectedDate) {
                        this.selectedIndex = 3;
                    } else if (summary.approvedDate) {
                        this.selectedIndex = 2;
                    }

                    this.serviceApi.getDynamicServiceFeeV2(summary.policyId, [line], 'Portal').then(sf => {
                        this.args.serviceFee = sf.serviceFee;
                    });
                });
            }
        });
    }

    get canSave() {
        return this.args.card && !this.saving;
    }

    get itemUrl() {
        if (!this.args.summary) {
            return null;
        }

        return `${ApiService.endPointNode}scheduled-maintenance/item-picture/${this.args.summary.workOrderItemId}`;
    }

    beginApprove() {
        this.selectedIndex = 1;
    }

    async decline() {
        const result = await this.dialogService.confirm('Confirm', 'Are you sure you want to decline this repair?').toPromise();
        if (result) {
            this.declining = true;

            await this.serviceApi.declineContractorProposedWorkOrder(this.args.summary.id);
            this.declining = false;
            this.selectedIndex = 3;
        }
    }


    async save() {
        const dialogResult = await this.dialogService.confirm('Confirm', 'Are you sure you want to approve this job?').toPromise();
        if (!dialogResult) {
            return;
        }
        this.saving = true;

        const result = await this.serviceApi.approveProposedContractorWorkOrder(this.args);
        if (result.success) {
            this.selectedIndex = 2;
        } else {
            this.dialogService.alert('Payment Declined', `Sorry, the payment did not go through<br>Message: ${result.message}`);
        }
        this.saving = false;
    }
}
