import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { WorkOrderServiceFeeCancellationInfo } from '@cogent/shared/models/service/work-order-service-fee-cancellation-info.model';
import { DispatchApiService } from '@cogent/client/shared/services/api/dispatch-api.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-cancel-work-order',
    templateUrl: './cancel-work-order.component.html',
    styleUrls: ['./cancel-work-order.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule
    ]
})
export class CancelWorkOrderComponent implements OnInit {
    args: WorkOrderServiceFeeCancellationInfo;
    cancelling = false;
    constructor(
        public dialogRef: MatDialogRef<CancelWorkOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: WorkOrderSummaryClient,
        private customerService: CustomerRepositoryService,
        private serviceApi: ServiceApiService,
        private dispatchApi: DispatchApiService,
        private missionService: MissionService
    ) { }

    ngOnInit() {
        this.serviceApi.getWorkOrderServiceFeeCancellationInfo(this.data.id).then(info => {
            this.args = info;
            if (info.canVoid) {
                info.doVoid = true;
            }
        });
    }

    async performCancellation() {
        this.cancelling = true;
        await this.customerService.cancelWorkOrder(this.data.id);

        if (this.data.dispatchExportDate) {
            await this.dispatchApi.cogentCancelled(this.data.id);
        }
        
        if (this.args.doVoid) {
            await this.serviceApi.processWorkOrderServiceFeeCancellationInfo(this.data.id, this.args);
        }
        this.cancelling = false;
        this.dialogRef.close(true);

        this.missionService.showSuccessToast('Your service request has been cancelled.');
    }

}
