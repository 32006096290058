import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCancellationArgs, MaintenanceServiceCustomerPropertyServiceSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';

@Component({
    selector: 'app-maint-services-cancel',
    templateUrl: './maint-services-cancel.component.html',
    styleUrls: ['./maint-services-cancel.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule, MatProgressSpinnerModule, PaymentMethodEntryModule, PaymentMethodEntryComponent, FormsModule, MatFormFieldModule, MatInputModule]
})
export class MaintServicesCancelComponent implements OnInit {

    cancellationArgs: MaintenanceServiceCancellationArgs;
    saving = false;
    card: any;

    constructor(public dialogRef: MatDialogRef<MaintenanceServiceCancellationArgs>,
        private maintApi: MaintenanceServiceApiService,
        private missionService: MissionService,
        @Inject(MAT_DIALOG_DATA) public service: MaintenanceServiceCustomerPropertyServiceSummary) { }

    ngOnInit(): void {
        this.load();
    }

    private async load() {
        this.cancellationArgs = await this.maintApi.getServiceCancellationArgs(this.service.id);
        this.cancellationArgs.refundToCard = true;
    }

    get canSave() {
        if (this.saving) {
            return false;
        }
        if (!this.cancellationArgs.selectedCancellationReason) {
            return false;
        }
        if (this.cancellationArgs.cancellationBalance < 0) {
            return this.card;
        }
        if ((this.cancellationArgs.selectedCancellationReason.reason == 'Service Issues' || this.cancellationArgs.selectedCancellationReason.reason == 'Other')
            && !this.cancellationArgs.cancellationNote) {
            return false;
        }

        return true;
    }

    async save() {
        this.saving = true;
        this.cancellationArgs.card = this.card;
        if (this.cancellationArgs.selectedCancellationReason.reason != 'Service Issues' && this.cancellationArgs.selectedCancellationReason.reason != 'Other')
            this.cancellationArgs.cancellationNote = null;
        await this.maintApi.cancelMaintenanceService(this.cancellationArgs);
        this.saving = false;
        this.dialogRef.close(true);
        this.missionService.showSuccessToast('Service Canceled');
    }


}
