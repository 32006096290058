

import { Entity, PhoneNumber, StripeCard } from '@upkeeplabs/models/cogent';
import { PolicyModel } from '@cogent/shared/models/policies/policy-legacy.model';
import { Address } from '@upkeeplabs/models/cogent';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';

export class AdditionalEmail {
    email: string;
    type: string;
}
 

export class NewPolicyModel {
    policy: PolicyModel = new PolicyModel();
    holder: Entity = new Entity();
    property: Address = new Address();
    holderAddress: Address = new Address();
    holderPhoneNumbers: PhoneNumber[] = [];
    options: SalesItemModel[];
    coverageType: string;
    isMonthly: boolean;
    selectedCard: StripeCard;
    promotionCode: string;
    closingOfficer: Entity;
    buyersAgent: Entity;
    sellersAgent: Entity;
    billTo: string;
    webQuoteId: string;

    
    newAgentName: string;
    newAgentCompany: string;
    newAgentEmail: string;
    newAgentPhone: string;

    propertyManagerName: string;

    newEscrowName: string;
    newEscrowCompany: string;
    newEscrowPhone: string;
    newEscrowEmail: string;

    
    newSellerName: string;
    newSellerCompany: string;
    newSellerPhone: string;
    newSellerEmail: string;


    additionalEmailAddresses: AdditionalEmail[];
    notes: string;

    constructor() {
        this.holder.type = 'Customer';
    }
}
