<div class="center">
    <lottie-player style="height: 150px;" src="https://lottie.host/9d8d9269-0a00-4bd2-9065-4fe0ad004809/FDlgAIJoMF.json"
        background="transparent" speed="1" loop autoplay [intermission]="20000"></lottie-player>

    <p class="info">We couldn't find information about your property.<br><br>Would you take a moment to provide a few
        details?</p>

    </div>
    <div margintop30>

        <mat-form-field style="max-width: 300px;" appearance="outline">
            <mat-label>Dwelling Type</mat-label>
            <mat-select block [(ngModel)]="address.dwellingType">
                <mat-option value="Condo">Condo</mat-option>
                <mat-option value="SingleFamily">Single Family</mat-option>
                <mat-option value="Duplex">Duplex</mat-option>
                <mat-option value="Triplex">Triplex</mat-option>
                <mat-option value="Fourplex">Fourplex</mat-option>
                <mat-option value="FivePlex">5-Plex</mat-option>
                <mat-option value="SixPlex">6-Plex</mat-option>
                <mat-option value="Mobile">Mobile</mat-option>
                <mat-option value="Other">Unknown</mat-option>
            </mat-select>
        </mat-form-field>



        <mat-form-field style="max-width: 200px;" class="sqft" appearance="outline">
            <mat-label>Square Footage</mat-label>
            <input matInput type="number" [(ngModel)]="address.squareFeet">
        </mat-form-field>
    </div>

<mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
        Confirm
    </button>
</mat-dialog-actions>