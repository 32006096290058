import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { AuthService } from '@cogent/client/auth';

@Component({
    selector: 'app-realtor-portal-login',
    templateUrl: './realtor-portal-login.component.html',
    styleUrls: ['./realtor-portal-login.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class RealtorPortalLoginComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
        private policyApi: PolicyApiService,
        private authService: AuthService) { }

    ngOnInit(): void {
        try {
            document.getElementById('appHeader').style.display = 'none';
            document.getElementById('appHeader').style.height = '0px';

            document.getElementById('app-content').style.paddingTop = '3px';
        } catch (e) { }
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                //

                this.policyApi.getHolderId(params.id).then(holderId => {
                    window.localStorage.setItem("redirectTo", "/request-service/" + params.id + '?embedded=true&entityId=' + params.entityId);
                    this.authService.logIn(holderId);
                });
            }
        });
    }

}
