import { Component, OnInit } from '@angular/core';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { ApiService } from '@cogent/client/api';
import { Login } from '@cogent/shared/models/user/login.model';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-attach-policy',
    templateUrl: './attach-policy.component.html',
    styleUrls: ['./attach-policy.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, MatFormFieldModule, FormsModule, MatButtonModule, MatInputModule, MatIconModule, MatProgressSpinnerModule, MatRadioModule, CheckCircleAnimatedComponent]
})
export class AttachPolicyComponent implements OnInit {

    isLegacy = true;
    searchFor: string;
    searching = false;
    searchResults: any[];
    sendingCode = false;
    login: Login;
    saving = false;
    registrationCode;
    baseUrl: string;
    selectedIndex = 0;

    constructor(private policyApi: PolicyApiService,
        private customerRepository: CustomerRepositoryService,
        private entityApi: EntityApiService,
        private api: ApiService,
        private missionService: MissionService,
        private dialogService: DialogsService) { }

    ngOnInit() {
        this.api.getApiEndpointDotNet().then(endpoint => this.baseUrl = endpoint);
    }

    async search() {
        this.searching = true;

        this.searchResults = await this.policyApi.findPoliciesWithoutLogins(this.searchFor);
        this.searching = false;
    }

    handleKey(evt: KeyboardEvent) {
        if (evt.key === 'Enter') {
            this.search();
        }
    }

    getUrl(searchResult) {
        return `${this.baseUrl}address/${searchResult.propertyAddressId}/StreetView`;
    }

    showVerifyDialog(policy) {
        policy.showCodeEntry = false;
        policy.sendingCode = true;
        this.customerRepository.sendVerificationCode({
            phoneNumberId: policy.sendOption === 'Home' ? policy.homeNumberId : (policy.sendOption === 'Mobile' ? policy.mobileNumberId : null),
            email: policy.sendOption === 'Email' ? policy.email : null,
            entityId: policy.id,
        }).then(() => {
            this.missionService.showSuccessToast('The code has been sent');
            policy.showCodeEntry = true;
            policy.sendingCode = false;
        });
    }

    searchAgain() {
        this.searchResults = null;
        window.scrollTo(0, 0);
        this.selectedIndex = 0;
    }

    async registerWithCode(policy) {
        this.saving = true;
        const entity = await this.entityApi.getLoggedInUser(false);
        this.login = new Login();

        this.login.id = entity.loginId;
        this.login.entityId = policy.id;

        const args = {
            entityId: policy.id,
            code: this.registrationCode,
            login: this.login,
        };

        const result = await this.customerRepository.attachRegistrationWithCode(args);
        this.saving = false;
        if (result) {
            // this.missionService.showSuccessToast('Registration Succeeded');
            this.searchResults.splice(this.searchResults.indexOf(policy), 1);
            this.selectedIndex = 1;
        } else {
            this.dialogService.alert('Could Not Register', 'Sorry, we could not register you with the code provided.');
        }
    }
}
