<div class="container fancy-container" *ngIf="workOrderSummary">
    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <div padding>
                <div class="center">
                    <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/event-orange-blob.svg" style="height: 100px">
                    <h2 class="sub-heading" topmargin20>Schedule Follow-Up</h2>
                </div>
                <p class="info" topmargin20 bottommargin40>
                    We show that all parts have been delivered for the repair on your {{workOrderSummary.itemName}}.
                    Please let us know your availability by picking 3 time slots below:
                </p>
                <ng-container *ngIf="workOrderSummary && timeSlots">
                    <app-appointment-selection [timeSlots]="timeSlots" [(selectedTimeSlots)]="selectedTimeSlots"
                        [timeSlotDayBuffer]="3">
                    </app-appointment-selection>
                </ng-container>
                <div >
                    <button mat-button mat-raised-button mobile-block color="primary" [disabled]="!canSave" (click)="save()">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Save
                    </button>
                </div>
            </div>

            <p class="info" topmargin40>
                Has the service pro already scheduled or completed your follow-up appointment?  Click the "Appointment Already Scheduled" button.
            </p>
            <button mat-button mat-raised-button (click)="appointmentAlreadyScheduled()">Appointment Already Scheduled</button>
        </mat-tab>
        <mat-tab label="Second">
            <div class="center">
                <div topmargin40>

                </div>
                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin20>Thank You</h1>
                <p>We'll send your preferred times to your service pro and have them confirm scheduling with you.</p>
            </div>
        </mat-tab>
        <mat-tab label="Second">
            <div class="center">
                <div topmargin40>

                </div>
                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin20>Thank You</h1>
                <p>We'll make a note that your follow-up appointment has already been scheduled.</p>

            </div>
        </mat-tab>
    </mat-tab-group>

</div>