<div class="container fancy-container" style="padding: 20px">

    <div *ngIf="planDetail">
        <div *ngIf="planDetail.coverage">

            <div class="center">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/circle-document.svg" style="height: 75px;">
                <h1 class="sub-heading">{{planDetail.coverage.title}}</h1>
            </div>

            <div class="columns" topmargin40>
                <div class="data-label" topmargin20>Covered</div>
                <div [innerHTML]="planDetail.coverage.coveredLanguage"></div>

                <div class="data-label" topmargin20>Not Covered</div>
                <div [innerHTML]="planDetail.coverage.notCoveredLanguage"></div>

                <div class="data-label" topmargin20>Limits</div>
                <div [innerHTML]="planDetail.coverage.limitsLanguage"></div>
            </div>
        </div>
    </div>

    <div *ngIf="loading">
        <mat-spinner thirty class="thirty"></mat-spinner> Loading coverage language...
    </div>
</div>