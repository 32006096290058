<div class="container">
    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab>
            <div style="max-width: 600px; display: table;margin: 0 auto" *ngIf="args.summary" class="center">
                <h1 style="text-align: center;" bottommargin40 class="sub-heading">Approve Suggested Repair</h1>
                <img [src]="itemUrl" style="max-height: 60px">

                <p class="info" style="    max-width: 600px;
                display: table;
                margin: 0 auto;
                margin-top: 30px;">
                    During a recent {{args.summary.maintenanceServiceName}} visit,
                    {{args.summary.contractorName}} found a problem with your
                    {{args.summary.workOrderItemName}} and suggested a repair.<br><br> The good news is it's
                    covered by your Elevate Homescriptions warranty!
                </p>


                <div *ngIf="args.summary.additionalNotes">
                    <p class="info" style="text-align: left;">
                        Here is what they have to say:
                    </p>

                    <blockquote>
                        <span class="quote-mark">&ldquo;</span>
                        {{args.summary.additionalNotes}}
                        <span class="quote-mark">&rdquo;</span>
                    </blockquote>
                </div>
                <button mat-button mat-raised-button (click)="decline()">
                    Decline Repairs
                </button>&nbsp;&nbsp;
                <button mat-button mat-raised-button color="primary" class="small-primary" (click)="beginApprove()">
                    Begin Authorization
                </button>
            </div>


        </mat-tab>
        <mat-tab label="First">
            <div *ngIf="args.summary && args.serviceFee > 0" style="max-width: 600px; display: table;margin: 0 auto">
                <p class="info">
                    A Service fee payment of {{args.serviceFee | currency:'USD':true:'1.0-0'}} is due now. You can pay
                    with
                    Visa, Mastercard, Discover and American Express
                </p>

                <div class="data-label">Due Now</div>
                <h1 class="big-data">{{args.serviceFee | currency:'USD':true:'1.0-0'}}</h1>
                <app-payment-method-entry *ngIf="args.summary && args.serviceFee > 0"
                    [customerId]="args.summary.customerId" paymentDescription="Service Fee Payment"
                    [paymentAmount]="args.serviceFee" [(selectedCard)]="args.card">
                </app-payment-method-entry>

                <div topmargin30>
                    <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner> Authorize Now
                    </button>

                </div>
            </div>
        </mat-tab>
        <mat-tab label="Second">
            <div class="center">
                <app-check-circle-animated></app-check-circle-animated>
                <h1>Success</h1>
                <p class="info">Your job has been submitted</p>
            </div>
        </mat-tab>
        <mat-tab label="Third">
            <div class="center">
                <h1>No problem</h1>
                <p class="info">
                    You can always start a service repair at a later time by visiting our <a
                        routerLink="/select-property">Request Service</a> page.
                </p>

                <div topmargin40>
                    <a mat-button mat-raised-button color="primary" routerLink="/select-property">Request Service</a>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>