import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { PolicyRenewalModel } from '@cogent/shared/models/sales/policy-renewal.model';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { ApiService } from '@cogent/client/api';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { PromotionCodeSummary } from '@upkeeplabs/models/cogent';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { CoverageType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { CommonModule } from '@angular/common';
import { AutoRenewComponent } from '@cogent/client/apps/homeowner/sales/auto-renew/auto-renew.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { OrderSummaryComponent } from '@cogent/client/apps/homeowner/sales/order-summary/order-summary.component';

@Component({
    selector: 'app-renew',
    templateUrl: './renew.component.html',
    styleUrls: ['./renew.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        AutoRenewComponent,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        PlanOfferingsModule,
        MatCheckboxModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        DisplayTotalModule,
        CheckCircleAnimatedComponent,
        RouterModule,
        OrderSummaryComponent
    ]
})
export class RenewComponent implements OnInit {

    selectedStripeCard: StripeCard;
    policyId: string;
    policySummary: PolicySummary;
    plans: PlanClient[];
    selectedPlan: PlanClient;
    newPolicy: PolicyRenewalModel = new PolicyRenewalModel();
    selectedIndex = 0;
    numbers = [0, 1, 2, 3, 4, 5, 6];
    renewing = false;
    loading = false;
    policyWasRenewed = false;
    dbNewPolicy: any;
    @ViewChild('paymentMethodEntry') paymentMethodEntry: PaymentMethodEntryComponent;
    savingPaymentMethod: boolean;

    otherPolicy
    isLegacy = true;

    constructor(
        private route: ActivatedRoute,
        private customerRepository: CustomerRepositoryService,
        private policyApi: PolicyApiService,
        private dialog: DialogsService,
        private planApi: PlanApiService,
    ) { }

    ngOnInit() {

        this.route.params.subscribe(params => {
            this.policyId = params.id;
            document.location.hash = '0';

            this.initialize();
        });

    }

    doNotRenew = false;
    async initialize() {
        this.loading = true;

        if (await this.policyApi.isPolicyRenewed(this.policyId)) {

            this.policyWasRenewed = true;
            this.selectedIndex = 1;
            this.loading = false;
            return;
        }

        this.policySummary = await this.policyApi.getRenewalPolicySummary(this.policyId);
        if (this.policySummary.doNotRenew) {
            this.doNotRenew = true;
            this.selectedIndex = 1;
            this.loading = false;
            return;
        }
        if (!this.policySummary.autoRenew) {
            await this.showPlanOptions();
        }
        else {
            this.otherPolicy = await this.policyApi.getRenewalPolicySummary2(this.policyId);
            this.loading = false;
        }

    }

    async showPlanOptions() {
        this.loading = true;
        this.selectedIndex = 1;
        this.plans = await this.planApi.getOfferedPlans(CoverageType.Renewal,
            this.policySummary.propertyAddress.dwellingType as any,
            this.policySummary.propertyAddress.postalCode,
            this.policySummary.propertyAddress.squareFeet,
            new Date().getFullYear() - this.policySummary.propertyAddress.yearBuilt,
            this.policySummary.id);
        this.loading = false;
    }

    getSampleContractUrl(item: any) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }

    planSelected(newPlan: PlanClient) {
        this.newPolicy.optionalItems = newPlan.optionalItems;
        this.newPolicy.renewalPlanId = newPlan.id;
        this.newPolicy.adjustments = newPlan.renewalAdjustments;
        this.selectedIndex = 2;
    }

    get addressStreetView() {
        if (!this.policySummary) {
            return null;
        }


        return `${ApiService.endPointDotNet}policy/${this.policyId}/StreetView`;
    }



    async renewPolicy() {
        this.renewing = true;
        if (this.selectedStripeCard) {
            this.newPolicy.stripeCardId = this.selectedStripeCard.id;
        } else {
            this.newPolicy.stripeCardId = null;
        }
        if (this.promotionCode) {
            this.newPolicy.promotionCodeId = this.promotionCode.id;
        }
        this.newPolicy.createdSource = 'CUSTOMER_PORTAL';

        const newPolicy = await this.customerRepository.renewPolicy(this.policySummary.id, this.newPolicy);

        this.renewing = false;
        this.dbNewPolicy = newPolicy;
        this.selectedIndex = 5;
    }

    get canSubmit() {
        return this.selectedPlan;
    }

    get newPolicyUrl() {
        if (!this.dbNewPolicy) {
            return null;
        }

        return `/view-property/${this.dbNewPolicy.Id}`;
    }


    @HostListener('window:hashchange', ['$event'])
    watchUrlHash(event) {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }


        let hashNumber = parseInt(hash, 10);
        if (this.selectedIndex === 5) {
            return;
        }

        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }

        if (!isNaN(hashNumber)) {
            this.selectedIndex = hashNumber;
        }

    }

    setHash(index) {
        document.location.hash = index;
    }

    paymentNext() {
        if (this.paymentMethodEntry.addingNewCard && this.paymentMethodEntry.canSubmit) {
            this.paymentMethodEntry.savePaymentMethod();
        } else {
            if (this.selectedStripeCard) {
                this.selectedIndex = 4;
            } else {
                this.dialog.alert('Confirm', 'Please enter a valid credit card');
                // this.dialog.confirm('Confirm', 'No credit card entered.<br>Paying by credit card is the easiest and most convenient way of paying for your subscription.<br><br>Are you sure you want to continue? ').subscribe(results => {
                //     if (results) {
                //         this.selectedIndex = 4;
                //     }
                // });
            }
        }
    }

    selectedCardChange(card: StripeCard) {
        if (card && this.selectedIndex == 2) {
            this.selectedIndex = 3;
        }
    }
    promotionCodeSearch: string;
    promotionCode: PromotionCodeSummary;
    async findPromotionCode() {
        this.promotionCode = null;
        const code = await this.customerRepository.getPromotionCode(this.promotionCodeSearch, CoverageType.Renewal);
        if (code) {
            let msg = "";

            // CHECK IF CODE IS EXPIRED
            if (code.expirationDate) {
                const today = new Date();
                let expired = false;
                if (code.expirationDate.getFullYear() < today.getFullYear()) {
                    expired = true;
                }
                else if (code.expirationDate.getFullYear() === today.getFullYear()) {
                    if (code.expirationDate.getMonth() < today.getMonth()) {
                        expired = true;
                    }
                    else if (code.expirationDate.getMonth() === today.getMonth()) {
                        if (code.expirationDate.getUTCDate() < today.getUTCDate()) {
                            expired = true;
                        }
                    }
                }

                if (expired) {
                    const expYear = code.expirationDate.getFullYear();
                    const expMonth = code.expirationDate.getMonth() + 1;
                    const expDate = code.expirationDate.getUTCDate();
                    msg += 'Sorry, that promotion code expired on ' + expMonth + '/' + expDate + '/' + expYear + '<br>';
                }
                else {
                    this.promotionCode = code;
                }
            }

            if (msg != "") {
                this.dialog.alert('Expired', msg);
            }
            else {
                // CHECK IF CODE DOES NOT APPLY TO RENEWALS
                if (!code.appliesToRenewal) {
                    this.promotionCode = null;
                    if (code.appliesToRealEstate) {
                        msg += 'Sorry, that promotion code is for Real Estate plans';
                    }
                    else if (code.appliesToD2C) {
                        msg += 'Sorry, that promotion code is not for Renewals';
                    }
                    this.dialog.alert('Invalid Code', msg);
                } else {
                    this.promotionCode = code;
                }
            }
        }
        else {
            this.dialog.alert('Not Found', 'Sorry, that promotion code could not be found');
            this.promotionCodeSearch = '';
        }
    }


    get selectedCardTypeUrl() {
        if (!this.selectedStripeCard) {
            return null;
        }

        if (this.selectedStripeCard.brand === 'Visa') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png';
        } else if (this.selectedStripeCard.brand === 'Discover') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png';
        } else if (this.selectedStripeCard.brand === 'American Express') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png';
        } else if (this.selectedStripeCard.brand === 'MasterCard') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png';
        }
    }


    optionSelected(option: SalesItemModel) {
        if (option.selected && option.quantity === 0) {
            option.quantity = 1;
        }
        if (!option.selected) {
            option.quantity = 0;
        }
    }

    get planBasePrice(): number {
        if (!this.selectedPlan) {
            return 0;
        }

        return this.newPolicy.isMonthly ? (this.selectedPlan.priceRecurring + this.selectedPlan.monthlyProcessingFee) : this.selectedPlan.price;
    }

    get optionsPrice(): number {
        if (!this.selectedPlan) {
            return;
        }

        let total = 0;
        this.newPolicy.optionalItems.filter(i => i.selected).forEach(item => total += item.quantity *
            (this.newPolicy.isMonthly ? item.priceRecurring : item.price));
        return total;
    }

    get totalPlanPrice(): number {
        return this.planBasePrice + this.optionsPrice + this.adjustmentAmount;
    }

    get adjustmentAmount() {
        // let amount = 0;
        // if (this.newPolicy && this.newPolicy.adjustments && this.newPolicy.adjustments.length > 0) {
        //     for (const adjustment of this.newPolicy.adjustments) {
        //         amount += adjustment.adjustmentAmount;
        //     }
        // }
        // if (this.newPolicy.isMonthly){
        //     amount = amount / 12;
        // }
        // return amount;

        if (!this.promotionCode || !this.selectedPlan) {
            return 0;
        }

        if (this.promotionCode.priceAdjustmentType === 'Flat') {
            return this.newPolicy.isMonthly ? this.promotionCode.priceAdjustment / this.selectedPlan.monthsOfCoverage : this.promotionCode.priceAdjustment;
        } else if (this.promotionCode.priceAdjustmentType === "Percent") {
            return parseFloat((this.promotionCode.priceAdjustment * this.planBasePrice).toFixed(2));
        } else if (this.promotionCode.priceAdjustmentType === "Months") {
            this.newPolicy.isMonthly = true;
            return 0;
        }
    }

    get selectedOptions(): SalesItemModel[] {
        const results: SalesItemModel[] = [];

        if (this.selectedPlan) {
            this.newPolicy.optionalItems.filter(i => i.selected && i.quantity > 0).forEach(i => results.push(i));
        }
        return results;
    }
}
