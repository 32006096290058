import { ContractorProposedWorkOrderSummary } from "./contractor-proposed-work-order.model";
import { StripeCard } from "./stripe-card.model";

export class ConvertProposedWorkOrderToWorkOrderArgs {
    summary: ContractorProposedWorkOrderSummary;
    card: StripeCard;
    serviceFee: number;
    contactEmail: string;
    contactName: string;
    contactPhone: string;
    contactMobile: string;
    contractorId: string;
}