<div class="container">

  

    <div class="menu-container" >
        <div>
            <div class="selection">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/add-orange-blob.svg">
                <h2>Order New Coverage</h2>
                <p>I have a property I would like to order new coverage for</p>

                <a mat-button mat-raised-button color="primary" routerLink="../order">Order New</a>
            </div>

            <div class="selection">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/attach-orange-blob.svg">
                <h2>Attach Existing Coverage</h2>
                <p>I have existing coverage I would like to attach to my login</p>
                <a mat-button mat-raised-button color="primary" routerLink="../attach">Attach</a>
            </div>
        </div>
    </div>
</div>