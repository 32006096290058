<div flex *ngIf="!notTracking">
    <div id="info" padding *ngIf="workOrderSummary">

        <div class="mobile-flex mobile-dotted-top mobile-dotted-left">
            <div>

                <div class="data-label" bottommargin10>Estimated Time Of Arrival</div>
                <div flex>
                    <div nogrow>
                        <mat-icon style="font-size: 40px;height: 40px;width: 40px">access_time</mat-icon>
                    </div>
                    <div>

                        <h2 class="sub-heading">{{eta}}</h2>
                    </div>
                </div>
            </div>
            <div>

                <ng-container *ngIf="tech">
                    <div class="data-label remove-margin" bottommargin10 topmargin30>Technician</div>
                    <div flex>
                        <div nogrow>

                            <img class="my-avatar" [src]="techImageUrl">
                        </div>

                        <div>

                            <h2 class="sub-heading">{{tech.name}}</h2>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="mobile-flex mobile-dotted-left" *ngIf="workOrderSummary">
            <div>
                <div class="data-label remove-margin" topmargin30 bottommargin10>Covered Property</div>
                <div flex>
                    <div nogrow>
                        <img [src]="getStreetViewUrl300x300(workOrderSummary.policyId)" id="streetViewImage"
                            class="my-avatar">
                    </div>

                    <div>
                        <h2 class="sub-heading">{{workOrderSummary.propertyAddress}}</h2>
                        {{workOrderSummary.propertyCity}}, {{workOrderSummary.propertyState}}
                        {{workOrderSummary.propertyPostalCode}}
                    </div>
                </div>
            </div>
            <div>
                <div class="data-label remove-margin" topmargin30 bottommargin10>Service Request</div>

                <div flex>
                    <div nogrow>
                        <img class="my-avatar" [src]="getItemThumbnailUrl()">
                    </div>

                    <div>
                        <h2 class="sub-heading">{{workOrderSummary.number}}</h2>
                        {{workOrderSummary.itemName}}
                    </div>
                </div>
            </div>
        </div>

        <a topmargin20 mat-button block style="text-align: center;" routerLink="/work-order/{{workOrderId}}">View More
            <mat-icon>chevron_right</mat-icon>
        </a>
    </div>
    <div>
        <div class="map" [id]="mapId"></div>

    </div>
</div>

<div *ngIf="notTracking" class="container" topmargin40>
    <div aflex class="soft-light" padding>
        <div nogrow> <img src="../../../assets/images/not-allowed.svg" style="height: 150px;"></div>
        <div>
            <h1>Not Allowed</h1>
            <p>Sorry, this technician can no longer be tracked.  Hopefully the technician has arrived and work has begun.  </p>
            <a topmargin20 mat-button mat-raised-button routerLink="/work-order/{{workOrderId}}">View
                More
                <mat-icon>chevron_right</mat-icon></a>
        </div>
    </div>
</div>