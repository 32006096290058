import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicEntitySummaryComponent} from './basic-entity-summary.component';
import { PhoneDisplayModule } from '@cogent/client/shared/components/communication/phone-display/phone-display.module';
import { EmailComposeLinkComponent } from '@cogent/client/shared/components/communication/email-compose-link/email-compose-link.component';


@NgModule({
  declarations: [BasicEntitySummaryComponent],
  exports: [BasicEntitySummaryComponent],
  imports: [
    CommonModule,
    PhoneDisplayModule,
    EmailComposeLinkComponent
  ]
})
export class BasicEntitySummaryModule { }
