import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import {CancelReason, StripeCard} from '@upkeeplabs/models/cogent';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentMethodEntryModule } from '../../../accounting/payment-method-entry/payment-method-entry.module';
import { PaymentMethodEntryComponent } from '../../../accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import {FormsModule} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatOption, MatSelect} from "@angular/material/select";

@Component({
    selector: 'app-change-auto-renewal',
    templateUrl: './change-auto-renewal.component.html',
    styleUrls: ['./change-auto-renewal.component.css'],
    standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    PaymentMethodEntryModule,
    PaymentMethodEntryComponent,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatRadioModule,
    MatSelect,
    MatOption
  ]
})
export class ChangeAutoRenewalComponent implements OnInit {

    saving = false;
    selectedCard: StripeCard;
    cancelAutoRenewReason: CancelReason;
    cancelAutoRenewReasons: CancelReason[]
    otherReasonValue: string;

    constructor(public dialogRef: MatDialogRef<ChangeAutoRenewalComponent>,
        @Inject(MAT_DIALOG_DATA) public policySummary: PolicySummary,
        private policyApi: PolicyApiService,
        private settingsApi: SettingsApiService) { }


    async ngOnInit() {
      this.cancelAutoRenewReasons = await this.policyApi.getAutoRenewCancelReasons()
    }

  get canSave() {

      if (this.policySummary.autoRenew && this.cancelAutoRenewReasons?.length > 0 && !this.cancelAutoRenewReason) {
        return false;
      }

      if (this.policySummary.autoRenew) {
          return !this.saving;
      }
      if (this.saving || !this.selectedCard) {
          return false;
      }
      return true;
    }

    async save() {
        this.saving = true;
        if (!this.policySummary.autoRenew) {
            await this.policyApi.updateAutoRenewStatus(this.policySummary.id, true, this.selectedCard);
        } else {
            await this.policyApi.updateAutoRenewStatus(this.policySummary.id, false, null, this.cancelAutoRenewReason?.id)
        }


        //403 error on get request for tags
        // const tags = await this.settingsApi.getTagsByType(false, true, false, false, false, false, false, false);
        // const autoRenewOptInTag = (tags).find(t => t.name === "Auto-Renew Opt-In");
        // if (autoRenewOptInTag) {
        //     await this.policyApi.addTags(this.policySummary.id, [autoRenewOptInTag]);
        // }
        this.saving = false;
        this.dialogRef.close(true);
    }
}
