<div class="container fancy-container">
    <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs">
        <mat-tab label="First">
            <div aflex>
                <div>
                    <h1 class="page-title">
                        <span class="big">Photos</span>
                        <br>
                        <span class="little">&amp; Video</span>
                    </h1>
                </div>
                <div nogrow style="margin: 0">
                    <button mat-button large mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Save
                    </button>
                </div>
            </div>

            <div class="info">
                Upload any photos that may help our service pro diagnose your item.
            </div>

            <div *ngIf="!attachments || !attachments.length" style="text-align: center" topmargin30>
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/camera-orange-blob.svg" style="max-width: 150px">

            </div>
            <div *ngIf="attachments && attachments.length" topmargin30 class="soft-light"
                style="margin-left: 20px;margin-right: 20px;">
                <h3 class="sub-heading" topmargin20>My Photos</h3>
                <div *ngFor="let attachment of attachments" class="attachment-item" aflex>
                    <div nogrow>
                        <img [src]="attachment.base64">
                    </div>
                    <div style="overflow: hidden; ">
                        <div class="data-label" style="overflow: hidden; text-overflow: ellipsis">
                            {{attachment.name}}</div>
                    </div>
                    <div nogrow>
                        <button mat-button class="remove-button" (click)="removeAttachment(attachment)">
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="top-margin-30" class="center">
                <button topmargin20 class="soft-light-button" (click)="showFileUpload()" mat-raised-button mat-button
                    mobile-block large>
                    <i class="material-icons" style="color: rgba(0,0,0,.6) !important">add_photo_alternate</i><br>
                    <span *ngIf="hasNativeCamera">Add From Camera</span>
                    <span *ngIf="!hasNativeCamera">Add Photo(s)</span>
                </button>

                <button topmargin20 *ngIf="hasNativeCamera" style="color: rgba(0,0,0,.6) !important"
                    (click)="showFromLibrary()" mat-raised-button mat-button mobile-block large>
                    <i class="material-icons" style="color: rgba(0,0,0,.6) !important">photo_library</i> Add
                    From
                    Library</button>
            </div>



            <input type="file" style="display: none" name="fileToUpload1" (change)="handleFileUpload($event)"
                accept="image/*" id="fileToUpload1" />
        </mat-tab>
        <mat-tab label="First">

            <div class="center">
                <app-check-circle-animated *ngIf="selectedIndex === 1"></app-check-circle-animated>
                <h1 topmargin30>Thanks For Sharing</h1>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>