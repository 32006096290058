import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Address } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-address-street-view',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './address-street-view.component.html',
    styleUrls: ['./address-street-view.component.css']
})
export class AddressStreetViewComponent implements OnInit {

    @Input() height = 40;
    @Input() width = 40;
    @Input() address: Address;

    constructor() { }

    ngOnInit(): void {
    }

    get url() {
        if(!this.address || !this.address.address1 || !this.address.state || !this.address.city || !this.address.postalCode) {
            return null;
        }

        return `https://maps.googleapis.com/maps/api/streetview?size=${this.width}x${this.height}&location=${this.address.address1}%20${this.address.city}%20${this.address.state}%20${this.address.postalCode}&key=AIzaSyB1xEizJ3bh0fvxTQkB-Pit7cPkzgr-tl0`;

    }

}
