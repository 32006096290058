<div class="wizard-header" [class.embedded]="embedded" *ngIf="selectedIndex > 0 && selectedIndex !== 8">
    <div class="container">
        <div aflex>
            <div>
                <h1 class="page-title" *ngIf="selectedIndex === 1">

                    <span class="big">Covered</span>
                    <br>
                    <span class="little">Property</span>
                </h1>
                <h1 class="page-title" *ngIf="selectedIndex === 2">
                    <span class="big">Plan</span>
                    <br>
                    <span class="little">Selection</span>
                </h1>
                <h1 class="page-title" *ngIf="selectedIndex === 3">
                    <span class="big">Available</span>
                    <br>
                    <span class="little">Options</span>
                </h1>
                <h1 class="page-title" *ngIf="selectedIndex === 4">
                    <span class="big">{{customerTypeName}}</span>
                    <br>
                    <span class="little">Information</span>
                </h1>
                <h1 class="page-title" *ngIf="selectedIndex === 5">

                    <span class="big">Payment</span>
                    <br>
                    <span class="little">Information</span>
                </h1>
                <h1 class="page-title" *ngIf="selectedIndex === 6">
                    <span class="big">Closing</span>
                    <br>
                    <span class="little">Information</span>
                </h1>
                <h1 class="page-title" *ngIf="selectedIndex === 7">
                    <span class="big">Review</span>
                    <br>
                    <span class="little">&amp; Submit</span>
                </h1>
            </div>
            <div nogrow>
                <button mat-button *ngIf="selectedIndex !== 6" title="Go Back"
                    (click)="selectedIndex = selectedIndex - 1">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button *ngIf="selectedIndex === 6" title="Go Back"
                    (click)="selectedIndex = selectedIndex - 2">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button *ngIf="selectedIndex === 1" color="primary" class="small-primary"
                    (click)="validateAddress()" [disabled]="!addressFilledOut || validatingAddress || gettingMeta">
                    <div>Next
                        <mat-icon>chevron_right</mat-icon>
                    </div>
                </button>
                <button mat-button *ngIf="selectedIndex === 3 && selectedPlan" mat-raised-button color="primary"
                    class="small-primary"
                    (click)="selectedIndex = customerSelectedIndex;recordStepProgress('optionsComplete')">
                    <div>Next <mat-icon>chevron_right
                        </mat-icon></div>
                </button>
                <button mat-button mat-raised-button *ngIf="selectedIndex === 4" color="primary" class="small-primary"
                    [disabled]="!canSaveCustomer || (isSellersCoverage && !newPolicy.policy.mlsNumber)"
                    (click)="saveCustomerAndGoNext()">
                    <div>
                        <mat-spinner *ngIf="savingCustomer" class="thirty"></mat-spinner>
                        Next
                        <mat-icon>chevron_right</mat-icon>
                    </div>
                </button>
                <button *ngIf="selectedIndex === 6" mat-button mat-raised-button color="primary" class="small-primary"
                    (click)="completeClosingInformation()">
                    <div>Next

                        <mat-icon>chevron_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>

<div flex>
    <div nogrow class="hide-mobile" id="side-panel" *ngIf="selectedIndex > 0 && selectedIndex < 8">
        <div id="status-list">
            <ul style="max-width: 330px;">
                <li [class.active-step]="selectedIndex === 1" (click)="goToStepIfAllowed(1)"
                    [class.complete]="selectedIndex > 1">

                    <div aflex style="width: 100%;" class="status-text">
                        <div>
                            <h2>Property</h2>
                            <span class="status-detail" *ngIf="selectedIndex > 1">
                                {{newPolicy.property.address1}} {{newPolicy.property.address2}}<br>
                                {{newPolicy.property.city}}, {{newPolicy.property.state}}
                                {{newPolicy.property.postalCode}}
                            </span>
                        </div>
                        <div nogrow *ngIf="selectedIndex > 1">
                            <button mat-button (click)="selectedIndex = 1">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                    </div>

                </li>
                <li [class.active-step]="selectedIndex === 2" (click)="goToStepIfAllowed(2)"
                    [class.complete]="selectedIndex > 2">


                    <div aflex style="width: 100%;" class="status-text">
                        <div>
                            <h2>Plan Selection</h2>
                            <span class="status-detail" *ngIf="selectedIndex > 2 && selectedPlan">
                                {{selectedPlan.name}}
                            </span>
                        </div>
                        <div nogrow *ngIf="selectedIndex > 2">
                            <button mat-button (click)="selectedIndex = 2">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li [class.active-step]="selectedIndex === 3" (click)="goToStepIfAllowed(3)"
                    [class.complete]="selectedIndex > 3">

                    <div aflex style="width: 100%;" class="status-text">
                        <div>
                            <h2>Options</h2>
                            <span class="status-detail" *ngIf="selectedIndex > 3 && selectedPlan">
                                {{selectedOptionsList}}
                            </span>
                        </div>
                        <div nogrow *ngIf="selectedIndex > 3">
                            <button mat-button (click)="selectedIndex = 3">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li [class.active-step]="selectedIndex === 4" (click)="goToStepIfAllowed(4)"
                    [class.complete]="selectedIndex > 4">

                    <div aflex style="width: 100%;" class="status-text">
                        <div>
                            <h2 *ngIf="!isSellersCoverage">Buyer</h2>
                            <h2 *ngIf="isSellersCoverage">Seller</h2>
                            <span class="status-detail" *ngIf="selectedIndex > 4">
                                {{newCustomer.name}}
                            </span>
                        </div>
                        <div nogrow *ngIf="selectedIndex > 4">
                            <button mat-button (click)="selectedIndex = 4">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li *ngIf="selectedCoverageType === 'RealEstate'" (click)="goToStepIfAllowed(6)"
                    [class.active-step]="selectedIndex === 6" [class.complete]="selectedIndex > 6">

                    <div aflex style="width: 100%;" class="status-text">
                        <div>
                            <h2>Closing</h2>
                        </div>
                        <div nogrow *ngIf="selectedIndex > 6">
                            <button mat-button (click)="selectedIndex = 6">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li *ngIf="selectedCoverageType !== 'RealEstate'" (click)="goToStepIfAllowed(5)"
                    [class.active-step]="selectedIndex === 5" [class.complete]="selectedIndex > 5">

                    <div aflex style="width: 100%;" class="status-text">
                        <div>
                            <h2>Payment</h2>
                        </div>
                        <div nogrow *ngIf="selectedIndex > 5">
                            <button mat-button (click)="selectedIndex = 5">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li [class.active-step]="selectedIndex === 7" [class.complete]="selectedIndex > 7">
                    <h2 class="status-text">Review</h2>

                </li>
            </ul>
        </div>
    </div>

    <div>
        <div class="container content-container" style="z-index: 0;"
            [class.the-container]="selectedIndex !== confirmationStepNumber && selectedIndex !== 0"
            [class.container-padding]="selectedPlan">
            <div style="margin-top:30px;">
                <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs no-padding"
                    (selectedIndexChange)="setHash($event)">

                    <mat-tab>
                        <div class="mobile-padding" style="padding-left: 10px;padding-right: 10px">
                            <ng-container *ngIf="!transactionType">

                                <div class="centering-container">
                                    <div></div>
                                    <div class="icon-button-container">
                                        <div class="real-estate-div">
                                            <img height="113"
                                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/real-estate-orange-blob.svg">
                                            <h3 style="margin-top: 60px">Pricing For Real Estate</h3>
                                            <h5>This subscription will be for a home that is being sold as part of a
                                                real
                                                estate
                                                transaction.</h5>

                                            <div class="solid-separator"></div>
                                            <button mat-raised-button mat-button color="primary" class="arrow-btn"
                                                (click)="setTransactionType('RealEstate')">
                                                View Plans
                                            </button>
                                        </div>

                                        <div class="homeowner-div">
                                            <img height="113"
                                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/person-house-orange-blob.svg">
                                            <h3 style="margin-top: 60px">Pricing For Homeowners</h3>
                                            <h5>
                                                I'm ordering a subscription for a home I currently live in.
                                            </h5>

                                            <div class="solid-separator"></div>
                                            <button mat-button mat-raised-button color="primary" data-icon="&#xe046;"
                                                class="arrow-btn" (click)="setTransactionType('Homeowner')">

                                                View Plans
                                            </button>
                                        </div>


                                    </div>
                                </div>

                            </ng-container>

                            <div class="fade-in-up" *ngIf="transactionType === 'RealEstate'">

                                <h1 class="center" *ngIf="selectedCoverageType !== 'RealEstate'">Homeowner Coverage</h1>

                                <div topmargin40>
                                    <div class="have-to-spend options-container" flex1 bottommargin10
                                        *ngIf="selectedCoverageType === 'RealEstate'">
                                        <h3>I Have</h3>
                                        <div class="options-block">
                                            <div style="display: table;margin: 0 auto">

                                                <form>
                                                    <div aflex>
                                                        <div>
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>$</mat-label>
                                                                <input matInput name="amount-to-spend" type="number" id="amount-to-spend-box"
                                                                    [(ngModel)]="amountToSpend">
                                                            </mat-form-field>
                                                        </div>
                                                        <div nogrow>
                                                            <button mat-button mat-raised-button color="primary"
                                                                class="arrow-btn" (click)="selectAmountToSpend()"> Order
                                                                Now</button>
                                                        </div>
                                                    </div>
                                                </form>



                                            </div>
                                            <h3 style="margin-top: 0">
                                                To Spend
                                            </h3>

                                        </div>

                                    </div>
                                </div>
                                <div flex topmargin40>

                                    <div flex1 bottommargin10 class="options-container big-padding">
                                        <div class="options-block">
                                            <h3 style="text-align: center;">Plans starting at:</h3>
                                            <div class="orange-title" *ngIf="selectedCoverageType === 'RealEstate'">$450
                                            </div>
                                            <div class="orange-title" *ngIf="selectedCoverageType !== 'RealEstate'">$450
                                            </div>
                                            <div>
                                                <button mat-button mat-raised-button color="primary" class="arrow-btn"
                                                    (click)="selectBase()">Show Me
                                                    Options</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div flex1 bottommargin10 class="options-container big-padding">
                                        <div class="options-block">
                                            <div>
                                                <h3>
                                                    Elevated</h3>
                                            </div>
                                            <div class="orange-title" *ngIf="selectedCoverageType === 'RealEstate'">
                                                $600
                                            </div>
                                            <div class="orange-title" *ngIf="selectedCoverageType !== 'RealEstate'">
                                                $600
                                            </div>
                                            <div>
                                                <button mat-button mat-raised-button color="primary" class="arrow-btn"
                                                    (click)="selectMostPopular()">Show
                                                    Me
                                                    Options</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div flex1 bottommargin10 class="options-container big-padding">
                                        <div class="options-block">

                                            <div>
                                                <div class="ribbon"><span>Most Popular</span></div>
                                                <h3>
                                                    Totally Elevated</h3>
                                            </div>
                                            <div class="orange-title" *ngIf="selectedCoverageType === 'RealEstate'">
                                                $750
                                            </div>
                                            <div class="orange-title" *ngIf="selectedCoverageType !== 'RealEstate'">
                                                $700
                                            </div>
                                            <div>
                                                <button mat-button mat-raised-button color="primary" class="arrow-btn"
                                                    (click)="selectedTotallyElevated()">Show Me
                                                    Options</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div *ngIf="selectedCoverageType === 'RealEstate'" bottommargin40 class="center">
                                    *These are price estimates for real estate transactions only. The actual price may
                                    vary
                                    by
                                    options and location.
                                </div>

                                <div style="display: table;margin: 0 auto;">
                                    <div flex>


                                        <div class="options-container" flex1 bottommargin10
                                            *ngIf="selectedCoverageType === 'RealEstate'">
                                            <div class="options-block">
                                                <div>
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/real-estate-orange-blob.svg">
                                                    <h3>
                                                        Listing<br>Coverage
                                                    </h3>
                                                </div>
                                                <div class="orange-title">$0</div>
                                                <div style="margin-bottom:25px">
                                                    <button mat-button mat-raised-button color="primary"
                                                        class="arrow-btn" (click)="selectSellersCoverage()">Order
                                                        Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="options-container" flex1 bottommargin10
                                            *ngIf="selectedCoverageType === 'RealEstate'">
                                            <div class="options-block">
                                                <div>
                                                    <img style="height: 100px;"
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/new-construction-orange-blob.svg">
                                                    <h3>
                                                        New<br>Construction
                                                    </h3>
                                                </div>
                                                <div class="separator"></div>
                                                <div style="margin-bottom:25px">
                                                    <button mat-button mat-raised-button color="primary"
                                                        class="arrow-btn" (click)="selectNewConstruction()">Order
                                                        Now</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <button mat-button (click)="transactionType = null">
                                    <mat-icon>chevron_left</mat-icon> Back
                                </button>
                            </div>

                            <div class="fade-in-up" *ngIf="transactionType === 'Homeowner'">
                                <div class="orange-border-container" flex1>
                                    <div class="options-block">
                                        <div class="fancy-title1">Homeowner Plans</div>
                                        <div>
                                            <button mat-button mat-raised-button (click)="selectHomeownerBase()">
                                                Show Me Options
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="mobile-padding">
                            <div class="container">

                                <div flex class="" topmargin40>
                                    <div>
                                        <app-address-editor *ngIf="newPolicy && newPolicy.property"
                                            [useOutlineFormField]="true"
                                            (addressCompleteChange)="addressCompleteChange($event)"
                                            [address]="newPolicy.property" (propertySelected)="propertySelected()">
                                        </app-address-editor>
                                        <div flex>
                                            <div>
                                                <mat-form-field appearance="outline" style="max-width: 350px;">
                                                    <mat-label>Dwelling Type</mat-label>
                                                    <mat-select block [(ngModel)]="newPolicy.property.dwellingType">
                                                        <mat-option value="Condo">Condo</mat-option>
                                                        <mat-option value="SingleFamily">Single Family</mat-option>
                                                        <mat-option value="Duplex">Duplex</mat-option>
                                                        <mat-option value="Triplex">Triplex</mat-option>
                                                        <mat-option value="Fourplex">Fourplex</mat-option>
                                                        <mat-option value="FivePlex">5-Plex</mat-option>
                                                        <mat-option value="SixPlex">6-Plex</mat-option>
                                                        <mat-option value="Mobile">Mobile</mat-option>
                                                        <mat-option value="Other">Unknown</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Property Square Footage</mat-label>
                                                    <input matInput id="property-square-footage"
                                                        [(ngModel)]="newPolicy.property.squareFeet">
                                                </mat-form-field>
                                            </div>
                                        </div>


                                    </div>

                                    <div id="about-property" class="show-gt-sm" nogrow>
                                        <div *ngIf="propertyMeta && !propertyMeta.squareFeetNotFound">
                                            <h2 class="sub-heading">About Your Property</h2>
                                            <div class="data-label">Square Footage</div>
                                            <div>{{propertyMeta.sqft}}</div>

                                            <div class="data-label" topmargin20>Year Built</div>
                                            <div>{{propertyMeta.yearBuilt}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div id="getting-property" *ngIf="gettingMeta">
                                <mat-spinner class="thirty"></mat-spinner><br>
                                Getting Information about your property
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Plan Selection">


                        <div class="order-plan-selection-container">

                            <div class="center" *ngIf="!loadingPlans && plans && plans.length === 0">
                                <app-frowny-face></app-frowny-face>
                                <h1>Sorry</h1>
                                <p class="info">We don't have any plans offered for your address.</p>
                                <button mat-button (click)="selectedIndex = selectedIndex - 1">
                                    <mat-icon>chevron_left</mat-icon> Back
                                </button>
                            </div>
                            <div *ngIf="!loadingPlans && plans && plans.length > 0">
                                <app-plan-offerings *ngIf="selectedIndex === planSelectionIndex"
                                    [hideMonthlySelection]="selectedCoverageType === 'RealEstate'"
                                    [(isMonthly)]="newPolicy.policy.isMonthly" [plans]="plans" [hideTitle]="true"
                                    [(selectedPlan)]="selectedPlan" (selectedPlanChange)="planSelected($event)">
                                </app-plan-offerings>
                            </div>
                        </div>

                        <mat-spinner *ngIf="loadingPlans" style="display: table; margin: 0 auto"></mat-spinner>

                    </mat-tab>
                    <mat-tab label="Options">

                        <div *ngIf="selectedPlan">
                            <div id="mobile-options" style="margin-top: 40px">
                                <div class="mobile-option" [class.expanded]="option.selected && option.allowQuantity"
                                    aflex *ngFor="let option of selectedPlan.optionalItems">

                                    <div nogrow>
                                        <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                                        </mat-checkbox>

                                    </div>
                                    <div style="margin-left: 0;" class="option-item-name-container">
                                        <h2 (click)="option.selected = !option.selected; optionSelected(option);">
                                            {{option.name}}
                                        </h2>
                                        <div>
                                            <div topmargin10 *ngIf="option.selected && !option.allowQuantity"><span
                                                    class="data-label">Quantity:
                                                </span> 1</div>
                                            <div aflex *ngIf="option.selected && option.allowQuantity" topmargin30>
                                                <div nogrow class="data-label">Quantity: </div>
                                                <div>

                                                    <mat-form-field appearance="outline"
                                                        style="margin-top: -20px; width: 100px !important"
                                                        class="no-padding">
                                                        <mat-select [(ngModel)]="option.quantity">
                                                            <mat-option *ngFor="let number of numbers" [value]="number">
                                                                {{ number }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nogrow>
                                        <span *ngIf="!newPolicy.policy.isMonthly">{{option.price|
                                            currency:'USD':'symbol':'1.2-2'}}</span>
                                        <span *ngIf="newPolicy.policy.isMonthly">{{option.priceRecurring|
                                            currency:'USD':'symbol':'1.2-2'}}</span><br>
                                        <button mat-button class="coverage-button" (click)="showCoverage(option)">
                                            <i style="opacity: .3" class="material-icons">search</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="table" id="desktop-options">
                                <div class="table-header">
                                    <div flex3>Item</div>
                                    <div flex1>Price</div>
                                    <div flex1>Selected</div>
                                    <div flex1>Qty</div>
                                </div>
                                <div class="table-row" *ngFor="let option of selectedPlan.optionalItems">
                                    <div flex3>{{option.name}}<button mat-button (click)="showCoverage(option)">
                                            <i style="opacity: .3" class="material-icons">search</i>
                                        </button></div>
                                    <div flex1>
                                        <span *ngIf="!newPolicy.isMonthly">{{option.price|
                                            currency:'USD':'symbol':'1.2-2'}}</span>
                                        <span *ngIf="newPolicy.isMonthly">{{option.priceRecurring|
                                            currency:'USD':'symbol':'1.2-2'}}</span>
                                    </div>
                                    <div flex1>
                                        <mat-checkbox [(ngModel)]="option.selected" (change)="optionSelected(option)">
                                        </mat-checkbox>

                                    </div>
                                    <div flex1>
                                        <span *ngIf="option.selected && !option.allowQuantity">
                                            1</span>
                                        <div style="margin-top: -5px" *ngIf="option.selected && option.allowQuantity">

                                            <mat-form-field style="max-width: 100px;" appearance="outline"
                                                class="no-padding">
                                                <mat-select [(ngModel)]="option.quantity">
                                                    <mat-option *ngFor="let number of numbers" [value]="number">
                                                        {{ number }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>

                    <mat-tab>
                        <div class="mobile-padding">


                            <div flex topmargin40>
                                <div style="max-width: 500px;">
                                    <div bottommargin20 *ngIf="isSellersCoverage">
                                        <div class="data-label">Listing Information
                                        </div>
                                    </div>
                                    <mat-form-field appearance="outline" bottommargin20 *ngIf="isSellersCoverage">
                                        <mat-label>MLS Number <span
                                                *ngIf="!newPolicy.policy.mlsNumber">*</span></mat-label>
                                        <input matInput autocomplete="0" [(ngModel)]="newPolicy.policy.mlsNumber">

                                    </mat-form-field>
                                    <div bottommargin20>
                                        <div class="data-label">Primary
                                            {{customerTypeName}}
                                        </div>
                                    </div>
                                    <app-basic-entity-entry [outline]="true" [nameIsSearchBox]="false"
                                        [(canSave)]="isCustomerValid" [showRequiredName]="true"
                                        (fieldChanged)="trackFormEntry($event)"
                                        [showRequiredEmail]="selectedCoverageType === 'Homeowner'"
                                        [hideContactMethodsEntry]="true" [hideTimezoneEntry]="true"
                                        [homePhoneRequired]="selectedCoverageType === 'Homeowner'"
                                        [showSinglePhoneNumberEntry]="true" [(entity)]="newCustomer">
                                    </app-basic-entity-entry>

                                    <div style="max-width:300px; margin-top:10px">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Preferred Language</mat-label>
                                            <mat-select [(ngModel)]="newCustomer.preferredLanguageId">
                                                <mat-option *ngFor="let language of languages"
                                                    [value]="language.id">{{language.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <button mat-button *ngIf="showAddCoHolder" (click)="showCoBuyer = !showCoBuyer">
                                        <mat-icon>add</mat-icon>
                                        <span *ngIf="!showCoBuyer">Add Co-{{customerTypeName}}</span>
                                        <span *ngIf="showCoBuyer">Hide Co-{{customerTypeName}}</span>
                                    </button>
                                </div>
                                <div *ngIf="showCoBuyer">
                                    <div bottommargin20>
                                        <div class="data-label">Co-{{customerTypeName}}</div>
                                    </div>

                                    <app-basic-entity-entry [outline]="true" [nameIsSearchBox]="false"
                                        [showRequiredEmail]="false" [hideContactMethodsEntry]="true"
                                        [hideTimezoneEntry]="true" [homePhoneRequired]="false"
                                        [showSinglePhoneNumberEntry]="true" [(entity)]="newCoCustomer">
                                    </app-basic-entity-entry>

                                    <div style="max-width:300px; margin-top:10px">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Preferred Language</mat-label>
                                            <mat-select [(ngModel)]="newCoCustomer.preferredLanguageId">
                                                <mat-option *ngFor="let language of languages"
                                                    [value]="language.id">{{language.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="showHowDidYouHearAboutUs">
                                <div class="data-label" topmargin30 bottommargin20>How Did You Hear About Us? *</div>

                                <mat-radio-group [(ngModel)]="selectedMarketingSource"
                                    (ngModelChange)="marketingSourceChanged($event)">
                                    <mat-radio-button *ngFor="let marketingSource of marketingSources"
                                        [value]="marketingSource.id">
                                        {{marketingSource.name}}</mat-radio-button>
                                    <mat-radio-button value="none">None of the above</mat-radio-button>
                                </mat-radio-group>
                            </ng-container>


                            <div class="fade-in-up" topmargin30 *ngIf="showAgentStuff" style="max-width: 400px">
                                <div class="data-label">Who do we have to thank for the order?</div>
                                <app-search-chip-selection searchSubType="Agent" [singleSelect]="true"
                                    [showEntityView]="true"
                                    [(selectedResults)]="selectedReferringAgent"></app-search-chip-selection>
                            </div>

                            <div class="fade-in-up" topmargin30 *ngIf="showAeStuff" style="max-width: 400px">
                                <div class="data-label">Who do we have to thank for the order?</div>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="selectedReferringAe">
                                        <mat-option *ngFor="let employee of accountExecs"
                                            [value]="employee.id">{{employee.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div topmargin40></div>
                            <ng-container *ngIf="selectedCoverageType !== 'RealEstate' && customerPortalEnabled">
                                <mat-checkbox [(ngModel)]="doCreateLogin">Create a login to our customer portal
                                </mat-checkbox>
                                <div topmargin10 *ngIf="doCreateLogin" style="max-width: 300px">
                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            <mat-icon>lock</mat-icon> Password *
                                        </mat-label>
                                        <input matInput type="password" (ngModelChange)="trackFormEntry('password')"
                                            [(ngModel)]="newPassword">
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            <mat-icon>lock</mat-icon> Confirm Password *
                                        </mat-label>
                                        <input matInput type="password"
                                            (ngModelChange)="trackFormEntry('passwordConfirm')"
                                            [(ngModel)]="passwordConfirm">
                                    </mat-form-field>
                                    <div style="color:red; margin-top: -15px; text-align: center;"
                                        *ngIf="doCreateLogin && newPassword && passwordConfirm && newPassword != passwordConfirm">
                                        * passwords must match</div>
                                </div>
                            </ng-container>

                            <div topmargin40></div>
                            <mat-checkbox [(ngModel)]="usePropertyAddressAsMailingAddress">The mailing address is the
                                same
                                as the
                                property address</mat-checkbox>

                            <div *ngIf="!usePropertyAddressAsMailingAddress" topmargin20>
                                <app-address-editor [useOutlineFormField]="true"
                                    [(addressComplete)]="mailingAddressComplete" [address]="mailingAddress">
                                </app-address-editor>
                            </div>


                        </div>

                    </mat-tab>
                    <mat-tab>
                        <div class="mobile-padding" topmargin40>

                            <div style="margin-bottom: 20px">
                                <div *ngIf="newCustomer">
                                    <app-payment-method-entry [customerId]="newCustomer.id"
                                        paymentDescription="Premium Payment" [paymentAmount]="totalPlanPrice"
                                        [(selectedCard)]="selectedCard" [newCustomerMode]="true"
                                        (selectedCardChange)="paymentValid($event)">
                                    </app-payment-method-entry>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="mobile-padding" topmargin40>

                            <div class="two-column-layout" *ngIf="!isSellersCoverage">
                                <div>
                                    <div class="data-label">Who should we thank for this order?</div>
                                    <mat-form-field appearance="outline" style="max-width: 300px;">

                                        <mat-select [(ngModel)]="newPolicy.policy.initiatorType">
                                            <mat-option value="Buyer">Buyer's Agent</mat-option>
                                            <mat-option value="Seller">Seller's Agent</mat-option>
                                            <mat-option value="Title">Title / Escrow Officer</mat-option>
                                            <mat-option value="Unknown">Unknown</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <div class="data-label">Who Do We Invoice?</div>
                                    <button mat-button class="toggle-button bill-to offset"
                                        (click)="newPolicy.billTo = 'Title'"
                                        [class.selected]="newPolicy.billTo === 'Title'">
                                        <mat-icon>corporate_fare</mat-icon><br>
                                        Title
                                    </button>
                                    <button mat-button class="toggle-button bill-to offset"
                                        (click)="newPolicy.billTo = 'Agent'"
                                        [class.selected]="newPolicy.billTo === 'Agent'">
                                        <mat-icon>real_estate_agent</mat-icon><br>
                                        Agent
                                    </button>

                                    <button mat-button class="toggle-button bill-to offset"
                                        (click)="newPolicy.billTo = 'Customer'"
                                        [class.selected]="newPolicy.billTo === 'Customer'">
                                        <mat-icon>cottage</mat-icon><br>
                                        Homeowner
                                    </button>
                                </div>
                            </div>




                            <div flex style="margin-top: 65px" class="closing-info-container">
                                <div class="soft-light padding" flex1 style="margin: 10px" *ngIf="!isSellersCoverage">
                                    <h2>Closing Information</h2>
                                    <div topmargin20>
                                        <div class="ribbon" *ngIf="newPolicy.policy.initiatorType === 'Title'">
                                            <span>Initiator</span>
                                        </div>



                                        <form topmargin20 style="position: relative;">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Closing Officer</mat-label>
                                                <input type="text" matInput [(ngModel)]="newPolicy.newEscrowName"
                                                    [formControl]="myClosingControl" [matAutocomplete]="auto1">
                                                <mat-autocomplete panelWidth="400px" (opened)="titleSearchOpened()"
                                                    (closed)="titleSearchClosed()" class="multi-line"
                                                    autoActiveFirstOption #auto1="matAutocomplete"
                                                    [displayWith]="displayFn"
                                                    (optionSelected)="closingOfficerSelected($event)">
                                                    <mat-option class="search-result-item"
                                                        *ngFor="let searchItem of allTitleSearchItems"
                                                        [value]="searchItem">
                                                        {{searchItem.name}} <span
                                                            *ngIf="searchItem.additionalInfo">({{searchItem.additionalInfo}})</span>
                                                        <div class="small">
                                                            {{searchItem.email}}</div>
                                                        <div class="small"
                                                            *ngIf="searchItem.homeNumber || searchItem.mobileNumber">
                                                            <span *ngIf="searchItem.homeNumber">Home:
                                                                {{searchItem.homeNumber}}</span> <span
                                                                *ngIf="searchItem.mobileNumber"> Mobile:
                                                                {{searchItem.mobileNumber}}</span>
                                                        </div>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <button mat-button mat-raised-button class="new-entity-button"
                                                *ngIf="titleSearchOpen || !isNewTitle" (click)="createNewTitle()">New
                                                Closer</button>
                                        </form>

                                        <ng-container *ngIf="isNewTitle">
                                            <form style="position: relative;">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Company</mat-label>
                                                    <input type="text" id="title-company-name" matInput
                                                        [(ngModel)]="newPolicy.newEscrowCompany"
                                                        [formControl]="myClosingCompanyControl"
                                                        [matAutocomplete]="auto4">
                                                    <mat-autocomplete panelWidth="400px"
                                                        (opened)="titleCompanySearchOpened()"
                                                        (closed)="titleCompanySearchClosed()" autoActiveFirstOption
                                                        #auto4="matAutocomplete" [displayWith]="displayFn"
                                                        (optionSelected)="closingOfficerCompanySelected($event)">
                                                        <mat-option
                                                            *ngFor="let searchItem of allTitleCompanySearchItems"
                                                            [value]="searchItem">
                                                            {{searchItem.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </form>
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>phone</mat-icon> Phone #
                                                </mat-label>
                                                <input matInput mask="(000) 000-0000"
                                                    [(ngModel)]="newPolicy.newEscrowPhone">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>email</mat-icon> Email
                                                </mat-label>
                                                <input matInput type="email" [(ngModel)]="newPolicy.newEscrowEmail">
                                            </mat-form-field>



                                        </ng-container>
                                        <div *ngIf="!isNewTitle && selectedTitleInfo" bottommargin20>

                                            <div class="data-label">Company</div>
                                            <div>{{selectedTitleInfo.additionalInfo}}</div>
                                            <div *ngIf="selectedTitleInfo.email" topmargin10 class="data-label">Email
                                            </div>
                                            <div *ngIf="selectedTitleInfo.email">{{selectedTitleInfo.email}}</div>
                                            <div *ngIf="selectedTitleInfo.mobileNumber" topmargin10 class="data-label">
                                                Phone
                                            </div>
                                            <div *ngIf="selectedTitleInfo.mobileNumber">
                                                {{selectedTitleInfo.mobileNumber}}
                                            </div>

                                        </div>
                                        <ng-container *ngFor="let email of closingAdditionalEmails">
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>email</mat-icon> CC Email
                                                </mat-label>
                                                <input matInput type="email" [(ngModel)]="email.email"
                                                    (ngModelChange)="addAdditionalFieldIfNeeded('Closing', email)">
                                            </mat-form-field>
                                        </ng-container>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Escrow Number</mat-label>
                                            <input matInput [(ngModel)]="newPolicy.policy.escrowNumber">
                                        </mat-form-field>

                                        <app-date-picker [(date)]="newPolicy.policy.estimatedClosingDate"
                                            [outline]="true" placeholder="Estimated Closing Date">
                                        </app-date-picker>
                                    </div>
                                </div>
                                <div class="soft-light padding" flex1 style="margin: 10px" *ngIf="!isSellersCoverage">
                                    <div class="ribbon" *ngIf="newPolicy.policy.initiatorType === 'Buyer'">
                                        <span>Initiator</span>
                                    </div>
                                    <mat-card-content>
                                        <h2>Buyer's Agent</h2>

                                        <form topmargin20 style="position: relative;">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Agent</mat-label>
                                                <input type="text" id="new-agent-name"
                                                    [(ngModel)]="newPolicy.newAgentName" matInput
                                                    [formControl]="myControl" [matAutocomplete]="auto">
                                                <mat-autocomplete (opened)="agentSearchOpened()"
                                                    (closed)="agentSearchClosed()" panelWidth="400px" class="multi-line"
                                                    autoActiveFirstOption #auto="matAutocomplete"
                                                    [displayWith]="displayFn" (optionSelected)="selected($event)">
                                                    <mat-option class="search-result-item"
                                                        *ngFor="let searchItem of allSearchItems" [value]="searchItem">
                                                        {{searchItem.name}} <span
                                                            *ngIf="searchItem.additionalInfo">({{searchItem.additionalInfo}})</span>
                                                        <div class="small">
                                                            {{searchItem.email}}</div>
                                                        <div class="small"
                                                            *ngIf="searchItem.homeNumber || searchItem.mobileNumber">
                                                            <span *ngIf="searchItem.homeNumber">Home:
                                                                {{searchItem.homeNumber}}</span> <span
                                                                *ngIf="searchItem.mobileNumber"> Mobile:
                                                                {{searchItem.mobileNumber}}</span>
                                                        </div>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>

                                            <button mat-button mat-raised-button class="new-entity-button"
                                                *ngIf="agentSearchOpen || !isNewAgent" (click)="createNewAgent()">New
                                                Agent</button>
                                        </form>

                                        <ng-container *ngIf="isNewAgent">
                                            <form style="position: relative;">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Company</mat-label>
                                                    <input type="text" id="new-agent-company" matInput
                                                        [(ngModel)]="newPolicy.newAgentCompany"
                                                        [formControl]="myAgentCompanyControl" [matAutocomplete]="auto6">
                                                    <mat-autocomplete panelWidth="400px"
                                                        (opened)="titleCompanySearchOpened()"
                                                        (closed)="titleCompanySearchClosed()" autoActiveFirstOption
                                                        #auto6="matAutocomplete" [displayWith]="displayFn"
                                                        (optionSelected)="agentCompanySelected($event)">
                                                        <mat-option
                                                            *ngFor="let searchItem of allAgentCompanySearchItems"
                                                            [value]="searchItem">
                                                            {{searchItem.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </form>
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>phone</mat-icon> Phone #
                                                </mat-label>
                                                <input matInput mask="(000) 000-0000"
                                                    [(ngModel)]="newPolicy.newAgentPhone">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>email</mat-icon> Email
                                                </mat-label>

                                                <input matInput type="email" [(ngModel)]="newPolicy.newAgentEmail">
                                            </mat-form-field>



                                        </ng-container>
                                        <ng-container *ngIf="!isNewAgent && selectedAgentInfo">


                                            <div class="data-label">Company</div>
                                            <div *ngIf="selectedAgentInfo.email">{{selectedAgentInfo.additionalInfo}}
                                            </div>
                                            <div *ngIf="selectedAgentInfo.email" class="data-label" topmargin10>Email
                                            </div>
                                            <div *ngIf="selectedAgentInfo.email">{{selectedAgentInfo.email}}</div>
                                            <div *ngIf="selectedAgentInfo.mobileNumber" class="data-label" topmargin10>
                                                Phone
                                            </div>
                                            <div *ngIf="selectedAgentInfo.mobileNumber">
                                                {{selectedAgentInfo.mobileNumber}}
                                            </div>

                                        </ng-container>


                                    </mat-card-content>

                                    <ng-container *ngFor="let email of agentAdditionalEmails">
                                        <mat-form-field appearance="outline">
                                            <mat-label>
                                                <mat-icon>email</mat-icon> CC Email
                                            </mat-label>
                                            <input matInput type="email" [(ngModel)]="email.email"
                                                (ngModelChange)="addAdditionalFieldIfNeeded('Agent', email)">
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                                <div class="soft-light padding" flex1 style="margin: 10px">
                                    <div class="ribbon" *ngIf="newPolicy.policy.initiatorType === 'Seller'">
                                        <span>Initiator</span>
                                    </div>
                                    <mat-card-content>
                                        <h2>Seller's Agent</h2>

                                        <form topmargin20 style="position: relative;">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Agent</mat-label>
                                                <input type="text" id="new-seller-agent-name"
                                                    [(ngModel)]="newPolicy.newSellerName" matInput
                                                    [formControl]="mySellerControl" [matAutocomplete]="auto3">
                                                <mat-autocomplete panelWidth="400px" class="multi-line"
                                                    autoActiveFirstOption #auto3="matAutocomplete"
                                                    [displayWith]="displayFn" (opened)="sellerSearchOpened()"
                                                    (closed)="sellerSearchClosed()"
                                                    (optionSelected)="sellerSelected($event)">
                                                    <mat-option class="search-result-item"
                                                        *ngFor="let searchItem of allSellerSearchItems"
                                                        [value]="searchItem">
                                                        {{searchItem.name}} <span
                                                            *ngIf="searchItem.additionalInfo">({{searchItem.additionalInfo}})</span>
                                                        <div class="small">
                                                            {{searchItem.email}}</div>
                                                        <div class="small"
                                                            *ngIf="searchItem.homeNumber || searchItem.mobileNumber">
                                                            <span *ngIf="searchItem.homeNumber">Home:
                                                                {{searchItem.homeNumber}}</span> <span
                                                                *ngIf="searchItem.mobileNumber"> Mobile:
                                                                {{searchItem.mobileNumber}}</span>
                                                        </div>

                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>

                                            <button mat-button mat-raised-button class="new-entity-button"
                                                *ngIf="sellerSearchOpen || !isNewSeller" (click)="createNewSeller()">New
                                                Agent</button>
                                        </form>

                                        <ng-container *ngIf="isNewSeller">

                                            <form style="position: relative;">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Company</mat-label>
                                                    <input type="text" id="new-seller-company" matInput
                                                        [(ngModel)]="newPolicy.newSellerCompany"
                                                        [formControl]="mySellerCompanyControl"
                                                        [matAutocomplete]="auto7">
                                                    <mat-autocomplete panelWidth="400px"
                                                        (opened)="sellerCompanySearchOpened()"
                                                        (closed)="sellerCompanySearchClosed()" autoActiveFirstOption
                                                        #auto7="matAutocomplete" [displayWith]="displayFn"
                                                        (optionSelected)="sellerCompanySelected($event)">
                                                        <mat-option
                                                            *ngFor="let searchItem of allSellerCompanySearchItems"
                                                            [value]="searchItem">
                                                            {{searchItem.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </form>
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>phone</mat-icon> Phone #
                                                </mat-label>
                                                <input matInput mask="(000) 000-0000"
                                                    [(ngModel)]="newPolicy.newSellerPhone">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label>
                                                    <mat-icon>email</mat-icon> Email
                                                </mat-label>
                                                <input matInput type="email" [(ngModel)]="newPolicy.newSellerEmail">
                                            </mat-form-field>



                                        </ng-container>
                                        <ng-container *ngIf="!isNewSeller && selectedSellerInfo">


                                            <div class="data-label">Company</div>
                                            <div *ngIf="selectedSellerInfo.email">{{selectedSellerInfo.additionalInfo}}
                                            </div>
                                            <div class="data-label" topmargin10>Email</div>
                                            <div *ngIf="selectedSellerInfo.email">{{selectedSellerInfo.email}}</div>
                                            <div class="data-label" topmargin10>Phone</div>
                                            <div *ngIf="selectedSellerInfo.mobileNumber">
                                                {{selectedSellerInfo.mobileNumber}}
                                            </div>

                                        </ng-container>
                                    </mat-card-content>
                                    <ng-container *ngFor="let email of sellersAdditionalEmails">
                                        <mat-form-field appearance="outline">
                                            <mat-label>
                                                <mat-icon>email</mat-icon> CC Email
                                            </mat-label>
                                            <input matInput type="email" [(ngModel)]="email.email"
                                                (ngModelChange)="addAdditionalFieldIfNeeded('Sellers', email)">
                                        </mat-form-field>
                                    </ng-container>

                                </div>
                            </div>

                        </div>
                    </mat-tab>

                    <mat-tab label="Review & Submit">
                        <div class="mobile-padding review-and-submit">

                            <div flex>
                                <div>
                                    <div *ngIf="selectedPlan" topmargin30>

                                        <div class="review-grid">
                                            <div class="options-container  mobile-margin" padding flex1>

                                                <div class="center blob-icon-container">
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/house-and-clouds-orange-blob.svg">
                                                </div>
                                                <div aflex>
                                                    <div>
                                                        <div class="sub-heading" bottommargin30>Covered Property</div>
                                                        <div *ngIf="newPolicy.property">
                                                            <img style="margin-top: 20px"
                                                                *ngIf="newPolicy.property && newPolicy.property.address1 && selectedIndex >= reviewSelectedIndex"
                                                                id="street-view-image" [src]="propertyStreetView" />
                                                            <address topmargin20>
                                                                {{newPolicy.property.address1}}<br>
                                                                {{newPolicy.property.city}},
                                                                {{newPolicy.property.state}}
                                                                {{newPolicy.property.postalCode}}
                                                            </address>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="options-container  mobile-margin" padding flex1>

                                                <div class="center blob-icon-container">
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/description-orange-blog.svg">
                                                </div>
                                                <div aflex>

                                                    <div>
                                                        <h2 class="sub-heading" bottommargin30>Selected Coverage</h2>

                                                        <form topmargin30>
                                                            <div aflex>
                                                                <div>
                                                                    <mat-form-field appearance="outline"
                                                                        style="margin-top: -12px">
                                                                        <mat-label>Promotion Code</mat-label>
                                                                        <input name="promotion-code-input" matInput
                                                                            [(ngModel)]="promotionCodeSearch">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div nogrow
                                                                    style="margin-top: -8px; margin-left: -86px">
                                                                    <button mat-button mat-raised-button
                                                                        class="small-primary"
                                                                        [disabled]="!promotionCodeSearch"
                                                                        (click)="findPromotionCode()"
                                                                        color="primary">Apply</button>
                                                                </div>
                                                            </div>


                                                        </form>
                                                        <ng-container>
                                                            <div aflex class="figure-row large">
                                                                <div flex1>{{selectedPlan.name}} </div>
                                                                <div nogrow class="dollar-figure">
                                                                    {{planBasePrice | currency:'USD':'symbol':'1.2-2'}}
                                                                </div>
                                                            </div>
                                                            <div *ngFor="let option of selectedOptionsNoneDefault" aflex
                                                                class="figure-row indent">
                                                                <div flex1>
                                                                    + {{option.name}}
                                                                    <span *ngIf="option.quantity > 1">
                                                                        (x{{option.quantity}})</span>
                                                                </div>
                                                                <div nogrow class="dollar-figure">
                                                                    {{option.quantity * (newPolicy.policy.isMonthly ?
                                                                    option.priceRecurring : option.price) |
                                                                    currency:'USD':'symbol':'1.2-2' }}
                                                                </div>
                                                            </div>

                                                            <div *ngIf="promotionCode" aflex class="figure-row indent">
                                                                <div flex1>
                                                                    <button style="margin-left: -59px; min-width: 56px;"
                                                                        mat-button (click)="promotionCode = null">
                                                                        <mat-icon>close</mat-icon>
                                                                    </button>
                                                                    {{promotionCode.code}} <span
                                                                        *ngIf="promotionCode.description">-
                                                                        {{promotionCode.description}}</span>
                                                                </div>
                                                                <div nogrow class="dollar-figure">
                                                                    {{adjustmentAmount | currency}}
                                                                </div>

                                                            </div>
                                                            <div aflex *ngIf="amountToSpendLessBase > 0"
                                                                class="figure-row large">
                                                                <div flex1>Additional Funds</div>
                                                                <div class="dollar-figure">
                                                                    {{amountToSpendLessBase |
                                                                    currency:'USD':'symbol':'1.2-2'}}
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <div aflex>
                                                            <div></div>
                                                            <div nogrow>
                                                                <app-display-total [total]="totalPlanPrice"
                                                                    label="Total Price:">
                                                                </app-display-total>

                                                            </div>
                                                        </div>
                                                        <mat-slide-toggle
                                                            *ngIf="selectedCoverageType !== 'RealEstate' || newPolicy.policy.isMonthly"
                                                            [(ngModel)]="newPolicy.policy.isMonthly">Pay By Month
                                                        </mat-slide-toggle>
                                                    </div>
                                                </div>
                                                <a mat-button block style="text-align: center" topmargin20
                                                    target="_blank" [href]="getSampleContractUrl(selectedPlan)"
                                                    *ngIf="selectedPlan">
                                                    <mat-icon>description</mat-icon>
                                                    View Sample Contract
                                                </a>
                                            </div>
                                            <div class="options-container  mobile-margin" padding flex1>

                                                <div class="center blob-icon-container">
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/contact-page-orange-blob.svg">
                                                </div>
                                                <div aflex>
                                                    <div>
                                                        <h2 class="sub-heading" bottommargin30>{{customerTypeName}}
                                                            Information</h2>

                                                        <app-basic-entity-summary
                                                            style="display: block; margin-top: -20px"
                                                            [entity]="newCustomer">
                                                        </app-basic-entity-summary>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="selectedCoverageType !== 'RealEstate'"
                                                class="options-container  mobile-margin" padding flex1>
                                                <div class="center blob-icon-container">
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/credit-card-orange-blob.svg">
                                                </div>
                                                <div aflex>
                                                    <div>
                                                        <h2 class="sub-heading" bottommargin30>Payment Information</h2>
                                                        <div class="center">
                                                            <img *ngIf="selectedCardTypeUrl" class="review-image"
                                                                [src]="selectedCardTypeUrl">
                                                            <div *ngIf="selectedCard">
                                                                {{selectedCard.brand}} {{selectedCard.last4}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="selectedCoverageType === 'RealEstate'"
                                                class="options-container  mobile-margin" padding flex1>

                                                <div class="center blob-icon-container">
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/real-estate-orange-blob.svg">
                                                </div>

                                                <div>
                                                    <h2 class="sub-heading" bottommargin30>Closing Information</h2>
                                                    <div>
                                                        <div>
                                                            <div class="data-label">Estimated Closing Date</div>
                                                            <div>{{newPolicy.policy.estimatedClosingDate | date:
                                                                'shortDate'}}</div>
                                                            <div class="data-label">Escrow #</div>
                                                            <div>{{newPolicy.policy.escrowNumber}}</div>
                                                            <ng-container *ngIf="newPolicy.closingOfficer">
                                                                <div class="data-label" topmargin30>Closing Officer
                                                                </div>
                                                                <app-basic-entity-summary
                                                                    *ngIf="newPolicy.closingOfficer"
                                                                    style="display: block; margin-top: -20px"
                                                                    [entity]="newPolicy.closingOfficer">
                                                                </app-basic-entity-summary>
                                                            </ng-container>
                                                        </div>
                                                        <div
                                                            *ngIf="newPolicy.buyersAgent && newPolicy.buyersAgent.name">

                                                            <ng-container>
                                                                <div class="data-label" topmargin30>Buyer's Agent
                                                                </div>
                                                                <app-basic-entity-summary
                                                                    style="display: block;margin-top: -20px"
                                                                    [entity]="newPolicy.buyersAgent">
                                                                </app-basic-entity-summary>
                                                            </ng-container>
                                                        </div>
                                                        <div
                                                            *ngIf="newPolicy.sellersAgent && newPolicy.sellersAgent.name">
                                                            <ng-container *ngIf="newPolicy.sellersAgent">
                                                                <div class="data-label" topmargin30>Seller's Agent
                                                                </div>
                                                                <app-basic-entity-summary
                                                                    style="display: block;margin-top: -20px"
                                                                    [entity]="newPolicy.sellersAgent">
                                                                </app-basic-entity-summary>

                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="selectedCoverageType !== 'RealEstate'"
                                                class="options-container  mobile-margin" padding flex1>
                                                <div class="center blob-icon-container">
                                                    <img
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/event-orange-blob.svg">
                                                </div>
                                                <div aflex>
                                                    <div>
                                                        <h2 class="sub-heading" bottommargin30>Payment Schedule</h2>

                                                        <p>When would you like your coverage to start?</p>
                                                        <mat-form-field style="max-width: 200px" appearance="outline">
                                                            <input matInput [min]="minStartDateCalculated"
                                                                [matDatepicker]="picker"
                                                                [(ngModel)]="newPolicy.policy.effectiveDate"
                                                                placeholder="Start Date">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <mat-form-field topmargin20 appearance="outline">
                                <mat-label>Notes</mat-label>
                                <textarea matInput rows="5" [(ngModel)]="newPolicy.notes"></textarea>
                            </mat-form-field>

                            <div aflex topmargin30>
                                <div>

                                </div>
                                <div nogrow>
                                    <button mat-button mobile-block mat-raised-button id="submit-button" color="primary"
                                        large [disabled]="submitting || !canSubmit" (click)="createOrder()">
                                        <mat-spinner class="thirty" *ngIf="submitting"></mat-spinner> Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <div class="mobile-padding"></div>

                        <div class="center">
                            <app-check-circle-animated height="150px"></app-check-circle-animated>
                        </div>

                        <div class="options-container" id="account-box">

                            <h2 class="sub-heading"
                                style="border-bottom: 1px solid rgb(220,220,200);padding-bottom: 10px;margin-bottom: 15px;">
                                My Downloads</h2>
                            <a mat-button mat-raised-button target="_blank" [href]="invoiceUrl">
                                <mat-icon>receipt</mat-icon> Invoice
                            </a>
                            <a mat-button mat-raised-button target="_blank" style="margin-left: 15px"
                                [href]="contractUrl">
                                <mat-icon>article</mat-icon> Contract
                            </a>
                            <a mat-button mat-raised-button target="_blank" style="margin-left: 15px"
                                [href]="certificateUrl">
                                <mat-icon>verified</mat-icon> Certificate
                            </a>


                            <div aflex topmargin40>
                                <div nogrow class="review-icon small">
                                    <img
                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/house-and-clouds-orange-blob.svg">
                                </div>
                                <div>
                                    <h2 class="sub-heading">Covered Property</h2>
                                    <div aflex>
                                        <div nogrow>
                                            <div *ngIf="newPolicy.property">
                                                <img style="height: 75px;margin-top: 20px"
                                                    *ngIf="newPolicy.property && newPolicy.property.address1 && selectedIndex >= reviewSelectedIndex"
                                                    id="street-view-image" [src]="propertyStreetView" />

                                            </div>
                                        </div>
                                        <div>
                                            <address topmargin20>
                                                {{newPolicy.property.address1}}<br>
                                                {{newPolicy.property.city}}, {{newPolicy.property.state}}
                                                {{newPolicy.property.postalCode}}
                                            </address>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div aflex topmargin40>
                                <div nogrow class="review-icon small">
                                    <img
                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/contact-page-orange-blob.svg">
                                </div>
                                <div>
                                    <h2 class="sub-heading">{{customerTypeName}}
                                        Information</h2>

                                    <app-basic-entity-summary style="display: block; margin-top: -20px"
                                        [entity]="newCustomer">
                                    </app-basic-entity-summary>
                                </div>
                            </div>


                            <div aflex topmargin40>
                                <div nogrow class="review-icon small">
                                    <img
                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/description-orange-blog.svg">
                                </div>
                                <div *ngIf="selectedPlan">
                                    <h2 class="sub-heading">Selected Coverage</h2>

                                    <ng-container>
                                        <div aflex class="figure-row large">
                                            <div flex1>{{selectedPlan.name}} </div>
                                            <div nogrow class="dollar-figure">
                                                {{planBasePrice | currency:'USD':'symbol':'1.2-2'}}
                                            </div>
                                        </div>
                                        <div *ngFor="let option of selectedOptionsNoneDefault" aflex
                                            class="figure-row indent">
                                            <div flex1>
                                                + {{option.name}}
                                                <span *ngIf="option.quantity > 1">
                                                    (x{{option.quantity}})</span>
                                            </div>
                                            <div nogrow class="dollar-figure">
                                                {{option.quantity * (newPolicy.policy.isMonthly ?
                                                option.priceRecurring : option.price) |
                                                currency:'USD':'symbol':'1.2-2' }}
                                            </div>
                                        </div>

                                        <div *ngIf="promotionCode" aflex class="figure-row indent">
                                            <div flex1>
                                                <!-- <button style="margin-left: -59px; min-width: 56px;" mat-button
                                                    (click)="promotionCode = null">
                                                    <mat-icon>close</mat-icon>
                                                </button> -->
                                                {{promotionCode.code}} <span *ngIf="promotionCode.description">-
                                                    {{promotionCode.description}}</span>
                                            </div>
                                            <div nogrow class="dollar-figure">
                                                {{adjustmentAmount | currency}}
                                            </div>

                                        </div>
                                        <div aflex *ngIf="amountToSpendLessBase > 0" class="figure-row large">
                                            <div flex1>Additional Funds</div>
                                            <div class="dollar-figure">
                                                {{amountToSpendLessBase |
                                                currency:'USD':'symbol':'1.2-2'}}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div aflex>
                                        <div></div>
                                        <div nogrow>
                                            <app-display-total [total]="totalPlanPrice" label="Total Price:"
                                                suffix="{{newPolicy.policy.isMonthly ? ' /mo' : ''}}">
                                            </app-display-total>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="selectedCoverageType === 'RealEstate'">

                                <div aflex topmargin40>
                                    <div nogrow class="review-icon small">
                                        <img
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/real-estate-orange-blob.svg">
                                    </div>
                                    <div>
                                        <h2 class="sub-heading" bottommargin30>Closing Information</h2>
                                        <div>
                                            <div>
                                                <div class="data-label">Estimated Closing Date</div>
                                                <div>{{newPolicy.policy.estimatedClosingDate | date:
                                                    'shortDate'}}</div>
                                                <div class="data-label">Escrow #</div>
                                                <div>{{newPolicy.policy.escrowNumber}}</div>
                                                <ng-container *ngIf="newPolicy.closingOfficer">
                                                    <div class="data-label" topmargin30>Closing Officer
                                                    </div>
                                                    <app-basic-entity-summary *ngIf="newPolicy.closingOfficer"
                                                        style="display: block; margin-top: -20px"
                                                        [entity]="newPolicy.closingOfficer">
                                                    </app-basic-entity-summary>
                                                </ng-container>
                                            </div>
                                            <div *ngIf="newPolicy.buyersAgent && newPolicy.buyersAgent.name">

                                                <ng-container>
                                                    <div class="data-label" topmargin30>Buyer's Agent</div>
                                                    <app-basic-entity-summary style="display: block;margin-top: -20px"
                                                        [entity]="newPolicy.buyersAgent">
                                                    </app-basic-entity-summary>
                                                </ng-container>
                                            </div>
                                            <div *ngIf="newPolicy.sellersAgent && newPolicy.sellersAgent.name">
                                                <ng-container *ngIf="newPolicy.sellersAgent">
                                                    <div class="data-label" topmargin30>Seller's Agent</div>
                                                    <app-basic-entity-summary style="display: block;margin-top: -20px"
                                                        [entity]="newPolicy.sellersAgent">
                                                    </app-basic-entity-summary>

                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>

<app-order-summary *ngIf="selectedIndex < confirmationStepNumber" [selectedIndex]="selectedIndex"
    [totalSteps]="totalSteps" [hideMonthly]="selectedCoverageType === 'RealEstate'" [selectedPlan]="selectedPlanDelayed"
    [discounts]="adjustmentAmount" [(isMonthly)]="newPolicy.policy.isMonthly" [basePrice]="planBasePrice"
    [showCompletion]="selectedIndex === reviewSelectedIndex" [optionsPrice]="optionsPrice">
</app-order-summary>