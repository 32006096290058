import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-delete-account',
    standalone: true,
    imports: [MatButtonModule],
    templateUrl: './delete-account.component.html',
    styleUrl: './delete-account.component.scss'
})
export class DeleteAccountComponent {
    isLegacy = true;
    constructor(
        private router: Router
    ) {
    }

    getDeleteAccountUrl() {
        if (this.router.url.includes('homeowner')) {
            return ''
        }
        return '/delete-account'
    }

}
