import { Component, Input } from '@angular/core';
import { Entity } from '@upkeeplabs/models/cogent';

@Component({
    templateUrl: './basic-entity-summary.component.html',
    styleUrls: ['./basic-entity-summary.component.css'],
    selector: 'app-basic-entity-summary',
})
export class BasicEntitySummaryComponent {
    @Input() entity: Entity;
}
