<div mat-dialog-content>
    <h1>Don't go unprotected</h1>
    <lottie-player style="height: 200px" src="https://lottie.host/16a932f2-8180-4ca5-909d-2fafa979a7df/s5QhaCa4oO.json"
        background="transparent" speed="1" autoplay loop></lottie-player>

        <p class="info" style="max-width: 500px;">
            Your coverage at {{data.propertyAddress.address1}} is set to expire on {{data.expirationDate | date: 'shortDate'}}.  Don't go unprotected.  Renew today.
        </p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="dismiss()">Dismiss</button>
    <button mat-button (click)="renew()" mat-raised-button color="primary" cdkFocusInitial>Renew Now</button>
</div>