<div class="container fancy-container">
    <ng-container *ngIf="workOrderSummary && workOrderSummary.alternateTimeSlotSuggestion">



        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
            <mat-tab label="First">

                <div class="padding">
                    <h2 class="sub-heading">Alternate Time Slots</h2>
                    <p topmargin40>
                        Unfortunately we have been informed by your service pro {{workOrderSummary.contractorName}} that
                        they
                        won't be able to
                        accommodate your preferred times to fix your {{workOrderSummary.itemName}} at
                        {{workOrderSummary.propertyAddress}} on job # {{workOrderSummary.number}}.
                    </p>


                    <p>
                        However, they have suggested a few alternate times. Please review their suggestions and let us
                        know
                        if
                        one
                        of
                        those times will work.
                    </p>

                    <div topmargin40>
                        <button mat-button mobile-block style="margin-right: 20px; margin-bottom: 20px;"
                            mat-raised-button [disabled]="accepting"
                            *ngFor="let slot of workOrderSummary.alternateTimeSlotSuggestion.timeSlots"
                            (click)="acceptTimeSlot(slot)">
                            {{slot.summary}}
                        </button>

                        <button mat-button mobile-block mat-raised-button [disabled]="accepting" (click)="reject()">
                            None of these suggestions work
                        </button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Second">
                <div class="center">
                    <app-check-circle-animated></app-check-circle-animated>
                    <h2 class="sub-heading" topmargin40>Appointment Scheduled</h2>
                    <p topmargin20>Sit back and relax, your appointment is scheduled. You should receive an email confirmation of
                        your appointment.</p>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="center">
                    <img src="../../../assets/images/calendar-circle.png">

                    <p topmargin20 *ngIf="unacceptedFollowUpCount === 0">
                        Let's see if we can find another service pro who can work within your schedule.
                    </p>
                    <p topmargin20 *ngIf="unacceptedFollowUpCount > 0 && workOrderSummary" >
                        We will let {{workOrderSummary.contractorName}} know these time slots will not work.  We will have them 
                        contact you directly to work out a mutually agreeable apppointment.<br><br>
                        For your reference, their number is: {{contractorPhoneFormatted}}
                    </p>
                </div>
            </mat-tab>

        </mat-tab-group>
    </ng-container>
</div>
