import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MaintenanceServiceAddOnRequestSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { TypingNotificationComponent } from '@cogent/client/shared/components/functions/function-question-renderer/typing-notification/typing-notification.component';
import { FrownyFaceComponent } from '@cogent/client/shared/components/misc/frowny-face/frowny-face.component';
import { AddOnRequestArgsViewerComponent } from '@cogent/client/shared/components/maintenance-service/add-on-request-args-viewer/add-on-request-args-viewer.component';

@Component({
    selector: 'app-approve-add-on-bid',
    templateUrl: './approve-add-on-bid.component.html',
    styleUrls: ['./approve-add-on-bid.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, CheckCircleAnimatedComponent, TypingNotificationComponent, FrownyFaceComponent, AddOnRequestArgsViewerComponent]
})
export class ApproveAddOnBidComponent implements OnInit {

    addOn: MaintenanceServiceAddOnRequestSummary;
    comments: string;
    cost: number;

    constructor(private maintApi: MaintenanceServiceApiService,
        private dialogService: DialogsService,
        private router: Router,
        private missionService: MissionService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.maintApi.getMaintenanceServiceAddOnRequestSummaryById(params.id).then(addOn => {
                    this.addOn = addOn;
                    if (addOn.quoteSubmittedDate) {
                        this.comments = addOn.contractorComments;
                        this.cost = addOn.price;
                    }
                });
            }
        });
    }

    async reject() {
        const result = await this.dialogService.confirm('Reject Job', 'Are you sure you want to reject this job?').toPromise();
        if (result) {
            await this.maintApi.rejectAddOnRequest(this.addOn.id);
            this.missionService.showSuccessToast('Add On Rejected');
            this.router.navigate([''], { queryParams: { selectedIndex: 0 } });
        }
    }

    get canApproveOrReject() {
        return this.addOn && !this.addOn.rejectedDate && !this.addOn.approvedDate && !this.addOn.customerRejectedDate && this.addOn.quoteSubmittedDate;
    }

    get wasContractorRejected() {
        return this.addOn && this.addOn.rejectedDate;
    }

    // async sendQuote() {
    //     await this.maintApi.quoteAddOn(this.addOn.id, this.comments, this.cost);
    //     this.missionService.showSuccessToast('Done')
    // }

    async approveBid() {
        const result = await this.dialogService.confirm('Approve Bid', 'Are you sure you want to accept this job?').toPromise();
        await this.maintApi.approveAddOnBid(this.addOn.id);

        this.addOn.approvedDate = new Date();
        this.missionService.showSuccessToast('Bid Accepted');
    }
}


