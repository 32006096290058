import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-apps',
    templateUrl: './apps.component.html',
    styleUrls: ['./apps.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class AppsComponent implements OnInit {

    showBg = false;
    detailVisible = false;
    constructor() { }

    ngOnInit(): void {
        setTimeout(() => {
            this.showBg = true;
        }, 1000);

        setTimeout(() => {
            this.detailVisible = true;
        }, 3000);
    }

}
