import { Component, OnInit } from '@angular/core';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { ActivatedRoute } from "@angular/router";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxStarRatingModule } from '@cogent/client/shared/components/misc/ngx-star-rating/ngx-star-rating.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WorkOrderSurvey } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';



@Component({
    templateUrl: './work-order-survey.html',
    styleUrls: ['./work-order-survey.css'],
    standalone: true,
    imports: [
        MatTabsModule,
        NgxStarRatingModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        CheckCircleAnimatedComponent,
        MatIconModule
    ]
})
export class WorkOrderSurveyComponent implements OnInit {
    workOrderId: string;
    workOrderSummary: WorkOrderSummaryClient;
    workOrderSurvey: WorkOrderSurvey = new WorkOrderSurvey();
    selectedIndex = 0;
    saving = false;
    giftCardSent = false;

    showGoogleLink: boolean;
    hideGoogleLink: boolean;
    isLegacy = true;


    constructor(private route: ActivatedRoute,
        private serviceRepository: ServiceRepositoryService) { }


    ngOnInit() {
        this.route.params.subscribe(params => {
            this.workOrderId = params.id;
            this.serviceRepository.getUnAuthenticatedWorkOrderSummary(this.workOrderId).then(results => {
                this.workOrderSummary = results;
                this.workOrderSurvey.workOrderId = this.workOrderId;

                this.serviceRepository.getReviewGiftCardWasSent(results.policyId).then(giftCardSent => {
                    this.giftCardSent = giftCardSent;
                });
            });
        });
    }

    get canSubmit(): boolean {
        return this.workOrderSurvey.rating > 0 && this.workOrderSurvey.elevateRating > 0;
    }

    save() {
        this.saving = true;
        this.serviceRepository.saveWorkOrderSurvey(this.workOrderSurvey).then((result) => {
            this.selectedIndex = 1;
            this.saving = false;
            if (this.workOrderSurvey.rating >= 4 && this.workOrderSurvey.elevateRating >= 4 && !this.giftCardSent) {
                setTimeout(() => this.showGoogleLink = true, 1000);
            } else {
                this.hideGoogleLink = true;
            }
        });
    }
}
