import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneDisplayComponent } from './phone-display/phone-display.component';
// import { PhoneDisplayComponent } from './phone-display/phone-display.component';



@NgModule({
//   declarations: [PhoneDisplayComponent],
  imports: [
    CommonModule
  ],
declarations: [PhoneDisplayComponent],
  exports: [
      PhoneDisplayComponent
  ]
})
export class PhoneDisplayModule { }
