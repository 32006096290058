<div class="container fancy-container">

    <h2 class="sub-heading">Payments</h2>
    <div flex topmargin30>
        <div> <app-date-range-display-and-selector memoryKey="paymentReport" location="bottom" [(startDate)]="startDate"
                [(endDate)]="endDate"></app-date-range-display-and-selector></div>
        <div class="center"> <button mat-button mat-raised-button color="primary" (click)="getData()"
                [disabled]="!canSave">
                <mat-spinner *ngIf="loading" class="thirty"></mat-spinner>
                Get Report</button></div>

    </div>



    <button mat-button (click)="export()" *ngIf="payments">
        
        <mat-icon>download</mat-icon>
        Export
    </button>
    <div style="overflow: auto;" [class.hide-overflow]="selectedPaymentItem">
        <table class="table" id="report" *ngIf="payments">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Number</th>
                    <th>Description</th>
                    <th>Property</th>
                    <th class="right">Amount</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let payment of payments">
                    <td>{{payment.paymentCreatedDate | date: 'shortDate'}}</td>
                    <td>
                        <a (click)="showPaymentReceipt(payment)" style="cursor: pointer;">
                            {{payment.paymentNumber}}</a>
                    </td>
                    <td>{{payment.description}}</td>
                    <td>{{payment.policyAddress1}}</td>
                    <td class="right">{{payment.amount | currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>


</div>

<div class="detail-pane" *ngIf="selectedPaymentItem">

    <div aflex>
        <div nogrow>
            <button mat-button (click)="selectedPaymentItem = null" class="close-detail-button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div>

            <iframe [src]="safeUrl">

            </iframe>
        </div>
    </div>
</div>