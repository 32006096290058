import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-frowny-face',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './frowny-face.component.html',
  styleUrls: ['./frowny-face.component.css']
})
export class FrownyFaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
