import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';

@Component({
    selector: 'app-accept-alternate-time',
    templateUrl: './accept-alternate-time.component.html',
    styleUrls: ['./accept-alternate-time.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, MatButtonModule, CheckCircleAnimatedComponent]
})
export class AcceptAlternateTimeComponent implements OnInit {

    workOrderSummary: WorkOrderSummaryClient;
    accepting = false;
    selectedIndex = 0;
    unacceptedFollowUpCount: number;

    constructor(private activatedRoute: ActivatedRoute,
        private serviceApi: ServiceApiService,
        private dialogService: DialogsService) { }

    ngOnInit(): void {

        this.activatedRoute.params.subscribe(params => {
            this.serviceApi.getWorkOrderSummaryById(params.id).then(async workOrderSummary => {
                this.unacceptedFollowUpCount =  await this.serviceApi.getUnacceptedFollowUpAppointmentCount(params.id);
                this.workOrderSummary = workOrderSummary;
                if (params.timeSlotId) {
                    const slot = this.workOrderSummary.alternateTimeSlotSuggestion.timeSlots.find(i => i.id === params.timeSlotId);
                    if (slot) {
                        this.acceptTimeSlot(slot);
                    }
                }
            });
        });
    }

    acceptTimeSlot(slot) {
        this.dialogService.confirm('Confirm', 'Are you sure you want to accept ' + slot.summary + '?').subscribe(results => {
            if (results) {
                this.accepting = true;
                this.serviceApi.acceptAlternateTimeSlotById(this.workOrderSummary.id, slot.id).then(()=> {
                    this.accepting = false;
                    this.selectedIndex = 1;
                });
            }
        });
    }

    get contractorPhoneFormatted() {
        if(!this.workOrderSummary) {
            return null;
        }

        return UtilitiesService.formatPhoneNumber(this.workOrderSummary.contractorPhone);
    }

    async reject() {
        this.dialogService.confirm('Confirm', 'Are you sure you want to reject these appointment suggestions?').subscribe(async results=> {
            if(results) {
                this.accepting = true;
                await this.serviceApi.rejectAlternateTimeSlots(this.workOrderSummary.id);
                this.accepting = false;
                this.selectedIndex = 2;
            }
        });


    }

}
