import { Address } from "./address.model";
import { BundleAndSave } from "./bundle-and-save.model";
import { Entity } from "./entity.model";
import { GroupedProductOfferings } from "./grouped-product-offering.model";
import { Login } from "./login.model";
import { PhoneNumber } from "./phone-number.model";
import { Plan } from "./plan.model";
import { PolicyAdjustment } from "./policy-adjustment.model";
import { PromotionCodeSummary } from "./promotion-code-summary.model";
import { SalesItem } from "./sales-item.model";
import { StripeCard } from "./stripe-card.model";
import { WebQuote } from "./web-quote.model";

export class ShoppingCartItem {
    constructor() {
        this.quantity = 1;
    }
    id: string;
    type: string;
    description: string;
    price: number;
    pricePerMonth: number;
    backingObj?: any;
    quantity: number;
    allowQuantityEntry: boolean;

    displayPrice() {
        return this.price * this.quantity;
    }

    displayPricePerMonth() {
        return this.pricePerMonth * this.quantity;
    }
}

export class AdditionalEmail {
    email: string;
    type: string;
}

export class QuoteAndOrderState {
    quote: WebQuote;
    plans: Plan[] | any[];
    promotionCode: PromotionCodeSummary;
    isMonthly = false;
    shoppingCartItems: ShoppingCartItem[];
    completedCartItems: ShoppingCartItem[];
    customerId: string;
    maintenanceOfferings: GroupedProductOfferings[];
    oneTimeServices: SalesItem[] | any[];
    selectedPlan?: Plan;
    coverageType: string;
    mailingAddress: Address;
    propertyAddress: Address;
    usePropertyMailingAddress = true;
    password: string;
    passwordConfirm: string;
    card: StripeCard;
    customer: Entity;
    login: Login;
    code: string;
    initiatorType: string;
    selectedTitleInfo: any;
    isNewTitle = false;
    newEscrowName: string | Entity;
    agentIdSetFromQuery = false;
    isNewAgent = false;
    isNewSeller = false;
    bundleAndSaveDiscount: BundleAndSave;
    isNewTitleCompany = false;
    isNewAgentCompany = false;
    isNewSellerCompany = false;
    payThroughEscrow = false;
    newEscrowCompany: string | Entity;
    selectedTitleCompanyInfo: any;
    newEscrowPhone: string;
    newEscrowEmail: string;
    additionalEmailAddresses: AdditionalEmail[];
    sellersAdditionalEmails: AdditionalEmail[];
    agentAdditionalEmails: AdditionalEmail[];
    closingAdditionalEmails: AdditionalEmail[];
    escrowNumber: string;
    estimatedClosingDate: Date;
    newSellerName: string;
    selectedSellerInfo: any;
    newSellerCompany: any;
    selectedSellerCompany: any;
    newSellerPhone: string;
    newSellerEmail: string;
    billTo: string;
    newAgentName: string | Entity;
    selectedAgentInfo: any;
    newAgentCompany: any;
    selectedAgentCompany: any;
    newAgentPhone: string;
    newAgentEmail: string;
    phoneNumber: PhoneNumber;
    adjustments: PolicyAdjustment[];
    notes: string;
    saveResult: any;
    warrantyStartDate: Date;

    constructor() {
    }

    get hasWarrantySelected() {
        return this.shoppingCartItems.find(i => i.type === 'plan');
    }

    get planName() {
        return this.completedCartItems.find(i=> i.type === 'plan')?.description;
    }

    get additionalItems() {
        return this.completedCartItems.filter(i=>i.type !== 'plan');
    }
}