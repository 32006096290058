
import { Address, GroupedProductOfferings  } from '@upkeeplabs/models/cogent';

export class MaintenanceServiceLogic {
    static applyFilters(offerings: GroupedProductOfferings[], address: Address) {
        for (const offering of offerings) {
            offering.filteredOfferings = offering.offerings;
            //Window cleaning
            if (offering.maintenanceServiceId === 'aa37213c-b515-4255-a8e6-1679eaa1a4fe') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart === 0);
            }

            //House cleaning
            if (offering.maintenanceServiceId === 'b688383e-0dcf-4943-bbd1-7c53477345b9') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= address.squareFeet && i.rangeEnd >= address.squareFeet);
            }

            // Lawn Care
            if (offering.maintenanceServiceId === 'f401dcec-0547-45c4-af6e-8bc792bf1e64') {
                offering.filteredOfferings = offering.offerings.filter(i => i.rangeStart <= address.lotSquareFootage && i.rangeEnd >= address.lotSquareFootage);
            }

            if (offering.filtersToShow && offering.filtersToShow.length > 0) {
                for (const filter of offering.filtersToShow) {
                    if (filter.selectedOption) {

                        const filterOptionId = filter.selectedOption.id;

                        offering.filteredOfferings = offering.filteredOfferings.filter(i => {


                            if (!i.pricingFilterOptions) {
                                return true;
                            }
                            try {

                                // Array of filter Ids that can be applied (setup on the maintenance service)
                                const filterOptionIdsToApply = JSON.parse(i.pricingFilterOptions);

                                const filterOptionIds = filter.filterOptions.map(i => i.id);
                                let applyFilter = false;
                                for (const idToApply of filterOptionIdsToApply) {

                                    if (filterOptionIds.indexOf(idToApply) > -1) {
                                        applyFilter = true;
                                    }
                                }

                                if (applyFilter) {
                                    if (filterOptionIdsToApply.indexOf(filterOptionId) === -1) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }

                            } catch {
                                return true;
                            }
                        });
                    }
                }
            }

            offering.filteredOfferings = offering.filteredOfferings.sort((a, b) => a.price > b.price ? 1 : -1);
        }
    }
}