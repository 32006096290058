import { Component, OnInit } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        CheckCircleAnimatedComponent,
        RouterModule,
    ]
})
export class ResetPasswordComponent implements OnInit {

    emailAddress: string;
    sending = false;
    resetting = false;
    selectedIndex = 0;
    code: number;
    newPassword: string;
    newPasswordConfirm: string;
    isLegacy = true;

    constructor(private customerRepository: CustomerRepositoryService,
        private missionService: MissionService,
        private dialogService: DialogsService) { }

    ngOnInit() {
    }

    get canSend() {
        return this.emailAddress
            && UtilitiesService.validateEmail(this.emailAddress);
    }

    async send() {
        this.sending = true;
        const succeeded = await this.customerRepository.sendPasswordResetEmail(this.emailAddress);
        if (succeeded) {
            this.selectedIndex = 1;
            this.missionService.showSuccessToast('Verification code sent.<br>Please check your inbox for the verification code.');
        } else {
            this.dialogService.alert("Email not found", "Cannot find Email address.");
        }

        this.sending = false;
    }

    get canReset() {
        return this.code && this.newPassword && this.newPassword === this.newPasswordConfirm;
    }

    get passwordMismatch() {
        return this.newPassword && this.newPasswordConfirm && this.newPassword !== this.newPasswordConfirm;
    }

    async reset() {
        this.resetting = true;
        const result = await this.customerRepository.resetPasswordFromCode(this.code, this.newPassword);
        if (result) {
            this.selectedIndex = 2;
        } else {
            this.dialogService.alert('Cannot Reset', 'Sorry, we were not able to reset your password with the code provided.');
        }
        this.resetting = false;
    }
}
