<div class="wizard-header" *ngIf="tabIndex !== 8">


    <div aflex class="container">
        <div>

            <h1 class="page-title sans" *ngIf="tabIndex === 0">
                <span class="big">Item</span>
                <br>
                <span class="little">Selection</span>
            </h1>

            <h1 class="page-title sans" *ngIf="tabIndex === 1"><span class="big">Existing</span><br /><span
                    class="little">Service
                    Requests</span></h1>
            <h1 class="page-title sans" *ngIf="tabIndex === 2">
                <span class="big">Item</span>
                <br>
                <span class="little">Questions</span>
            </h1>
            <h1 class="page-title sans" *ngIf="tabIndex === 3">
                <span class="big">Photos</span>
                <br>
                <span class="little">&amp; Video</span>
            </h1>
            <h1 class="page-title sans" *ngIf="tabIndex === 4">
                <span class="big">Service Fee</span>
                <br>
                <span class="little">Payment</span>
            </h1>
            <h1 class="page-title sans" *ngIf="tabIndex === 5">
                <span class="big">Contact</span>
                <br>
                <span class="little">Information</span>
            </h1>
            <h1 class="page-title sans" *ngIf="tabIndex === 6">
                <span class="big">Appointment</span>
                <br>
                <span class="little">Selection</span>
            </h1>
            <h1 class="page-title sans" *ngIf="tabIndex === 7">
                <span class="big">Review</span>
                <br>
                <span class="little">&amp;Submit</span>
            </h1>

        </div>
        <div nogrow style="padding-top: 12px;padding-right: 10px;">
            <ng-container *ngIf="tabIndex === 1">
                <button mat-button (click)="clearSelectedItem()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <!-- <button class="pull-right rubberBand small-primary" mat-button mat-raised-button color="primary"
                    (click)="tabIndex=2" data-cy="request-service-continue">
                    <div>Next
                        <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                </button> -->
            </ng-container>
            <ng-container *ngIf="tabIndex === 2">
                <button mat-button (click)="clearSelectedItem()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <!--
        &nbsp;&nbsp; -->

                <button [disabled]="!questionWizardComplete" class="small-primary"
                    [class.rubberBand]="questionWizardComplete" mat-button mat-raised-button color="primary"
                    (click)="questionsCompleted()" data-cy="request-service-continue">
                    <div>Next
                        <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                </button>

            </ng-container>
            <ng-container *ngIf="tabIndex === 3">
                <button mat-button (click)="backToQuestions()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button class=" small-primary" color="primary" (click)="goToServiceFees()"
                    data-cy="request-service-continue">
                    <div>
                        Next
                        <i class="material-icons">chevron_right</i>
                    </div>
                </button>
            </ng-container>
            <ng-container *ngIf="tabIndex === 4">
                <button mat-button (click)="tabIndex = tabIndex - 1">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button class=" small-primary" (click)="goToContactInformation()"
                    [disabled]="!creditCardValid && serviceFeeBalance > 0 && !selectedPrepaidServiceFee" color="primary"
                    data-cy="request-service-continue">
                    <div>Next
                        <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                </button>
            </ng-container>
            <ng-container *ngIf="tabIndex === 5">
                <button mat-button (click)="photosBack()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button class=" small-primary" color="primary" [disabled]="!contactValid"
                    (click)="goToTimeslotStep()" data-cy="request-service-continue">
                    <div>Next
                        <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                </button>
            </ng-container>
            <ng-container *ngIf="tabIndex === 6">
                <button mat-button (click)="tabIndex = tabIndex - 1">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button [disabled]="!timeSlotsAreSelected" class="rubberBand small-primary"
                    (click)="tabIndex = lastStepIndex" color="primary" data-cy="request-service-continue">
                    <div>Next
                        <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                </button>
            </ng-container>
        </div>
    </div>
</div>

<div flex class="wizard-content">


    <div>

        <div style="position: relative; " #wizardContainer class="invert-itt">
            <button mat-button class="down-button" *ngIf="downButtonVisible" (click)="goToBottom()">
                <i class="material-icons">keyboard_arrow_down</i> Down</button>

            <div class="container" style="padding-bottom: 100px;padding-top: 55px;" [class.no-shadow]="tabIndex === 0">

                <mat-tab-group [(selectedIndex)]="tabIndex" (selectedIndexChange)="onStepChange()" class="hide-tabs">

                    <mat-tab label="Item Selection">



                        <div class="center" *ngIf="loading">
                            <mat-spinner style="display: inline-block;"></mat-spinner>
                            <br>
                            Loading...
                        </div>

                        <div>
                            <button mat-button block *ngIf="lineItems && lineItems.length > 0"
                                (click)="continueAfterItemSelection()">
                                Continue <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>

                        <div *ngIf="!loading">
                            <app-job-item-selection [hideHeader]="true" [workOrderItems]="workOrderItems"
                                [showAddMaintServicesButton]="true" #jobItemSelection
                                [maintenanceServiceCustomerPropertyServiceSummaries]="maintenanceServiceCustomerPropertyServiceSummaries"
                                (maintenanceServiceSpecialRequest)="customMaintenanceServiceRequest($event)"
                                (addMaintServiceClicked)="addMaintServiceClicked()" [maintOfferings]="maintOfferings"
                                (addOnInvoked)="addOnInvoked($event)" (selectedItemChange)="itemSelected($event)">
                            </app-job-item-selection>

                            <div *ngIf="notActiveVisible">
                                <h1>Sorry, this property is not available for service.</h1>
                            </div>
                        </div>

                    </mat-tab>

                    <mat-tab label="Existing Service Request">
                        <div *ngIf="!showWhiteGloveConfirmation">

                            <div class="center">
                                <h2>Same Item?</h2>
                                <p class="info">We see there has been work done on {{selectedItem?.name}} on
                                    {{firstPreviousDate | date: 'shortDate'}} and {{secondPreviousDate | date:
                                    'shortDate'}}.<br><br>
                                    Is this the exact same item that has malfunctioned previously?
                                </p>
                                <button mat-button mat-raised-button color="primary" class="small-primary"
                                    (click)="showWhiteGloveConfirmation = true" style="margin-right: 20px;">Yes</button>
                                <button mat-button mat-raised-button (click)="tabIndex = tabIndex + 1">No</button>
                            </div>

                            <!-- <table class="table">
                                <thead>
                                    <tr>
                                        <th>Job #</th>
                                        <th>Date</th>
                                        <th>Resolution</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of previousJobs">
                                        <td>{{item.workOrderNumber}}</td>
                                        <td>{{item.createdDate | date: 'shortDate'}}</td>
                                        <td>{{item.resolution}}</td>
                                    </tr>
                                </tbody>
                            </table> -->


                        </div>

                        <div *ngIf="showWhiteGloveConfirmation" class="fade-in-up">




                            <div class="center">
                                <h1>So Sorry</h1>
                                <p>Oh! We're sorry to hear your still having issues with your {{selectedItem?.name}}.
                                </p>
                                <p>We've got a dedicated team and we're going to escalate this job to them. <br>We'll ask
                                    just a few more questions and then make
                                    sure
                                    we get things back on track.</p>

                                <button mat-button mat-raised-button color="primary" class="small-primary"
                                    (click)="doWhiteGlove()">
                                    Sounds Good
                                </button>
                                <button mat-button (click)="showWhiteGloveConfirmation  = false">

                                    Go Back
                                </button>
                            </div>

                        </div>

                        <!-- <div style="padding: 0;margin-left: 20px;margin-right: 20px;">
                            <a class="flex service-request-item" *ngFor="let workOrder of existingWorkOrders"
                                routerLink="/work-order/{{workOrder.id}}">
                                <div>
                                    <div flex>
                                        <div nogrow>
                                            <img src="{{getThumbnailUrl(workOrder)}}" class="item-thumbnail" />
                                        </div>
                                        <div>
                                            <h3>{{workOrder.number}} - {{workOrder.itemName}} </h3>
                                            <p>Opened: {{workOrder.createdDate | date:'shortDate'}}
                                            </p>
                                        </div>
                                        <div nogrow>
                                            <span class="badge">{{workOrder.status}}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>

                        </div>
                        <button style="margin-top:15px;" mat-button (click)="tabIndex=2">This is a new request
                            <i class="material-icons">keyboard_arrow_right</i>
                        </button> -->

                    </mat-tab>

                    <mat-tab label="Questions" [disabled]="!selectedItem" class="">



                        <p class="info" *ngIf="!questionWizardComplete">Please answer these brief questions to prepare
                            the service provider.
                        </p>


                        <div topmargin20 class="more-items-container expand-in"
                            *ngIf="questionWizardComplete && selectedItem && !selectedItem.singleItemJobItem">

                            <div class="data-label" style="margin-bottom: 10px;">Do you need another item serviced in
                                {{selectedItem.category}}?</div>

                            <button mat-button mat-raised-button class="answer-button" (click)="questionsCompleted()">
                                No. Let's Continue
                            </button>
                            <button mat-button mat-raised-button (click)="addAdditionalItem()" class="answer-button">
                                Yes
                            </button>
                        </div>

                        <div id="question-wizard-panel" style="padding: 10px">
                            <app-question-wizard-v2 [showGenericProfilePic]="true" [useOutlineFormField]="true"
                                [isAuthoProcess]="false" *ngIf="selectedItem" #questionWizard
                                [workOrderItemId]="selectedItem.id" [objectInScope]="policySummary" [objectTags]="tags"
                                userType="Customer" (isCompleteChange)="quesionWizardCompleteChange($event)"
                                [(isComplete)]="questionWizardComplete">
                            </app-question-wizard-v2>
                        </div>
                    </mat-tab>

                    <mat-tab label="Photos">

                        <div class="info">
                            Upload any photos that may help your service pro diagnose your item.
                        </div>

                        <div topmargin30 style="">
                            <div class="attachments-container">
                                <div *ngFor="let attachment of attachments" class="attachment-item">
                                    <img [src]="attachment.base64">
                                    <div style="overflow: hidden; ">
                                        <div style="overflow: hidden; text-overflow: ellipsis">
                                            {{attachment.name}}</div>
                                    </div>
                                    <button mat-button class="remove-button" (click)="removeAttachment(attachment)">
                                        <i class="material-icons">close</i>
                                    </button>
                                </div>

                                <button style="border-radius: 12px;min-height: 170px;" (click)="showFileUpload()"
                                    mat-raised-button mat-button>
                                    <i class="material-icons"
                                        style="color: rgba(0,0,0,.6) !important; font-size: 64px;">add_photo_alternate</i><br>
                                    <span *ngIf="hasNativeCamera">Add From Camera</span>
                                    <span *ngIf="!hasNativeCamera">Add Photo</span>
                                </button>
                            </div>
                        </div>

                        <div class="top-margin-30" class="center">


                            <button topmargin20 *ngIf="hasNativeCamera" style="color: rgba(0,0,0,.6) !important"
                                (click)="showFromLibrary()" mat-raised-button mat-button mobile-block large>
                                <i class="material-icons" style="color: rgba(0,0,0,.6) !important">photo_library</i> Add
                                From
                                Library</button>
                        </div>
                        <div style="text-align: center" topmargin20 *ngIf="!attachments || attachments.length === 0">
                            -OR-</div>

                        <div topmargin20 class="center" *ngIf="!attachments || attachments.length === 0">
                            <button class="top-margin-20" (click)="goToServiceFees()" mat-button mobile-block>
                                Skip
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>

                        <input type="file" style="display: none" name="fileToUpload1"
                            (change)="handleFileUpload($event)" accept="image/*" id="fileToUpload1" />

                    </mat-tab>

                    <mat-tab label="Service Fee" [disabled]="!questionWizardComplete">

                        <ng-container *ngIf="!selectedPrepaidServiceFee">
                            <div *ngIf="policySummary" class="info">A service fee payment of
                                {{serviceFeeBalance | currency}} is due now.

                                <span *ngIf="appliedCreditAmount > 0">We have applied a credit of
                                    {{appliedCreditAmount| currency}} towards your service fee payment.
                                    Your remaining credit balance is {{creditBalance - appliedCreditAmount | currency}}.
                                </span>
                                <span *ngIf="serviceFeeBalance > 0"> You
                                    can pay with Visa,
                                    MasterCard, Discover and American Express.</span>
                                <span *ngIf="allPrepaidServiceFees && allPrepaidServiceFees.length > 0"> Or you can
                                    redeem a pre-paid service fee.<br><br>
                                    <button mat-button mat-raised-button
                                        (click)="selectedPrepaidServiceFee = allPrepaidServiceFees[0]">Redeem
                                        Now</button>
                                </span>
                            </div>

                            <div flex topmargin40>
                                <div>

                                    <div class="service-fee-messages">
                                        <div *ngFor="let message of serviceFeeMessages"
                                            [style.background-color]="message.messageColor">
                                            <mat-icon [style.color]="message.messageColor"
                                                [style.border-color]="message.messageColor">{{message.messageIcon}}
                                            </mat-icon>
                                            {{message.message}}
                                        </div>
                                    </div>
                                </div>
                                <div nogrow>
                                    <div style="margin-bottom: 20px">


                                        <div style="text-align: right">
                                            <div id="amountDueContainer">
                                                <div class="data-label">Due Now</div>
                                                <h1 *ngIf="policySummary" class="big-data"
                                                    style="margin-top: 5px; color: rgba(0,0,0,.6) !important">
                                                    {{serviceFeeBalance | currency }}</h1>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div *ngIf="isAgent && !isSellersCoverage" class="center">
                                <button mat-button mat-raised-button color="primary" (click)="doPayAfterService()">
                                    Send Me An Invoice
                                </button>
                                <div class="line-separators">Or</div>

                                <p class="info">
                                    You can pay by credit card today
                                </p>
                            </div>


                            <app-payment-method-entry *ngIf="policySummary && serviceFeeBalance > 0" [canDelete]="false"
                                [customerId]="customerId" paymentDescription="Service Fee Payment"
                                [allowOneTimeUse]="true" [paymentAmount]="serviceFeeBalance"
                                [(selectedCard)]="selectedCard" (selectedCardChange)="paymentValid($event)">
                            </app-payment-method-entry>
                        </ng-container>


                        <ng-container *ngIf="selectedPrepaidServiceFee">
                            <div class="center">
                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/redeem.png"><br>
                                <h2 class="sub-heading">Great News!</h2>
                                <p topmargin20>
                                    You have a pre-paid service fee available. Normally there is a payment of
                                    {{serviceFeeBalance | currency }} required.
                                    But you can submit this request for absolutely nothing.
                                </p>
                                <div topmargin30>
                                    <button mat-button mat-raised-button (click)="removePrepaidServiceFee()">Save It For
                                        Later</button>

                                </div>
                            </div>
                        </ng-container>



                    </mat-tab>

                    <mat-tab label="Contact Information" *ngIf="policySummary"
                        [disabled]="!questionWizardComplete || !creditCardValid">

                        <p class="info">Let us know how you'd like our service provider to reach you.</p>


                        <div>
                            <my-contact-selection [(contactValid)]="contactValid" [customerId]="policySummary.holder.id"
                                [contact]="contact">
                            </my-contact-selection>

                            <div class="info" topmargin40>How would you prefer to be contacted?</div>
                            <button mat-button class="toggle-button offset"
                                (click)="workOrder.preferredContactMethod = 'Phone'"
                                [class.selected]="workOrder.preferredContactMethod === 'Phone'">
                                <mat-icon>phone</mat-icon><br>
                                Phone
                            </button>

                            <button mat-button class="toggle-button offset"
                                (click)="workOrder.preferredContactMethod = 'SMS'"
                                [class.selected]="workOrder.preferredContactMethod === 'SMS'">
                                <mat-icon>sms</mat-icon><br>
                                SMS Text
                            </button>

                            <mat-form-field topmargin40 appearance="outline">
                                <mat-label>Additional Notes</mat-label>
                                <textarea matInput rows="5" [(ngModel)]="workOrder.additionalNotes"
                                    placeholder="Ex. Gate code, cross streets, etc."></textarea>
                            </mat-form-field>
                        </div>

                    </mat-tab>

                    <mat-tab label="Timeslot Selection">

                        <div class="padding">

                            <p class="info">Select your top 3 preferred appointment time slots. We will make every
                                effort to
                                match you with a service pro
                                who can come on your schedule.
                            </p>


                            <div topmargin20 *ngIf="firstItem">
                                <app-appointment-selection [timeSlots]="timeSlots"
                                    [(selectedTimeSlots)]="selectedTimeSlots"
                                    [timeSlotDayBuffer]="firstItem.item.timeSlotDayBuffer">
                                </app-appointment-selection>
                            </div>


                            <p class="info" topmargin30>
                                Don't see a time that will work for you? We can have the service pro call you to
                                schedule a
                                mutually
                                convenient time.
                            </p>
                            <button mat-button block (click)="callMe()">
                                <mat-icon>phone</mat-icon> Call Me To Schedule
                            </button>
                        </div>
                    </mat-tab>

                    <mat-tab label="Review & Submit" [disabled]="!questionWizardComplete || !creditCardValid">


                        <p class="info">Please take a moment to review the request and press the 'Submit' button below.
                        </p>

                        <div class="summary-container">
                            <div>
                                <div class="item-picture-container">
                                    <div *ngFor="let item of lineItems">
                                        <img src="{{baseUrl}}WorkOrderItem/{{item.itemId}}/Photo">
                                    </div>
                                </div>
                                <div aflex class="job-line-item-review" *ngFor="let item of lineItems">

                                    <div class="itemNameContainer">{{item.itemName}}</div>
                                    <div nogrow>
                                        <button mat-button class="remove-item"
                                            (click)="removeWorkOrderLine(item, $event)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="center" topmargin10>
                                    <button mat-button (click)="addAdditionalItem(false)">
                                        <mat-icon>add</mat-icon> Add {{categoryName}}
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div class="review-icon">
                                    <app-lottie-player src="https://lottie.host/c4e6951d-a923-46cf-8f24-d78e4cbafe6c/fJK2j0aA6O.json" [intermission]="11000" *ngIf="tabIndex === 7" height="125px"></app-lottie-player>
                                </div>
                                <div aflex>
                                    <div class="data-label">Photos</div>
                                    <div nogrow>
                                        <button mat-button (click)="tabIndex = 3">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div class="summary-photos-container">

                                    <img *ngFor="let attachment of attachments" [src]="attachment.base64">
                                </div>



                            </div>
                            <div>
                                <div>
                                    <div class="review-icon">
                                        <app-lottie-player src="https://lottie.host/2471b8cd-7e99-42fc-87ae-3d5e1d99565c/qh0CD5fIS1.json"  *ngIf="tabIndex === 7" height="125px" [intermission]="8000"></app-lottie-player>
                                    </div>
                                    <div aflex>
                                        <div class="data-label">Service Fee Due Now</div>
                                        <div nogrow>
                                            <button mat-button (click)="tabIndex = 4">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div>

                                        <h1 *ngIf="policySummary" class="big-data"
                                            style="margin-top: 5px; color: rgba(0,0,0,.6) !important">
                                            {{serviceFeeBalance | currency}}</h1>
                                    </div>

                                    <div *ngIf="selectedCard" class="card-brand">
                                        <img *ngIf="selectedCard.brand == 'Visa'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png">
                                        <img *ngIf="selectedCard.brand == 'Discover'"
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png">
                                        <img *ngIf="selectedCard.brand == 'American Express'"
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png">
                                        <img *ngIf="selectedCard.brand == 'MasterCard'"
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png">

                                        xxxx-{{selectedCard.last4}}
                                    </div>
                                    <div *ngIf="sendServiceFeeInvoice">
                                        We'll send you an invoice
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="review-icon">
                                    <app-lottie-player src="https://lottie.host/762d03ce-58ae-4e79-a55a-d38e128912c7/3ACQZ8ekNw.json"  *ngIf="tabIndex === 7" height="125px" [intermission]="9000"></app-lottie-player>
                                </div>
                                <div aflex>
                                    <div class="data-label">Contact:</div>
                                    <div nogrow>
                                        <button mat-button (click)="tabIndex = 5">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div class="contact-information" *ngIf="contact">
                                    <div>
                                        {{contact.name}}
                                        <br>
                                        <i class="material-icons">phone</i> {{contact.homeNumber | formatPhoneNumber}}
                                        <br>
                                        <i class="material-icons">email</i> {{contact.email}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedTimeSlots && selectedTimeSlots.length">
                                <div class="review-icon">
                                    <app-lottie-player src="https://lottie.host/955d7d73-2512-4379-ac3d-11fe0b5b6f9a/FL7fkkemsA.json"  *ngIf="tabIndex === 7" height="125px" [intermission]="7500"></app-lottie-player>
                                </div>
                                <div aflex>
                                    <div>
                                        <div>
                                            <div class="data-label">Preferred Times:</div>

                                        </div>
                                    </div>
                                    <div nogrow>
                                        <button mat-button (click)="tabIndex = 6">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <ol>
                                    <li *ngFor="let item of selectedTimeSlots"><span
                                            *ngIf="item">{{item.summary}}</span></li>
                                </ol>

                            </div>
                        </div>

                        <div style="padding-left: 10px;padding-right: 10px;">



                            <div *ngFor="let item of lineItems">
                                <h3 style="color: rgba(0, 0, 0, .3) !important" class="coverage-header">
                                    {{item.itemName}}
                                    Coverage Terms
                                    &amp;
                                    Conditions</h3>

                                <div class="desktop-columns no-border">


                                    <p class="data-label">Covered</p>
                                    <p class="coverage-language" [innerHTML]="item.planDetails.coveredLanguage"></p>
                                    <p class="data-label" *ngIf="item.planDetails.notCoveredLanguage">Not Covered</p>
                                    <p class="coverage-language" *ngIf="item.planDetails.notCoveredLanguage"
                                        [innerHTML]="item.planDetails.notCoveredLanguage">
                                    </p>
                                    <p class="data-label" *ngIf="item.planDetails.limitsLanguage">Limits</p>
                                    <p class="coverage-language" *ngIf="item.planDetails.limitsLanguage"
                                        [innerHTML]="item.planDetails.limitsLanguage"></p>
                                </div>
                            </div>
                            <mat-checkbox id="accept-checkbox" class="wrap" [(ngModel)]="acceptTermsAndConditions">I
                                have read,
                                understood and accept the terms specified</mat-checkbox>
                            <button mat-button mat-raised-button color="primary"
                                [disabled]="!canSubmitClaim || submittingWorkOrder" (click)="submitWorkOrder()"
                                mobile-block>
                                <mat-spinner class="thirty" *ngIf="submittingWorkOrder"></mat-spinner>
                                Submit
                            </button>
                        </div>

                    </mat-tab>
                    <mat-tab>
                        <div class="center">

                            <div style="height: 500px;cursor: pointer;" routerLink="/maintenance-service">
                                <lottie-player src="https://lottie.host/4a6273f2-0d8d-4af5-abac-cebef74d42ea/go6berBQmr.json"
                                    background="transparent" speed="1" autoplay>
                                </lottie-player>
                            </div>

                            <div *ngIf="isLoggedIn && newWorkOrderId" topmargin10 class="fade-in-up">

                                <a mat-button mat-raised-button color="primary" large
                                    routerLink="/work-order/{{newWorkOrderId}}">Job Details</a>

                            </div>

                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div *ngIf="tabIndex > 0 && planDetails && tabIndex < lastStepIndex" id="right-pane" nogrow appMinAutoHeight>

        <div *ngIf="planDetails" class="no-border" style="margin-bottom: 100px;">


            <h1 class="sub-heading">{{planDetails.title}}</h1>

            <div class="data-label" topmargin20>Covered</div>
            <div [innerHTML]="planDetails.coveredLanguage"></div>

            <div class="data-label" topmargin20>Not Covered</div>
            <div [innerHTML]="planDetails.notCoveredLanguage"></div>

            <div class="data-label" topmargin20>Limits</div>
            <div [innerHTML]="planDetails.limitsLanguage"></div>
        </div>

    </div>
</div>

<app-service-request-summary [(selectedIndex)]="tabIndex" *ngIf="tabIndex < 8" [selectedTimeSlots]="selectedTimeSlots"
    [selectedCard]="selectedCard" [totalSteps]="totalSteps" [policySummary]="policySummary" [lineItems]="lineItems">
</app-service-request-summary>