<div class="map-measure-container">
    <div matTooltip="Click points on the map to outline the area of your lawn" matTooltipPosition="below"
        #mapTooltip="matTooltip"></div>
    <div #mapWrapper id="map"></div>
    <div class="padding">
        <button mat-button class="toggle-button" (click)="drawingToolOn()" matTooltipPosition="right"
            #drawToolTip="matTooltip" matTooltip="You can toggle the drawing mode on and off using this button"
            [class.selected]="drawingMode"><mat-icon style="margin-right: 0;">square_foot</mat-icon></button>

        <button mat-button class="toggle-button" (click)="drawingToolOff()" matTooltipPosition="right"
            matTooltip="Click here to move and pan the map" #panTooltip="matTooltip" [class.selected]="!drawingMode">
            <mat-icon style="margin-right: 0">pan_tool</mat-icon>
        </button>
        <div topmargin20>
            @for(polygon of editablePolygons; track editablePolygons; let i = $index) {
            @if (editablePolygons.length > 0) {


            <div aflex class="poly-container">
                <p (click)="highlightPolygon(i)" style="cursor: pointer"
                    [class.highlighted]="highlightedPolygonIndex === i">Area {{i+1}}: {{polygon.area | number: '1.0-0'}}
                    SQFT</p>
                <button nogrow mat-button (click)="deletePolygon(polygon)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>


            }
            }
        </div>

        <h1 class="big-data">{{totalArea | number: '1.0-0'}}</h1>
        <div class="data-label">Total SQFT</div>
        <div class="center" topmargin20>
            <button mat-button mat-raised-button color="primary" 
                matTooltip="Once you've drawn out the area(s) of lawn, click the save button to update your lawn square footage."
                #saveToolTip="matTooltip" (click)="updateSQFT()">Save</button>

        </div>
    </div>
</div>