import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCustomerPropertyServiceAppointmentSummary, MaintenanceServiceRateAppointmentArgs } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgxStarRatingModule } from '@cogent/client/shared/components/misc/ngx-star-rating/ngx-star-rating.module';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';

@Component({
    selector: 'app-maint-services-rate-service',
    templateUrl: './maint-services-rate-service.component.html',
    styleUrls: ['./maint-services-rate-service.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        NgxStarRatingModule,
        CheckCircleAnimatedComponent
    ]
})
export class MaintServicesRateServiceComponent implements OnInit {

    saving = false;
    id: string;
    selectedIndex = 0;
    images: any[];
    summary: MaintenanceServiceCustomerPropertyServiceAppointmentSummary;
    isLegacy = true;
    args = new MaintenanceServiceRateAppointmentArgs();
    constructor(private maintApi: MaintenanceServiceApiService,
        private api: ApiService,
        private activatedRoute: ActivatedRoute) { }

    get canSave() {
        if(!this.args.rating) {
            return false;
        }

        if(this.saving) {
            return false;
        }

        return true;
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            this.id = params.id;

            this.maintApi.getMainServiceAppointmentSummaryNoAuth(id).then(summary => {
                this.summary = summary;
            });

            this.maintApi.getAppointmentImagesNoAuth(id).then(images=> {
                this.images = images.filter(i=>i.name && i.name.indexOf('thumb_') > -1);
            });
        });
    }

    getPictureUrl(img: any) {
        return `${ApiService.endPointNode}scheduled-maintenance/photo/${img.path.replaceAll('/','--')}--${img.name}`;
    }

    getFullUrl(img:any) {
        let url = `${ApiService.endPointNode}scheduled-maintenance/photo/${img.path.replaceAll('/','--')}--${img.name}`;
        url = url.replace('thumb_', '');

        return url;
    }

    async save() {
        this.saving = true;

        await this.maintApi.rateAppointment(this.id, this.args);
        this.saving = false;
        this.selectedIndex = 1;
    }

}
