import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { UpdatePolicyCoverageArgs } from '@cogent/shared/models/policies/update-policy-coverage-args.model';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { PaymentMethodEntryModule} from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { PaymentMethodEntryComponent } from '../../../accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';



class UpgradeItem {
    name: string;
    shown = false;
}

@Component({
    selector: 'app-upsell-coverage',
    templateUrl: './upsell-coverage.component.html',
    styleUrls: ['./upsell-coverage.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        DisplayTotalModule,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        CheckCircleAnimatedComponent,
        MatIconModule,
        MatTabsModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDialogModule
    ]
})
export class UpsellCoverageComponent implements OnInit {

    animationComplete = false;
    selectedCard: StripeCard;
    policySummary: PolicySummary;
    upgrading = false;
    upgradeAmount = 0;

    upgradeItems: UpgradeItem[] = [
        { name: 'One Pre-paid Service Call ($65 value)', shown: true },
        { name: 'Sprinkler System & Timer', shown: true },
        { name: 'Kitchen Refrigerator', shown: true },
        { name: 'HVAC Incompatibility Coverage', shown: true },

    ];

    selectedIndex = 0;

    constructor(public dialogRef: MatDialogRef<UpsellCoverageComponent>,
        private planApi: PlanApiService,
        private policyApi: PolicyApiService,
        private dialogService: DialogsService,
        @Inject(MAT_DIALOG_DATA) public data: PolicySummary) {
        this.policySummary = data;

        this.planApi.getPlanOfferingByPlanId(this.policySummary.upgradeSuggestionPlanId,
            this.policySummary.coverageType as any,
            this.policySummary.propertyAddress.dwellingType as any,
            this.policySummary.propertyAddress.postalCode,
            this.policySummary.propertyAddress.squareFeet,
            new Date().getFullYear() - this.policySummary.propertyAddress.yearBuilt,
            null,
            this.policySummary.propertyAddress.latitude,
            this.policySummary.propertyAddress.longitude).then(planOffering => {
                this.upgradeAmount = planOffering.price - this.policySummary.basePrice;
            });
    }

    ngOnInit(): void {
        setTimeout(() => {

            this.animationComplete = true;
        }, 2000);

        for (let i = 0; i < this.upgradeItems.length; i++) {
            this.showUpgradeItem(this.upgradeItems[i], i);
        }
    }

    get netAmount() {
        return this.upgradeAmount - this.policySummary.serviceFeeAmount;
    }

    showUpgradeItem(upgradeItem: UpgradeItem, index: number) {
        setTimeout(() => {
            upgradeItem.shown = true;
        }, index * 500);
    }

    get canUpgrade() {
        return this.selectedCard && !this.upgrading;
    }

    async upgrade() {
        this.upgrading = true;

        const upgradeArgs = new UpdatePolicyCoverageArgs();
        upgradeArgs.planId = this.policySummary.upgradeSuggestionPlanId;
        upgradeArgs.stripeCardId = this.selectedCard.id;
        upgradeArgs.stripeCustomerId = this.selectedCard.customer;
        upgradeArgs.upgradePaymentAmount = this.upgradeAmount;
        upgradeArgs.policyId = this.policySummary.id;

        const result = await this.policyApi.savePortalPolicyCoverage(upgradeArgs)

        if (result && result.success) {
            this.selectedIndex = 2;
            this.upgrading = false;
            setTimeout(() => {
                this.dialogRef.close(true);
            }, 4000);
        } else {
            this.dialogService.alert('Not Processed', `Sorry, we couldn\'t process your payment. Here is the message we received:<br>result.message`);
            this.upgrading = false;
        }

    }

}
