
<svg id="Layer_1" data-name="Layer 1" [style.height]="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 270">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
        stroke: #3fa9f5;
        stroke-miterlimit: 10;
        stroke-width: 20px;
      }

      .cls-2 {
        fill: #3fa9f5;
        stroke-width: 0px;
      }
    </style>
  </defs>

  <path class="cls-1" d="m192,10C91.48,10,10,55.82,10,112.35c0,19.58,7.04,36.25,24,51.81l1.42.94,8.98,83.08c1.28,10.4,11.12,15.37,17.71,8.94l50.88-51.27h1c24.28,6.75,49.09,8.85,78,8.85,100.52,0,182-45.82,182-102.35S292.52,10,192,10Z"/>
  <circle class="cls-2" [class.running]="oneRunning" cx="109.5" cy="86.5" r="30.5"/>
  <circle class="cls-2" [class.running]="twoRunning" cx="194" cy="86.5" r="30.5"/>
  <circle class="cls-2" [class.running]="threeRunning" cx="278.5" cy="86.5" r="30.5"/>
</svg>