import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MarketingSource } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class MarketingSourceApi {
    saveMarketingSource(marketingSource: MarketingSource) {
        return this.api.postNode('MarketingSource', marketingSource);
    }
    constructor(
        private api: ApiService,
    ) { }

    getMarketingSources(sourceType: 'promptForAgent' | 'allowManualPick' | 'internalHMS' | 'customerPortalHMS' = null): Promise<MarketingSource[]> {
        const params = {
          orderby: 'name'
        }

        if (sourceType) {
          params[`${sourceType}_eq`] = 'true'
        }

        if (sourceType == 'customerPortalHMS') {
          return this.api.getArrayNode('quote/marketing-sources');
        }

        return this.api.getArrayNode(`MarketingSource`, params, () => new MarketingSource());
    }

    addMarketingSource(marketingSource: MarketingSource) {
        if (!marketingSource.id) {
            marketingSource.id = UtilitiesService.newid();

        }

        return this.api.postSingleNode(`MarketingSource`, marketingSource);
    }

    deleteMarketingSource(marketingSource: MarketingSource) {
        return this.api.deleteNode(`MarketingSource/${marketingSource.id}`);
    }

    undoDeleteMarketingSource(marketingSource: MarketingSource) {
        return this.api.unDeleteNode(`MarketingSource/${marketingSource.id}`);
    }
}
