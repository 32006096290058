import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        MatFormFieldModule,
        MatProgressSpinnerModule
    ]
})
export class ChangePasswordComponent implements OnInit {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    changingPassword = false;

    constructor(private entityApi: EntityApiService,
        private dialogService: DialogsService,
        public dialogRef: MatDialogRef<ChangePasswordComponent>) { }

    ngOnInit() {
    }

    async changePassword() {

        this.changingPassword = true;
        const results = await this.entityApi.changeLocalLoginPassword(this.oldPassword, this.newPassword);

        this.changingPassword = false;

        if (results && !results.success) {
            this.dialogService.alert('Password Not Changed', `Sorry, we could not change your password.<br>${results.message}`);
        } else {
            this.dialogRef.close(true);
        }
    }


    get canChangePassword() {
        return this.oldPassword && this.newPassword && this.newPassword === this.newPasswordConfirm;
    }

}
