<div style="position: relative;">
    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <div class="v-scroll-container">
                <div class="void-stamp" *ngIf="appointment.cancellationDate">
                    <h1>Canceled</h1>
                    {{appointment.cancellationDate | date: 'shortDate'}}
                </div>
                <div aflex>
                    <h2 class="sub-heading">View Appointment</h2>
                    <div nogrow>
                        <button mat-button mat-dialog-close>
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>

                <div flex>
                    <mat-card flex1>
                        <h2 class="sub-heading">Service Pro</h2>
                        <div class="center">
                            <img [src]="contractorUrl" style="height: 50px;border-radius: 50%;width: 50px">
                        </div>
                        <div>{{appointment.contractorName}}</div>
                        <div>
                            {{appointment.contractorEmail}}
                        </div>
                        <div>
                            {{appointment.contractorNumber}}
                        </div>
                    </mat-card>
                    <mat-card flex1>
                        <h2 class="sub-heading">Service Information</h2>

                        <div class="center">
                            <img [src]="serviceUrl" style="height: 50px; border-radius: 50%;width: 50px">
                        </div>
                        <div class="two-column-grid">
                            <div class="data-label">Service</div>
                            <div>{{appointment.maintenanceServiceName}}</div>
                            <div class="data-label">Date</div>
                            <div aflex>
                                {{appointment.scheduledDate | date: 'shortDate'}}
                            </div>
                            <div class="data-label">Frequency</div>
                            <div>{{appointment.frequency}}</div>
                        </div>
                    </mat-card>
                </div>
                <div flex topmargin20>
                    <mat-card flex1>
                        <h2 class="sub-heading">Outcome</h2>
                        <div class="two-column-grid" *ngIf="appointment.completedDate">
                            <div class="data-label">Completed Date</div>
                            <div>{{appointment.completedDate | date: 'shortDate'}}</div>
                            <div class="data-label">Service Pro Comments:</div>
                            <div>
                                {{appointment.contractorComments}}
                            </div>
                            <div class="data-label">Rating</div>
                            <div>
                                <div class="star-ratings-sprite-sm">
                                    <span id="rating" [style.width]="(appointment.serviceRating / 5 * 100) + '%'"
                                        class="star-ratings-sprite-rating-sm"></span>
                                </div>
                            </div>
                            <div class="data-label">Customer Comments</div>
                            <div>
                                {{appointment.comments}}
                            </div>
                        </div>

                        <div *ngIf="!appointment.completedDate" class="center" topmarmgin30>

                            <mat-icon class="pending-hourglass">hourglass_empty</mat-icon><br>
                            <p class="info" style="margin-bottom: 0;padding-bottom: 0">
                                Appointment Not Complete</p>
                        </div>

                    </mat-card>

                    <mat-card flex1>
                        <h2 class="sub-heading">Appointment</h2>
                        <!-- <div class="data-label">Visit Price</div>
                        <h1 class="big-data">
                            {{appointment.price | currency}}
                        </h1> -->
                        <div class="center" topmargin30>
                            <app-calendar-day-viewer [date]="appointment.scheduledDate">
                            </app-calendar-day-viewer>
                        </div>
                    </mat-card>
                </div>

                <div mat-dialog-actions style="padding-bottom: 14px;">
                    <button mat-button mat-raised-button
                        *ngIf="!appointment.completedDate && !appointment.cancellationDate"
                        (click)="cancelAppointment()" [disabled]="cancelling">
                        <mat-spinner class="thirty" *ngIf="cancelling"></mat-spinner>
                        <mat-icon>cancel</mat-icon> Cancel Appointment
                    </button>

                    <button mat-button mat-raised-button (click)="uncancel()"
                        *ngIf="appointment.cancellationDate && appointment.canCreditOnCancellation"
                        [disabled]="cancelling">
                        <mat-spinner *ngIf="cancelling" class="thirty"></mat-spinner>
                        Uncancel
                    </button>


                </div>
            </div>
        </mat-tab>
        <mat-tab label="Second">
            <div aflex>
                <div>
                    <h2 class="sub-heading">Cancel Appointment</h2>
                </div>
                <div nogrow>

                    <button mat-button mat-dialog-close>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>

            <div *ngIf="appointment.canCreditOnCancellation">

                <div class="data-label">Credit Amount</div>
                <h1 class="big-data">{{appointment.price | currency}}</h1>

                <p>Good news, this cancellation is eligible for a refund. A credit of {{appointment.price | currency}}
                    will apply to your next payment.</p>

                <p>We'll contact your service pro to let them know to skip this trip and catch you the next time.</p>

                <div class="center" topmargin30>
                    <button mat-button mat-raised-button color="primary" class="cancellation-button"
                        [disabled]="cancelling" (click)="cancelAndCredit()">
                        <mat-spinner *ngIf="cancelling" class="thirty"></mat-spinner> Cancel
                    </button>
                </div>
            </div>

            <div *ngIf="!appointment.canCreditOnCancellation">
                <p>We'll contact your service pro to let them know to skip this trip and catch you the next time.</p>
                <div class="center">
                    <button mat-button large mat-raised-button color="primary" class="cancellation-button"
                        (click)="cancelNoCredit()" [disabled]="cancelling">
                        <mat-spinner *ngIf="cancelling" class="thirty"></mat-spinner> Cancel
                    </button>
                </div>

            </div>




            <div aflex topmargin40>
                <div>
                    <button mat-button (click)="selectedIndex = 0">
                        <mat-icon>chevron_left</mat-icon> Back
                    </button>
                </div>
                <div nogrow>

                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</div>